import React from 'react';
import EditPO from '../../components/Purchase Orders Internal/Edit PO/EditPO';
import Helper from '../../inc/Helper';
import Gui_id_list from '../../inc/Gui_id_list';
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';
import Alert from '../../components/inc/Alert';
import MasterComonent from '../../components/Backend/MasterComonent';
import { useLocation } from 'react-router-dom';

const PoEditPage = ({security}) => {
    const location = useLocation(); 
    const urlParams = new URLSearchParams(location?.search);

    let commonUserDocumentAccess = urlParams?.get('common_user_access') && location?.state?.prevRoutePath === '/dashboard' ? true : false; 
    let POFormVisible = security?.canView(Gui_id_list.procure.purchase_orders.purchase_orders_edit_screen) || commonUserDocumentAccess;
 
    if (!POFormVisible) {
        return (
            <MasterComonent>
                <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
            </MasterComonent>
        )
    };
    return (
        <EditPO
            security={security}
            commonUserDocumentAccess={commonUserDocumentAccess}
            title={Helper.getLabel(null, 'edit_po', "Edit Purchase Order")}
            backUrl={location?.state?.isPrevPath || location?.state?.prevRoutePath === '/dashboard' ? -1 :'/purchase-order/all'}
            versionUrl='/purchase-order/version'
            historyUrl='/purchase-order/history'
            logsUrl='/purchase-order/logs'
            goodReceivingUrl='/purchase-order/good-receiving/notes'
            returnOrderUrl='/purchase-order/return-order/all'
            amendmentRequestUrl='/purchase-order/amendment-request/all'
            hideStartDate={true}
            hideEndDate={true}
            hideBillingRule={true}
            source_id={null}
        />
    );
};

const SecurityOptions = {
    gui_id: Gui_id_list.procure.purchase_orders.purchase_orders_edit_screen
};

export default ApplySecurityRoles(PoEditPage, SecurityOptions);