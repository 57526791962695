import React,{useEffect,useState} from 'react';

import TimeLapse from "../../../Widgets/Time lapse/TimeLapse";

import { CalculateDateDelta } from "./CalculateDateDelta";

const Timer = (props) => {
 const [dateDelta, setDateDelta] = useState(null)
 const [title, setTitle] = useState("Remaining Time");
 let endDateStr = props.rfqData?.expiry_datetime
 let startDateStr = props.rfqData?.auction_start_date_time
 let auctionEndDate = props.rfqData?.auction_end_date_time
  useEffect(() => {
    if(props?.rfqData?.RFQ_type===2){
      return
    }
    const interval = setInterval(() => {
      const newDateDelta = CalculateDateDelta(endDateStr)

      //for check bid is expiry or not
      const dateExpiryCheck =
        newDateDelta &&
        newDateDelta.days === 0 &&
        newDateDelta.hours === 0 &&
        newDateDelta.minutes === 0 &&
        newDateDelta.seconds === 0

      props.setAllFieldsAreZero(dateExpiryCheck)
      setDateDelta(newDateDelta)
    }, 1000)
    return () => clearInterval(interval)
  }, [endDateStr])
  
  useEffect(() => {
    if(props?.rfqData?.RFQ_type!==2){
      return
    }
    let activeEndDate = startDateStr; 
    let startExpired = false; 
    const interval = setInterval(() => {
      const newDateDelta = CalculateDateDelta(activeEndDate);
  
      const isExpired =
        newDateDelta &&
        newDateDelta.days === 0 &&
        newDateDelta.hours === 0 &&
        newDateDelta.minutes === 0 &&
        newDateDelta.seconds === 0;
    
      if (!startExpired && isExpired) {
        startExpired = true;
        activeEndDate = auctionEndDate;
        props.setIsAuctionBidEditable(false); 
        setTitle("Remaining Time to End");
      } else if (!startExpired) {
        props.setIsAuctionBidEditable(true); 
        setTitle("Remaining Time to Start");
      } else if (startExpired && isExpired) {
        props.setIsAuctionBidEditable(true); 
        props.setAllFieldsAreZero(true)
      }

      // props.setAllFieldsAreZero(isExpired && startExpired); 
      setDateDelta(newDateDelta);
    }, 1000);
  
    return () => clearInterval(interval);
  }, [startDateStr, auctionEndDate]);
  
  return (
    <div>
      <TimeLapse
          days={dateDelta?.days}
          minutes={dateDelta?.minutes}
          hours={dateDelta?.hours}
          seconds={dateDelta?.seconds}
          title={title}
        />
    </div>
  );
}

export default Timer;
