import React, { Fragment, useEffect, useState } from 'react';
import $ from 'jquery'; 
import { connect } from "react-redux";
import axios from 'axios';    
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import FormValidator from '../../../inc/FormValidator';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import Helper from '../../../inc/Helper'; 
import Popup from '../../Popup/Popup';
import Collapse from '../../inc/Collapse';
import Button from '../../inc/Button';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';

let cancelTokenSource = axios.CancelToken.source();
const CreateTeamHeaderModal = ({setShowCreateTeamHeaderModal, isDynamic, language}) => {
    const navigate = useNavigate(); 

    const [teamHeaderData, setTeamHeaderData] = useState({});
 
    const [apiCallBtn, setApiCallBtn] = useState(false);
 
    const [documentType, setDocumentType] = useState([]) 
    
    let teamHeaderValidator;   

    const validationConfigure = () => {
        let teamHeaderFieldConfig = [
            {name:'name', displayName:Helper.getLabel(language,'name','Name'),types:['Required']},  
            {name:'type', displayName:Helper.getLabel(language,'type','Type'),types:['Required']} 
        ];

        if(!teamHeaderValidator) {
            teamHeaderValidator = new FormValidator(teamHeaderFieldConfig, language)
        }; 
    };

    validationConfigure();

    let documentTypeListCalled = false; 

    useEffect(() => { 
        if(isDynamic){
            setTeamHeaderData((prevState) => {
                return {
                    ...prevState,
                    is_dynamic_team: true
                }
            })
        }
        getDocumentTypes(); 
    }, []);
    
    const getDocumentTypes = () => {
        if(documentTypeListCalled) return;

        let api = Api
        documentTypeListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/teamheader_type',{}).then(function(res){
            if(res.data.status==="success"){
                setDocumentType(res?.data?.data?.map((element) => {
                    return {
                        value: element.id,
                        label: element.name
                    }
                }))
            }
        })
    };

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateTeamHeaderModal(false);
    }

    let fieldsArrayGeneral = [
        {className:'col-lg-6', maxLength: 255, disable: apiCallBtn, required: true, label: Helper.getLabel(language,'name',"Name"), inputType: "text", id:'name', name:'name'},
        {className:'col-lg-6', disable: apiCallBtn, label: Helper.getLabel(language,'type',"Type"), required:true, inputType: "dropdown", placeHolder: 'Select type', options: documentType, id:'type', name:'type'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'sme_team',"SME team"), inputType: "checkbox", id:'sme_team', name:'sme_team'},
        {className:'col-lg-4', disable: isDynamic || apiCallBtn, label: Helper.getLabel(language,'dynamic',"Dynamic"), inputType: "checkbox", id:'is_dynamic_team', name:'is_dynamic_team'}, 
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'vendor_notification',"Vendor notification"), inputType: "checkbox", id:'vendor_notification', name:'vendor_notification'}
    ];

    if(isDynamic){
        let dynamicTeamColumns = [
            {className:'col-lg-4', disable: false, label: Helper.getLabel(language,'perform_RFQ_technical_evaluation','Perform RFQ technical evaluation'), inputType: "checkbox", id:'perform_RFQ_technical_evaluation', name:'perform_RFQ_technical_evaluation'}, 
            {className:'col-lg-4', disable: false, label: Helper.getLabel(language,'perform_RFQ_financial_evaluation',"Perform RFQ commercial evaluation"), inputType: "checkbox", id:'perform_RFQ_financial_evaluation', name:'perform_RFQ_financial_evaluation'}, 
            // {className:'col-lg-4', disable: false, label: Helper.getLabel(language,'allowed_to_award',"Allowed to award"), inputType: "checkbox", id:'allowed_to_award', name:'allowed_to_award'}, 
            {className:'col-lg-4', disable: false, label: Helper.getLabel(language,'allowed_to_unsealed_technical_proposal',"Allowed to unsealed technical proposal"), inputType: "checkbox", id:'allowed_to_unsealed_technical_proposal', name:'allowed_to_unsealed_technical_proposal'}, 
            {className:'col-lg-4', disable: false, label: Helper.getLabel(language,'allowed_to_unsealed_commercial_proposal',"Allowed to unsealed commercial proposal"), inputType: "checkbox", id:'allowed_to_unsealed_commercial_proposal', name:'allowed_to_unsealed_commercial_proposal'}, 
            {className:'col-lg-4', disable: false, label: Helper.getLabel(language,'add_buyer',"Add buyer"), inputType: "checkbox", id:'add_buyer', name:'add_buyer'},   
            {className:'col-lg-4', disable: false, label: Helper.getLabel(language,'add_requester',"Add requester"), inputType: "checkbox", id:'add_requester', name:'add_requester'}, 
            {className:'col-lg-4', disable: false, label: Helper.getLabel(language,'add_sme',"Add SME"), inputType: "checkbox", id:'add_sme', name:'add_sme'},         
        ]
        fieldsArrayGeneral = fieldsArrayGeneral.concat(dynamicTeamColumns)
    }

    const executePOSTTeamHeaderAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();

            let payload = {
                name: teamHeaderData.name,
                type: teamHeaderData.type,
                is_dynamic_team: teamHeaderData.is_dynamic_team == 'on' || teamHeaderData.is_dynamic_team === true,
                sme_team: teamHeaderData.sme_team == 'on' || teamHeaderData.sme_team === true,
                vendor_notification: teamHeaderData.vendor_notification == 'on' || teamHeaderData.vendor_notification === true,
                team_lines: []
            }

            if(isDynamic){
                payload = {
                    ...payload,
                    perform_RFQ_technical_evaluation: teamHeaderData?.perform_RFQ_technical_evaluation == 'on' || teamHeaderData?.perform_RFQ_technical_evaluation===true,
                    perform_RFQ_financial_evaluation:teamHeaderData?.perform_RFQ_financial_evaluation == 'on' || teamHeaderData?.perform_RFQ_financial_evaluation === true,
                    // allowed_to_award:teamHeaderData?.allowed_to_award == 'on' || teamHeaderData?.allowed_to_award === true,
                    allowed_to_unsealed_technical_proposal:teamHeaderData?.allowed_to_unsealed_technical_proposal == 'on' || teamHeaderData?.allowed_to_unsealed_technical_proposal === true,
                    allowed_to_unsealed_commercial_proposal:teamHeaderData?.allowed_to_unsealed_commercial_proposal == 'on' || teamHeaderData?.allowed_to_unsealed_commercial_proposal === true,
                    add_buyer:teamHeaderData?.add_buyer == 'on' || teamHeaderData?.add_buyer === true,
                    add_requester:teamHeaderData?.add_requester == 'on' || teamHeaderData?.add_requester === true,
                    add_sme:teamHeaderData?.add_sme == 'on' || teamHeaderData?.add_sme === true
                }
            }
        
            const res = await api.axios().post(Settings.apiUrl + '/teamheader', payload,
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res.data.message); 
                onPopupClose();
                navigate(`/team-header-edit/${res?.data?.teamheader_id}`); 
            };
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        };
    };

    const handleSubmit = () => { 
        if(!teamHeaderValidator.isValid({...teamHeaderData})){
            teamHeaderValidator.displayMessage();
            teamHeaderValidator.reset();
            return;
        };
 
        Helper.createDebouncedAPIFunction( 
            [executePOSTTeamHeaderAPI], 
            setApiCallBtn,
            cancelTokenSource
        )();
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} width="1000px"
                onClose={onPopupClose}
            >
                <>
                    <h5 className="create_rfq_modal__title">Create Team Header</h5>
                    <p className='mb-0 fw-bold'>Disclaimer: Once you create a team header, you can't change the Dynamic field later. Make sure your settings are right before creating the team header.</p>
                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "team_header",
                            "Team Header"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setTeamHeaderData} currentState={teamHeaderData} fieldsArray={fieldsArrayGeneral} />
                            </div>
                        </Form> 
                    </Collapse> 
                </> 

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        className='black-btn-style'
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />
                    <Button
                        isDisable={apiCallBtn}
                        className="solid_btn" 
                        isActive={true}
                        title={Helper.getLabel(language, "create", "Create")} 
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps)(CreateTeamHeaderModal); 