import React from 'react';
import EditPO from '../../components/Purchase Orders Internal/Edit PO/EditPO';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import Gui_id_list from '../../inc/Gui_id_list';
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';
import Alert from '../../components/inc/Alert';
import MasterComonent from '../../components/Backend/MasterComonent';
import { useLocation } from 'react-router-dom';

const ReleaseOrderEditPage = ({security}) => {
    const location = useLocation(); 
    const urlParams = new URLSearchParams(location?.search);

    let commonUserDocumentAccess = urlParams?.get('common_user_access') && location?.state?.prevRoutePath === '/dashboard' ? true : false; 
    let ROFormVisible = security?.canView(Gui_id_list.procure.release_orders.release_orders_edit) || commonUserDocumentAccess;

    if (!ROFormVisible) {
        return (
            <MasterComonent>
                <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
            </MasterComonent>
        )
    }
    return (
        <EditPO
            commonUserDocumentAccess={commonUserDocumentAccess}
            title={Helper.getLabel(null, 'edit_release_order', "Edit Release Order")}
            lineTitle={Helper.getLabel(null, 'release_order_lines', "Release Order Lines")}
            backUrl={location?.state?.isPrevPath || location?.state?.prevRoutePath === '/dashboard' ? -1 : '/release-order/all'}  
            security={security}
            versionUrl='/release-order/version'
            historyUrl='/release-order/history'
            logsUrl='/release-order/logs'
            goodReceivingUrl='/release-order/good-receiving/notes'
            returnOrderUrl='/release-order/return-order/all'
            amendmentRequestUrl='/release-order/amendment-request/all'
            hideStartDate={true}
            hideEndDate={true}
            hideBillingRule={true}
            formType={"release order"}
            type={"2"}
            source_id={Settings.source_id.release_order}
        />
    );
};

const SecurityOptions = {
  gui_id: Gui_id_list.procure.release_orders.release_orders_edit
};

export default ApplySecurityRoles(ReleaseOrderEditPage, SecurityOptions);