import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import Api from "../../../inc/Api";
import FormValidator from "../../../inc/FormValidator";
import Gui_id_list from "../../../inc/Gui_id_list";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import Input from "../../Forms/Input";
import Button from "../../inc/Button";
import Collapse from "../../inc/Collapse";
import SimpleLoading from "../../Loading/SimpleLoading";
import DropDownInput from "../../Forms/Dropdown";
import Popup from "../../Popup/Popup";
import ApplySecurityRoles from "../../SecurityRoles/ApplySecurityRoles";
import Checkbox from "../../Forms/Checkbox";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";
import axios from "axios";
import OverlayLoader from "../../PurchaseRequisition/common/OverlayLoader";

// Universal Component /--
// Master Banks & Banks Accounts Component -- Vendor's Account Association's Inc.
// --- Compiled For VNVendors ---

let cancelTokenSource = axios.CancelToken.source();
const BanksPopup = ({
  language,
  vendorsite_id,
  dataExportable,
  setVendorsGrid,
  onPopupClose,
  reloadBanksList,
  setBanksGridData,
  disableFields=false,
  editData,
  editable,
  reloadBanksAfterAccountCreation,
  gridApi,
  type,
}) => {
  // Receiving data from Component --

  let vendors_bank_popup = {
    bank_id: editData.bank_id,
    name: editData.name,
    account_number: editData.account_number,
    SWIFTno: editData.SWIFTno,
    IBAN: editData.IBAN,
    status: dataExportable ? false : editData.status,
    account_code: editData.account_code,
    currency_id: editData.currency_id,
    uniqueid: editData.uniqueid,
    exist: null,
    bank_name: null,
  };

  // -----

  // Component Wide -- State Management --
  const [createVendorBank, setCreateVendorBank] = useState({
    exist: null,
    bank_name: null,
  });
  const [bankExist, setBankExist] = useState(false);
  const [saveBtnApi, setSaveBtnApi] = useState(false);
  const [banks, setBanks] = useState([]);
  const [currency, setCurrency] = useState([]); 
  // ------

  // Component Renders Function [] loads when component is ready! ---

  useEffect(() => {
    BanksList();
    CurrencyList();
  }, []);

  let validator;

  const validationConfigure = () => {
    let fieldConfig = [
      {
        name: "name",
        displayName: Helper.getLabel(language, "account_name", "Account name"),
        types: ["Required"],
      },
      {
        name: "account_number",
        displayName: Helper.getLabel(
          language,
          "account number",
          "Account number"
        ),
        types: ["Required", "Number"],
      },
      {
        name: "currency_id",
        displayName: Helper.getLabel(language, "currency", "Currency"),
        types: ["Required"],
      },
      {
        name: "SWIFTno",
        displayName: Helper.getLabel(language, "SWIFTno", "SWIFT code"),
      },
      { name: "IBAN", types: ["Required"], displayName: Helper.getLabel(language, "IBAN", "IBAN") },
    ];

    if (!validator) {
      validator = new FormValidator(fieldConfig, language);
    }
  };

  validationConfigure();

  const CurrencyList = () => { 
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + `/currency`, {})
      .then(function (res) {
        if (res.data.status === "success") {
          let UPDATED_STATE = [];
          res.data.data.forEach((element) => {
            UPDATED_STATE.push({
              value: element.currency_id,
              label: `${element.currency_code} - ${element.currency_name}`,
            });
          });
          setCurrency(UPDATED_STATE);
        }
      });
  };

  const BanksList = () => {
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiOrgUrl + `/bank`, {})
      .then(function (res) {
        if (res.data.status === "success") {
          let UPDATED_STATE = [];
          res.data.data.forEach((element) => {
            UPDATED_STATE.push({
              value: element.bank_id,
              label: element.name,
            });
          });
          setBanks(UPDATED_STATE);
        }
      });
  };

  // ----

  // Fields Handlers --

  const BanksHandler = (event) => {
    let Bank = event.target.value;
    setBanksGridData((prevState) => {
      return {
        ...prevState,
        bank_id: parseInt(Bank),
      };
    });
  };

  const BanksNameHandler = (event) => {
    let BankName = event.target.value;
    setCreateVendorBank((prevState) => {
      return {
        ...prevState,
        bank_name: BankName,
      };
    });
  };

  const AccountCodeHandler = (event) => {
    let code = event.target.value;
    setBanksGridData((prevState) => {
      return {
        ...prevState,
        account_code: code,
      };
    });
  };

  const AccountNameHandler = (event) => {
    let account_name = event.target.value;
    setBanksGridData((prevState) => {
      return {
        ...prevState,
        name: account_name,
      };
    });
  };

  const AccountNumberHandler = (event) => {
    let account_num = event.target.value;
    setBanksGridData((prevState) => {
      return {
        ...prevState,
        account_number: account_num,
      };
    });
  };

  const SWFTHandler = (event) => {
    let swft_code = event.target.value;
    setBanksGridData((prevState) => {
      return {
        ...prevState,
        SWIFTno: swft_code,
      };
    });
  };

  const IBANHandler = (event) => {
    let iban = event.target.value;
    setBanksGridData((prevState) => {
      return {
        ...prevState,
        IBAN: iban,
      };
    });
  };

  const DefaultBankHandler = (name, value) => {
    if (value == "on") {
      setBanksGridData((prevState) => {
        return {
          ...prevState,
          status: true,
        };
      });
    } else {
      setBanksGridData((prevState) => {
        return {
          ...prevState,
          status: false,
        };
      });
    }
  };

  const CurrencyHandler = (event) => {
    let Currency = event.target.value;
    setBanksGridData((prevState) => {
      return { ...prevState, currency_id: parseInt(Currency) };
    });
  };

  const ExistBankHandler = (name, value) => {
    if (value == "on") {
      setCreateVendorBank((prevState) => {
        return {
          ...prevState,
          exist: true,
        };
      });
    } else {
      setCreateVendorBank((prevState) => {
        return {
          ...prevState,
          exist: false,
        };
      });
    }
    setBankExist(!bankExist);
  };

  // ---
 
  const onPopupCloseHandler = () => { 
    if (onPopupClose) {
      onPopupClose();
    }
    $("html").removeClass("rs_popup_open");
  };

  // -----

  // Bank Assciation w Banks Accounts API handling --

  const AddbankAssociation = (account) => {
    if (vendorsite_id) {
      let api = Api;
      api.setUserToken();
      api
        .axios()
        .post(Settings.apiVendorUrl + `/vendorbank`, {
          account_id: account,
          vendorsite_id: vendorsite_id,
          is_default:
            vendors_bank_popup.status == true ||
            vendors_bank_popup.status == "yes"
              ? true
              : false,
        })
        .then(function (res) {
          if (res.data.status === "success") {
            Helper.alert(res.data.message);
            setVendorsGrid((prevState) => {
              return {
                ...prevState,
                banksPopup: false,
              };
            });
            setBanksGridData({});
            reloadBanksAfterAccountCreation();
            setTimeout(() => {
              gridApi?.api?.refreshServerSide({ purge: true });
              gridApi?.api?.deselectAll();
            }, 200);
            onPopupCloseHandler()
            // onSavePopupClose()
          }
        })
        .catch((res) => {
          getPopupMessageBasedOnStatus(res);
        });
    }
    return;
  };

  const bankAssociation = (account) => {
    if (!vendorsite_id) {
      return;
    }
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .put(Settings.apiVendorUrl + `/vendorbank/${vendorsite_id}/${account}`, {
        account_id: account,
        vendorsite_id: vendorsite_id,
        is_default:
          vendors_bank_popup.status == true ||
          vendors_bank_popup.status == "yes"
            ? true
            : false,
      })
      .then(function (res) {
        if (res.data.status === "success") {
          Helper.alert(res.data.message);
          setVendorsGrid((prevState) => {
            return {
              ...prevState,
              banksPopup: false,
            };
          });
          setBanksGridData({});
          setTimeout(() => {
            gridApi?.api?.refreshServerSide({ purge: true });
            gridApi?.api?.deselectAll();
          }, 200);
          // onSavePopupClose()
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const BanksPopupModule = () => {
    return (
      <Fragment>
        <div className={`d-flex justify-content-end mb-4`}>
          <Button
           className='blue-btn-style'
            isDisable={saveBtnApi || disableFields}
            title={
              <div>
                <span>
                  {" "}
                  {Helper.getLabel(
                    language,
                    "save_and_close",
                    "Save & Close"
                  )}{" "}
                </span>{" "}
                {saveBtnApi ? (
                  <output
                    className="spinner-border spinner-border-sm ms-1"
                    aria-hidden="true"
                  ></output>
                ) : (
                  ""
                )}
              </div>
            }
            onClick={onSaveHandler}
            isActive={false}
          />
        </div>
        <Collapse
          title={Helper.getLabel(
            language,
            "account_details",
            "Account Details"
          )}
          open={true}
        >
          <div className="row">
            <div className="col-xs-12 col-sm-4">
              {bankExist ? (
                <Input
                  maxLength={80}
                  type="text"
                  required={true}
                  isDisable={disableFields}
                  label={Helper.getLabel(language, "bank_name", "Bank name")}
                  placeholder="Enter bank name"
                  value={createVendorBank.bank_name}
                  onChange={BanksNameHandler}
                />
              ) : (
                <DropDownInput
                  required={true}
                  isDisable={disableFields}
                  label={Helper.getLabel(language, "bank_name", "Bank name")}
                  options={banks}
                  onChange={BanksHandler}
                  value={vendors_bank_popup.bank_id}
                  id="bank_name_id"
                />
              )}
            </div>
            <div className="col-xs-12 col-sm-4">
              <Checkbox
                type="checkbox"
                label={Helper.getLabel(
                  language,
                  "bank_exist",
                  "Bank does not exist?"
                )}
                disableWithoutIcon={disableFields}
                className="is_bank_exist me-2"
                isChecked={createVendorBank.exist}
                onChange={ExistBankHandler}
              />
            </div>
            <div className="col-xs-12 col-sm-4">
              <Checkbox
                label={Helper.getLabel(
                  language,
                  "default_bank",
                  "Default bank"
                )}
                disableWithoutIcon={dataExportable || disableFields}
                type="checkbox"
                className="is_default_bank me-2"
                isChecked={vendors_bank_popup.status}
                onChange={DefaultBankHandler}
              />
            </div>
            <div className="col-xs-12 col-sm-4">
              <Input
                maxLength={80}
                required={true}
                label={Helper.getLabel(
                  language,
                  "account_name",
                  "Account name"
                )}
                disable={disableFields}
                type="text"
                onChange={AccountNameHandler}
                value={vendors_bank_popup.name}
              />
            </div>
            <div className="col-xs-12 col-sm-4">
              <Input
                required={true}
                maxLength={35}
                disable={disableFields}
                label={Helper.getLabel(
                  language,
                  "account_number",
                  "Account number"
                )}
                inputType="number"
                value={vendors_bank_popup.account_number}
                onChange={AccountNumberHandler}
              />
            </div>
            <div className="col-xs-12 col-sm-4">
              <Input
                maxLength={20} 
                disable={disableFields}
                label={Helper.getLabel(
                  language,
                  "account_code",
                  "Account code"
                )}
                type="text"
                value={vendors_bank_popup.account_code}
                onChange={AccountCodeHandler}
              />
            </div>
            <div className="col-xs-12 col-sm-4">
              <Input
                maxLength={35}
                required={true}
                disable={disableFields}
                label={Helper.getLabel(language, "iban", "IBAN")}
                type="text"
                value={vendors_bank_popup.IBAN}
                onChange={IBANHandler}
              />
            </div>
            <div className="col-xs-12 col-sm-4">
              <Input
                maxLength={20}
                disable={disableFields}
                label={Helper.getLabel(language, "swift_code", "SWIFT code")}
                type="text"
                value={vendors_bank_popup.SWIFTno}
                onChange={SWFTHandler}
              />
            </div>
            <div className="col-xs-12 col-sm-4">
              <DropDownInput
                required={true}
                isDisable={disableFields}
                label={Helper.getLabel(language, "curency", "Currency")}
                options={currency}
                onChange={CurrencyHandler}
                value={vendors_bank_popup.currency_id}
                id="currency_id_bank"
              />
            </div>
            <div className="col-xs-12 col-sm-4">
              <div className="row"></div>
            </div>
          </div>
        </Collapse>
        {/* {!checkIfArrayIsEmpty(vendorSitesChip) && type !== "vendor registration" && SitesModule()} */}
      </Fragment>
    );
  };

  // ---

  // API Executions ----

  // ---- Creating API EXECUTE ---
  const CreateVendorBankAccount = async (bank_id = null) => {
    try {
      if (!vendorsite_id) {
        return;
      }

      let api = Api;
      api.setUserToken();

      const res = await api.axios().post(
        Settings.apiOrgUrl + `/bankaccount`,
        {
          bank_id: vendors_bank_popup.bank_id
            ? vendors_bank_popup.bank_id
            : bank_id,
          account_code: vendors_bank_popup.account_code,
          name: vendors_bank_popup.name,
          account_number: vendors_bank_popup.account_number,
          SWIFTno: vendors_bank_popup.SWIFTno,
          IBAN: vendors_bank_popup.IBAN,
          phone: null,
          email: null,
          URL: null,
          status:
            vendors_bank_popup.status == true ||
            vendors_bank_popup.status == "yes"
              ? true
              : false,
          currency_id: vendors_bank_popup.currency_id,
        },
        { cancelToken: cancelTokenSource.token }
      );

      if (res.data.status === "success") {
        AddbankAssociation(res.data.bank_account_id);
        Helper.alert(res.data.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const UpdateVendorBankAccount = async (bank_id = null) => {
    try {
      if (!vendorsite_id) {
        return;
      }

      let api = Api;
      api.setUserToken();

      const res = await api.axios().put(
        Settings.apiOrgUrl + `/bankaccount/${editData.account_id}`,
        {
          bank_id: !bank_id ? vendors_bank_popup.bank_id : bank_id,
          account_code: vendors_bank_popup.account_code,
          name: vendors_bank_popup.name,
          account_number: vendors_bank_popup.account_number,
          SWIFTno: vendors_bank_popup.SWIFTno,
          IBAN: vendors_bank_popup.IBAN,
          phone: null,
          email: null,
          URL: null,
          status:
            vendors_bank_popup.status == true ||
            vendors_bank_popup.status == "yes"
              ? true
              : false,
          currency_id: vendors_bank_popup.currency_id,
        },
        { cancelToken: cancelTokenSource.token }
      );

      if (res.data.status === "success") {
        bankAssociation(editData.account_id);
        Helper.alert(res.data.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const processBankLogic = async () => {
    try {
      let api = Api;
      api.setUserToken();

      if (
        createVendorBank.bank_name &&
        createVendorBank.exist &&
        vendorsite_id
      ) {
        const res = await api.axios().post(
          Settings.apiOrgUrl + `/bank`,
          {
            code: vendors_bank_popup.account_code,
            name: createVendorBank.bank_name,
          },
          { cancelToken: cancelTokenSource.token }
        );

        if (res.data.status === "success") {
          Helper.alert(res.data.message);
          const new_bank_id = res.data.bank_id;

          if (!editData.account_id && vendorsite_id) {
            await CreateVendorBankAccount(new_bank_id);
          }

          if (
            editData.account_id &&
            editable &&
            vendorsite_id &&
            dataExportable === false
          ) {
            await UpdateVendorBankAccount(new_bank_id);
          }
        }
      } else {
        if (!vendorsite_id) {
          if (
            createVendorBank.bank_name &&
            createVendorBank.exist &&
            !vendorsite_id
          ) {
            const res = await api.axios().post(Settings.apiOrgUrl + `/bank`, {
              code: vendors_bank_popup.account_code,
              name: createVendorBank.bank_name,
            });

            if (res.data.status === "success") {
              const bankID = res.data.bank_id;

              if (editable && dataExportable === true) {
                const newData = Helper.getDataFromGrid(gridApi);
                const newUpdatedData = newData.map((element) => {
                  if (vendors_bank_popup.uniqueid == element.uniqueid) {
                    return vendors_bank_popup;
                  }
                  return element;
                });

                setVendorsGrid((prevState) => {
                  return {
                    ...prevState,
                    vendorBanks: newUpdatedData,
                  };
                });

                reloadBanksList();
                setBanksGridData({});
                // onSavePopupClose();
              } else {
                const newData = Helper.getDataFromGrid(gridApi);
                newData.push({
                  bank_id: bankID,
                  name: vendors_bank_popup.name,
                  account_number: vendors_bank_popup.account_number,
                  SWIFTno: vendors_bank_popup.SWIFTno,
                  IBAN: vendors_bank_popup.IBAN,
                  status:
                    vendors_bank_popup.status === true ||
                    vendors_bank_popup.status === "yes"
                      ? true
                      : false,
                  account_code: vendors_bank_popup.account_code,
                  currency_id: vendors_bank_popup.currency_id,
                  uniqueid: vendors_bank_popup.uniqueid,
                });

                setVendorsGrid((prevState) => {
                  return {
                    ...prevState,
                    vendorBanks: newData,
                  };
                });

                // onSavePopupClose();
                reloadBanksList();
                setBanksGridData({});
              }
            }
          } else {
            if (editable && dataExportable === true) {
              const newData = Helper.getDataFromGrid(gridApi);
              const newUpdatedData = newData.map((element) => {
                if (vendors_bank_popup.uniqueid == element.uniqueid) {
                  return vendors_bank_popup;
                }
                return element;
              });

              setVendorsGrid((prevState) => {
                return {
                  ...prevState,
                  vendorBanks: newUpdatedData,
                  banksPopup: false,
                };
              });

              reloadBanksList();
              setBanksGridData({});
              // onSavePopupClose();
            } else {
              const newData = Helper.getDataFromGrid(gridApi);
              vendors_bank_popup.uniqueid = Date.now();
              newData.push(vendors_bank_popup);

              setVendorsGrid((prevState) => {
                return {
                  ...prevState,
                  vendorBanks: newData,
                  banksPopup: false,
                };
              });

              // onSavePopupClose();
              reloadBanksList();
              setBanksGridData({});
            }
          }
        } else {
          if (!editData.account_id && vendorsite_id) {
            await CreateVendorBankAccount();
          }

          if (
            editData.account_id &&
            editable &&
            vendorsite_id &&
            dataExportable === false
          ) {
            await UpdateVendorBankAccount();
          }
        }
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const onSaveHandler = () => {
    let validData = {
      ...vendors_bank_popup,
    };

    if (!vendorsite_id) {
      return;
    }

    if (
      !validator.isValid(validData) ||
      (bankExist && !createVendorBank.bank_name) ||
      (!bankExist && !vendors_bank_popup.bank_id)
    ) {
      if (
        (bankExist && !createVendorBank.bank_name) ||
        (!bankExist && !vendors_bank_popup.bank_id)
      ) {
        Helper.alert("Bank Name field is required", "error");
      }

      validator.displayMessage();
      validator.reset();
      return;
    }

    Helper.createDebouncedAPIFunction(
      [processBankLogic],
      setSaveBtnApi,
      cancelTokenSource
    )(); 
  };

  // -----

  return (  
    <div className="close-popup">  
      { saveBtnApi ? <OverlayLoader isPopupInTab={true} /> : null } 
      <Popup onClose={onPopupCloseHandler} autoOpen={true} width="1350px">  
        {BanksPopupModule()}
      </Popup>
    </div>  
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

const SecurityOptions = {
  gui_id: Gui_id_list.masterData.vendors,
};

export default connect(mapStateToProps)(
  ApplySecurityRoles(BanksPopup, SecurityOptions)
); 