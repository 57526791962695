import React, { useState, useEffect } from 'react';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import FileListItem from './FileListItem';
import { checkIfArrayIsEmpty } from '../../../inc/Validation';

function FileUploaderList(props) {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadFiles();
  }, [props.activeKey, props.attachmenttype_id]);

  const loadFiles = (isLoaderNeeded = true) => {
    const api = Api;
    api.setUserToken();
    const { attachmentsource_integrator, source_id, activeKey } = props;
    if(!attachmentsource_integrator) return
    setIsLoading(isLoaderNeeded);

    let apiUrl
    const type = activeKey === 'internal' ? 0 : 1;

    if (attachmentsource_integrator && source_id) {
      apiUrl = Settings.apiUrl + `/get_attachments/${source_id}/${attachmentsource_integrator}?attachment_for=${type}`;
    }

    if(attachmentsource_integrator && source_id && props.attachmenttype_id){
       apiUrl = Settings.apiUrl + `/get_attachments/${source_id}/${attachmentsource_integrator}?attachment_for=${type}&attachment_type_id=${props.attachmenttype_id}`
    }

    api.axios()
      .get(apiUrl)
      .then((res) => {
        if (res.data.status === 'success') {
          const fetchedFiles = res.data.data;
          setFiles(fetchedFiles.reverse());
          let attachmentState = type===0 ? "isNewInternalAttachments" : "isNewExternalAttachments"
          props.updateNewAttachments(attachmentState,res?.data?.new_attachment_added)
          const attachmentIds = res?.data?.unread_attachments
          if (props.onFilesLoaded) {
            props.onFilesLoaded(fetchedFiles);
          }
          if(fetchedFiles?.length > 0 && !checkIfArrayIsEmpty(res?.data?.unread_attachments)){
              handleUnreadAttachments(attachmentIds)
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const handleUnreadAttachments = (attachmentIds) => {
    const postApiUrl = Settings.apiUrl + '/attachmentacknowledge'
    const payload = {
      attachment_id: attachmentIds,
    }
    let api = Api
    const type = props.activeKey === 'internal' ? 0 : 1;
    let attachmentState = type===0 ? "isNewInternalAttachments" : "isNewExternalAttachments"
    api
      .axios()
      .post(postApiUrl, payload)
      .then(() => {
        props.updateNewAttachments(attachmentState,false)
      })
      .catch((error) => {
      })
  }
  const handleFileDeleted = (deletedAttachmentId) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.attachment_id !== deletedAttachmentId));
    loadFiles(false)
  };
  if (isLoading) {
    return <SimpleLoading />;
  }
  return (
    <div className="uploaded_files_list">
      {files.length !==0? <div className='app_navigation' style={{marginBottom:"0.5rem",borderBottom:"1px solid #AFAFAF"}}>
          <h5 className='app_nav_title'>Attachments</h5>
        </div>:null}
      {files?.length===0 ? <p>No Attachment Found</p>: files.map((item) => (
        <FileListItem file={item} key={item.attachment_id} condition={props.condition} onFileDeleted={handleFileDeleted} />
      ))}
    </div>
  );
};

export default FileUploaderList;