import React,{useEffect} from "react";
import { getMessaging, onMessage } from "firebase/messaging"; 
import "./App.css";
import "./rtl.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import Style from "./components/Item Master/Style/Style";
import ItemBuyerGroup from "./components/Item Master/Item Buyer Group/ItemBuyerGroup";
import ItemGroup from "./components/Item Master/Item Group/ItemGroup";
import Color from "./components/Item Master/Color/Color";
import Brand from "./components/Item Master/Item Brand/Brand";
import Configuration from "./components/Item Master/Configuration/Configuration";
import ItemGroupAccount from "./components/Item Master/Item Group Account/ItemGroupAccount";
import ItemGroupAccountType from "./components/Item Master/Item Group Account Type/ItemGroupAccountType";
import SystemParams from "./components/System Parameters/SystemParams";
import SiteScreen from "./screens/Sites Screen/SiteScreen";
import AddressPopup from "./components/Edit Address/Address Popup/AddressPopup";
import Privileges from "./components/Form Modules/Privilege/Privileges";
import TenantScreen from "./screens/Tenant Screen/TenantScreen";
import AdminScreen from "./screens/Administration Screen/AdminScreen";
import SetupScreen from "./screens/Setup Screen/SetupScreen";
import EntityScreen from "./screens/Entity Screen/EntityScreen";
import Form from "./components/Form Modules/Form/Form"; 
import EditItem from "./components/Item Master/Item/EditItem";
import Resources from "./components/Form Modules/Resources/Resources";
import Module from "./components/Form Modules/Module/Module";
import FormResources from "./components/Form Modules/Form Resources/FormResources";
import ResourceAccess from "./components/Form Modules/Resources Access/ResourceAccess";
import Login from "./screens/LoginRegister/Login";
import Register from "./screens/LoginRegister/Register";
import DashboardScreen from "./screens/Dashboard Screen/DashboardScreen";
import Dashboard from "./screens/Backend/Dashboard";
import Roles from "./components/Form Modules/Roles/Roles";
import Unit from "./components/Form Modules/Unit Of Measurement/Unit";
import Conversion from "./components/Form Modules/Unit Of Conversion/Conversion";
import AssignPrev from "./components/Form Modules/Privilege Assignment/AssignPrev";
import Language from "./components/Form Modules/Language/Language";
import TenantsArchive from "./screens/Tenant Screen/TenantsArchive";
import EditTenantPage from "./screens/Tenant Screen/EditTenantPage";
import FiscalYear from "./components/Fiscal Year/FiscalYear";
import Currency from "./components/Form Modules/Currency/Currency";
import CurrencyConversion from "./components/Form Modules/Currency Conversion/CurrencyConversion";
import UserArchive from "./components/Users/User Archive/UserArchive";
import NewUser from "./components/Users/NewUser/NewUser";
import EditUsers from "./components/Users/EditUsers/EditUsers";
import NumberArchive from "./components/Numbers/Numbers Archive/NumberArchive";
import NewNumber from "./components/Numbers/New Numbers/NewNumber";
import EditNumber from "./components/Numbers/Edit Numbers/EditNumber";
import WarehouseScreen from "./screens/Warehouse/WarehouseScreen";
import Organisation from "./components/Form Modules/Organisation/Organisation";
import OrgArchive from "./components/Organisation Unit/Org Archive/OrgArchive";
import EditPO from "./components/Purchase Orders Internal/Edit PO/EditPO";
import PaymentArchive from "./components/Payment Terms/Payment Archive/PaymentArchive";
import BankAccounts from "./components/Form Modules/Banks/Banks";
import BankArchive from "./components/Bank Accounts/Banks Accounts Archive/BankArchive"; 
import EditBanksAccounts from "./components/Bank Accounts/Edit Bank Accounts/EditBanksAccounts";
import PositionArchive from "./components/Employee Position/Position Archive/PositionArchive"; 
import EmployeeArchive from "./components/Employee/Employee Archive/EmployeeArchive";
import EditEmployee from "./components/Employee/Edit Employee/EditEmployee"; 
import EmailArchive from "./components/Email Template/Email Archive/EmailArchive";
import CreateEmail from "./components/Email Template/Create Email/CreateEmail";
import EditEmail from "./components/Email Template/Edit Email/EditEmail";
import AttachmentTypes from "./components/AttachmentType/AttachmentTypes"; 
import EditBanks from "./components/Form Modules/Banks/EditBanks";
import TaxScreen from "./screens/TaxScreen";
import LabelGrid from "./components/Label Form/Label Grid/LabelGrid";
import DemoPage from "./screens/DemoPage";
import VendorsArchive from "./components/Vendors/Vendors Archive/VendorsArchive";
import MasterScreen from "./screens/Vendor Interface Screen/MasterScreen";
import VendorsEdit from "./components/Vendors/Vendors Edit/VendorsEdit"; 
import GroupArchive from "./components/Vendors/Vendors Group/Archive/GroupArchive";
import MasterCategory from "./components/CategoryMaster/MasterCategory";
import ReasonArchive from "./components/Vendors/Vendors Reason/Archive/ReasonArchive";
import BusinessArchive from "./components/Vendors/Vendors Business Classification/Archive/BusinessArchive";  
import Dimensions from "./components/Item Master/Dimensions/Dimensions";
import Size from "./components/Item Master/Size/Size";
import ItemArchive from "./components/Item Master/Item/ItemArchive";
import SegmentsScreen from "./screens/SegmentsScreen";
import LogoutComponent from "./components/Auth/LogoutComponent";
import ExtHome from "./components/External Screens/User Home Page/ExtHome";
import POArchive from "./components/Purchase Orders Internal/Archive PO/POArchive";
import ProcureScreen from "./screens/Procure Screen/ProcureScreen";
import PurchaseOrderListing from "./components/External Screens/Purchase Order/PurchaseOrderListing";
import PurchaseOrderDetailView from "./components/External Screens/Purchase Order/PurchaseOrderDetailView";
import DeliveryArchive from "./components/Procurement Settings/Delivery Item/DeliveryArchive";
import ModeDeliveryArchive from "./components/Procurement Settings/Mode Of Delivery/ModeDeliveryArchive";
import ParametersArchive from "./components/Procurement Settings/ParametersArchive";
import ParametersEdit from "./components/Procurement Settings/ParametersEdit";
import Error404 from "./screens/Pages/Error404";
import MyAccount from "./screens/MyAccount";
import TeamHeader from "./components/Procurement Settings/Team Header/TeamHeader"; 
import EditTeamHeader from "./components/Procurement Settings/Team Header/EditTeamHeader";
import Join from "./screens/Join";
import ShopScreen from "./screens/Purchase Requisition/ShopScreen";
import WishlistScreen from "./screens/Purchase Requisition/WishlistScreen";
import ProductDetailScreen from "./screens/Purchase Requisition/ProductDetailScreen";
import ShoppingCartScreen from "./screens/Purchase Requisition/ShoppingCartScreen";
import SummaryScreen from "./screens/Purchase Requisition/SummaryScreen";
import RFQDetails from "./components/External Screens/Request For Quotation/RFQDetails";
import RequestScreen from "./screens/Request Screen/RequestScreen";
import AddRequisitionDetailsScreen from "./screens/Purchase Requisition/AddRequisitionDetailsScreen";
import SuccessScreen from "./screens/Purchase Requisition/SuccessScreen";
import AccessLevels from "./components/Procurement Settings/Access Levels/AccessLevels";
import HeaderAccessLevels from "./components/Procurement Settings/Team Header Access Levels/HeaderAccessLevels";
import TeamHeaderAccessLevelsEdit from "./components/Procurement Settings/Team Header Access Levels/TeamHeaderAccessLevelsEdit";
import TeamHeaderAccessLevelsCreate from "./components/Procurement Settings/Team Header Access Levels/TeamHeaderAccessLevelsCreate";
import HistoryPO from "./components/Purchase Orders Internal/History & Versions/HistoryPO";
import ScoringTemplate from "./components/External Screens/Request For Quotation/Scoring Module/ScoringTemplate";
import CreateScoringTemplate from "./components/External Screens/Request For Quotation/Scoring Module/CreateScoringTemplate";
import EditScoringTemplate from "./components/External Screens/Request For Quotation/Scoring Module/EditScoringTemplate";
import ORConfiguration from "./components/Organisation Settings/ORConfiguration";
import PurchaseRequisitionScreen from "./screens/Purchase Requisition/PurchaseRequisitionScreen";
import AllRequisitionsScreen from "./screens/Request For Quotation/AllRequisitionsScreen";
import RFQDetailFormScreen from "./screens/Request For Quotation/RFQDetailFormScreen";
import RFQHeaderListScreen from "./screens/Request For Quotation/RFQHeaderListScreen"; 
import DiscountType from "./components/Item Master/Discount Type/DiscountType";
import BiddingCount from "./components/External Screens/Request For Quotation/Bidding/BiddingCount";
import Awarding from "./components/External Screens/Request For Quotation/Bidding/Awarding";
import BiddingCountListings from "./components/External Screens/Request For Quotation/Bidding/BiddingCountListings";
import EditBiddingCount from "./components/External Screens/Request For Quotation/Bidding/EditBiddingCount";
import RecallBiddingListing from "./components/External Screens/Request For Quotation/Bidding/RecallBiddingListing";
import RFQVendorReplyGraphScreen from "./screens/Request For Quotation/RFQVendorReplyGraphScreen";
import PoVersion from "./components/Purchase Orders Internal/PoVersion/PoVersion";
import GoodsReceivingNotes from "./components/GoodsReceiving/GoodsReceivingNotes";
import EditGoodsReceivingNote from "./components/GoodsReceiving/CreateNote/EditGoodsReceivingNote";
import TechEvaluationConsolidated from "./components/External Screens/Request For Quotation/Team Evaluation/TechEvaluationConsolidated";
import ReturnOrders from "./components/Purchase Orders Internal/ReturnOrder/ReturnOrders";
import EditReturnOrder from "./components/Purchase Orders Internal/ReturnOrder/Edit/EditReturnOrder";
import RFQList from "./components/External Screens/RFQ Vendor Reply/RFQList/RFQList";
import RFQBiddingDetails from "./components/External Screens/RFQ Vendor Reply/RFQBiddingDetails";
import RFQVersions from "./components/External Screens/RFQ Vendor Reply/Versions";
import BiddingGeneralDetails from "./components/External Screens/RFQ Vendor Reply/BiddingGeneralDetails/BiddingGeneralDetails";
import ItemDetail from "./components/External Screens/RFQ Vendor Reply/ItemDetail/ItemDetail";
import RFQHelpVideos from "./components/External Screens/RFQ Vendor Reply/RFQHelpVideos/RFQHelpVideos";
import PurchaseOrderList from "./components/External Screens/Purchase Order Screen/PurchaseOrderList/PurchaseOrderList";
import PurchaseOrderDetails from "./components/External Screens/Purchase Order Screen/PurchaseOrderDetails/PurchaseOrderDetails";
import GoodsReceivingList from "./components/External Screens/Goods Receiving/GoodsReceivingList/GoodsReceivingList";
import GoodsReceivingDetails from "./components/External Screens/Goods Receiving/GoodsReceivingDetails/GoodsReceivingDetails";
import WorkConfirmationList from "./components/External Screens/Work Confirmation/WorkConfirmationList/WorkConfirmationList";
import WorkConfirmationDetails from "./components/External Screens/Work Confirmation/WorkConfirmationDetails/WorkConfirmationDetails";
import NewWorkConfirmation from "./components/External Screens/Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/NewWorkConfirmation";
import NewInvoice from "./components/External Screens/Invoice/NewInvoice";
import InvoiceList from "./components/External Screens/Invoice/InvoiceList/InvoiceList";
import InvoiceDetails from "./components/External Screens/Invoice/InvoiceDetails/InvoiceDetails";
import ContractOrderList from "./components/External Screens/Contract Order/ContactOrderList/ContractOrderList";
import ContractOrderDetails from "./components/External Screens/Contract Order/ContractOrderDetails/ContractOrderDetails";
import ReceiveScreen from "./screens/Receive Screen/ReceiveScreen";
import RFQTstScreen from "./screens/Backend/RFQTstScreen"; 
import EmployeeDashboardScreen from "./components/Employee Dashboard/EmployeeDasboardScreen/EmployeeDashboardScreen";
import AmendmentRequestList from "./components/Purchase Orders Internal/AmendmentRequest/AmendmentRequestList";
import AmendmentRequest from "./components/Purchase Orders Internal/AmendmentRequest/AmendmentRequest"; 
import RFI from "./components/External Screens/Request For Quotation/RFI"; 
import Finance from "./screens/Finance Screen/Finance";
import CreditNotes from "./components/External Screens/Invoice/InvoiceList/CreditNotes";
import OpenCreditNotes from "./components/External Screens/Invoice/InvoiceList/OpenCreditNotes";
import CreditNotesCreatedByMe from "./components/External Screens/Invoice/InvoiceList/CreditNotesCreatedByMe";
import CreditNotesOnAccount from "./components/External Screens/Invoice/InvoiceList/CreditNotesOnAccount";
import GoodsReceivingAll from "./components/GoodsReceiving/GoodsReceivingAll";
import GoodsReceivingCreatedByMe from "./components/GoodsReceiving/GoodsReceivingCreatedByMe";
import GoodsReceivingOpen from "./components/GoodsReceiving/GoodsReceivingOpen";
import PurchaseOrderFreeze from "./components/Purchase Orders Internal/Archive PO/PurchaseOrderFreeze";
import OnHoldPO from "./components/Purchase Orders Internal/Archive PO/OnHoldPO";
import CancelledPO from "./components/Purchase Orders Internal/Archive PO/CancelledPO";
import ReleaseOrderList from "./components/Purchase Orders Internal/Archive PO/ReleaseOrderList";
import ReleaseOrderFreeze from "./components/Purchase Orders Internal/Archive PO/ReleaseOrderFreeze";
import OnHoldReleaseOrder from "./components/Purchase Orders Internal/Archive PO/OnHoldReleaseOrder";
import CancelledReleaseOrder from "./components/Purchase Orders Internal/Archive PO/CancelledReleaseOrder";
import WithdrawOpenFreezePO from "./components/Purchase Orders Internal/Archive PO/WithdrawOpenFreezePO";
import WithdrawOpenFreezeRO from "./components/Purchase Orders Internal/Archive PO/WithdrawOpenFreezeRO"; 
import VendorsInvitationList from "./components/Vendors/Vendors Archive/VendorsInvitationList";
import RequestForInformationScreen from "./screens/Request For Information/RequestForInformationScreen";
import MiscellaneousCharges from './components/Miscellaneous Charges/MiscellaneousCharges'; 
import COArchive from "./components/Purchase Orders Internal/Archive PO/COArchive";
import EditCO from "./components/Purchase Orders Internal/Edit PO/EditCO";
import WithdrawOpenFreezeCO from "./components/Purchase Orders Internal/Archive PO/WithdrawOpenFreezeCO";
import ContractOrderFreeze from "./components/Purchase Orders Internal/Archive PO/ContractOrderFreeze";
import CancelledCO from "./components/Purchase Orders Internal/Archive PO/CancelledCO";
import OnHoldCO from "./components/Purchase Orders Internal/Archive PO/OnHoldCO";
import AmendmentsCO from "./components/Purchase Orders Internal/Archive PO/AmendmentsCO";
import RenewalCO from "./components/Purchase Orders Internal/Archive PO/RenewalCO";
import RenewCO from "./components/Purchase Orders Internal/Archive PO/RenewCO";
import BillingRuleCO from "./components/Purchase Orders Internal/Archive PO/BillingRuleCO";
import PoArchivePage from "./screens/PurchaseOrders/PoArchivePage";
import PurchaseAgreementArchivePage from "./screens/PurchaseAgreement/PurchaseAgreementArchivePage";
import ContractOrderArchivePage from "./screens/ContractOrders/ContractOrderArchivePage";
import ReleaseOrdersArchivePage from "./screens/ReleaseOrders/ReleaseOrdersArchivePage";
import PoEditPage from "./screens/PurchaseOrders/PoEditPage";
import ContractOrderEditPage from "./screens/ContractOrders/ContractOrderEditPage";
import PurchaseAgreementEditPage from "./screens/PurchaseAgreement/PurchaseAgreementEditPage";
import ReleaseOrderEditPage from "./screens/ReleaseOrders/ReleaseOrderEditPage";
import ContractOrderVersionPage from "./screens/ContractOrders/ContractOrderVersionPage";
import PurchaseAgreementVersionPage from "./screens/PurchaseAgreement/PurchaseAgreementVersionPage";
import ReleaseOrderVersionPage from "./screens/ReleaseOrders/ReleaseOrderVersionPage";
import PoVersionPage from "./screens/PurchaseOrders/PoVersionPage";
import PoHistoryPage from "./screens/PurchaseOrders/PoHistoryPage";
import PoGoodReceivingNotesPage from "./screens/PurchaseOrders/PoGoodReceivingNotesPage";
import ReGoodReceivingPage from "./screens/ReleaseOrders/ReGoodReceivingPage";
import ContractOrderGoodReceivingPage from "./screens/ContractOrders/ContractOrderGoodReceivingPage";
import BPAGoodReceivingNotesPage from "./screens/PurchaseAgreement/PurchaseAgreementGoodReceivingNotesPage";
import ReOGoodReNotePage from "./screens/ReleaseOrders/ReOGoodReNotePage";
import PoGoodReceivingEditPage from "./screens/PurchaseOrders/PoGoodReceivingEditPage";
import ContractGoodReceivingNoteEdit from "./screens/ContractOrders/ContractGoodReceivingNoteEdit";
import PurchaseAgreementGoodReceivingNoteEditPage from "./screens/PurchaseAgreement/PurchaseAgreementGoodReceivingNoteEditPage";
import PoReturnOrderPage from "./screens/PurchaseOrders/PoReturnOrderPage";
import PoReturnOrderEditPage from "./screens/PurchaseOrders/PoReturnOrderEditPage";
import ContractReturnOrderPage from "./screens/ContractOrders/ContractReturnOrderPage";
import ContractReturnOrderEditPage from "./screens/ContractOrders/ContractReturnOrderEditPage";
import RoReturnOrderEditPage from "./screens/ReleaseOrders/RoReturnOrderEditPage";
import RoReturnOrderPage from "./screens/ReleaseOrders/RoReturnOrderPage";
import CoAmendmentPage from "./screens/ContractOrders/CoAmendmentPage";
import CoAmendmentEditPage from "./screens/ContractOrders/CoAmendmentEditPage";
import PoAmendmentPage from "./screens/PurchaseOrders/PoAmendmentPage";
import PoAmendmentEditPage from "./screens/PurchaseOrders/PoAmendmentEditPage";
import RoAmendmentPage from "./screens/ReleaseOrders/RoAmendmentPage";
import RoAmendmentEditPage from "./screens/ReleaseOrders/RoAmendmentEditPage";
import RenewOrderEditPage from "./screens/PurchaseOrders/RenewOrderEditPage";
import EditInvoiceOnAccount from "./components/External Screens/Invoice/EditInvoiceOnAccount/EditInvoiceOnAccount";
import VendorVersioning from "./components/Vendors/Vendors Edit/VendorVersioning";
import LogsSearch from "./components/Audit History/LogsSearch/LogsSearch";
import LogsDetails from "./components/Audit History/LogsDetails/LogsDetails";
import TermConditionsTemplates from "./components/External Screens/Request For Quotation/Terms Conditions/TermConditionsTemplates";
import CommonDetailViewAddHeadings from "./components/External Screens/Request For Quotation/Terms Conditions/CommonDetailViewAddHeadings";
import ComplianceTemplates from "./components/External Screens/Request For Quotation/Compliances/ComplianceTemplates";
import EditCreditNote from "./components/External Screens/Credit Notes/CreditNoteDetails/CreditNoteDetails";  
import DetailViewResponses from "./components/External Screens/Request For Quotation/Compliances/Responses/DetailViewResponses";
import ListViewResponses from "./components/External Screens/Request For Quotation/Compliances/Responses/ListViewResponses";
import DetailViewComplianceTemplatesWithLines from "./components/External Screens/Request For Quotation/Compliances/DetailViewComplianceTemplatesWithLines";
import InvoiceDetailsInternal from "./components/External Screens/Invoice/InvoiceDetails/InvoiceDetailsInternal";
import RFQVendorReply from "./components/RequestForQuotation/RFQVendorReplyInternal/RFQVendorReply";
import NotificationsScreen from "./screens/NotificationsScreen/NotificationsScreen";
import NotificationEventsArchieve from "./components/NotificationEvents/NotificationEventsArchieve";
import TechnicalEvaluationPreview from "./components/External Screens/Request For Quotation/Team Evaluation/TechnicalEvaluationPreview";
import ContractReportScreen from "./screens/ContractReports/ContractReportScreen";
import ReportsScreen from "./screens/ReportsScreen/ReportsScreen";
import QuestionnaireList from "./components/Questionnaire/QuestionnaireList";
import QuestionGroup from "./components/Questionnaire/QuestionGroup";
import AnswerGroup from "./components/Questionnaire/AnswerGroup";
import AnswerDetails from "./components/Questionnaire/AnswerDetails"
import QuestionDetails from "./components/Questionnaire/QuestionDetails"
import NewQuestionnaire from "./components/Questionnaire/NewQuestionnaire"
import QuestionnaireScreen from "./screens/Questionnaire/QuestionnaireScreen";
import TestQuestionnaire from "./components/Questionnaire/TestQuestionnaire";
import QuestionnaireIntegration from "./components/Questionnaire/QuestionnaireIntegration";
import AwardingPreview from "./components/RequestForQuotation/RFQDetailForm/AwardingPreview";
import LynkazAdminScreens from "./screens/Lynkaz Admin Screen/LynkazAdminScreens";
import ApiMasterFormsListings from "./components/Api Master/ApiMasterFormsListings";
import NonCompliancePolicyListings from "./components/External Screens/Request For Quotation/Non Compliance Policy/NonCompliancePolicyListings";
import PRRequestPolicyListings from "./components/External Screens/Request For Quotation/PR Request Policy/PRRequestPolicyListings";
import PRRequestPolicyHistory from "./components/External Screens/Request For Quotation/PR Request Policy/PRRequestPolicyHistory";
import NonCompliancePolicyHistory from "./components/External Screens/Request For Quotation/Non Compliance Policy/NonCompliancePolicyHistory";
import AuctionScreen from "./screens/AuctionScreen/AuctionScreen";
import PurchaseAgreementClassifications from "./components/Form Modules/PurchaseAgreementClassifications/PurchaseAgreementClassifications";
import PurchaseAgreementReturnOrderEditPage from "./screens/PurchaseAgreement/PurchaseAgreementReturnOrderEditPage"
import PurchaseAgreementReturnOrderPage from "./screens/PurchaseAgreement/PurchaseAgreementReturnOrderPage";
import PurchaseAgreementList from "./components/External Screens/Purchase Agreement/Purchse Agreement List/PurchaseAgreementList";
import PurchaseAgreementDetails from "./components/External Screens/Purchase Agreement/PurchaseAgreementDetails/PurchaseAgreementDetails";
import VendorDestinations from "./components/Vendors/Vendor Designations/VendorDesignations";
import VendorContactType from "./components/Vendors/Vendor Contact Type/VendorContactType";
import ReleaseOrdersList from "./components/External Screens/Release Order/Release Order List/ReleaseOrdersList";
import ReleaseOrderDetails from "./components/External Screens/Release Order/Release Order Details/ReleaseOrderDetails";
import InvoiceListWrapper from "./components/External Screens/Invoice/InvoiceList/InvoiceListWrapper";
import ChangePassword from "./screens/ChangePassword/ChangePassword";
import AllPRListingsCreatedByMe from "./components/PurchaseRequisition/PurchaseRequisitionList/AllPRListingsCreatedByMe";
import BudgeEntryList from "./components/Budget/BudgetEntry/BudgeEntryList";
import BudgetEntryEdit from "./components/Budget/BudgetEntry/BudgetEntryEdit";
import BudgetTransferList from "./components/Budget/BudgetTransfer/BudgetTransferList";
import BudgetTransferEdit from "./components/Budget/BudgetTransfer/BudgetTransferEdit";
import TermsAndConditionsScreen from "./screens/Terms And Conditions Screen/TermsAndConditionsScreen";
import PrivacyPolicyScreen from "./screens/Privacy Policy Screen/PrivacyPolicyScreen";

function App() {
  // here all screens will rendered according to the routes!

  const navigate= useNavigate()

  useEffect(() => {
    const handleServiceWorkerMessage = (event) => {
      const { type, url } = event.data;
      if (type === 'navigate' && url) {
        navigate(url);
      }
    };
    navigator?.serviceWorker?.addEventListener('message', handleServiceWorkerMessage);
     return () => {
      navigator?.serviceWorker?.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, []); 

  useEffect(() => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      const notificationTitle = payload?.notification?.title;
      const notificationOptions = {
        body: payload?.notification?.body,
        icon: "/images/login-reg-screen-logo.png"
      };

      const notification =  new Notification(notificationTitle, notificationOptions);

      notification.onclick = () => {
        navigate(`/${payload?.data?.url}`)
      };
    });
  }, []);

  return (
      <Routes>
        <React.Fragment>
          <Route path="/labels-form" element={<LabelGrid />} />
          <Route path="/address-form" element={<AddressPopup />} />
          <Route path="/tenants" element={<TenantsArchive />} />
          <Route path="/users" element={<UserArchive />} />
          <Route path="/number-sequence" element={<NumberArchive />} /> 
          <Route path="/banks" element={<BankAccounts />} />
          <Route path="/dimensions" element={<Dimensions />} />
          <Route path="/size" element={<Size />} />
          <Route path="/style" element={<Style />} />
          <Route path="/color" element={<Color />} />
          <Route path="/procurement-settings" element={<ParametersArchive />} /> 
          <Route path="/edit-procurement-settings/:id" element={<ParametersEdit />}/>
          <Route path="/delivery-terms" element={<DeliveryArchive />} />
          <Route path="/modes-of-delivery" element={<ModeDeliveryArchive />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/item-buyer-group" element={<ItemBuyerGroup />} />
          <Route path="/brand" element={<Brand />} />
          <Route path="/procure" element={<ProcureScreen />} />
          <Route path="/request" element={<RequestScreen />} />
          <Route path="/access-levels" element={<AccessLevels />} />
          <Route
            path="/edit-teamlines-access-levels/:id"
            element={<TeamHeaderAccessLevelsEdit />}
          />
          <Route
            path="/create-teamlines-access-levels"
            element={<TeamHeaderAccessLevelsCreate />}
          />
          <Route
            path="/teamlines-access-levels"
            element={<HeaderAccessLevels />}
          />
          <Route path="/external-user" element={<ExtHome />} />
          <Route path="/request-for-informations" element={<RFI />} /> 
          <Route
            path="/request-for-quotation-details"
            element={<RFQDetails />}
          /> 
          <Route path="/scoring-template" element={<ScoringTemplate />} />
          <Route path="/terms-conditions-templates" element={<TermConditionsTemplates />} />

          <Route path="/compliance-templates">
            <Route path="listings" element={<ComplianceTemplates />} />
            <Route path="create" element={<DetailViewComplianceTemplatesWithLines />} />
            <Route path="edit/:id" element={<DetailViewComplianceTemplatesWithLines editMode={true} />} />
          </Route> 
          
          <Route path="/compliances-responses">
            <Route path="listings" element={<ListViewResponses />} /> 
            <Route path="edit/:id" element={<DetailViewResponses editMode={true} />} />
          </Route>

          <Route path="/add-headings-template/:id" element={<CommonDetailViewAddHeadings />} />
          <Route
            path="/create-scoring-template"
            element={<CreateScoringTemplate />}
          />
          <Route
            path="/edit-scoring-template/:id"
            element={<EditScoringTemplate />}
          />
          <Route
            path="/purchase-orders-listing"
            element={<PurchaseOrderListing />}
          />
          <Route
            path="/purchase-orders-details"
            element={<PurchaseOrderDetailView />}
          />
          <Route path="/team-header" element={<TeamHeader />} />
          <Route path="/team-header-edit/:id" element={<EditTeamHeader />} /> 

          <Route path="/purchase-orders" element={<POArchive />} />
          <Route
            path="/purchase-orders-freeze"
            element={<PurchaseOrderFreeze />}
          />
          <Route path="/purchase-orders-onhold" element={<OnHoldPO />} />
          <Route path="/purchase-orders-cancelled" element={<CancelledPO />} />
          <Route
            path="/purchase-orders-open-withdraw-freeze"
            element={<WithdrawOpenFreezePO />}
          />

          <Route path="/release-orders" element={<ReleaseOrderList />} />
          <Route
            path="/release-orders-freeze"
            element={<ReleaseOrderFreeze />}
          />
          <Route
            path="/release-orders-onhold"
            element={<OnHoldReleaseOrder />}
          />
          <Route
            path="/release-orders-cancelled"
            element={<CancelledReleaseOrder />}
          />
          <Route
            path="/release-orders-open-withdraw-freeze"
            element={<WithdrawOpenFreezeRO />}
          />

          <Route
            path="/purchase-orders/versions-history/:header_id/:line_id"
            element={<HistoryPO />}
          />
          <Route path="/edit-purchase-order/:id" element={<EditPO />} />
          <Route path="/purchase-order-version/:id" element={<PoVersion />} />
          <Route path="/item-group" element={<ItemGroup />} />
          <Route path="/item-group-account" element={<ItemGroupAccount />} />
          <Route
            path="/item-group-account-type"
            element={<ItemGroupAccountType />}
          />
          <Route path="/previleges-assignment" element={<AssignPrev />} />
          <Route path="/items" element={<ItemArchive />} /> 
          <Route path="/edit-item/:id" element={<EditItem />} />
          <Route
            path="/technical-evaluation-consolidated"
            element={<TechEvaluationConsolidated />}
          />
          <Route path="/items" element={<ItemArchive />} /> 
          <Route path="/edit-bank/:id" element={<EditBanks />} />
          <Route path="/email-templates" element={<EmailArchive />} />
          <Route path="/create-email-template" element={<CreateEmail />} />

          <Route path="/vendors" element={<VendorsArchive />} />
          <Route path="/vendor-versioning/:id" element={<VendorVersioning />} />
          <Route
            path="/vendors-invitation-list"
            element={<VendorsInvitationList />}
          />

          <Route path="/vendors-group" element={<GroupArchive />} />
          <Route path="/vendors-reason" element={<ReasonArchive />} />
          <Route path="/create-bidding-count" element={<BiddingCount />} />
          <Route
            path="/edit-bidding-count/:id"
            element={<EditBiddingCount />}
          />
          <Route
            path="/bidding-count-listings"
            element={<BiddingCountListings />}
          />
          <Route
            path="/bidding-count-recall-listings"
            element={<RecallBiddingListing />}
          />
          <Route path="/awarding-process-policy" element={<Awarding />} />
          <Route
            path="/vendors-business-classification"
            element={<BusinessArchive />}
          />  
          <Route path="/labels-form" element={<LabelGrid />} />
          <Route path="/address-form" element={<AddressPopup />} />
          <Route path="/tenants" element={<TenantsArchive />} /> 
          <Route path="/number-sequence" element={<NumberArchive />} /> 
          <Route path="/banks" element={<BankAccounts />} />
          <Route path="/dimensions" element={<Dimensions />} />
          <Route path="/size" element={<Size />} />
          <Route path="/style" element={<Style />} />
          <Route path="/color" element={<Color />} />
          <Route path="/procurement-settings" element={<ParametersArchive />} /> 
          <Route
            path="/edit-procurement-settings/:id"
            element={<ParametersEdit />}
          />
          <Route path="/delivery-terms" element={<DeliveryArchive />} />
          <Route path="/mode-of-delivery" element={<ModeDeliveryArchive />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/item-buyer-group" element={<ItemBuyerGroup />} />
          <Route path="/brand" element={<Brand />} />
          <Route path="/procure" element={<ProcureScreen />} />
          <Route path="/request" element={<RequestScreen />} />
          <Route path="/access-levels" element={<AccessLevels />} />
          <Route
            path="/edit-teamlines-access-levels/:id"
            element={<TeamHeaderAccessLevelsEdit />}
          />
          <Route
            path="/create-teamlines-access-levels"
            element={<TeamHeaderAccessLevelsCreate />}
          />
          <Route
            path="/teamlines-access-levels"
            element={<HeaderAccessLevels />}
          />
          <Route path="/external-user" element={<ExtHome />} />
          <Route
            path="/request-for-quotation-details"
            element={<RFQDetails />}
          />
          <Route path="/scoring-template" element={<ScoringTemplate />} />
          <Route
            path="/create-scoring-template"
            element={<CreateScoringTemplate />}
          />
          <Route
            path="/edit-scoring-template/:id"
            element={<EditScoringTemplate />}
          />
          <Route
            path="/purchase-orders-listing"
            element={<PurchaseOrderListing />}
          />
          <Route
            path="/purchase-orders-details"
            element={<PurchaseOrderDetailView />}
          /> 
          <Route path="/purchase-orders" element={<POArchive />} />

          <Route path="/edit-purchase-order/:id" element={<EditPO />} />
          <Route path="/edit-purchase-order/:id/:instance_id" element={<EditPO />} />
          
          <Route path="/purchase-order-version/:id" element={<PoVersion />} />
          <Route path="/item-group" element={<ItemGroup />} />
          <Route path="/item-group-account" element={<ItemGroupAccount />} />
          <Route
            path="/item-group-account-type"
            element={<ItemGroupAccountType />}
          />
          <Route path="/previleges-assignment" element={<AssignPrev />} />
          <Route path="/items" element={<ItemArchive />} /> 
          <Route path="/edit-item/:id" element={<EditItem />} />
          <Route
            path="/technical-evaluation-consolidated"
            element={<TechEvaluationConsolidated />}
          />
          <Route path="/items" element={<ItemArchive />} /> 
          <Route path="/edit-bank/:id" element={<EditBanks />} />
          <Route path="/email-templates" element={<EmailArchive />} />
          <Route path="/create-email-template" element={<CreateEmail />} />
          <Route path="/vendors" element={<VendorsArchive />} />
          <Route path="/vendors-group" element={<GroupArchive />} />
          <Route path="/vendors-reason" element={<ReasonArchive />} />
          <Route path="/create-bidding-count" element={<BiddingCount />} />
          <Route
            path="/edit-bidding-count/:id"
            element={<EditBiddingCount />}
          />
          <Route
            path="/pr-request-policy-history"
            element={<PRRequestPolicyHistory />}
          />
          <Route
            path="/pr-request-policies"
            element={<PRRequestPolicyListings />}
          />
          <Route
            path="/non-compliance-policies"
            element={<NonCompliancePolicyListings />}
          />
          <Route
            path="/non-compliance-policy-history"
            element={<NonCompliancePolicyHistory />}
          />
          <Route path="/awarding-process-policy" element={<Awarding />} />
          <Route
            path="/vendors-business-classification"
            element={<BusinessArchive />}
          />
          <Route path="/edit-vendor">
            <Route path=":id" element={<VendorsEdit />} />
            <Route path=":id/:instance_id" element={<VendorsEdit />} />
          </Route>
          <Route path="/master-data" element={<MasterScreen />} />
          <Route path="/item-discount-type" element={<DiscountType />} /> 
          <Route
            path="/edit-email-templates/:id"
            element={<EditEmail />}
          /> 
          <Route path="/payment-terms" element={<PaymentArchive />} />
          <Route path="/new-number" element={<NewNumber />} />
          <Route path="/banks-accounts" element={<BankArchive />} /> 
          <Route
            path="/edit-bank-account/:id"
            element={<EditBanksAccounts />}
          />
          <Route path="/employee" element={<EmployeeArchive />} />
          <Route path="/edit-employee">
            <Route path=":id" element={<EditEmployee />} />
            <Route path=":id/:instance_id" element={<EditEmployee />} />
          </Route> 
          <Route path="/position" element={<PositionArchive />} /> 
          <Route path="/number-sequence/edit/:id" element={<EditNumber />} />
          <Route path="/new-user" element={<NewUser />} />
          <Route path="/new-tenant" element={<TenantScreen />} />
          <Route path="/tenant/edit/:id" element={<EditTenantPage />} />
          <Route path="/user/edit/:id" element={<EditUsers />} />
          <Route path="/organisation-unit" element={<OrgArchive />} />  
          <Route path="/sites" element={<SiteScreen />} />
          <Route
            path="/site/edit/:id"
            element={<SiteScreen pageName="edit" />}
          />
          <Route path="/warehouse/" element={<WarehouseScreen />} />
          <Route
            path="/warehouse/edit/:id"
            element={<WarehouseScreen pageName="edit" />}
          />
          <Route path="/entity/" element={<EntityScreen />} />
          <Route
            path="/entity/new-entity"
            element={<EntityScreen pageName="new" />}
          />
          <Route
            path="/entity/edit-entity/:id"
            element={<EntityScreen pageName="edit" />}
          />
          <Route path="/setup" element={<SetupScreen />} />
          <Route path="/lynkaz-administration" element={<LynkazAdminScreens />} />
          <Route path="/api-master-forms" element={<ApiMasterFormsListings />} />
          <Route path="/finance" element={<Finance />} />
          <Route path="/form" element={<Form />} />
          <Route path="/module" element={<Module />} />
          <Route path="/organisation-type" element={<Organisation />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/form-resources" element={<FormResources />} />
          <Route path="/resources-access" element={<ResourceAccess />} /> 
          <Route
            path="/organisation-configuration"
            element={<ORConfiguration />}
          />
          <Route path="/currency-conversion" element={<CurrencyConversion />} />
          <Route path="/security-roles" element={<Roles />} />
          <Route path="/privileges" element={<Privileges />} />
          <Route path="/privileges-assignment" element={<AssignPrev />} />
          <Route path="/language" element={<Language />} />
          <Route path="/administration" element={<AdminScreen />} />
          <Route path="/unit" element={<Unit />} />
          <Route path="/fiscal-year" element={<FiscalYear />} />
          <Route path="/system-parameters" element={<SystemParams />} />
          <Route path="/unit-conversion" element={<Conversion />} />
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/dashboard2" element={<Dashboard />} /> 
          <Route path="/goods-receiving/:type/:id?" element={<EditGoodsReceivingNote />} /> 
          <Route path="/purchase-agreement-classifications" element={<PurchaseAgreementClassifications />} />
          
          {/* test screen */}
          <Route path="/dashboard3" element={<RFQTstScreen />} />       
          {/* -- */}
          
          <Route path="/attachment-types" element={<AttachmentTypes />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<LogoutComponent />} />
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/category" element={<MasterCategory />} />
          <Route path="/segments" element={<SegmentsScreen />} />
          <Route path="/segments/:page" element={<SegmentsScreen />} />
          <Route path="/segments/:page/:id" element={<SegmentsScreen />} />
          <Route path="/tax" element={<TaxScreen />}>
            <Route path=":page" element={<TaxScreen />} />
            <Route path=":page/:id" element={<TaxScreen />} />
          </Route> 
          <Route path="/position" element={<PositionArchive />} /> 
          <Route path="/number-sequence/edit/:id" element={<EditNumber />} />
          <Route path="/new-user" element={<NewUser />} />
          <Route path="/new-tenant" element={<TenantScreen />} />
          <Route path="/tenant/edit/:id" element={<EditTenantPage />} />
          <Route path="/user/edit/:id" element={<EditUsers />} />
          <Route path="/organisation-unit" element={<OrgArchive />} />  
          <Route path="/sites" element={<SiteScreen />} />
          <Route
            path="/site/edit/:id"
            element={<SiteScreen pageName="edit" />}
          />
          <Route path="/warehouse/" element={<WarehouseScreen />} />
          <Route
            path="/warehouse/edit/:id"
            element={<WarehouseScreen pageName="edit" />}
          />
          <Route path="/entity/" element={<EntityScreen />} />
          <Route
            path="/entity/new-entity"
            element={<EntityScreen pageName="new" />}
          />
          <Route
            path="/entity/edit-entity/:id"
            element={<EntityScreen pageName="edit" />}
          /> 
          <Route path="/form" element={<Form />} />
          <Route path="/module" element={<Module />} />
          <Route path="/organisation-type" element={<Organisation />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/form-resources" element={<FormResources />} />
          <Route path="/resources-access" element={<ResourceAccess />} />
          <Route path="/currency" element={<Currency />} />
          <Route
            path="/organisation-configuration"
            element={<ORConfiguration />}
          /> 
          <Route path="/security-roles" element={<Roles />} />
          <Route path="/privileges" element={<Privileges />} />
          <Route path="/privileges-assignment" element={<AssignPrev />} />
          <Route path="/language" element={<Language />} />
          <Route path="/administration" element={<AdminScreen />} />
          <Route path="/unit" element={<Unit />} />
          <Route path="/fiscal-year" element={<FiscalYear />} />
          <Route path="/system-parameters" element={<SystemParams />} />
          <Route path="/unit-conversion" element={<Conversion />} />
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/dashboard2" element={<Dashboard />} />
          <Route path="/dashboard3" element={<RFQTstScreen />} />
          <Route path="/attachment-types" element={<AttachmentTypes />} />
          <Route path="/tax" element={<TaxScreen />}>
            <Route path=":page" element={<TaxScreen />} />
            <Route path=":page/:id" element={<TaxScreen />} />
          </Route>
          <Route
            path="/purchase-requisition"
            element={<PurchaseRequisitionScreen />}
          />
          <Route
            path="/purchase-requisition-created-by-me"
            element={<AllPRListingsCreatedByMe />}
          />
          <Route path="/purchase-requisition/shop" element={<ShopScreen />} />
          <Route path="/purchase-requisition/wishlist" element={<WishlistScreen />} />
          <Route path="/purchase-requisition/shop/:id" element={<ProductDetailScreen />} />
          <Route path="/purchase-requisition/cart" element={<ShoppingCartScreen />} />
          <Route path="/purchase-requisition/addrequisitiondetails" element={<AddRequisitionDetailsScreen />} />
          <Route path="/purchase-requisition/summary" element={<SummaryScreen />} />
          <Route path="/purchase-requisition/success" element={<SuccessScreen />} />

          <Route path="/request-for-quotation/allrequisitions" element={<AllRequisitionsScreen/>}/>
          <Route path="/request-for-quotation/rfqdetailform" element={<RFQDetailFormScreen/>}/>
          <Route path="/request-for-quotation/technicalevaluation" element={<TechnicalEvaluationPreview/>}/> 
          <Route path="/request-for-quotation/awarding" element={<AwardingPreview />}/> 
  
          <Route path="/request-for-quotation/rfqheaderlist" element={<RFQHeaderListScreen/>}/>  
          <Route
            path="/purchase-requisition/wishlist"
            element={<WishlistScreen />}
          />
          <Route
            path="/purchase-requisition/shop/:id"
            element={<ProductDetailScreen />}
          />
          <Route
            path="/purchase-requisition/cart"
            element={<ShoppingCartScreen />}
          />
          <Route
            path="/purchase-requisition/addrequisitiondetails"
            element={<AddRequisitionDetailsScreen />}
          />
          <Route
            path="/purchase-requisition/summary"
            element={<SummaryScreen />}
          />
          <Route
            path="/purchase-requisition/success"
            element={<SuccessScreen />}
          /> 
          <Route
            path="/request-for-quotation/rfqdetailform"
            element={<RFQDetailFormScreen />}
          /> 
          <Route
            path="/request-for-information/"
            element={<RequestForInformationScreen />}
          />
          <Route path="/graph" element={<RFQVendorReplyGraphScreen />} />
          <Route path="/good-receiving">
            <Route path="notes/:poid" element={<GoodsReceivingNotes />} />
            <Route path="edit/:id" element={<EditGoodsReceivingNote />} />
          </Route>
          <Route path="/return-order">
            <Route path="all/:poid" element={<ReturnOrders />} />
            <Route path="edit/:id" element={<EditReturnOrder />} />
          </Route>
          <Route path="/amendment-request">
            <Route path="all/:header_id" element={<AmendmentRequestList />} />
            <Route path="edit/:id" element={<AmendmentRequest />} />
          </Route>
          <Route path="/goods-receiving-list" element={<GoodsReceivingList />} ></Route>
          <Route path="/goods-receiving-details" element={<GoodsReceivingDetails />}></Route> 
          <Route path="/rfq-vendor-reply/rfq-bidding-details" element={<RFQBiddingDetails />} />
          <Route path="/rfq-vendor-reply/rfq-versions" element={<RFQVersions />}></Route>
          <Route path="/rfq-vendor-reply/rfq-bidding-general-details" element={<BiddingGeneralDetails />}></Route>
          <Route path="/rfq-vendor-reply/rfq-bidding-details/:id" element={<ItemDetail />}></Route>
          <Route path="/rfq-vendor-reply/help-videos" element={<RFQHelpVideos />}></Route>
          <Route path="/purchase-order-list" element={<PurchaseOrderList />} ></Route>

          <Route path="/contract-orders-all" element={<COArchive />} /> 
          <Route path="/contract-orders-open-withdraw-freeze" element={<WithdrawOpenFreezeCO />} /> 
          <Route path="/contract-orders-freeze" element={<ContractOrderFreeze />} />
          
          <Route path="/contract-orders-onhold" element={<OnHoldCO />} />
          <Route path="/contract-orders-cancelled" element={<CancelledCO />} />
          <Route path="/contract-orders-amendments" element={<AmendmentsCO />} />
          <Route path="/contract-orders-coming-renewal" element={<RenewalCO />} />
          <Route path="/contract-orders-renew" element={<RenewCO />} />
          <Route path="/contract-orders-billing-rule" element={<BillingRuleCO />} />

          <Route path='/edit-contract-order/:id' element={<EditCO />} /> 
          <Route path="/purchase-order-details" element={<PurchaseOrderDetails />}></Route>

          <Route path="/goods-receiving-all" element={<GoodsReceivingAll />} />
          <Route
            path="/goods-receiving-open"
            element={<GoodsReceivingOpen />}
          />
          <Route
            path="/goods-receiving-created-by-me"
            element={<GoodsReceivingCreatedByMe />}
          />

          <Route
            path="/work-confirmation-list"
            element={<WorkConfirmationList />}
          ></Route>
   
          <Route
            path="/work-confirmation/:type"
            element={<NewWorkConfirmation />}
          ></Route>

          <Route path="/rfq-vendor-reply/rfq-list" element={<RFQList />} />
          <Route
            path="/rfq-vendor-reply/rfq-bidding-details"
            element={<RFQBiddingDetails />}
          />
          <Route
            path="/rfq-vendor-reply/rfq-versions"
            element={<RFQVersions />}
          ></Route>
          <Route
            path="/rfq-vendor-reply/rfq-bidding-general-details"
            element={<BiddingGeneralDetails />}
          ></Route>
          <Route
            path="/rfq-vendor-reply/rfq-bidding-details/:id"
            element={<ItemDetail />}
          ></Route>
          <Route
            path="/rfq-vendor-reply/help-videos"
            element={<RFQHelpVideos />}
          ></Route>
          <Route
            path="/purchase-order-list"
            element={<PurchaseOrderList />}
          ></Route>
          <Route
            path="/purchase-order-details"
            element={<PurchaseOrderDetails />}
          ></Route>
          <Route
            path="/goods-receiving-list"
            element={<GoodsReceivingList />}
          ></Route>
          <Route
            path="/goods-receiving-details"
            element={<GoodsReceivingDetails />}
          ></Route>
          <Route
            path="/work-confirmation-list"
            element={<WorkConfirmationList />}
          ></Route>
          <Route
            path="/work-confirmation-details"
            element={<WorkConfirmationDetails />}
          ></Route>
          <Route
            path="/work-confirmation/:type"
            element={<NewWorkConfirmation />}
          ></Route>
          <Route path="/invoice/:type/:id?" element={<NewInvoice />}></Route>

          <Route path="/invoice-list" element={<InvoiceListWrapper />} />
      
          <Route path="/credit-notes" element={<CreditNotes />} />
          <Route path="/credit-notes-open" element={<OpenCreditNotes />} />
          <Route
            path="/credit-notes-created-by-me"
            element={<CreditNotesCreatedByMe />}
          />
          <Route
            path="/credit-notes-on-account"
            element={<CreditNotesOnAccount />}
          />

          <Route path="/invoice-details" element={<InvoiceDetails />}></Route>
          <Route
            path="/contract-order-list"
            element={<ContractOrderList />}
          ></Route>
          <Route
            path="/contract-order-details"
            element={<ContractOrderDetails />}
          ></Route>
          <Route path="/receive" element={<ReceiveScreen />}></Route>
          <Route path="/reports" element={<ReportsScreen />}></Route>
          <Route path="/employee-task-performance" element={<EmployeeDashboardScreen />}></Route>
          <Route path="/miscellaneous-charges/:id" element={<MiscellaneousCharges />}></Route>
          <Route path='/edit-purchase-order/:id/:instance_id' element={<EditPO />} /> 

          <Route path="/demo-page" element={<DemoPage />} />
          <Route path="/my-account/*" element={<MyAccount />} />
          <Route path="/join/*" element={<Join />} />
 
          <Route path="/purchase-order">
            <Route path="all" element={<PoArchivePage />} />
            <Route path="edit/:id" element={<PoEditPage />} />
            <Route path="edit/:id/:instance_id" element={<PoEditPage />} />
            <Route path="version/:id" element={<PoVersionPage />} />
            <Route path="history/:id" element={<PoHistoryPage/>} />
            <Route path="invoice/:id" element={<PoVersionPage />} />
            <Route path="logs/:id" element={<PoVersionPage />} />
            <Route path="good-receiving/notes/:poid" element={<PoGoodReceivingNotesPage />} />
            <Route path="good-receiving/edit/:id" element={<PoGoodReceivingEditPage />} />
            <Route path="return-order/all/:poid" element={<PoReturnOrderPage />} />
            <Route path="return-order/edit/:id" element={<PoReturnOrderEditPage />} />
            <Route path="amendment-request/all/:header_id" element={<PoAmendmentPage />} />
            <Route path="amendment-request/edit/:id" element={<PoAmendmentEditPage />} />
            {/* <Route path="renew/:header_id" element={<RenewOrderPage />} /> */}
            <Route path="renew/:id" element={<RenewOrderEditPage />} />
            <Route path="renew/edit/:id" element={<RenewOrderEditPage />} />
          </Route>
          <Route path="/contract-order">
            <Route path="all" element={<ContractOrderArchivePage />} />
            <Route path="edit/:id" element={<ContractOrderEditPage />} />
            <Route path="edit/:id/:instance_id" element={<ContractOrderEditPage />} />
            <Route path="version/:id" element={<ContractOrderVersionPage />} />
            <Route path="history/:id" element={<ContractOrderVersionPage />} />
            <Route path="invoice/:id" element={<ContractOrderVersionPage />} />
            <Route path="logs/:id" element={<ContractOrderVersionPage />} />
            <Route path="renew/:id" element={<RenewOrderEditPage />} />
            <Route path="good-receiving/notes/:poid" element={<ContractOrderGoodReceivingPage />} />
            <Route path="good-receiving/edit/:id" element={<ContractGoodReceivingNoteEdit />} />
            <Route path="return-order/all/:poid" element={<ContractReturnOrderPage />} />
            <Route path="return-order/edit/:id" element={<ContractReturnOrderEditPage />} />
            <Route path="amendment-request/all/:header_id" element={<CoAmendmentPage />} />
            <Route path="amendment-request/edit/:id" element={<CoAmendmentEditPage />} />
          </Route>
          <Route path="/release-order">
            <Route path="all" element={<ReleaseOrdersArchivePage />} />
            <Route path="edit/:id" element={<ReleaseOrderEditPage />} />
            <Route path="edit/:id/:instance_id" element={<ReleaseOrderEditPage />} />
            <Route path="version/:id" element={<ReleaseOrderVersionPage />} />
            <Route path="history/:id" element={<ReleaseOrderVersionPage />} />
            <Route path="invoice/:id" element={<ReleaseOrderVersionPage />} />
            <Route path="logs/:id" element={<ReleaseOrderVersionPage />} />
            <Route path="good-receiving/notes/:poid" element={<ReGoodReceivingPage />} />
            <Route path="good-receiving/edit/:id" element={<ReOGoodReNotePage />} />
            <Route path="return-order/all/:poid" element={<RoReturnOrderPage />} />
            <Route path="return-order/edit/:id" element={<RoReturnOrderEditPage />} />
            <Route path="amendment-request/all/:header_id" element={<RoAmendmentPage />} />
            <Route path="amendment-request/edit/:id" element={<RoAmendmentEditPage />} />
          </Route>
          <Route path="/purchase-agreement">
            <Route path="all" element={<PurchaseAgreementArchivePage />} />
            <Route path="edit/:id" element={<PurchaseAgreementEditPage />} />
            <Route path="edit/:id/:instance_id" element={<PurchaseAgreementEditPage />} />
            <Route path="version/:id" element={<PurchaseAgreementVersionPage />} />
            <Route path="history/:id" element={<PurchaseAgreementVersionPage />} />
            <Route path="invoice/:id" element={<PurchaseAgreementVersionPage />} />
            <Route path="logs/:id" element={<PurchaseAgreementVersionPage />} />
            <Route path="good-receiving/notes/:poid" element={<BPAGoodReceivingNotesPage />} />
            <Route path="good-receiving/edit/:id" element={<PurchaseAgreementGoodReceivingNoteEditPage />} />
            <Route path="return-order/all/:poid" element={<PurchaseAgreementReturnOrderPage />} />
            <Route path="return-order/edit/:id" element={<PurchaseAgreementReturnOrderEditPage />} />
            <Route path="amendment-request/all/:header_id" element={<PurchaseAgreementVersionPage />} />
            <Route path="amendment-request/edit/:id" element={<PurchaseAgreementVersionPage />} />
          </Route>

          <Route path="/purchase-agreement-list" element={<PurchaseAgreementList />}></Route>
          <Route path="/purchase-agreement-details" element={<PurchaseAgreementDetails />}></Route>
          <Route path="/release-order-list" element={<ReleaseOrdersList />}></Route>
          <Route path="/designations" element={<VendorDestinations />}></Route>
          <Route path="/contact-types" element={<VendorContactType />}></Route>
          <Route path="/release-order-details" element={<ReleaseOrderDetails />}></Route>

          <Route path="/invoices-on-account">
            <Route path="edit/:id" element={<EditInvoiceOnAccount />} />
          </Route>

          <Route path="/credit-notes">
            <Route path="edit/:id" element={<EditCreditNote />} />
          </Route>

          <Route path="/invoice-details/:id" element={<InvoiceDetailsInternal />}>
          </Route>

          <Route path="/request-for-quotation-reply/:id" element={<RFQVendorReply />}></Route>
          <Route path="/contract-dashboard" element={<ContractReportScreen />}></Route>

          <Route path="/history-logs" element={<LogsSearch />}></Route>
          <Route path="/logs-details" element={<LogsDetails />}></Route>
          <Route path="/notifications" element={<NotificationsScreen />}></Route>
          <Route path="/notification-events" element={<NotificationEventsArchieve />} />
          <Route path="/questionnaire-list" element={<QuestionnaireList />} />
          <Route path="/question-group" element={<QuestionGroup />} />
          <Route path="/question-details" element={<QuestionDetails />} />
          <Route path="/answer-groups" element={<AnswerGroup />} />
          <Route path="/answer-details" element={<AnswerDetails />} /> 
          <Route path="/new-questionnaire" element={<NewQuestionnaire />} />
          <Route path="/edit-questionnaire/:id" element={<NewQuestionnaire />} />
          <Route path="/questionnaire" element={<QuestionnaireScreen />} />
          <Route path="/questionnaire-form" element={<TestQuestionnaire />} />
          <Route path="/questionnaire-integration" element={<QuestionnaireIntegration />} />
          <Route path="/auction" element={<AuctionScreen />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/budget-entry-list" element={<BudgeEntryList />}></Route>
          <Route path="/edit-budget-entry/:id" element={<BudgetEntryEdit />}></Route>
          <Route path="/budget-transfer-list" element={<BudgetTransferList />}></Route>
          <Route path="/edit-budget-transfer/:id" element={<BudgetTransferEdit />}></Route>
          <Route path="/terms-and-conditions" element={<TermsAndConditionsScreen />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicyScreen />}></Route>

          <Route path="*" element={<Error404 />} />
        </React.Fragment>
      </Routes>
  );
}

export default App;