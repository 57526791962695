import React, {useState, useEffect, Fragment, useCallback, useRef} from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import DropDownInput from '../../Forms/Dropdown';
import Checkbox from '../../Forms/Checkbox';
import Input from '../../Forms/Input';
import $ from 'jquery'
import AgGrid from '../../grid/ag/ag-grid';
import Collapse from '../../inc/Collapse';
import AddressList from '../../Tenant/Address/AddressList';
import ContactAddress from '../../Tenant/Address/ContactAddress';
import Tabs from '../../Widgets/Tabs/Tabs';
import SitesHierarchy from '../Sites Hierarchy/SitesHierarchy';
import SimpleLoading from '../../Loading/SimpleLoading';
import DatepickerFunctions from '../../../inc/DatepickerHelper';  
import DateEditor from "../../grid/ag/cellEditor/CustomDatePicker";
import { checkIfArrayIsEmpty, customDateComparator, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import SegmentsDistribution from '../../Segments/SegmentsDistribution';
import { gridIdActions } from '../../../store/grid-id';
import BanksEdit from './BanksEdit';
import AgGridNew from '../../grid/ag/ag-grid-new';
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown';
import { getLabelFromDropdown, getNewValue, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import Dropdown from '../../Forms/Dropdown';

let vendorsite_id = null
let addresssource_id = null 
let contactsource_id = null
let gridApi = null  
let totalDropdownPageEntityItem = 1;

const VendorSitesEdit = ({language, version_id=null, parentFormLoading, version=false, setDefaultProcurementSettingsValues=() => {}, CreateDistributions, disableFields, getDataFromVendorSite, vendorSitesLoading, vendorSites, DeleteVendorSite, currency, id, vendorSiteHierarchy, editVendorSite, setEditVendorSite}) => {

    // Getting the Respective Site Selected by the user, from the vendor sites list ..--
    const SelectedVendorSite = useSelector(state => state.gridIdFlow.vendorSiteId) 
    const dispatch = useDispatch()

    const paymentTermDataRef = useRef([]);

    const [sitesLoading, setSitesLoading] = useState(false) 
    const [paymentTerms, setPaymentTerms] = useState([])
    const [vendorGroup, setVendorGroup] = useState([])
    const [taxGroup, setTaxGroup] = useState([])
    const [vendorSiteEntity, setVendorSiteEntity] = useState([])
    const [vendorOnHold, setVendorOnHold] = useState([]) 

    // API Reduction Calls 
        let vendorSitesIdCalled = false
        let vendorOnHoldListCalled = false
        let vendorGroupListCalled = false
        let paymentTermsListCalled = false
        let vendorEntityListCalled = false
        let taxGroupListCalled = false
    // ----

    let sites_navs = [
        { title: Helper.getLabel(language, 'general', 'General'), forceLoadContents:true, isActive: true },
        { title: Helper.getLabel(language, 'banks', 'Banks'), forceLoadContents:true},
        { title: Helper.getLabel(language, 'entity', 'Entity'), forceLoadContents:true },
        { title: Helper.getLabel(language, 'address', 'Address'), forceLoadContents:true },
        { title: Helper.getLabel(language, 'contact', 'Contact'), forceLoadContents:true }
    ];

    const gridEntityColsHeader = [ 
        {
            field: 'entity_id',
            headerName: 'Entity',
            valueGetter: (params) => {
                if(!params?.data?.entity_name || !params?.data){
                    return '-'
                }
                else {
                    return params?.data?.entity_name
                }
            },
            editable: false,
            cellEditor: SimpleDropdown,
            isAllowZero:true,
            minWidth:200, 
            valueFormatter: (params)=> getLabelFromDropdown(params),
            filter:'agTextColumnFilter'
        },
        {
            field: 'vendorsite_id',
            headerName: 'Site',
            editable: false,
            cellEditor: SimpleDropdown,
            isAllowZero:true,
            minWidth:200,
            cellEditorParams: {
                values: vendorSites
            },
            cellDataType:false,
            valueFormatter: (params)=> getLabelFromDropdown(params),
            filter:'agSetColumnFilter',
            filterParams: {
                values: (params) => getOptions(params, vendorSites),
                keyCreator: (params) => optionsKeyCreator(params, 'value'),
                valueFormatter: params => params.value.label
            }
        },
        {
            field:'onhold', editable: !disableFields, headerName: 'On hold', 
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            cellRendererParams: { disabled: true },
            filter: 'agSetColumnFilter', 
            filterParams: { 
                values: [true, false], 
                keyCreator: (params) => params.value,
                valueFormatter: params => params.value ? 'true' : 'false'
            },
            valueGetter:(params) => {
                if(!params?.data?.onhold || !params?.data){
                    return false
                }
                else {
                    return params.data?.onhold
                }
            }
        },
        {
            field: 'hold_releasedate',
            headerName: 'On hold release date',
            filter:'agDateColumnFilter', 
            valueFormatter: (params) => { 
             return params.value
                ? DatepickerFunctions.convertDateFromDataBase(params.value)
                : '-'
            },
            editable: !disableFields,
            cellEditor: DateEditor,
            minWidth: 200,
            comparator: customDateComparator,
        },
        {
            field: 'hold_onoperation',
            headerName: 'On hold operations',
            editable: !disableFields,
            cellEditor: SimpleDropdown,
            isAllowZero:true,
            minWidth:200,
            cellEditorParams: {
                values: vendorOnHold
            },
            cellDataType:false,
            valueFormatter: (params)=> getLabelFromDropdown(params),
            filter:'agSetColumnFilter',
            filterParams: {
                values: (params) => getOptions(params, vendorOnHold),
                keyCreator: (params) => optionsKeyCreator(params, 'value'),
                valueFormatter: params => params.value.label
            }
        }
    ];

    useEffect(() => {
        VendorGroupList()
        TaxGroupList()
        PaymentTermsList()
        VendorOnHoldList()
        VendorSiteEntities()
    }, [])

    useEffect(() => {
        if(SelectedVendorSite) getVendorSitesBySitesId();
    }, [SelectedVendorSite, parentFormLoading])
    
    const VendorOnHoldList = () => {
        if(vendorOnHoldListCalled){
            return;
        }
        let api = Api
        vendorOnHoldListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/enum/vendoronholdoperation`,{}).then(function(res){
            if(res.data.status==="success"){ 
                let UPDATED_STATE = []
                res.data.data.forEach((element) => {
                    UPDATED_STATE.push({
                        value: element.id,
                        label: element.name
                    })
                })
                setVendorOnHold(UPDATED_STATE) 
            }
        })
    }

    const PaymentTermsList = () => {
        if(paymentTermsListCalled) {
          return;
        }
        let api = Api
        paymentTermsListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+`/paymentterm?active=true`,{}).then(function(res){
          if(res.data.status==="success"){ 
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
             UPDATED_STATE.push({
              value: element.paymentterm_id,
              label: element.name
            })
          })
          setPaymentTerms(UPDATED_STATE) 
          }
        })
    }
    
    const VendorGroupList = () => {
        if(vendorGroupListCalled) {
        return;
        }
        let api = Api
        vendorGroupListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+`/vendorgroup`,{}).then(function(res){
        if(res.data.status==="success"){ 
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
                UPDATED_STATE.push({
                    value: element.vendorgroup_id,
                    label: element.name
                })
            })
            setVendorGroup(UPDATED_STATE) 
        }
        })
    }

    const TaxGroupList = () => {
        if(taxGroupListCalled) return;
        let api = Api
        taxGroupListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/taxgroup`,{}).then(function(res){
            if(res.data.status==="success"){ 
                let UPDATED_STATE = []
                res.data.data.forEach((element) => {
                    UPDATED_STATE.push({
                        value: element.taxgroup_id,
                        label: element.name
                    })
                })
                setTaxGroup(UPDATED_STATE) 
            }
        })
    }
 
    const VendorSiteEntities = () => {
        if(vendorEntityListCalled){
          return;
        } 
        let api = Api
        vendorEntityListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/get_entities`).then(function(res){
          if(res.data.status==="success"){ 
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
             UPDATED_STATE.push({
              value: element.entity_id,
              label: element.name
            })
          })
          setVendorSiteEntity(UPDATED_STATE)  
          }
        })
    }
    
    const getVendorSitesForVersions = async () => {        
        if(!SelectedVendorSite || checkIfArrayIsEmpty(vendorSiteHierarchy.data)) return;
        setSitesLoading(true);

        const vendorSiteObject = vendorSiteHierarchy?.data?.find(val => val.id === SelectedVendorSite);
        if(vendorSiteObject) setEditVendorSite(vendorSiteObject);

        setSitesLoading(false);
    }

    const getVendorSitesBySitesId = () => { 
        if(version) {
            getVendorSitesForVersions()
            setSitesLoading(false)
            return;
        };

        let siteID = SelectedVendorSite ? SelectedVendorSite : null
        if(vendorSitesIdCalled || !siteID){
            return;
        }
        setSitesLoading(true)
        if(SelectedVendorSite !== undefined){ 
            let api = Api
            vendorSitesIdCalled = true
            api.setUserToken()
            api.axios().get(Settings.apiVendorUrl+`/vendorsite/${SelectedVendorSite}`,{}).then(function(res){
                if(res.data.status==="success"){   
                    addresssource_id = res.data.data.addresssource_id
                    contactsource_id = res.data.data.contactsource_id
                    vendorsite_id = res.data.data.vendorsite_id
                    getDataFromVendorSite(res.data.data) 
                    setEditVendorSite(res.data.data)
                    setSitesLoading(false)
                } else {
                    setSitesLoading(false)
                }
            }).catch((err) => { setSitesLoading(false) })
        }
    }

    const CreateNewVendorSite = () => { 
        $('.treeitem').removeClass('treeview_sub_item_active'); 
        dispatch(gridIdActions.VendorID({payload: null})) 
        setEditVendorSite({});
        setDefaultProcurementSettingsValues();
    }

    const ContactCreateVendorSite = (data) => {
       if(contactsource_id !== null){
         return;
       }
       contactsource_id = data.contact_source_id
    }

    const AddressCreateVendorSite = (data) => {
        if(addresssource_id !== null){
            return;
        }
        addresssource_id=data?.address_source_id
    }

    const onRowValueChanged = (event) => {  
        updateEntity(event)
    }
  
    const updateEntity = async (event) => {
        if(!id) return;

        let payload = {
            entity_id: parseInt(event?.data?.entity_id),
            vendor_id: parseInt(id),
            vendorsite_id: parseInt(event?.data?.vendorsite_id),
            onhold: event?.data?.onhold,
            hold_releasedate: DatepickerFunctions.gridDateForDatabase(event?.data?.hold_releasedate),
            hold_onoperation: parseInt(event?.data?.hold_onoperation),
            reason_id: parseInt(event?.data?.reason_id)
        };

        try {
            let api = Api
            api.setUserToken()
            const entityResponse = await api
                .axios()
                .put(Settings.apiVendorUrl+`/vendorentity/${parseInt(event?.data?.entity_id)}/${parseInt(event?.data?.vendorsite_id)}/${parseInt(id)}`, payload)
            if(entityResponse){
                getVendorSitesBySitesId()
            } 
            setTimeout(() => {
                gridApi?.refreshServerSide({ purge: true })
                gridApi?.deselectAll();
            }, 300)
            Helper.alert(entityResponse?.data?.message)
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    };
   
    // Handlers --

    const CurrencyHandler = (event) => {
        let Currency = event.target.value 
        setEditVendorSite((prevState) => {
          return {...prevState, currency_id: parseInt(Currency)}
        })
    }

    const NameSiteHandler = (event) => {
        let name = event.target.value 
        setEditVendorSite((prevState) => {
            return {
                ...prevState,
                name: name
            }
        })
    }
  
    const TaxPayerIdHandler = (event) => {
        let tax = event.target.value
        setEditVendorSite((prevState) => {
        return {
            ...prevState,
            taxpayer_id: tax
        }
        })
    }
   
    const TaxRegistrationHandler = (event) => {
        let tax_registration_number = event.target.value
        setEditVendorSite((prevState) => {
            return {
                ...prevState,
                taxregistration_number: tax_registration_number
            }
        })
    }
    
    const ExcludeFreightHandler = (name, value) => { 
        if(value == "on"){
            setEditVendorSite((prevState) => {
                return {
                    ...prevState,
                    exclude_freight_from_discount: true
                }
            })
        }else{
            setEditVendorSite((prevState) => {
                return {
                    ...prevState,
                    exclude_freight_from_discount: false
                }
            })
        } 
    }

    const DefaultSiteHandler = (name, value) => {
        if(value == "on"){
            setEditVendorSite((prevState) => {
                return {
                    ...prevState,
                    is_default: true
                }
            })
        }else{
            setEditVendorSite((prevState) => {
                return {
                    ...prevState,
                    is_default: false
                }
            })
        } 
    }

    const PriceTaxHandler = (name, value) => {
        if(value == "on"){
            setEditVendorSite((prevState) => {
                return {...prevState, price_include_tax: true }
            })
        }else {
            setEditVendorSite((prevState) => {
                return {...prevState, price_include_tax: false }
            })
        }
    }

    const VendorGroupHandler = (event) => {
        let vendor_group = event.target.value 
        setEditVendorSite((prevState) => {
          return {
            ...prevState, 
            vendor_group_id: parseInt(vendor_group)
          }
        })
    }

    const TaxGroupHandler = (event) => {
        let tax_group = event.target.value 
        setEditVendorSite((prevState) => {
          return {
            ...prevState, 
            taxgroup_id: parseInt(tax_group)
          }
        })
    }
 
    const PaymentTermsHandler = (event) => {
        let payment = event.target.value 
        setEditVendorSite((prevState) => {
            return {
                ...prevState, 
                paymentterm_id: parseInt(payment)
            }
        })
    } 

    const mapPaymentTermData = (item) => {
        return {
            text: `${item.name}`,
            id: item.paymentterm_id
        };
    }

    // Vendor Sites Module ---

    const VendorSitesModule = () => {
        return (   
        <Collapse title={Helper.getLabel(language,'sites','Sites')} className="mb-4" open={true}> 
            <Tabs disableSticky={true} navs={sites_navs} showAll={false} scrollOffset="500">
                <div className='container-fluid general_vn_site_sc'>
                    <div className='row general_vn_site_sc_row'>
                        <div className='col-12 border-1 border-bottom mb-2'>
                            <div className='sites_action_btns d-flex justify-content-start align-items-center'>
                                <div className='rs_h_controller me-2 mb-2'>
                                    {!version && <button disabled={disableFields} onClick={CreateNewVendorSite}><img src='/images/icons/add-new.svg'  className={!disableFields ? 'img-fluid mb-1' : 'img-fluid disable-img mb-1'} /> Add Site</button>}
                                </div>

                                <div className='remove_btn mb-2'>
                                    { !disableFields && <button disabled={disableFields} onClick={DeleteVendorSite}><img src='/images/icons/delete.svg'  className={!disableFields ? 'img-flu mb-1id' : 'img-fluid disable-img mb-1'} /> Delete</button> }
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 mb-5 col-md-6'>
                            <div className='hierarchy-border'>
                                <SitesHierarchy items={vendorSiteHierarchy} />
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-12'>
                            {sitesLoading ? <SimpleLoading /> : VendorSitesFields()} 
                        </div>
                    </div>
                </div>
                <div className='container-fluid banks'>
                    {sitesLoading ? <SimpleLoading /> : BanksModuleForVendorSites()}
                </div>
                <div className='container-fluid entity'>
                    {sitesLoading ? <SimpleLoading /> : EntityModuleForVendorSites()}
                </div>
                {/* <div className='container-fluid cost-segment'>
                    {sitesLoading ? <SimpleLoading /> : CostSegmentModule()}
                </div> */}
                <div className='container-fluid address'>  
                    {sitesLoading ? <SimpleLoading /> : AddressModuleForVendorSites()}
                </div>
                <div className='container-fluid contact'>
                    {sitesLoading ? <SimpleLoading /> : ContactModuleForVendorSites()}
                </div>
            </Tabs>
        </Collapse> 
    )}

    const VendorSitesFields = () => {
        return (
            <form>
                <div className='row'>
                    <div className='col-lg-6'> 
                        <Input maxLength={100} disable={disableFields} label={Helper.getLabel(language,'vendor_site_name',"Name")} type='text' value={editVendorSite.name} onChange={NameSiteHandler} required={true} />
                    </div>
                    <div className='col-lg-6'> 
                        <DropDownInput placeHolder={'Select currency'} reRenderRequired={true} isDisable={disableFields} label={Helper.getLabel(language,'currency_id',"Currency")} onChange={CurrencyHandler} options={currency} value={editVendorSite.currency_id} required={true} id='currency_id' />
                    </div>
                    <div className='col-lg-6'>
                        <Dropdown
                            label={Helper.getLabel(language,'payment_terms',"Payment terms")}
                            isDisable={disableFields} 
                            options={ 
                                    editVendorSite.paymentterm_id
                                ? 
                                    [{
                                        value: editVendorSite.paymentterm_id,
                                        label: editVendorSite?.paymentterm_name ?? paymentTermDataRef?.current?.find(item => parseInt(item.paymentterm_id) === parseInt(editVendorSite.paymentterm_id))?.name,
                                    }]
                                : 
                                    paymentTerms
                            }
                            apiUrl={Settings.apiVendorUrl+`/paymentterm?active=true`} 
                            pagination={true}
                            mapFunction={mapPaymentTermData} 
                            allDataRef={paymentTermDataRef}  
                            placeHolder={`${Helper.getLabel(
                                language,
                                "select_payment_terms",
                                'Select payment terms'
                            )}`}
                            id="select_payment_id"
                            reRenderRequired={true}
                            onChange={PaymentTermsHandler}
                            value={editVendorSite?.paymentterm_id}
                        />
                    </div>
                    <div className='col-lg-6'>
                        <DropDownInput placeHolder={'Select vendor group'} reRenderRequired={true} isDisable={disableFields} label={Helper.getLabel(language,'vendor_group_id',"Vendor group")} options={vendorGroup} onChange={VendorGroupHandler} value={editVendorSite.vendor_group_id} required={true} id='vendor_group_id' />
                    </div>
                    <div className='col-lg-6'>
                        <DropDownInput placeHolder={'Select tax group'} reRenderRequired={true} isDisable={disableFields} label={Helper.getLabel(language,'taxgroup_id',"Tax group")} options={taxGroup} onChange={TaxGroupHandler} value={editVendorSite.taxgroup_id} id='taxgroup_id' />
                    </div>
                    <div className='col-lg-6'> 
                        <Checkbox disableWithoutIcon={disableFields} label={Helper.getLabel(language,'price_include_tax',"Price include tax")} type='checkbox' isChecked={editVendorSite.price_include_tax} onChange={PriceTaxHandler} />
                    </div>
                    {/* <div className='col-lg-6'> 
                        <Checkbox disableWithoutIcon={disableFields} label={Helper.getLabel(language,'exclude_freight_discount',"Exclude freight from discount")} type='checkbox' isChecked={editVendorSite.exclude_freight_from_discount} onChange={ExcludeFreightHandler} />
                    </div> */}
                    <div className='col-lg-6'> 
                        <Checkbox disableWithoutIcon={disableFields} label={Helper.getLabel(language,'default',"Default")} type='checkbox' isChecked={editVendorSite.is_default} onChange={DefaultSiteHandler} />
                    </div>
                </div>
            </form>
        )
    }

    const BanksModuleForVendorSites = () => {
        return (
            <BanksEdit
                disableFields={disableFields}
                currency={currency}
                version={version}
                version_id={version_id}
                id={SelectedVendorSite}  
            />
        )
    }

    const EntityModuleForVendorSites = () => {
        return (
            <div className='rs_entity'>
                <AgGridNew
                    apiUrl={ version && SelectedVendorSite ? `${Settings.apiVendorUrl}/vendor_sites_entities_version/${SelectedVendorSite}/${version_id}` : (!version && SelectedVendorSite ? `${Settings.apiVendorUrl}/vendorentity/${id}` : null) }
                    isAddBtnDisable={disableFields}
                    hideDeleteBtn={true}
                    additionalParams={`&site_id=${SelectedVendorSite}`}
                    filterAdditionalParams={`&site_id=${SelectedVendorSite}`}
                    key={ (SelectedVendorSite ? 'rerender' : 'no-rerender') }
                    hideAddBtn={true} 
                    onGridReady={ (params) => gridApi = params?.api }
                    pagination={true}
                    columnDefs={gridEntityColsHeader}
                    onRowValueChanged={onRowValueChanged}  
                    gridId={'vendor-sites-entity-grid'}
                />
            </div>
        )
    }

    const AddressModuleForVendorSites = () => {
        return (  
            <AddressList allBtnHide={version} disableRecords={disableFields} id='sites_address' autoReload={true} onAddressCreate={AddressCreateVendorSite} address_source_integrator={vendorsite_id} source_id ={Settings.source_id.vendorsite_source} addressSourc_id = {addresssource_id} />
        )
    }

    const ContactModuleForVendorSites = () => {
        return(
            <Collapse title='Contact' className='site_contact grid_save_btn_hide' open={true}>
                <ContactAddress allBtnHide={version} disableRecords={disableFields} id='site_contact' onContactCreate={ContactCreateVendorSite} contactsource_integrator={vendorsite_id} address_id = {null}  contactsource_id ={contactsource_id} source_id = {Settings.source_id.vendorsite_source} />
            </Collapse>
        )
    }
    // -----
 
return (
    <Fragment>
        {vendorSitesLoading ? <SimpleLoading /> : VendorSitesModule()} 
    </Fragment>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}

export default connect(mapStateToProps) (VendorSitesEdit)