import React, { useEffect, useRef, useState } from 'react'
import { ButtonSkeletonComponent, TextSkeletonComponent } from '../../Skeleton/SkeletonComponent';
import Button from '../../inc/Button';
import NavigationHeder from '../../Navigations/NavigationHeder';
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction';
import WorkFlowOffCanvas from '../../Header/WorkFlowOffCanvas';
import MasterSidebar from '../../Sidebars/MasterSidebar';
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory';
import Settings from '../../../inc/Settings';
import RFQAwardingScreen from '../RFQAwardingScreen';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RFQCommonApiCalls from '../RFQCommonApiCalls';
import Api from '../../../inc/Api';
import GeneralInformationForm from './HeaderView/GeneralInformationForm';
import Collapse from '../../inc/Collapse';
import WorkFlowCommonHeader from '../../Header/WorkFlowCommonHeader';
import TabContentWraper from '../../Widgets/Tabs/TabContentWraper';
import Helper from '../../../inc/Helper';
import WorkFlowCommonHeaderAPICalls from '../../Header/WorkflowCommonHeaderAPICalls';
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import FixedNavigationHeder from '../../Navigations/FixedNavigationHeder';
import TotalWidget from '../../Widgets/TotalWidget/TotalWidget';

const AwardingPreview = (props) => { 
    const [numberSequence, setNumberSequence] = useState({});
    const [rfqHeader, setRfqHeader] = useState({});

    let sectionName = 'request-quotation-preview-awarding-edit'; 

    const [selectedSupNav, setSelectedSupNav] = useState('General');
    const [scrollDitection, setScrollDitection] = useState("down");

    const [updateRFQ, setUpdateRFQ] = useState(null);
    const [tags, setTags] = useState(); 

    const [employeesList, setEmployeesList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [securityLevelList, setSecurityLevelList] = useState([]);
    const [quotationTypeList, setQuotationTypeList] = useState([]);
    const [paymentTermList, setPaymentTermList] = useState([]);
    const [modeOfDeliveryList, setModeOfDeliveryList] = useState([]);
    const [deliveryTermList, setDeliveryTermList] = useState([]);
    const [buyerList, setBuyerList] = useState([]);
    const [rfqStages, setRfqStages] = useState([]);
    const [allRfqStatus, setAllRfqStatus] = useState([]);
    const [targetDocuments, setTargetDocuments]=useState([]);
    const [collaborationTeams, setCollaborationTeams] = useState([]);
    const [taxGroup, setTaxGroup] = useState([]);
    const [taxGroupItem, setTaxGroupItem] = useState([]);

    // Workflow States
    const [workflowActionHistory, setWorkflowActionHistory] = useState([]);
    const [workflowEligible, setWorkflowEligible] = useState(null);
    const [workflowExist, setWorkflowExist] = useState({});

    const [showCanvas, setShowCanvas] = useState(false);
    const [showCurrentAssignees, setShowCurrentAssignees] = useState(false);
   
    const myDivRef = useRef(null);
    
    const [height, setHeight] = useState(0);
    const [loading, setLoading] = useState(true);

    const commonApiCalls = new RFQCommonApiCalls();
    const location = useLocation();

    const getSearchParam = (name) => {
        const params = new URLSearchParams(location.search);
        return params.get(name);
    };

    useEffect(() => { 
        Helper.resizeWindowHandle(myDivRef, setHeight, window)
    }, [myDivRef?.current?.clientHeight, scrollDitection]);
 
    useEffect(() => {  
        getRFqHeaderData();
        getWorkFlowCalls();
        getAsyncData();
        getRfqStatus();
        getTargetDocuments();
        getCollabTeamAsyncData();
    }, []);

    useEffect(() => {
        getTags();
    }, [rfqHeader]);

    const getRFqHeaderData = async () => {
        let rfqHeaderData = null;

        let api = Api;
        api.setUserToken();

        setLoading(true);

        try {
            rfqHeaderData = await commonApiCalls.getRFQHeaderByID(
                getSearchParam('rfq_view_id')
            )
            if (rfqHeaderData) {
                setRfqHeader(rfqHeaderData)
                let numberSequenceData = null;

                try {
                    numberSequenceData = await api.axios().get(Settings.apiUrl + `/ns_status/${rfqHeaderData?.entity_id}/${rfqHeaderData?.rfq_type===2?Settings.source_id.auction:Settings.source_id.request_for_quotation}`);
                    setNumberSequence(numberSequenceData?.data)
                } catch (error) {
                    console.error('Failed to fetch number sequence data:', error);
                }
                setLoading(false);
            } else {
                setLoading(false);
                setRfqHeader(rfqHeaderData);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    };

    const getAsyncData = async () => { 
        try {
            const employeesData = await commonApiCalls.getAllEmployees();
            const currenciesData = await commonApiCalls.getAllCurrencies();
            const securityLevelData = await commonApiCalls.getRfqSecurityLevels();
            const quotationTypeData = await commonApiCalls.getRfqQuotationTypes();
            const paymentTermsData = await commonApiCalls.getPaymentTerms();
            const modeOfDeliveryData = await commonApiCalls.getModeOfDelivery();
            const deliveryTermsData = await commonApiCalls.getDeliveryTerms();
            const buyersListData = await commonApiCalls.getAllBuyers();
            const rfqStagesList = await commonApiCalls.getAllRfqStages();
            const taxGroupListData = await commonApiCalls.getAllTaxGroups();
            const taxGroupItemListData = await commonApiCalls.getAllTaxGroupItems();
      
            setEmployeesList(employeesData);
            setCurrencyList(currenciesData);
            setSecurityLevelList(securityLevelData);
            setQuotationTypeList(quotationTypeData);
            setPaymentTermList(paymentTermsData);
            setModeOfDeliveryList(modeOfDeliveryData);
            setDeliveryTermList(deliveryTermsData);
            setBuyerList(buyersListData);
            setRfqStages(rfqStagesList);
            setTaxGroup(taxGroupListData);
            setTaxGroupItem(taxGroupItemListData);
        } catch (err) {
            console.log(err);
        }
    };

    const getWorkFlowCalls = async () => {
        const workflowCalls = new WorkFlowCommonHeaderAPICalls();

        const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(Settings.source_id.rfq_awarding, getSearchParam("rfq_view_id"));

        const trigger = await workflowCalls.getWorkFlowTriggers(); 
        const mode = !checkIfArrayIsEmpty(trigger) && trigger?.find(id => id.name == "Update")?.id;
 
        const workflowExist = await workflowCalls.getWorkFlowInstanceExist(props.auth?.user?.tenant_id, Settings.source_id.rfq_awarding, getSearchParam("rfq_view_id"));  
        const eligibleWorkflow = await workflowCalls.checkWorkFlowForSpecificModule(Settings.source_id.rfq_awarding, props.auth?.user?.tenant_id, mode); 
  
        if(workflowActionHistory) setWorkflowActionHistory(workflowActionHistory);
        if(workflowExist) setWorkflowExist(workflowExist);
        if(eligibleWorkflow) setWorkflowEligible(eligibleWorkflow);
    }
  
    const getRfqStatus = () => {
        const api = Api;
        api.setUserToken();
        api.axios()
            .get(`${Settings.loginUrl}/enum/RFQ_status`)
            .then((res) => {
                setAllRfqStatus(res?.data?.data);
            })
            .catch((error) => console.log("Error in RFQ_status: ", error));
    };

    const getTargetDocuments = () => {
        const api = Api;
        api.setUserToken();
        api.axios()
            .get(Settings.loginUrl + `/enum/pr_targetdocumenttype`)
            .then((res) => {
                const filteredData = res?.data?.data?.filter(item => item.name !== 'RFQ' && item.name !== 'Release Order');
                setTargetDocuments(filteredData);
            })
            .catch((error) => console.log("Error in RFQ_status: ", error));
    };

    const getCollabTeamAsyncData = async () => {
        try {
            if (getSearchParam('rfq_view_id')) {
                const teamsData = await commonApiCalls.getAllTeamSourceLines(
                    Settings?.source_id?.request_for_quotation,
                    getSearchParam('rfq_view_id')
                );
                if (teamsData) {
                    setCollaborationTeams(teamsData); 
                }
            }
        } catch (err) {
          console.log(err);
        }
    };

    let teamData = collaborationTeams?.find((item)=> item.employee_id === props?.auth?.user?.employee_id)

    const getRFQStatusNameById = () => {
        let rfq_status = null;
        if (getSearchParam("rfq_view_id")) {
            rfq_status = rfqHeader?.rfq_status;
        }
        const status = allRfqStatus?.find((el) => el.id === rfq_status);
        return status?.name;
    };
 
    const handleAwardingRFQ = () => {
        let api = Api;
          api.setUserToken();
          setLoading(true)
          api
            .axios()
            .get(
                Settings.apiPurchaseOrderUrl +
                `/awarded/${getSearchParam('rfq_view_id')}`  
            )
            .then((res) => {
                setLoading(false)
                setRfqHeader({...rfqHeader,rfq_status:3})
                Helper.alert(res?.data?.message, "success");
            })
            .catch((error) => {
                getPopupMessageBasedOnStatus(error)
                setLoading(false)
            });
    }

    const submitWorkflow = () => {};

    const getTags = () => {
        let tags = [
            {
                title: Helper.getLabel(
                props.language,
                    getRFQStatusNameById()?.toLowerCase(),
                    getRFQStatusNameById()
                ),
                type: "error"
            }
        ];
        setTags(tags);
    };

    const addComponentRight = () => {
        return (
            <div className='parent-sidebar-cn'>
                <MasterSidebar>
                    <NoteAttachmentHistory
                        openCanvas={setShowCanvas}
                        openCurrentAssignees={setShowCurrentAssignees}
                        hideHistory={!workflowActionHistory?.history?.length}
                        historyArray={workflowActionHistory}
                        source_id={Settings.source_id.rfq_awarding} 
                        notesource_integrator={getSearchParam('rfq_view_id')}
                        attachmentsource_integrator={getSearchParam('rfq_view_id')}
                        scrollDitection={scrollDitection}
                        height={height}
                    />
                </MasterSidebar>
        
                {showCanvas &&
                    <WorkFlowOffCanvas
                        isOpen={showCanvas}
                        type={{ label: "Action History", value: 9 }}
                        closeSidebar={setShowCanvas}
                        source_id={Settings.source_id.rfq_awarding}
                        source_item_id={getSearchParam('rfq_view_id')}
                    />
                }

                {showCurrentAssignees && 
                    <WorkFlowOffCanvas
                        isOpen={showCurrentAssignees}
                        type={{ label: "View current assignment", value: 43 }}
                        closeSidebar={setShowCurrentAssignees}
                        source_id={Settings.source_id.rfq_awarding}
                        source_item_id={getSearchParam('rfq_view_id')}
                    />
                }
            </div>
        )
    };

    const addComponentTop = () => {
        let items = [
            {
                title: Helper.getLabel(props.language, "rfq_number", "RFQ Number"),
                subtitle: !rfqHeader ? ( <TextSkeletonComponent /> ) : rfqHeader?.document_number || "-",
            },
            {
                title: Helper.getLabel(
                props.language,
                "document_type",
                "Document Type"
                ),
                subtitle: !rfqHeader ? ( <TextSkeletonComponent /> ) : rfqHeader?.quotation_type ?? "-",
            },
            {
                title: Helper.getLabel(
                props.language,
                "title",
                "RFQ Title"
                ),
                subtitle: !rfqHeader ? ( <TextSkeletonComponent /> ) : (rfqHeader?.title?.length > 30) ? ( rfqHeader?.title?.substring(0, 30) + "..." || "-" ) : rfqHeader?.title || "-",
            },
            {
                title: Helper.getLabel(props.language, "publish_Date", "Publish Date and Time"),
                subtitle: !rfqHeader ? ( <TextSkeletonComponent /> ) : DatepickerFunctions.convertDateTimeFromDataBase(rfqHeader?.publish_datetime) || "-",
            },
            {
                title: Helper.getLabel(
                props.language,
                "expiry_date",
                "Expiry Date and Time"
                ),
                subtitle: !rfqHeader ? ( <TextSkeletonComponent /> ) : DatepickerFunctions.convertDateTimeFromDataBase(rfqHeader?.expiry_datetime) || "-" 
            }
        ];
        
        return (
            <div className={`rfq_detail_form mt-4`}>
                <div className="rfq-internal-blue">
                    <BlueCallToAction
                        scrollDitection={scrollDitection}
                        items={items}
                        tags={tags}
                    />
                </div>
            </div>
        )
    };

    const DefaultNavs = [
        {title: Helper.getLabel(props.language,'general','General') },
        {title: Helper.getLabel(props.language,'technical_evaluation','Awarding')}
    ];

    return (
        <div className="scrollBarBlinkControl">
            <div ref={myDivRef}>
                <NavigationHeder
                    removeMargin={true}
                    backUrl={getSearchParam('return') === 'rfqheaderlist' ? '/request-for-quotation/rfqheaderlist' : '/dashboard'}
                    title={Helper.getLabel(
                        props?.language,
                        "request_for_quotation",
                        "Request for Quotation"
                    )} 
                    hideMoreBtn={true}
                >
                    {!rfqHeader ? (
                        <ButtonSkeletonComponent /> 
                    ) : (
                        <>
                            <Button
                                isActive={false}
                                className="rfq_vendor_reply_publish_btn"
                                title={Helper.getLabel(props.language, "award_rfq", "Award RFQ")}
                                onClick={handleAwardingRFQ}
                                isDisable={rfqHeader?.rfq_status!==7 || rfqHeader?.onhold || rfqHeader?.rfq_status===2}
                            />

                            <TotalWidget
                                source_id={Settings.source_id?.request_for_quotation}
                                integrator={getSearchParam('rfq_view_id')}
                                isDisable={rfqHeader?.onhold || rfqHeader?.rfq_status===2?true:rfqHeader?.rfq_status !== 7 && rfqHeader?.rfq_status !== 3}
                            />
                        </>
                    )}
                </NavigationHeder>   

                <div style={{marginTop: '15px'}}> 
                    {workflowExist?.instance_exist ?
                        <WorkFlowCommonHeader 
                            submitWorkflowAction={
                                !workflowExist?.instance_exist &&
                                workflowEligible &&
                                submitWorkflow
                            }
                            workflowInstanceDetails={workflowExist?.instance_details}
                            instance_task_id={getSearchParam('instance_id')}
                            module_id={!getSearchParam('instance_id') ? Settings.source_id.rfq_awarding : null}
                            source_id={Settings.source_id.rfq_awarding}
                            source_item_id={getSearchParam('rfq_view_id')}
                            SubmitWorkflowObjDetails={workflowExist?.workflow_name}
                            module_item_id={!getSearchParam('instance_id') ? getSearchParam('rfq_view_id') : null}
                            workflowInstanceExist={workflowExist?.instance_exist}
                            workflowEligible={workflowEligible}
                            reloadFunction={getWorkFlowCalls}
                            workflowName={"RFQ Awarding Workflow"}
                        />
                    : ''}
                </div>

                <FixedNavigationHeder
                    addComponentTop={addComponentTop}
                    sectionName={sectionName}
                    navs={[]}
                    scrollDitection={scrollDitection} 
                    selectedSupNav={selectedSupNav}
                    setSelectedSupNav={setSelectedSupNav}
                    superNav={DefaultNavs} 
                />
            </div>

            <TabContentWraper
                height={height}
                setScrollDitection={setScrollDitection}
                rightSideContent={addComponentRight}
                leftSideContent = 
                {
                    <>
                        {rfqHeader &&
                            selectedSupNav == 'General' && ( 
                                <div className="lists_contant one" id={`${sectionName}General`} style={{ position: "relative" }}>
                                    <Collapse
                                        title={Helper.getLabel(
                                            props.language,
                                            "general_information",
                                            "General Information"
                                            )}
                                        open={true}
                                        className="mb-5"
                                    >
                                        <GeneralInformationForm
                                            rfqHeader={rfqHeader}
                                            setRfqHeader={setRfqHeader}
                                            updateRFQ={updateRFQ}
                                            setUpdateRFQ={setUpdateRFQ}
                                            employeesList={employeesList}
                                            currencyList={currencyList}
                                            securityLevelList={securityLevelList}
                                            quotationTypeList={quotationTypeList}
                                            paymentTermList={paymentTermList}
                                            modeOfDeliveryList={modeOfDeliveryList}
                                            deliveryTermList={deliveryTermList}
                                            isLoading={loading}
                                            rfqStatus={getRFQStatusNameById()}
                                            targetDocumentsList={targetDocuments}
                                            buyersList={buyerList}
                                            isDisabled={true}
                                            isDisableAllFields={true}
                                            user={props?.auth?.user}
                                            teamData={teamData}
                                            rfqStages={rfqStages}
                                            numberSequenceData={numberSequence}
                                            taxGroupList={taxGroup}
                                            taxGroupItemList={taxGroupItem}
                                        />
                                    </Collapse>
                                </div>
                            )
                        }
    
                        {rfqHeader &&
                            selectedSupNav === "Awarding" && ( 
                                <div className='rfq-line-view mb-4'>
                                    <RFQAwardingScreen
                                        language={props?.language}
                                        rfqHeader={rfqHeader}
                                    />
                                </div>
                            )
                        }
                    </>
                }
            /> 
        </div> 
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
};

export default connect(mapStateToProps) (MasterComponentWraper(AwardingPreview));