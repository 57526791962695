import React, {useState, useEffect, useCallback} from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings'; 
import DateEditor from "../../grid/ag/cellEditor/CustomDatePicker";
import Collapse from '../../inc/Collapse'; 
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import { customDateComparator, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getLabelFromDropdown, getNewValue, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown';

let gridApi = null;

// validation system to be integrated as well
// gridData is also an array coming vendor versioning, and it was being setted inside the grid data's state,
// businessClassificationGet for sending data previously getting it from the get api call, now what to do
const BusinessClassEdit = ({id, version=false, version_id=null, gridData, language, disableFields, onGridReady, businessClassificationGet=()=>{}, getBusinessClassifications=()=>{}}) => {
    const [vendorBusinessList, setVendorBusinessList] = useState([]) 

    let vendorBusinessListCalled = false;

    let AddBtn;
    let transaction;

    useEffect(() => {
        getVendorBusinessList()
    }, [])
   
    const getVendorBusinessList = () => {
        if(vendorBusinessListCalled){
          return;
        }
        let api = Api
        vendorBusinessListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+`/businessclassification`,{}).then(function(res){
            if(res.data.status==="success"){ 
                let UPDATED_STATE = []
                res.data.data.forEach((element) => {
                    UPDATED_STATE.push({
                        value: element.bussinessclassification_id,
                        label: element.name
                    })
                })
                setVendorBusinessList(UPDATED_STATE) 
            }
        })
    }

    const ValidationRulesForFields = {
        businessclassification_id: {
            types:['Required']
        },
        license_no: { 
            types:['Required'],
            min: 1,
            max: 50
        },
        license_effective_date: {
            types:['Required', 'datepicker']
        },
        license_expiry_date: {
            types:['datepicker']
        }
    }

    const Editable = (event) => {
        if (!event.data.vendor_id && !disableFields ) {
            return true;
        } else {
            return false;
        }
    };

    const addVendorBusinessClass = async (event) => {
        if(!id) return;

        let payload = {
            vendor_id: parseInt(id),
            business_classification_id: parseInt(event?.data?.businessclassification_id),
            license_no: event?.data?.license_no ? (event?.data?.license_no) : "",
            license_effective_date: event?.data?.license_effective_date ? DatepickerFunctions.gridDateForDatabase(event?.data?.license_effective_date) : null,
            license_expiery_date: event?.data?.license_expiry_date ? DatepickerFunctions.gridDateForDatabase(event?.data?.license_expiry_date) : null,
            status: event?.data?.status ? event?.data?.status : false
        }

        try {
            let api = Api
            api.setUserToken()
            const businessClassResponse = await api
                .axios()
                .post(Settings.apiVendorUrl+'/classificationgroup', payload)
            setTimeout(() => {
                gridApi?.refreshServerSide({ purge: true });
                gridApi?.deselectAll();
            }, 100)
            if(businessClassResponse?.data?.status==='success' && getBusinessClassifications !== null && getBusinessClassifications !== undefined && typeof getBusinessClassifications === 'function' ){
                 getBusinessClassifications(id)
            }
            setTimeout(()=>{
                if(AddBtn){
                    gridApi?.applyServerSideTransaction(transaction)
                }
            }, 1000)
            Helper.alert('Classification group added successfully')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    };

    const updateVendorBusinessClass = async (event) => {
        if(!id) return;

        let payload = {
            vendor_id: parseInt(id),
            business_classification_id: parseInt(event?.data?.businessclassification_id),
            license_no: event?.data?.license_no ? (event?.data?.license_no) : "",
            license_effective_date: event?.data?.license_effective_date ? DatepickerFunctions.gridDateForDatabase(event?.data?.license_effective_date) : "",
            license_expiery_date: event?.data?.license_expiry_date ? DatepickerFunctions.gridDateForDatabase(event?.data?.license_expiry_date) : "",
            status: event?.data?.status ? event?.data?.status : false
        };

        try {
            let api = Api
            api.setUserToken()
            const businessClassResponse = await api
                .axios()
                .put(Settings.apiVendorUrl+`/classificationgroup/${id}/${event?.data?.businessclassification_id}`, payload)
            setTimeout(() => {
                gridApi?.refreshServerSide({ purge: true })
                gridApi?.deselectAll();
            }, 100)
            Helper.alert(businessClassResponse?.data?.message)
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    };

    const DeleteBusinessClass = useCallback(async (element) => {
        let api = Api
        api.setUserToken();
        try {
            const res = await api
                .axios()
                .delete(Settings.apiVendorUrl + `/classificationgroup/${id}/${element?.data?.businessclassification_id}`)
            const rowNode = gridApi?.getRowNode(element?.data?.businessclassification_id)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi.applyServerSideTransaction(transaction)
            }, 200)
            gridApi.refreshServerSide({ purge: true })
            gridApi?.deselectAll();
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, [])

    const onRowValueChanged = (event) => {
        if (event?.data?.vendor_id) {
            updateVendorBusinessClass(event)
        } else {
            addVendorBusinessClass(event)
        }
    }

    const handleBtn = (value, transactionData) => {
        AddBtn = value
        transaction = transactionData
    }

    const getGridReadyObject = (params) => {
        if(params){
            gridApi = params?.api
            if(onGridReady){
                onGridReady(params)
            } 
        }
    }

    const handleFetchData = (response) => {
        if(typeof businessClassificationGet === 'function'){
          businessClassificationGet(response?.data?.data)
        }
        return {
          rowData: response?.data?.data?.map((item) => ({
            ...item, 
            gridId: `${Date.now()}_${getNewValue()}`
          })),
          rowCount: response?.data?.data?.length 
        }
      };

    /* Grid Column Headers */

    const getBusinessColsHeader = [
        {
            field: 'businessclassification_id',
            headerName: 'Description*',
            editable: Editable,
            cellEditor: SimpleDropdown,
            isAllowZero:true,
            minWidth:180, 
            validationRule: ValidationRulesForFields.businessclassification_id,
            cellEditorParams: {
                values: vendorBusinessList,
            },
            cellDataType:false,
            valueFormatter: (params)=> getLabelFromDropdown(params),
            filter:'agSetColumnFilter',
            filterParams: {
                values: (params) => getOptions(params, vendorBusinessList),
                keyCreator: (params) => optionsKeyCreator(params, 'value'),
                valueFormatter: params => params.value.label,
            }
        },
        { field:'license_no', filter: 'agTextColumnFilter', validationRule: ValidationRulesForFields.license_no, editable: !disableFields, minWidth: 180, headerName:'License number*'},
        {
            field: 'license_effective_date',
            headerName: 'License effective date*',
            filter:'agDateColumnFilter',
            validationRule: ValidationRulesForFields.license_effective_date,
            valueFormatter: (params) => { 
             return params.value
                ? DatepickerFunctions.convertDateFromDataBase(params.value)
                : '-'
            },
            editable: !disableFields,
            cellEditorParams: { empty: true },
            cellEditor: DateEditor,
            minWidth: 220,
            comparator: customDateComparator,
        },
        {
            field: 'license_expiry_date',
            headerName: 'License expiry date',
            filter:'agDateColumnFilter',
            validationRule: ValidationRulesForFields.license_expiry_date,
            valueFormatter: (params) => { 
             return params.value
                ? DatepickerFunctions.convertDateFromDataBase(params.value)
                : '-'
            },
            editable: !disableFields,
            cellEditor: DateEditor,
            cellEditorParams: { empty: true },
            minWidth: 220,
            comparator: customDateComparator,
        },
        {   
            field:'status', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'true' : 'false' }, 
            editable: !disableFields, headerName: 'Active', 
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            cellRendererParams: { disabled: true },
            valueGetter: (params) => {
                if(!params?.data?.status || !params?.data){
                    return false
                }
                else {
                    return params.data?.status
                }
            }
        }
    ];
console.log(id,version,'id')
    return (
        <Collapse title={Helper.getLabel(language,'business_classification','Business Classification')} className="mb-4" open={true}>
            <div className='container-fluid business_classification'> 
                <AgGridNew
                    apiUrl={id && !version ? Settings.apiVendorUrl + `/vendorbusinessclassificationgroup/${id}` : (version && id ? `${Settings.apiVendorUrl}/vendor_classification_version/${id}/${version_id}` : null)}
                    pagination={false}
                    key={ id ? 'rerender' : 'no-rerender' }
                    hideAddBtn={version}
                    isAddBtnDisable={disableFields}
                    hideDeleteBtn={disableFields}
                    columnDefs={getBusinessColsHeader}
                    onRowValueChanged={onRowValueChanged} 
                    fetchData={handleFetchData}
                    newAddItems={{status:true}}
                    handleDeleteSelectedRows={DeleteBusinessClass}
                    onGridReady={getGridReadyObject}
                    handleAddButton={handleBtn}
                    uniqueField={`businessclassification_id`}
                    isUniqueIdExist={false}
                    gridId={'vendor-business-class-common'}
                />
            </div>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
}

export default connect(mapStateToProps) (BusinessClassEdit);