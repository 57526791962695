import React, { Component, createRef } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import ActionTypes from "../../../inc/ActionsTypes";
import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import Dropdown from "../../Forms/Dropdown";
import Input from "../../Forms/Input";
import NewInputDatePicker from "../../Forms/NewInputDatePicker";
import Collapse from "../../inc/Collapse";
import Popup from "../../Popup/Popup";
import DistributionTabs from "../common/DistributionTabs";
import OverlayLoader from "../common/OverlayLoader";
import ToggleSwitch, { TitlePosition } from "../../Forms/ToggleSwitch";
import {
  getPopupMessageBasedOnStatus,
  isValidDiscountPercentage,
  isValidNumber,
} from "../../../inc/Validation";
import DatepickerFunctions from "../../../inc/DatepickerHelper";
import Button from "../../inc/Button";

class EditOldCartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      requesterList: [],
      employeeList: [],
      requester: this.props.activeCartItem?.requester_id,
      requester_name: this.props.activeCartItem?.requester_name,
      submitterList: [],
      submitter: this.props.activeCartItem?.orderer_id,
      onBehalfRequest: this.props.activeCartItem?.onbehalfrequest ?? null,
      itemDescription: this.props.activeCartItem?.description ?? null,
      accountingDate:null,
      oldDeliveryDate: null,
      deliveryDate: null,
      quantity: this.props.activeCartItem?.ordered_qty
        ? parseFloat(this.props.activeCartItem?.ordered_qty)
        : null,
      units: [],
      unit: this.props.activeCartItem?.unit ?? null,
      unitPrice: this.props.activeCartItem?.unit_price
        ? parseFloat(this.props.activeCartItem?.unit_price)
        : null,
      lineAmount: this.props.activeCartItem?.line_amount
        ? parseFloat(this.props.activeCartItem?.line_amount)
        : null,
      discountPercentage: this.props.activeCartItem?.discount_percentage
        ? parseFloat(this.props.activeCartItem?.discount_percentage)
        : null,
      discountAmount: this.props.activeCartItem?.discount_amount
        ? parseFloat(this.props.activeCartItem?.discount_amount)
        : null,
      taxGroups: [],
      taxGroup: this.props.activeCartItem?.taxgroup_id ?? null,
      taxCodes: [],
      taxCode: this.props.activeCartItem?.taxgroupitem_id
        ? parseFloat(this.props.activeCartItem?.taxgroupitem_id)
        : null,
      itemGroups:[],
      itemGroup: this.props.activeCartItem?.itemgroup_id
      ? parseInt(this.props.activeCartItem?.itemgroup_id)
      : null,
      lineNumber: this.props.activeCartItem?.line_number
      ? parseInt(this.props.activeCartItem?.line_number)
      : null,
      sites: [],
      existingSiteIds: [],
      itemType: this.props.activeCartItem?.type ?? null,
      lineTypesList: [],
      selectedVendorName: this.props.activeCartItem?.vendor_name ?? null,
      selectedVendor: this.props.activeCartItem?.vendor_id ?? null,
      oldVendor: this.props.activeCartItem?.vendor_id ?? null,
      oldVendorSite: this.props.activeCartItem?.vendorsite_id ?? null,
      selectedVendorSite: this.props.activeCartItem?.vendorsite_id ?? null,
      selectedVendorSiteName: this.props.activeCartItem?.vendorsite_name ?? null,
      itemName:this.props.activeCartItem?.line_type === 0
      ? this.props.activeCartItem?.item_name
      : this.props.activeCartItem?.line_type === 1
      ? this.props.activeCartItem?.category_name
      : this.props.activeCartItem?.comments || null,
      currentSearchTerm: '',
      currentSearchTermVendorSite: '',
      vendorList: [],
      vendorSiteList: [],
      sources: [],
      material_service_not_included:this.props.activeCartItem?.material_service_not_included ?? null,
      scope_of_work:this.props.activeCartItem?.scope_of_work ?? null,
      requirement_description:this.props.activeCartItem?.requirement_description ?? null
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.updateSitesData = this.updateSitesData.bind(this);
    this.updateExistingSiteIds = this.updateExistingSiteIds.bind(this);
    this.onCreateFirstDistribution = this.onCreateFirstDistribution.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.mapEmployeesData = this.mapEmployeesData.bind(this)
    this.vendorDataRef = createRef()
    this.vendorSiteDataRef = createRef()
    this.employeesData = createRef()
  }

  componentDidMount() {
    this.getTaxGroups();
    this.getTaxCodes();
    this.getItemGroups();
    this.getAllEmployees();
    this.getUnits();
    this.getLineTypesList();
    this.getAllVendors();
    if (this.state.oldVendor) {
      this.getVendorSitesByVendorId(this.state.oldVendor);
    } else {
      this.getAllVendorSites();
    }
    this.getExistingHeaderData();
    this.getAllSources(); 
  }

  componentDidUpdate(prevProps, prevState) {
    // set requester submitter if not set on page load
    if (
      (JSON.stringify(prevProps.userData) !==
        JSON.stringify(this.props.userData) ||
        JSON.stringify(prevState.employeeList) !==
          JSON.stringify(this.state.employeeList)) &&
      this.state.employeeList?.length > 0
    ) {
      if (
        Object.keys(this.state.requesterList).length === 0 &&
        Object.keys(this.state.submitterList).length === 0
      ) {
        const customEmployeeList = this.state.employeeList?.filter(
          (employee) =>
            employee.employee_id === this.props.userData?.employee_id
        );
        this.setState({
          requesterList: [{employee_id:this.props.userData?.employee_id,displayname:this.props.userData?.employee_name}],
          submitterList: customEmployeeList,
        });
      }
      if (!this.state.requester) {
        this.setState({
          requester: this.props.userData?.employee_id,
          requester_name:this.props.userData?.employee_name
        });
      }
      if (!this.state.submitter) {
        this.setState({
          submitter: this.props.userData?.employee_id,
        });
      }
      if(prevProps.prCart !== this.props.prCart){
        this.getExistingHeaderData()
      }
    }

    // update line_amount
    if (
      (this.state.quantity && prevState.quantity !== this.state.quantity) ||
      (this.state.unitPrice && prevState.unitPrice !== this.state.unitPrice) ||
      ((this.state.discountPercentage ||
        this.state.discountPercentage === "") &&
        prevState.discountPercentage !== this.state.discountPercentage) ||
      ((this.state.discountAmount || this.state.discountAmount === "") &&
        prevState.discountAmount !== this.state.discountAmount)
    ) {
      let discount_amount = parseFloat(this.state.discountAmount)
      if (discount_amount > parseFloat(this.state.unitPrice)) {
        discount_amount = parseFloat(this.state.unitPrice)
      }
      discount_amount = discount_amount * parseFloat(this.state.quantity)
      let totalLineAmountBeforeDiscount =  parseFloat(this.state.quantity) * parseFloat(this.state.unitPrice);
      let lineAmount = totalLineAmountBeforeDiscount -  discount_amount
      lineAmount = lineAmount - ((this.state.discountPercentage) / 100) * lineAmount
      let finalLineAmount = lineAmount
      if (finalLineAmount <= 0) {
         finalLineAmount = 0
      }
      // let baseAmount =
      //   parseFloat(this.state.quantity) * parseFloat(this.state.unitPrice);
      // let discountAmount1 =
      //   this.state.discountPercentage && this.state.discountPercentage !== ""
      //     ? (parseFloat(this.state.discountPercentage) / 100) * baseAmount
      //     : 0;
      // let discountAmount2 =
      //   this.state.discountAmount && this.state.discountAmount !== ""
      //     ? parseFloat(this.state.discountAmount * this.state.quantity)
      //     : 0;
      // let newLineAmount = baseAmount - discountAmount1 - discountAmount2;
      this.setState({ lineAmount: finalLineAmount });
    }
  }

  mapEmployeesData(item){
    return {
        id:item.employee_id,
        text:item.displayname
    }
  }

  async getExistingHeaderData() {
    let api = Api;
    api.setUserToken();
    const existingPRId = this.props.prId;

    if(!existingPRId){
      return
    }

    try {
      const headerDataRes = await api
        .axios()
        .get(Settings.apiPurchaseRequisitionUrl + "/pr_header/" + existingPRId);
      if (headerDataRes) {
        const data = headerDataRes.data?.data;
        const newState = {
          oldDeliveryDate: data?.delivery_date,
          deliveryDate:data?.delivery_date,
          accountingDate:data?.accounting_date,
          ...(!this.state.requester ? { requester: data.requester_id, requester_name: data.requester_name } : {}),
          ...(!this.state.submitter ? { submitter: data.orderer_id } : {}),
        };
        this.setState(newState);
      }
    } catch (error) {
      Helper.alert(
        error?.response?.data?.message ?? "Something went wrong",
        "error"
      );
    }
  }

  async handleFormSubmit(e) {
    e.preventDefault();
    const formData = {
      description: this.state.itemDescription,
      header_id: this.props.activeCartItem.line_id ,
      ordered_qty: parseInt(this.state.quantity),
      unit: this.state.unit,
      unit_price: parseFloat(this.state.unitPrice),
      line_amount: this.state.lineAmount,
      taxgroup_id: parseFloat(this.state.taxGroup),
      itemgroup_id:parseInt(this.state.itemGroup),
      taxgroupitem_id: parseInt(this.state.taxCode),
      discount_amount: this.state.discountAmount
        ? parseFloat(this.state.discountAmount)
        : 0,
      discount_percentage: this.state.discountPercentage
        ? parseFloat(this.state.discountPercentage)
        : 0,
      requester_id: parseFloat(this.state.requester),
      onbehalf_request: this.state.onBehalfRequest,
      vendor_id: parseInt(this.state.selectedVendor) || "",
      vendorsite_id: parseInt(this.state.selectedVendorSite) || "",
      material_service_not_included:this.state.material_service_not_included,
      scope_of_work:this.state?.scope_of_work,
      requirement_description:this.state?.requirement_description,
    };
  
    if (
      this.state.requester === null ||
      this.state.requester === undefined ||
      this.state.requester === ""
    ) {
      Helper.alert("Select requester", "error");
      return;
    }
    if (
      this.state.submitter === null ||
      this.state.submitter === undefined ||
      this.state.submitter === ""
    ) {
      Helper.alert("Select submitter", "error");
      return;
    }

    let api = Api;
    api.setUserToken();
    this.setState({ isLoading: true });

    try {
      const existingPRId = this.props?.prCart?.purchaserequistion_id;
      if (
        existingPRId &&
        this.state.oldDeliveryDate !== this.state.deliveryDate
      ) {
        await api
          .axios()
          .put(
            Settings.apiPurchaseRequisitionUrl + "/pr_header/" + existingPRId,
            {
              delivery_date: DatepickerFunctions.convertDateForDataBase(this.state.deliveryDate),
            }
          );
      }

      const res = await api
        .axios()
        .put(
          Settings.apiPurchaseOrderUrl +
            "/line/" +
            this.props.activeCartItem.line_id + "/" + Settings.source_id.purchase_requisition,
          formData
        );
      if (res.data.status === "success") {
        this.setState({ isLoading: false });
        this.props.toggleDidCartUpdate();

        if (
          ((this.state.selectedVendor &&
          this.state.selectedVendor !== this.state.oldVendor &&
          this.props.cartCount > 1) || (this.state.selectedVendorSite && this.state.selectedVendorSite !== this.state.oldVendorSite)  )
        ) {
          let vendor = this.state?.vendorList?.find((item)=>parseInt(item.vendor_id) === parseInt(this.state.selectedVendor))
          vendor={
            ...vendor,
            vendorsite_id:this.state.selectedVendorSite,
            taxgroup_id:this.state.taxGroup
          }
          this.props.updateVendorChanged(vendor);
          this.props.showApplyVendorModal(true);
        }

        // this.props.closeEditOldCartModal();
        setTimeout(()=>{
          this.props.resetEditOldCartModal()
        },200)
        $("html").removeClass("rs_popup_open");
        Helper.alert(res.data?.message, "success");
      }

      // handle deletion of sites
      if (this.state.existingSiteIds?.length > this.state.sites?.length) {
        const deletedSiteIds = this.state.existingSiteIds.filter(
          (id) => !this.state.sites.find((site) => site.id === id)
        );

        if (deletedSiteIds.length > 0) {
          await Promise.all(
            deletedSiteIds.map((id) =>
              api
                .axios()
                .delete(
                  Settings.apiPurchaseRequisitionUrl + `/linesite/` + id + "/" + Settings.source_id.purchase_requisition
                )
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
      getPopupMessageBasedOnStatus(error)
    }
  }

  onCreateFirstDistribution(source_id,source_integator) {
    let api = Api;
    api.setUserToken();
    api
    .axios()
    .put(
      Settings.apiPurchaseOrderUrl +
        "/linesite/" +
        source_integator + "/" + Settings.source_id.purchase_requisition,
      { distributionsource_id: source_id }
    )
    .then((res) => {
      if (res) {
        this.setState({ distributionSourceId: source_id });
      }
    })
    .catch((error) => {
        getPopupMessageBasedOnStatus(error)
    });
  }

  getAllSources() {
    let api = Api;
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/source")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, sources: res.data?.data });
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getLineTypesList() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.loginUrl + `/enum/po_lineType`)
      .then(function (res) {
        if (res.data?.status === "success") {
          that.setState({
            lineTypesList: [...res.data?.data],
          });
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      });
  }

  getTaxGroups() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/taxgroup")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, taxGroups: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getTaxCodes() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/taxgroupitem")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, taxCodes: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getItemGroups() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiItemUrl + "/item_group")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, itemGroups: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getAllEmployees() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiOrgUrl + "/employee_dropdown?status=true")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, employeeList: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getUnits() {
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isLoading: true });
    api
      .axios()
      .get(Settings.loginUrl + "/unit")
      .then(function (res) {
        that.setState({
          isLoading: false,
          units: [...res.data.data],
        });
      })
      .catch((error) => {
        that.setState({ isLoading: false });
      });
  }

  getAllVendors() {
    let api = Api;
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiVendorUrl + "/get_active_vendors")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ vendorList: res.data?.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAllVendorSites() {
    let api = Api;
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiVendorUrl + "/get_active_vendor_sites")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ vendorSiteList: res.data?.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getVendorSitesByVendorId(id) {
    let api = Api;
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiVendorUrl + "/get_active_vendor_sites/" + id)
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ vendorSiteList: res.data?.data });
        }
      })
      .catch((error) => {
        console.log(error);
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  checkLineTypeName(id) {
    const type = this.state.lineTypesList?.find((obj) => obj.id === id);
    return type ? type.name : "-";
  }

  checkTaxGroupName(id) {
    const type = this.state.taxGroups?.find((obj) => obj.taxgroup_id === id);
    return type ? type.name : "-";
  }

  checkUnitName(id) {
    const type = this.state.units?.find((obj) => obj.unit_id === id);
    return type ? type.description : "-";
  }

  updateSitesData(data) {
    this.setState({ sites: data });
  }

  updateExistingSiteIds(data) {
    this.setState({ existingSiteIds: data });
  }

  mapVendorItems(item){
    return {
      id:item.vendor_id,
      text: item.name
    }
  }

  mapVendorSiteItems(item){ 
    return {
      id:item.vendorsite_id,
      text: item.name
    }
  }

  isTotalDiscountValid(percentage, amount) {
    let discount_amount = parseFloat(amount)
      if (discount_amount > this.state.unitPrice) {

        discount_amount = this.state.unitPrice
      }
      discount_amount = discount_amount * this.state.quantity
      let totalLineAmountBeforeDiscount =  parseFloat(this.state.quantity) * parseFloat(this.state.unitPrice);
      let lineAmount = totalLineAmountBeforeDiscount -  discount_amount
      lineAmount = lineAmount - ((percentage) / 100) * lineAmount
      let newLineAmount = lineAmount
      if (newLineAmount <= 0) {
         newLineAmount = 0
      }
    if (newLineAmount <= 0) return false;
    return true;
  }

  getSourceId(name) {
    for (let i = 0; i < this.state.sources?.length; i++) {
      if (this.state.sources[i]?.source_name === name) {
        return this.state.sources[i]?.source_id; 
      }
    }
    return null;
  }

  getVendorFromSite(siteId) {
    const obj = this.state.vendorSiteList?.find(
      (item) => parseInt(item.vendorsite_id) === parseInt(siteId)
    );
    if (obj) this.setState({ selectedVendor: obj?.vendor_id ?? null });
  }

  handleInputChange(e){
    this.setState({
      [e.target.name]:e.target.value
    })
  }

  
  render() {
    return (
      <>
       <Popup
        autoOpen={true}
        width="1000px"
        className="editCartModal"
        onClose={() => {
          $("html").removeClass("rs_popup_open");
          this.props.closeEditOldCartModal();
          setTimeout(()=>{
            this.props.resetEditOldCartModal()
          },200)
        }}
      >
         <div className="generalInformation-action">
          <Button 
            onClick={() => {
              this.props.closeEditOldCartModal();
              setTimeout(()=>{
                this.props.resetEditOldCartModal()
              },200)
              $("html").removeClass("rs_popup_open");
            }}
            title={Helper.getLabel(this.props.language, "Close", "Close")}
            className="black-btn-style"
          /> 
          <button
            className="generalInformation-action-solid"
            onClick={this.handleFormSubmit}
            disabled={this.props.viewOnly}
          >
            {Helper.getLabel(this.props.language, "save", "Save")}
          </button>
        </div>
        <Collapse
          title={Helper.getLabel(
            this.props.language,
            "general_distribution",
            "General Information"
          )}
          open={true}
          className="mt-4"
        >
          <form
            onSubmit={(e) => e.preventDefault()}
            className="generalInformation"
          >
            <div className="generalInformation-oldCartItem">
              <div className="generalInformation-oldCartItem-grid2">
                <Input 
                 label={Helper.getLabel(
                  this.props.language,
                  "line_number",
                  "Line number"
                )}
                onChange={(e)=>{}}
                value={this.state.lineNumber}
                disable={true} />
                <Dropdown
                  label={Helper.getLabel(
                    this.props.language,
                    "requester",
                    "Requester"
                  )}
                  key={this.state.onBehalfRequest ? 'onBehalf' : 'regular'}
                  options={
                    this.state.onBehalfRequest
                      ? this.props.activeCartItem?.requester_id ? [{
                        value:this.props.activeCartItem?.requester_id,
                        label:this.props.activeCartItem?.requester_name,
                      }] :this.state.employeeList.map((item) => {
                          return {
                            value: item.employee_id,
                            label: item.displayname,
                          };
                        })
                      : this.state.requesterList.map((item) => {
                          return {
                            value: item.employee_id,
                            label: item.displayname,
                          };
                        })
                  }
                  placeHolder={Helper.getLabel(
                    this.props.language,
                    "select_requester",
                    "Select requester"
                  )}
                  id="requester_id"
                  onChange={(e) => {
                    const requesterName = this?.employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(e.target.value))?.displayname;
                    this.setState({ requester: e.target.value , requester_name: requesterName});
                  }}
                  value={this.state.requester}
                  {...(this.state.onBehalfRequest === true
                    ? {
                      pagination:this.state.onBehalfRequest,
                      apiUrl:Settings?.apiOrgUrl + `/employee_dropdown?status=true`,
                      allDataRef:this.employeesData,
                      mapFunction:this.mapEmployeesData
                      
                      }
                    : {})}
                  isDisable={this.props.viewOnly}
                />
                <ToggleSwitch
                    title={Helper.getLabel(
                      this.props.language,
                      "on_behalf_request",
                      "On behalf request"
                    )}
                    onChange={() => {
                      this.setState(
                        {
                          onBehalfRequest: !this.state.onBehalfRequest,
                        },
                        () => {
                          if (this.state.onBehalfRequest)
                            this.setState({ requester: null });
                          else
                            this.setState({
                              requester: this.props.userData?.employee_id,
                              requester_name: this.props.userData?.employee_name
                            });
                        }
                      );
                    }}
                    margin={"mb-4 w-100"}
                    titlePosition={TitlePosition.left}
                    value={this.state.onBehalfRequest}
                    isDisable={this.props.viewOnly}
                  />
                  <Dropdown
                    label={Helper.getLabel(
                      this.props.language,
                      "submitter",
                      "Submitter"
                    )}
                    options={this?.state?.submitter ? [{value:this.props.activeCartItem?.orderer_id,label:this.props.activeCartItem?.orderer_name}] : this.state.submitterList.map((item) => {
                      return {
                        value: item.employee_id,
                        label: item.displayname,
                      };
                    })}
                    placeHolder={Helper.getLabel(
                      this.props.language,
                      "select_submitter",
                      "Select submitter"
                    )}
                    className="w-100 disabled-dropdown-class"
                    id="submitter_id"
                    onChange={(e) => {
                      this.setState({ submitter: e.target.value });
                    }}
                    value={this.state.submitter}
                    isDisable={true}
                  />
              </div>
              <div className="generalInformation-oldCartItem-grid1">
              <Input
                  label={Helper.getLabel(
                    this.props.language,
                    "name",
                    "Name"
                  )}
                  onChange={(e)=>{}}
                  value={this.state.itemName}
                  disable={true}
                />
                <Input
                  label={Helper.getLabel(
                    this.props.language,
                    "description",
                    "Description"
                  )}
                  onChange={(e) => {
                    this.setState({ itemDescription: e.target.value });
                  }}
                  value={this.state.itemDescription}
                  disable={this.props.viewOnly}
                />
              </div>
              <div className="generalInformation-oldCartItem-grid2">
                <Input
                  label={Helper.getLabel(
                    this.props.language,
                    "quantity",
                    "Quantity"
                  )}
                  onChange={(e) => {
                    if(e.target.value === '0'){
                      return 
                    }
                    if (isValidNumber(e.target.value))
                      this.setState({ quantity: e.target.value });
                  }}
                  value={this.state.quantity}
                  disable={this.props.viewOnly}
                />
                <Dropdown
                  label={Helper.getLabel(this.props.language, "unit", "Unit")}
                  options={this.state.units.map((item) => {
                    return {
                      value: item.unit_id,
                      label: item.description,
                    };
                  })}
                  placeHolder={Helper.getLabel(
                    this.props.language,
                    "select_unit",
                    "Select unit"
                  )}
                  id="unit_of_measure_id"
                  onChange={(e) => {
                    this.setState({
                      unit: e.target.value,
                    });
                  }}
                  value={this.state.unit}
                  isDisable={this.props.viewOnly}
                />
                <Input
                  label={Helper.getLabel(
                    this.props.language,
                    "unit_price",
                    "Unit price"
                  )}
                  onChange={(e) => {
                    if (isValidNumber(e.target.value))
                      this.setState({ unitPrice: e.target.value });
                  }}
                  value={this.state.unitPrice}
                  disable={this.props.viewOnly}
                />
                <div className="generalInformation-oldCartItem-grid1-info">
                  <label>
                    {Helper.getLabel(
                      this.props.language,
                      "line_amount",
                      "Line amount"
                    )}
                  </label>
                  <span>{Helper.formateCurrency(this.state.lineAmount)}</span>
                </div>
                <Input
                  label={Helper.getLabel(
                    this.props.language,
                    "discount_amount",
                    "Discount amount"
                  )}
                  onChange={(e) => {
                    if (isValidNumber(e.target.value)) {
                      if (
                        this.isTotalDiscountValid(
                          this.state.discountPercentage,
                          e.target.value
                        )
                      )
                        this.setState({ discountAmount: e.target.value });
                    }
                  }}
                  value={this.state.discountAmount}
                  disable={this.props.viewOnly}
                />
                <Input
                  label={Helper.getLabel(
                    this.props.language,
                    "discount_percentage",
                    "Discount percentage"
                  )}
                  onChange={(e) => {
                    if (!isValidDiscountPercentage(e.target.value)) return;
                    if (
                      this.isTotalDiscountValid(
                        e.target.value,
                        this.state.discountAmount
                      )
                    )
                      this.setState({ discountPercentage: e.target.value });
                  }}
                  value={this.state.discountPercentage}
                  disable={this.props.viewOnly}
                />
                <Dropdown
                  label={Helper.getLabel(
                    this.props.language,
                    "tax_group",
                    "Tax group"
                  )}
                  options={this.state.taxGroups.map((item) => {
                    return {
                      value: item.taxgroup_id,
                      label: item.name,
                    };
                  })}
                  placeHolder={Helper.getLabel(
                    this.props.language,
                    "select_tax_group",
                    "Select tax group"
                  )}
                  id="tax_group_id"
                  onChange={(e) => {
                    this.setState({ taxGroup: e.target.value });
                  }}
                  value={this.state.taxGroup}
                  disable={this.props.viewOnly}
                  reRenderRequired={true}
                />
                <Dropdown
                  label={Helper.getLabel(
                    this.props.language,
                    "tax_group_item",
                    "Tax group item"
                  )}
                  options={this.state.taxCodes.map((item) => {
                    return {
                      value: item.taxgroupitem_id,
                      label: item.name,
                    };
                  })}
                  reRenderRequired={true}
                  placeHolder={Helper.getLabel(
                    this.props.language,
                    "select_tax_group_item",
                    "Select tax group item"
                  )}
                  id="tax_code_id"
                  onChange={(e) => {
                    this.setState({ taxCode: e.target.value });
                  }}
                  value={this.state.taxCode}
                  isDisable={this.props.viewOnly}
                /> 
                <Dropdown
                  label={`${Helper.getLabel(
                    this.props.language,
                    "vendor",
                    "Vendor"
                  )}`}
                  apiUrl={Settings.apiVendorUrl + '/get_active_vendors'}
                  currentSearchTerm={this?.state?.currentSearchTerm}
                  mapFunction={this.mapVendorItems}
                  pagination={true}
                  allDataRef={this?.vendorDataRef} 
                  options={
                    this.state?.selectedVendor
                    ? 
                      [{
                        value: this.state?.selectedVendor,
                        label: this.state?.selectedVendorName ?? this?.vendorDataRef?.current?.find(item => parseInt(item.vendor_id) === parseInt(this.state?.selectedVendor))?.name,
                      }]
                    : 
                    this.state?.vendorList?.map((item) => {
                      return {
                        value: item.vendor_id,
                        label: item.name,
                      };
                    })
                  }
                  placeHolder={`${Helper.getLabel(
                    this.props.language,
                    "select_vendor",
                    "Select vendor"
                  )}`}
                  id="vendor_id"
                  reRenderRequired={true}
                  onChange={(e) => {
                    let vendor = this.state?.vendorList?.find((item)=>parseInt(item.vendor_id) === parseInt(e.target.value))
                    this.setState({
                      selectedVendor: e.target.value,
                      selectedVendorSite: vendor?.defaultsite_id,
                      selectedVendorSiteName: vendor?.defaultsite_name,
                      taxGroup:vendor?.vendorsite_grouptax_id
                    });
                    this.getVendorSitesByVendorId(e.target.value);
                  }}
                  value={this.state?.selectedVendor}
                  isDisable={this.props.viewOnly}
                />
                <Dropdown
                  label={`${Helper.getLabel(
                    this.props.language,
                    "vendor_site",
                    "Vendor site"
                  )}`}
                  apiUrl={this.state?.selectedVendor?Settings.apiVendorUrl + `/get_active_vendor_sites/${this.state?.selectedVendor}`:null}
                  currentSearchTerm={this?.state?.currentSearchTermVendorSite}
                  mapFunction={this.mapVendorSiteItems}
                  pagination={true}
                  allDataRef={this?.vendorSiteDataRef} 
                  options={
                    this.state?.selectedVendorSite
                    ? 
                      [{
                        value: this.state?.selectedVendorSite,
                        label: this.state?.selectedVendorSiteName ?? this?.vendorSiteDataRef?.current?.find(item => parseInt(item.vendorsite_id) === parseInt(this.state?.selectedVendorSite))?.name,
                      }]
                    : 
                    this.state.vendorSiteList?.map((item) => {
                      return {
                        value: item.vendorsite_id,
                        label: item.name,
                      };
                    })
                  } 
                  placeHolder={`${Helper.getLabel(
                    this.props.language,
                    "select_vendor_site",
                    "Select vendor site"
                  )}`}
                  id="vendor_site_id"
                  onChange={(e) => {
                    this.setState({ selectedVendorSite: e.target.value });
                    if (this.state.selectedVendor === null)
                      this.getVendorFromSite(e.target.value);
                  }}
                  value={this.state?.selectedVendorSite}
                  isDisable={this.props.viewOnly}
                  reRenderRequired={true}
                />
                  <Dropdown
                  label={Helper.getLabel(
                    this.props.language,
                    "item_group",
                    "Item group"
                  )}
                  options={this.state.itemGroups.map((item) => {
                    return {
                      value: item.itemgroup_id,
                      label: item.name,
                    };
                  })}
                  placeHolder={Helper.getLabel(
                    this.props.language,
                    "select_item_group",
                    "Select item group"
                  )}
                  id="item_group_id"
                  onChange={(e) => {
                    this.setState({ itemGroup: e.target.value });
                  }}
                  value={this.state.itemGroup}
                  disable={this.props.viewOnly}
                  reRenderRequired={true}
                />
                 <NewInputDatePicker
                  label={Helper.getLabel(
                    this.props.language,
                    "delivery_date",
                    "Delivery date"
                  )}
                  name="delivery_date"
                  onChange={(date) => {
                    this.setState({
                      deliveryDate: date,
                    });
                  }}
                  value={this.state?.deliveryDate}
                  disable={this.props.viewOnly}
                />
              </div>
            </div>
          </form>
        </Collapse>
        <Collapse
          title={Helper.getLabel(
            this.props.language,
            "additional_information",
            "Additional information"
          )}
          open={true}
        >
          <DistributionTabs
            gridId="rs_purchase_requisition_lines_list_edit_distribution_general_grid"
            sites={this.state.sites}
            updateSitesData={this.updateSitesData}
            updateExistingSiteIds={this.updateExistingSiteIds}
            source_id={this.getSourceId(
              "Purchase Requisition line Distribution"
            )}
            onCreateFirstDistribution={this.onCreateFirstDistribution}
            viewOnly={this.props.viewOnly}
            currentDocumentStatus={this.props.currentDocumentStatus}
            type={this.props.type}
            material_service_not_included={this.state.material_service_not_included}
            scope_of_work={this.state?.scope_of_work}
            requirement_description={this.state?.requirement_description}
            handleInputChange={this.handleInputChange}
            isDisable={this.props.viewOnly}
            accountingDate={this.state.accountingDate || new Date()}
          />
        </Collapse>
      </Popup>
      {this.state.isLoading ? <OverlayLoader isLogoCenter={true}/> : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartCount: state.prShop.cartCount,
    userData: state.auth.user,
    activeCartItem: state.prShop.activeCartItem,
    vendorChanged: state.prShop.vendorChanged,
    prCart:state.prCart.prCartInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeEditOldCartModal: () =>
      dispatch({ type: ActionTypes.CLOSE_EDITOLDCART_MODAL }),
    resetEditOldCartModal: () =>
      dispatch({ type: ActionTypes.RESET_EDITOLDCART_MODAL }),
    toggleDidCartUpdate: () => {
      dispatch({ type: ActionTypes.TOGGLE_DIDCARTUPDATE });
    },
    showApplyVendorModal: () =>
      dispatch({ type: ActionTypes.SHOW_APPLY_VENDOR_MODAL }),
    updateVendorChanged: (payload) =>
      dispatch({ type: ActionTypes.UPDATE_VENDOR_CHANGED, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOldCartModal); 