import { useState, useEffect, useRef } from "react";

const RFQComponent = ({ rfqData, onPriceUpdate }) => {
  const [currentPrice, setCurrentPrice] = useState(() => {
    const startDate = new Date(rfqData?.auction_start_date_time).getTime();
    const currentTime = new Date().getTime();
    const elapsedTime =
      getElapsedTime(rfqData?.automatic_time_extensions_type, currentTime, startDate)
    const totalDecrement =
      Math.floor(elapsedTime / rfqData?.automatic_time_extensions) *
      rfqData?.price_decrease;
    return Math.max(rfqData?.auction_bid_to_range - totalDecrement, 0);
  });

  const [startTimePassed, setStartTimePassed] = useState(false);
  const [endTimePassed, setEndTimePassed] = useState(false);

  const originalPrice = useRef(rfqData?.auction_bid_to_range);

  useEffect(() => {
    const checkTime = () => {
      const currentTime = new Date().getTime();
      const startDate = new Date(rfqData?.auction_start_date_time).getTime();
      const endDate = new Date(rfqData?.auction_end_date_time).getTime();

      if (currentTime >= startDate && currentTime <= endDate) {
        setStartTimePassed(true);
        setEndTimePassed(false);
      } else if (currentTime > endDate) {
        setEndTimePassed(true);
        setStartTimePassed(false);
      }
    };

    checkTime();

    const interval = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(interval);
  }, [rfqData?.auction_start_date_time, rfqData?.auction_end_date_time]);

  useEffect(() => {
    if (!startTimePassed || endTimePassed) return;

    const timeInMilliseconds = convertToMilliseconds(
      rfqData?.automatic_time_extensions,
      rfqData?.automatic_time_extensions_type
    );

    const interval = setInterval(() => {
      setCurrentPrice((prevPrice) => {
        const decreaseAmount = parseInt(rfqData?.price_decrease, 10);
        const newPrice = Math.max(prevPrice - decreaseAmount, 0);
        onPriceUpdate(newPrice); 
        return newPrice;
      });
    }, timeInMilliseconds);

    return () => clearInterval(interval);
  }, [
    startTimePassed,
    endTimePassed,
    rfqData?.price_decrease,
    rfqData?.automatic_time_extensions,
    rfqData?.automatic_time_extensions_type,
    onPriceUpdate,
  ]);

  useEffect(() => {
    const startDate = new Date(rfqData?.auction_start_date_time).getTime();
    const currentTime = new Date().getTime();

    if (currentTime >= startDate && !endTimePassed) {
      const elapsedTime =
         getElapsedTime(rfqData?.automatic_time_extensions_type, currentTime, startDate)
      const totalDecrement =
        Math.floor(elapsedTime / rfqData?.automatic_time_extensions) *
        rfqData?.price_decrease;
      const recalculatedPrice = Math.max(
        originalPrice.current - totalDecrement,
        0
      );
      setCurrentPrice(recalculatedPrice);
      onPriceUpdate(recalculatedPrice); 
    }
  }, [
    rfqData?.auction_start_date_time,
    rfqData?.price_decrease,
    endTimePassed,
    rfqData?.automatic_time_extensions_type,
    onPriceUpdate,
  ]);

  return (
    <div>
      <div className="rfq-current-price d-flex align-items-center"><p style={{fontWeight:700, fontSize:"18px", marginBottom:0}}>Current price:</p> <p style={{color:"#2D74E0",fontWeight:600, marginBottom:0, marginTop:"2px"}}>{currentPrice}</p></div>
    </div>
  );
};

export default RFQComponent;

const convertToMilliseconds = (time, type) => {
  if (type === 0) {
    return time * 60 * 1000;
  }
  if (type === 1) {
    return time * 60 * 60 * 1000;
  }
  if(type === 2){
    return time * 24 * 60 * 60 * 1000;
  }
  return 0;
};


const getElapsedTime = (type, currentTime, startDate) => {
  switch (type) {
    case 0: // Minutes
      return (currentTime - startDate) / (1000 * 60);
    case 1: // Hours
      return (currentTime - startDate)  / (1000 * 60 * 60);
    case 2: // Years
      return (currentTime - startDate)  / (1000 * 60 * 60 * 24 * 365);
    default:
      console.log("Invalid type. Use 0 for minutes, 1 for hours, or 2 for years.");
  }
}