import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';  
import $ from 'jquery'; 
import FormValidator from '../../../../inc/FormValidator';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import { connect } from 'react-redux';
import Button from '../../../inc/Button';
import Popup from '../../../Popup/Popup';
import Collapse from '../../../inc/Collapse';
import { Form } from 'react-bootstrap';
import FieldsRenderer from '../../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Helper from '../../../../inc/Helper';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';

let cancelTokenSource = axios.CancelToken.source();
const VendorReasonModal = ({setShowCreateModal, vendor_reason_id, reloadParentGrid, language}) => {  
    const [formData, setFormData] = useState({}); 

    const [apiCallBtn, setApiCallBtn] = useState(false);
     
    let formValidatory;   

    const validationConfigure = () => {
        let formFieldConfig = [  
            {name:'description', displayName:Helper.getLabel(language,'description','Description'),types:['Required']}
        ];
      
        if(!formValidatory) {
            formValidatory = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();

    let vendorReasonCalled = false; 
    
    useEffect(() => { 
        if(!vendor_reason_id) {
            setFormData({});
            return;
        }
        getVendorReason(); 
    }, []);

    const getVendorReason = () => {
        if(vendorReasonCalled) return;
        vendorReasonCalled = true

        setApiCallBtn(true);
        let api = Api 
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+`/vendorreason/${vendor_reason_id}`,{}).then(function(res){
          if(res.data.status==="success"){   
            setFormData(res?.data?.data)
            setApiCallBtn(false);
          }
        }).catch((res) => {
            setApiCallBtn(false);
            setFormData({});
        })
    };

    const fieldsArrayGeneral = [
        {className:'col-lg-6', required: true, maxLength: 255, disable: apiCallBtn, label: Helper.getLabel(language,'description',"Description"), inputType: "textarea", id:'description', name:'description'},
        {className:'col-lg-3', disable: apiCallBtn, label: Helper.getLabel(language,'reason_for_vendor',"Reason for vendor"), inputType: "checkbox", id:'reason_for_vendor', name:'reason_for_vendor'},
        {className:'col-lg-3', disable: apiCallBtn, label: Helper.getLabel(language,'tax_related_reason',"Tax related reason"), inputType: "checkbox", id:'tax_related_reason', name:'tax_related'}
    ];
 
    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().post(Settings.apiVendorUrl + '/vendorreason', {
                ...formData,
                reason_for_vendor: formData?.reason_for_vendor == 'on',
                tax_related: formData?.tax_related == 'on'
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                reloadParentGrid();
                onPopupClose(); 
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };
 
    const executePUTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().put(Settings.apiVendorUrl+`/vendorreason/${vendor_reason_id}`, {
                ...formData,
                reason_for_vendor: formData?.reason_for_vendor == 'on' || formData?.reason_for_vendor == true,
                tax_related: formData?.tax_related == 'on' || formData?.tax_related == true
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                reloadParentGrid();
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    };

    const handleSubmit = () => { 
        if(!formValidatory.isValid({...formData})){
            formValidatory.displayMessage();
            formValidatory.reset();
            return;
        };
     
        if(!vendor_reason_id){
            Helper.createDebouncedAPIFunction( 
                [executePOSTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        } else {
            Helper.createDebouncedAPIFunction( 
                [executePUTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        }
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">{!vendor_reason_id?'Create Vendor Reason':'Edit Vendor Reason'}</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setFormData} currentState={formData} fieldsArray={fieldsArrayGeneral} />
                            </div>
                        </Form> 
                    </Collapse>
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        className='black-btn-style'
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />

                    <Button
                        isDisable={apiCallBtn}                        
                        className={vendor_reason_id?"rfq_save_btn":"solid_btn" } 
                        isActive={!vendor_reason_id}
                        title={!vendor_reason_id?Helper.getLabel(language, "create", "Create"):Helper.getLabel(language, "save", "Save")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps) (VendorReasonModal);