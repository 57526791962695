import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Api from '../../inc/Api'
import { getPopupMessageBasedOnStatus } from '../../inc/Validation'
import Settings from '../../inc/Settings'

const TermsAndConditionsScreen = ({ language, auth }) => {
const [termsConditionsData,setTermsConditionsData]=useState({})
  const navigate = useNavigate()

  const getTermsConditionsData = async () => { 
    try {
        const api = Api;
        api.setUserToken();
        let res
        res = await api.axios().get(Settings.loginUrl + `/e-files`);
  
        if (res?.data?.status==="success") {
          setTermsConditionsData(res?.data?.data)
        }
      } catch (error) {
        getPopupMessageBasedOnStatus(error)
      }
  };

  const onBackBtnClick = () => {
    navigate(-1)
  }

  useEffect(() => {
    const backButton = document.getElementById('ayc-terms-condition-back')

    const handleClick = (e) => {
      e.preventDefault()
      onBackBtnClick()
    }

    if (backButton) {
      backButton?.addEventListener('click', handleClick)
    }

    return () => {
      if (backButton) {
        backButton?.removeEventListener('click', handleClick)
      }
    }
  }, [onBackBtnClick])

  useEffect(()=>{
    getTermsConditionsData()
},[])
  return (
    <div className='modules_screen_lynkaz register-vendor-master-form  change-password-screen'>
      <div>
        <div dangerouslySetInnerHTML={{ __html: termsConditionsData?.term_and_conditions }}></div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(TermsAndConditionsScreen)
