import React, {useEffect, useState, useCallback, Fragment } from 'react';   
import "./SettingsHeader.css";
import Col from 'react-bootstrap/Col'; 
import Nav from 'react-bootstrap/Nav';   
import DropDownInput from '../Forms/Dropdown';
import $ from 'jquery';
import Form from 'react-bootstrap/Form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { systemActions } from '../../store/system-params'; 
import Tab from 'react-bootstrap/Tab'; 
import { useNavigate } from "react-router-dom";
import Api from '../../inc/Api'; 
import Settings from '../../inc/Settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import Helper from '../../inc/Helper';
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';  
import Popup from '../Popup/Popup';
import SimpleLoading from '../Loading/SimpleLoading';
import Input from '../Forms/Input';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../inc/Gui_id_list';
import Alert from '../inc/Alert';
import ActionTypes from '../../inc/ActionsTypes';
import ThemeSettings from './ThemeSettings';
import MasterComponentWraper from '../Backend/MasterComponentWraper';
import ButtonCommon from '../inc/Button';
import Button  from 'react-bootstrap/Button';
import PasswordInput from '../Forms/PasswordInput';
import Checkbox from '../Forms/Checkbox';
import JoditEditor from 'jodit-react' 
import Collapse from '../inc/Collapse';
import AgGridNew from '../grid/ag/ag-grid-new';
import { statusLabelValueFormat } from '../grid/ag/commonFunctions/GridCommonFunctions';
import DatepickerFunctions from '../../inc/DatepickerHelper';

// LYNKAZ- SYSTEM CONFIGURATIONS :

// variables for presistence
let configExist = null
let IDPConfigExist = null
let updateAPICalled = false
let createAPICalled = false
let updateAPICalledIDP = false
let createAPICalledIDP = false
let defaultThemeSettings = null

const AttachmentInput = ({ name, label, fileName, fileData, onChange }) => (
  <>
    <div className='col-lg-6'>
      <Input
        name={name}
        label={label}
        inputType="file"
        onChange={onChange}
      /> 
    </div>
    <div className='col-lg-6 mt-2 d-flex align-items-start justify-content-start'>
      {
        !fileName && !fileData
          ? 'No file selected.'
          : fileName
            ? `Selected file: ${fileName}`
            : 
          (
            <a 
              href={fileData}
              target="_blank"
              rel="noopener noreferrer"
              className='setup_attachments_download_btn_styles'
            >
              <FontAwesomeIcon 
                icon={faFile} 
                className='setup_attachments_download_btn_font_icon_styles'
              />
              <span>Download {label}</span>
            </a>
          )
      }
    </div>
  </>
);

const SystemParams = (props) => { 
  // Component Wide - Loading States handling 
  const [isLoading, setIsLoading] = useState(true)   
  const [isLoadingBurger, setIsLoadingBurger] = useState(true);
  const [isLoadingEmailHeader, setIsLoadingEmailHeader] = useState(true)
  const [isLoadingEmailFooter, setIsLoadingEmailFooter] = useState(true)

  // toggle states for opening closing of popup-views 
  const [emailHeader, setEmailHeader] = useState(false)
  const [emailFooter, setEmailFooter] = useState(false)
  const [burgerLogo, setBurgerLogo] = useState(false)
  const [mailBodyPopup,setMailBodyPopup]=useState(false)

  // Redux + Local State connections -
  const [tenantConfig, setTenantConfig] = useState({})
  const [externalUserObj, setExternalUserObj] = useState({});
  const [idpConfig, setIDPConfig] = useState({})
  const [mailBodyData,setMailBodyData]=useState('')
  const get_system_params_content = useSelector(state => state.system) 

  // React Router Action URLS -
  const navigate = useNavigate()
  const dispatch = useDispatch()
   
  // Dropdown states -
  const [language, setLanguage] = useState([])
  const [dates, setDates] = useState([])
  const [sources,setSources]=useState([])
  const [sourceId,setSourceId]=useState('')

  //Feed Fields State
  const [selectedFields,setSelectedFields]=useState([])
  const [showSelectedFields,setShowSelectedFields]=useState([])
  const [availableFields,setAvailableFields]=useState([])
  const [selectedShowFields,setSelectedShowFields]=useState([])

  //API Reduction Calls
  let defaultLanguagesCalled = false
  let systemParamsCalled = false
  let IDPSettingsCalled = false
  let dateFormatCalled = false
  //End

  // API render calls-

  useEffect(() => {
    get_default_languages()
    get_system_params()
    get_dates_format()
    get_sources()
    getIDPSettings()
  }, []) 

  // get Calls for API --  

  const get_system_params  = () => {
    if(systemParamsCalled) {
      return;
    }
    setIsLoading(true)
    let api = Api
    systemParamsCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/tenant_configuration',{}).then(function(res){ 
      if(res.data.status==="success"){
        configExist = true 
        setIsLoading(false)
        setTenantConfig(res.data.data)
        setExternalUserObj({
          bid_submission_attachment: res.data.data.bid_submission_attachment,
          invoice_submission_attachment: res.data.data.invoice_submission_attachment,
          work_confirmation_attachment: res.data.data.work_confirmation_attachment,
          grn_submission_attachment: res.data.data.grn_submission_attachment,
          profile_update_attachment: res.data.data.profile_update_attachment,
          reset_password_attachment: res.data.data.reset_password_attachment,
          create_new_user_attachment: res.data.data.create_new_user_attachment,
          vendor_registration_attachment: res.data.data.vendor_registration_attachment,
          external_portal_support_email: res.data.data.external_portal_support_email,
          external_portal_support_number: res.data.data.external_portal_support_number
        })
        props.setLogoURL(res?.data?.data?.burger_menu_logo,res.data.data)
        defaultThemeSettings = res.data.data.theme_setting 
      } 
    }).catch((res) => {  
      if(res.response.data.status === "fail"){
        configExist = false 
        setIsLoading(false) 
        setTenantConfig({}) 
        defaultThemeSettings = {}
      }
    })
  }

  const getIDPSettings  = () => {
    if(IDPSettingsCalled) return;
    IDPSettingsCalled = true;

    let api = Api; 
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/idpconfiguration',{}).then(function(res){ 
      if(res.data.status==="success"){
        IDPConfigExist = true;
        setIDPConfig(res?.data?.data);
      } 
    }).catch((res) => {  
      if(res.response.data.status === "fail"){
        IDPConfigExist = false;
        setIDPConfig({});
      }
    })
  }

  const get_dates_format = () => {
    if(dateFormatCalled){
      return
    }
    let api = Api
    dateFormatCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/date`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.date_format_id,
            label: element.format
          })
        })
        setDates(UPDATED_STATE)
      }
    })
  }
  
  const get_default_languages = () => {
    if(defaultLanguagesCalled){
      return;
    }
    let api = Api
    defaultLanguagesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/language`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.language_id,
            label: element.language
          })
        })
        setLanguage(UPDATED_STATE)
      }
    })
  }

  //get_sources
  const get_sources= () => {  
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/source`).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        const filteredData = res.data.data.filter((element) => {
          return element.source_name === "Purchase Order" || element.source_name === "Contract" || element.source_name==="RFQ-VendorReply" || element.source_name==="Shipment, Good receiving, Work confirmation" || element.source_name==="Invoice"|| element.source_name==="Release Order" || element.source_name==="Purchase Agreement" || element.source_name==="Auction";
        });
        filteredData?.forEach((element) => {
         UPDATED_STATE.push({
          value: element.source_id,
          label: element.source_name
        })
      }) 
      setSources(UPDATED_STATE)
      }
    }) 
  }

  const get_feed_columns = (sourceId) => {  
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/source_fields/${sourceId}`).then(function(res){
      if(res.data.status==="success"){ 
         setAvailableFields(res.data.data)
      }
    }) 
  }

  const get_selected_feed_value = (sourceId) => {
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/feed_fields/${sourceId}`).then(function(res){
      if(res.data.status==="success"){ 
        setShowSelectedFields(res.data.data?.field)
      }
    }) 
  }

  const sourceInputChangeHandler = (e) => {
    get_feed_columns(e.target.value)
    get_selected_feed_value(e.target.value)
    setSourceId(e.target.value)
    setAvailableFields([])
    setShowSelectedFields([])
    setSelectedFields([])
    setSelectedShowFields([])
  }

  
  const handleFieldSelection = (key) => {
    const keyLowerCase = key.toLowerCase();

    if(showSelectedFields.includes(keyLowerCase)){
      Helper.alert("Field Already Exist","error")
    }
    else if (selectedFields.includes(keyLowerCase)) {
      const updatedFields = selectedFields.filter((fieldKey) => fieldKey !== keyLowerCase);
      setSelectedFields(updatedFields);
    } else {
      setSelectedFields([...selectedFields, keyLowerCase]);
    }
  };


  const putFields = (data) => {
    let api = Api
    api.setUserToken()
    api.axios().put(Settings.apiUrl+ `/feed_fields/${sourceId}`,data).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message) 
        get_selected_feed_value(sourceId)
      }
  }).catch((res) => { 
    getPopupMessageBasedOnStatus(res)
  })
  }

  const handleShowFieldSelection = (key) => {
    if (selectedShowFields.includes(key)) {
      const updatedFields = selectedShowFields.filter((fieldKey) => fieldKey !== key);
      setSelectedShowFields(updatedFields);
    } else {
      setSelectedShowFields([...selectedShowFields, key]);
    }
  };
  
  const handleShowFields = () => {
    const newSelectedFields = selectedFields?.reduce((acc, key) => {
      if (!showSelectedFields.includes(key.toLowerCase()) && selectedFields.includes(key.toLowerCase())) {
        acc.push(key);
      }
      return acc;
    }, []);
  
    const newAvailableFields = availableFields?.filter((key) => !selectedFields.includes(key.toLowerCase()));
  
    setShowSelectedFields((prevSelectedFields) => [...prevSelectedFields, ...newSelectedFields]);
    setAvailableFields(newAvailableFields);
    putFields({"fields":[...showSelectedFields,...newSelectedFields]})
    setSelectedFields([]);
  };
  
  const handleRightFields = () => {
    const newSelectedFields = selectedShowFields.reduce((acc, key) => {
      if (showSelectedFields.includes(key)) {
        acc.push(key)
      }
      return acc;
    }, []);
  
    const newAvailableFields = showSelectedFields.filter((key) => !selectedShowFields.includes(key));
    setShowSelectedFields(newAvailableFields);
    putFields({"fields":newAvailableFields})
    setAvailableFields((prevSelectedFields) => [
      ...prevSelectedFields,
      ...newSelectedFields,
    ]);
    setSelectedShowFields([]);
  };
  
  // onChange Handlers - for input fields --

  const ColorHandler = (event) => {
    let color = event.target.value 
    setTenantConfig((prevState) => {
      return {...prevState, burger_menu_background_color : color}
    })
    dispatch(systemActions.BurgerLogoBackgroundChange({payload: {
     burger_bg_color: color
    }}))
  }

  const FilePathHandler = (event) => {   
    let path = event.target.value
    setTenantConfig((prevState) => {
      return {...prevState, file_server_path: path}
    })
    dispatch(systemActions.fileServerChange({payload: {
      file_path: path 
    }}))  
  }

  const onInputValueChangeIDPConfig = (event) => {    
    let name = event.target.name;
    let value = event.target.value;

    setIDPConfig((prevState) => {
      return {...prevState, [name]: value}
    }) 
  }
 
  const EmailHeaderHandler = (event, fileData) => {  
    dispatch(systemActions.emailHeaderImageChange({payload: {
      email_header_image: fileData.fileData
    }}))
  }

  const OutgoingMainServer = (event) => {
    let outgoingMainServer = event.target.value
    setTenantConfig((prevState) => {
      return {...prevState, outgoing_mail_server: outgoingMainServer}
    })
    dispatch(systemActions.OutgoingMailServer({payload: {
      outgoing_mail: outgoingMainServer
    }}))
  }

  const EmailFooterHandler = (event,fileData) => {  
    dispatch(systemActions.emailFooterImageChange({payload: {
      email_footer_image: fileData.fileData
    }}))
  }

  const PortChangeHandler = (event) => {
    let portChange = event.target.value
    setTenantConfig((prevState) => {
      return {...prevState, smtp_port_number: portChange}
    })
    dispatch(systemActions.smtpPortChange({payload: {
      smtp_num: portChange
    }}))
  }

  const MaxEmailSeconds = (event) => {
    let maxEmailSecs = event.target.value
    setTenantConfig((prevState) => {
      return {...prevState, max_email_connection_time_sec: maxEmailSecs}
    })
    dispatch(systemActions.MaxEmailConnectionSec({payload: {
      max_email_connection_time_sec: maxEmailSecs
    }}))
  }
 
  const EmailUserChangeHandler = (event) => {
    let emailUserChange = event.target.value 
    setTenantConfig((prevState) => {
      return {...prevState, email_user_name: emailUserChange}
    })
    dispatch(systemActions.emailUserNameChange({payload: {
      email: emailUserChange
    }}))
  }

  const MinEmailSecs = (event) => {
    let minEmailSecs = event.target.value
    setTenantConfig((prevState) => {
      return {...prevState, min_email_interval_connection_sec:minEmailSecs }
    })
    dispatch(systemActions.MinEmailIntervalSec({payload: {
      min_email_interval_sec: minEmailSecs
    }}))
  }

  const MailPasswordHandler = (event) => {
    let mailPassword = event.target.value 
    setTenantConfig((prevState) => {
      return {...prevState, mailusernamepassword:mailPassword }
    })
    dispatch(systemActions.MailPasswordChange({payload: {
      mailusernamepassword: mailPassword
    }}))
  }

  const MaxEmailSize = (event) => {
    let maxEmailSize = event.target.value
    setTenantConfig((prevState) => {
      return {...prevState, max_email_message_size_KB: maxEmailSize}
    })
    dispatch(systemActions.MaxEmailMessageKB({payload: {
      max_email_message_size_KB: maxEmailSize
    }}))
  }

  const MaxEmailSizeBatch = (event) => {
    let maxEmailSizeBatch = event.target.value
    setTenantConfig((prevState) => {
      return {...prevState, max_email_message_batch_size_KB: maxEmailSizeBatch}
    })
    dispatch(systemActions.MaxEmailMessageBatchKB({payload: {
      max_email_message_batch_KB: maxEmailSizeBatch
    }}))
  }

  const onInputCheckboxChangeHandler = (name,value) => {
    if(value === 'on'){
      setTenantConfig((prevState) => {
         return {
           ...prevState,
           [name]: true
         }
       })
     }else {
       setTenantConfig((prevState) => {
         return {
           ...prevState,
           [name]: false
         }
       })
     }
     if(name==="mailuseSSL"){
      dispatch(systemActions.MailUseSSLChange({payload: {
        mailuseSSL: value == "on"
      }}))   
     }
     else if(name==="mailuseTLS"){
      dispatch(systemActions.MailUseTLSChange({payload: {
        mailuseTLS: value == "on"
      }}))   
     }
  }

  const FileHandler = (e,fileData) => {   
   dispatch(systemActions.BurgerLogoChange({payload: {
     burger_logo: fileData.fileData
   }}))   
  }

  const getDateFormatStringFromId =(dateFormateId) => {
    let dateFormate = Settings.defaultDateFormate; 
    if(dates && Array.isArray(dates)){
      dates.forEach( item => {
        if(item.value === dateFormateId){
          dateFormate = item.label;
        }
      })
    }
    return dateFormate
  }

  const DefaultLanguage = (event) => { 
    let lang = event.target.value
    setTenantConfig((prevState) => {
      return {...prevState, default_language_id:lang}
    })
  }

  const DateFormat = (event) => { 
    let date = event.target.value
    setTenantConfig((prevState) => {
      return {...prevState, date_format_id:date}
    })
  }

  const VendorRegistrationTermsHandler = useCallback(
    (newContent) => { 
      setTenantConfig((prevState) => {
        return {...prevState, vendorregistration_termandcondition: newContent}
      })
    }, []
  );
 
  // toggle states for viewing popups

  const ViewBurgerLogo = () => { 
    if( !tenantConfig.burger_menu_logo ) { 
      Helper.alert('Burger Logo image not found, please upload one!', 'error')
      return 
    };
    setBurgerLogo(true) 
  }  

  const ViewEmailHeader = () => {
    if(!tenantConfig.email_header_image) {
      Helper.alert('Email Header image not found, please upload one!', 'error')
      return;
    }
    setEmailHeader(true)
  }

  const ViewEmailFooter = () => { 
    if(!tenantConfig.email_footer_image) {
      Helper.alert('Email Footer image not found, please upload one!', 'error')
      return;
    }
    setEmailFooter(true) 
  }

  const burgerClose = () => { 
    setBurgerLogo(false)
    setIsLoadingBurger(true)
  }

  const footerClose = () => { 
    setEmailFooter(false)
    setIsLoadingEmailFooter(true)
  }

  const headerClose = () => { 
    setEmailHeader(false) 
    setIsLoadingEmailHeader(true) 
  }

  // ----
  // Modules - Functions -
  const SystemParametersWrapperModule = () => {
    return (
      <div className='system-params-section-pads'>
        <div className='container-fluid ps-0'>
          <Fragment>
            <div className='system-parameters-section text-start'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-lg-4 col-md-5 col-xl-3 col-xxl-2 p-0'></div>
                  <div className='col-xl-8 col-md-6 col-lg-8 col-xxl-10 ps-xl-0 ps-md-0 ps-xxl-5 mt-3 d-flex'>
                    <div className='container-fluid border-bottom'>
                      <div className='row'>
                        <div className='col-lg-8 col-md-12'>
                          <h2 className='system-param-main-heading text-start mb-3'>System Parameters</h2>
                        </div>
                        <div className='col-lg-4 col-md-12 d-lg-flex justify-content-end'>
                          <div className='system-params-btns'>
                            <ButtonCommon 
                              isActive={false}
                              onClick={() => navigate('/administration')}
                              className='black-btn-style me-2'
                              title={Helper.getLabel(props.language, 'close', "Close")}
                            />
                            <ButtonCommon
                              className='rfq_save_btn'
                              onClick={SaveHandler}
                              isDisable={!security.canCreate(frontendIds.administrationForms.system_parameters.system_parameters_main_button)}
                              title={Helper.getLabel(props.language, 'save', "Save")} 
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <Tab.Container className='sidebar_section' defaultActiveKey="general">
                    <div className='col-lg-4 col-md-5 col-xl-3 col-xxl-2 p-0'>
                      <Col sm={5} md={5} lg={4} xxl={2} className='sidebar_section' style={{ width: '220px', height: '100%', background: '#5584AC', color: '#ffffff' }}>
                        <Nav className='sidebar_menu'>
                          <Nav.Item>
                            <Nav.Link eventKey="general">General</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="terms_conditions">Terms & Conditions</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="file-server">File Server</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="emails">Emails</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Themes Setting">Themes Settings</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Feed Fields">Feed Fields</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="IDP Configuration">IDP Configuration</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="External User Manuals">External User Manuals</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                    </div>
                    {TabFields()}
                  </Tab.Container>
                </div>
              </div>
            </div>
          </Fragment>
        </div>
        {showPopupBurgerLogo()}
        {showPopupEmailHeader()}
        {showPopupEmailFooter()}
      </div>
    )
  }

  const TabFields = () => { 
    {
      return (
        <div className='col-xl-8 col-md-6 col-lg-8 col-xxl-10 ps-xl-0 ps-lg-0 ps-md-0 ps-xxl-5'>
          <Tab.Content>
            <Tab.Pane eventKey='general'> 
              {GeneralTabPane()}
            </Tab.Pane>
   
            <Tab.Pane eventKey='terms_conditions'> 
              {TermsConditionsTabPane()}
            </Tab.Pane>

            <Tab.Pane eventKey='file-server'>
              {FileServerTabPane()}
            </Tab.Pane>
  
            <Tab.Pane eventKey='emails'>
              {EmailTabPane()}
            </Tab.Pane>
  
            <Tab.Pane eventKey='Themes Setting'>
              {ThemeSettingsPane()}
            </Tab.Pane>

            <Tab.Pane eventKey='Feed Fields'>
              {FeedFieldsPane()}
            </Tab.Pane>

            <Tab.Pane eventKey='IDP Configuration'>
              {IDPConfigurationPane()}
            </Tab.Pane>

            <Tab.Pane eventKey='External User Manuals'>
              {ExternalUserManualsPane()}
            </Tab.Pane>
          </Tab.Content>
        </div>
      );
    }
  }

  const GeneralTabPane = () => {
    return (
      <div className='row system-params-general gy-2'>
        <h2 className='system-params-content-heading mt-4'>General</h2>
        <div className='system-params-content-fields'>
          <div className='row mb-2'>
            <div className='col-3 col-md-6'>  
              <Input
                name='burger_logo'
                label={Helper.getLabel(props.language,'burger_logo','Burger Icon')}
                placeholder={Helper.getLabel(props.language,'type_here','Type here')}
                onChange={FileHandler}
                inputType="file"
              />   
              <button onClick={ViewBurgerLogo}>View Burger Icon</button>  
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-4'>
              <label className='mb-2'>Burger Menu Background Color</label>
              <Form.Control
                type="color"
                className='custom'
                size='sm'
                defaultValue="#563a7c"  
                onChange={ColorHandler}
                value={tenantConfig.burger_menu_background_color}
              /> 
            </div> 
          </div>  
          <div className='row mb-2'> 
            <div className='col-3'>
              <DropDownInput
                label={Helper.getLabel(props.language,'default_language','Default Language')}
                options={language}
                name='language_id'
                onChange={DefaultLanguage}
                value={tenantConfig.default_language_id}
              /> 
            </div> 
            <div className='col-3'>
              <DropDownInput
                label={Helper.getLabel(props.language,'default_date_picker','Date Format')}
                required={true}
                options={dates}
                name='date_picker_format_id'
                id='date_picker_format_id'
                onChange={DateFormat}
                value={tenantConfig.date_format_id}
              /> 
            </div>  
          </div>
        </div>
      </div>    
    )
  }

  const TermsConditionsTabPane = () => {
    return (
      <div className='row system-params-terms-conditions mt-2 mb-2 gy-2'>
        <h2 className='system-params-content-heading mt-4'>Terms & Conditions</h2> 
        <div className='collapse_for_texteditor'>
          <JoditEditor   
            value={tenantConfig?.vendorregistration_termandcondition} 
            tabIndex={1} 
            onBlur={VendorRegistrationTermsHandler}
          />
        </div>
      </div>
    )
  }

  const FileServerTabPane = () => {
    return (
      <div className='row system-params-file-server mt-2 mb-2 gy-2'>
        <h2 className='system-params-content-heading'>File Server</h2>
        <div className='system-params-content-area'>
          <div className='row'>
            <div className='col-5'>
              <Input label={Helper.getLabel(props.language,'file_server_path','File Server Path')} type='text' value={tenantConfig.file_server_path} onChange={FilePathHandler} />
            </div> 
          </div>
          <div className='row'>
            <div className='col-12'> 
              <h4 className='file-size'>{tenantConfig.attachment_size_limit_MB}MB</h4>
            </div> 
          </div>
        </div>
      </div>
    )
  }

  const displayViewLogo = () => {
    return <div className="logo_inside_grid"><img src="/images/icons/view.svg" alt="view logo" style={{height:'24px'}} /></div>
  }

  const onCellClickHandler = (event) => {
    if(event?.colDef?.field==='mailbody'){
       setMailBodyPopup(true)
       setMailBodyData(event?.data?.mailbody)
    }
  }

  const EmailTabPane = () => {
    const mailBodyPopupClose = () => { 
      setMailBodyPopup(false)
    }

    let Columns_Headings = [
      {
        field: "mailbody",
        headerName: "Mail Body",
        editable: false,
        cellRenderer: displayViewLogo
      },
      {
        field: "subject",
        headerName: "Email Subject",
        editable: false,
      },
      {
        field: "recipient_to",
        headerName: "Recipient (To)",
        editable: false,
        valueFormatter:(params)=>{return params?.value ?? "-"}
      },
      {
        field: "recipient_CC",
        headerName: "Recipient (CC)",
        editable: false,
        valueFormatter:(params)=>{return params?.value ?? "-"}
      },
      {
        field: "sender_email",
        headerName: "Sender Email Address",
        editable: false,
      },
      {
        field: "priority",
        headerName: "Email Priority",
        editable: false,
        valueFormatter:(params)=>{return params?.value ?? "-"}
      },
      {
        field: "reason",
        headerName: "Reason for Email",
        editable: false,
      },
      {
        field: 'status',
        headerName: "Email Status",
        editable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          keyCreator: (params) => params.value,
          valueFormatter: (params) => params.value ? 'Success' : 'Fail'
        },
        cellRenderer: (params) => statusLabelValueFormat(params,'Success','Fail'),
      },
      {
        field: "last_status_change_datetime",
        headerName: "Last Status Change (Date & Time)",
        editable: false,
        valueFormatter: (params) => {
          return DatepickerFunctions.convertDateTimeFromDataBase(params.value) ?? "-";
        }
      },
      {
        field: "next_retry_datetime",
        headerName: "Next Retry (Date & Time)",
        editable: false,
        valueFormatter: (params) => {
          return DatepickerFunctions.convertDateTimeFromDataBase(params.value) ?? "-";
        }
      },
    ];
    
    return (
      <div className='row system-params-emails mt-2'>
        <h2 className='system-params-content-heading'>Emails</h2>
        <div className='system-params-content-area'>
          <div className='row gy-3'>
            <div className='col-lg-3 col-md-9 col-2'> 
              <Input label={Helper.getLabel(props.language,'smtp_port_number','SMTP Port Number')} type='number' placeholder='2234' value={tenantConfig.smtp_port_number} onChange={PortChangeHandler} />
            </div> 

            <div className='col-lg-4 col-md-9'>  
              <Input label={Helper.getLabel(props.language,'mail_username','Mail Username')} type='text' placeholder='abc@outlook.com' value={tenantConfig.email_user_name}  onChange={EmailUserChangeHandler}/>
            </div> 
            
            <div className='col-lg-8 col-md-12'> 
              <Input name='email_header_image' label={Helper.getLabel(props.language,'email_header_image','Email Header Image')} placeholder={Helper.getLabel(props.language,'type_here','Type here')}  onChange= {EmailHeaderHandler }   inputType="file" />
              <button onClick={ViewEmailHeader}>View Email Header</button> 
            </div>

            <div className='col-lg-8 col-md-12'> 
              <Input name='email_header_image' label={Helper.getLabel(props.language,'email_header_image','Email Header Image')} placeholder={Helper.getLabel(props.language,'type_here','Type here')}  onChange= {EmailFooterHandler }   inputType="file" />
              <button onClick={ViewEmailFooter}>View Email Footer</button> 
            </div> 
          </div>

          <div className='row mt-0 gy-3'>
            <div className='col-lg-4 col-md-9 col-2'>  
              <Input label={Helper.getLabel(props.language,'outgoing_main_server','Outgoing Main Server')} type='number' value={tenantConfig.outgoing_mail_server} placeholder='22342' onChange={OutgoingMainServer} />
            </div> 

            <div className='col-lg-4 col-md-9 col-2'>
              <Input label={Helper.getLabel(props.language,'max_email_message_size', 'Max email message size (KB)')} type='number' value={tenantConfig.max_email_message_size_KB} placeholder='2234321KB' onChange={MaxEmailSize} />
            </div>

            <div className='col-lg-4 col-md-9 col-3'>  
              <Input type='number' label={Helper.getLabel(props.language,'max_email_message_batch_size','Max email message batch size (KB)')} value={tenantConfig.max_email_message_batch_size_KB} placeholder='1024KB' onChange={MaxEmailSizeBatch} /> 
            </div>

            <div className='col-lg-4 col-md-9 col-3'>  
              <Input label={Helper.getLabel(props.language,'maximum_email_connection_time','Maximum email connection time (seconds)')} type='number' value={tenantConfig.max_email_connection_time_sec} placeholder='888341s' onChange={MaxEmailSeconds} />
            </div> 

            <div className='col-lg-4 col-md-9 col-3'>  
              <Input label={Helper.getLabel(props.language, 'min_email_interval_between_connections', 'Min email Interval between connections (seconds)')} type='number' value={tenantConfig.min_email_interval_connection_sec} placeholder='84600s' onChange={MinEmailSecs} />
            </div>
            
            <div className='col-lg-4 col-md-9 col-3'>  
              <PasswordInput label={Helper.getLabel(props.language, 'password', 'Password')} value={tenantConfig.mailusernamepassword}  onChange={MailPasswordHandler} />
            </div>

            <div className='col-lg-4 col-md-9 col-3'>  
              <Checkbox   id="main-use-ssl" name="mailuseSSL" onChange={onInputCheckboxChangeHandler} isChecked={tenantConfig.mailuseSSL} label={Helper.getLabel(props.language, 'mail-use-ssl', 'Mail use SSL')} />
            </div>

            <div className='col-lg-4 col-md-9 col-3'>  
              <Checkbox id="mail-use-tls" name="mailuseTLS" label={Helper.getLabel(props.language, 'mail-use-tls', 'Mail use TLS')} onChange={onInputCheckboxChangeHandler} isChecked={tenantConfig.mailuseTLS}  />
            </div>

          </div>
          <div>
          <div>
               <Collapse title="Email logs" open={true} className="mt-3">
                  <AgGridNew 
                    apiUrl={Settings.apiUrl + `/get_email_logs`}
                    gridId={'email-logs'}
                    hideAddBtn={true}
                    hideDeleteBtn={true}
                    height={500}                  
                    columnDefs={Columns_Headings}
                    isDisabledCheckbox={true}
                    onCellClicked={onCellClickHandler}
                    pagination={true}
                  />
               </Collapse>
          </div>
          {mailBodyPopup &&  <Popup autoOpen={true} width={"60%"} onClose={mailBodyPopupClose}>
               <div>
                 <h5 className='create_rfq_modal__title mb-4'>Mail Body</h5>
                 <div dangerouslySetInnerHTML={{__html:mailBodyData}} style={{margin:"1rem"}} className='email-logs-mail-screen'></div>
               </div>
        </Popup>}
          </div>
        </div>
      </div>
    )
  }

  const ThemeSettingsPane = () => {
    return (
      <div className='row system-params-file-server mt-2 mb-2 gy-2'>
        <h2 className='system-params-content-heading'>Themes</h2>
        <div className='system-params-content-area'> 
          <ThemeSettings defaultThemeSettings={defaultThemeSettings} executeAPI={SaveHandler} />
        </div>
      </div>
    )
  }

  const FeedFieldsPane = () => {
    return (
      <div className='mt-2 feed-fields-form'>
        <div className='row'>
          <div className='col-sm-12'>
            <DropDownInput
              label={Helper.getLabel(
                props.language,
                'source_type',
                'Source Type'
              )}
              options={sources}
              placeholder={Helper.getLabel(
                props.language,
                'select_source',
                'Select Source'
              )}
              onChange={sourceInputChangeHandler}
            ></DropDownInput>
          </div>
        </div>
        <div className='row feed-fields-row'>
          <div className='col-sm-5 feed-fields-col'>
            <h5 className='mt-2 mb-2'>Fields for Feed</h5>
            <ul className='px-0'>
              {availableFields?.map((item) => (
                <li
                  key={item}
                  className={selectedFields.includes(item.toLowerCase())?'selected-field':'field-style'}
                  onClick={()=>{handleFieldSelection(item)}}
                >
                  {item.replace(/_/g, " ")}
                </li>
              ))}
            </ul>
          </div>
          <div className='col-sm-2 d-flex align-items-center justify-content-center flex-column gap-3'>
            <Button variant='outline-primary' className='w-50' onClick={handleShowFields}>
              &gt;&gt;
            </Button>
            <Button variant='outline-primary' className='w-50' onClick={handleRightFields}>
              &lt;&lt;
            </Button>
          </div>
          <div className='col-sm-5 feed-fields-col'>
            <h5 className='mt-2 mb-2'>Selected Fields for Feed</h5>
            <ul className='px-0'>
              {showSelectedFields.map((item) => (
                <li
                  key={item}
                  className={selectedShowFields.includes(item.toLowerCase())?'selected-field':'field-style'}
                  onClick={()=>{
                    handleShowFieldSelection(item)
                  }}
                >
                  {item.replace(/_/g, " ")}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }

  const IDPConfigurationPane = () => {
    return (
      <div className='idp-settings-form mt-2'>
        <div className='row'>
          <div className='col-lg-4 col-md-6'>
            <Input label={Helper.getLabel(props.language,'display_name','Display name')} type='text' name='display_name' value={idpConfig.display_name} onChange={onInputValueChangeIDPConfig} />
          </div>
          <div className='col-lg-4 col-md-6'>
            <Input label={Helper.getLabel(props.language,'entity_id','Entity')} type='text' name='entity_id' value={idpConfig.entity_id} onChange={onInputValueChangeIDPConfig} />
          </div>
          <div className='col-lg-4 col-md-6'>
            <Input label={Helper.getLabel(props.language,'sso_url','SSO url')} type='text' name='sso_url' value={idpConfig.sso_url} onChange={onInputValueChangeIDPConfig} />
          </div>
          <div className='col-lg-4 col-md-6'>
            <Input label={Helper.getLabel(props.language,'slo_url','SLO url')} type='text' value={idpConfig.slo_url} name='slo_url' onChange={onInputValueChangeIDPConfig} />
          </div>
        </div>
      </div>
    )
  }

  const ExternalUserManualsPane = () => {
    const handleFileChange = (field) => (e, fileData) => {
      setExternalUserObj((prevState) => ({
        ...prevState,
        [`${field}_name`]: fileData.fileName,
        [field]: fileData.fileData
      }));
    };
  
    return (
      <div className='external_user_manuals_tab mt-2'>
        <div className='row'>
          {[
            { name: 'bid_submission_attachment', label: 'Bid submission attachment' },
            { name: 'invoice_submission_attachment', label: 'Invoice submission attachment' },
            { name: 'work_confirmation_attachment', label: 'Work confirmation attachment' },
            { name: 'grn_submission_attachment', label: 'GRN submission attachment' },
            { name: 'profile_update_attachment', label: 'Vendor profile update attachment' },
            { name: 'reset_password_attachment', label: 'Reset password attachment' },
            { name: 'create_new_user_attachment', label: 'Create new user attachment' },
            { name: 'vendor_registration_attachment', label: 'Vendor registration attachment' },
          ].map(({ name, label }) => (
            <div className='col-lg-6 col-md-8 col-12' key={name}>
              <div className='row'>
                <AttachmentInput
                  name={name}
                  label={Helper.getLabel(props?.language, name, label)}
                  fileName={externalUserObj[`${name}_name`]}
                  fileData={externalUserObj[name]}
                  onChange={handleFileChange(name)}
                />
              </div>
            </div>
          ))}

          {[
            { name: 'external_portal_support_email', label: 'External portal support email' },
            { name: 'external_portal_support_number', label: 'External portal support number' }
          ].map(({ name, label }) => (
            <div className='col-lg-3 col-md-4 col-6'>
              <Input
                label={Helper.getLabel(props?.language, name, label)}
                type='text'
                name={name}
                value={externalUserObj[name]}
                onChange={(event) => {
                  const targetVal = event.target.value;
                  setExternalUserObj((prevState) => ({
                    ...prevState,
                    [name]: targetVal
                  }));
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const showPopupBurgerLogo = () => {
    if (burgerLogo && tenantConfig.burger_menu_logo) { 
  
      const handleImageLoad = () => {
        setIsLoadingBurger(false);
      };
  
      return (
        <Popup autoOpen={true} width={"60%"} onClose={burgerClose}>
          <div className='container-fluid'>
            <div className='row gy-0'>
              <div className='col'>
                <img
                  src={tenantConfig.burger_menu_logo}
                  className='img-fluid'
                  alt='Burger Logo'
                  style={{ display: isLoadingBurger ? 'none' : 'block' }}
                  onLoad={handleImageLoad}
                />
                {isLoadingBurger && <SimpleLoading />}
              </div>
            </div>
          </div>
        </Popup>
      );
    } else {
      return null;
    }
  };

  const showPopupEmailHeader = () => {
    if (emailHeader && tenantConfig.email_header_image) { 
  
      const handleImageLoad = () => {
        setIsLoadingEmailHeader(false);
      };
  
      return (
        <Popup autoOpen={true} width={"60%"} onClose={headerClose}>
          <div className='container-fluid'>
            <div className='row gy-0'>
              <div className='row'>
                {isLoadingEmailHeader && <SimpleLoading />} {/* Display the loader component while the image is loading */}
                <img
                  className='img-fluid'
                  src={tenantConfig.email_header_image}
                  alt='Email Header'
                  style={{ display: isLoadingEmailHeader ? 'none' : 'block' }}
                  onLoad={handleImageLoad}
                />
              </div>
            </div>
          </div>
        </Popup>
      );
    } else {
      return null;
    }
  };

  const showPopupEmailFooter = () => {
    if (emailFooter && tenantConfig.email_footer_image) { 
  
      const handleImageLoad = () => {
        setIsLoadingEmailFooter(false);
      };
  
      return (
        <Popup autoOpen={true} width={"60%"} onClose={footerClose}>
          <div className='container-fluid'>
            <div className='row gy-0'>
              <div className='row'>
                {isLoadingEmailFooter && <SimpleLoading />} {/* Display the loader component while the image is loading */}
                <img
                  src={tenantConfig.email_footer_image}
                  className='img-fluid'
                  alt='Email Footer'
                  style={{ display: isLoadingEmailFooter ? 'none' : 'block' }}
                  onLoad={handleImageLoad}
                />
              </div>
            </div>
          </div>
        </Popup>
      );
    } else {
      return null;
    }
  };

  // ----

  // Execute API for saving data

  const executeAPIUpdate = (themeSettings) => {
    if(updateAPICalled) return;
    updateAPICalled = true;

    let api = Api
    api.setUserToken()
    api.axios().put(Settings.apiUrl+'/tenant_configuration', {
      "burger_menu_logo": get_system_params_content.burger_menu_logo !== "" ? get_system_params_content.burger_menu_logo: null,
      "burger_menu_background_color": get_system_params_content.burger_menu_background_color !== "" ? get_system_params_content.burger_menu_background_color: null,
      "default_language_id": parseInt(tenantConfig.default_language_id),
      "file_server_path": get_system_params_content.file_server_path !== "" ? get_system_params_content.file_server_path : "",
      "smtp_port_number": get_system_params_content.smtp_port_number !== "" ? parseInt(get_system_params_content.smtp_port_number) : "",
      "email_user_name": get_system_params_content.email_user_name !== "" ? get_system_params_content.email_user_name : "",
      "email_header_image": get_system_params_content.email_header_image !== "" ? get_system_params_content.email_header_image : null,
      "email_footer_image": get_system_params_content.email_footer_image !== "" ? get_system_params_content.email_footer_image : null,
      "attachment_size_limit_MB": "5",
      "bid_submission_attachment": externalUserObj?.bid_submission_attachment !== "" ? externalUserObj?.bid_submission_attachment?.startsWith("https") ? null : externalUserObj?.bid_submission_attachment : "", 
      "grn_submission_attachment": externalUserObj?.grn_submission_attachment !== "" ? externalUserObj?.grn_submission_attachment?.startsWith("https") ? null : externalUserObj?.grn_submission_attachment : "",
      "invoice_submission_attachment": externalUserObj?.invoice_submission_attachment !== "" ? externalUserObj?.invoice_submission_attachment?.startsWith("https") ? null : externalUserObj?.invoice_submission_attachment : "",
      "profile_update_attachment": externalUserObj?.profile_update_attachment !== "" ? externalUserObj?.profile_update_attachment?.startsWith("https") ? null : externalUserObj?.profile_update_attachment : "",
      "work_confirmation_attachment": externalUserObj?.work_confirmation_attachment !== "" ? externalUserObj?.work_confirmation_attachment?.startsWith("https") ? null : externalUserObj?.work_confirmation_attachment : "",
      "vendor_registration_attachment": externalUserObj?.vendor_registration_attachment !== "" ? externalUserObj?.vendor_registration_attachment?.startsWith("https") ? null : externalUserObj?.vendor_registration_attachment : "",
      "create_new_user_attachment": externalUserObj?.create_new_user_attachment !== "" ? externalUserObj?.create_new_user_attachment?.startsWith("https") ? null : externalUserObj?.create_new_user_attachment : "",
      "reset_password_attachment": externalUserObj?.reset_password_attachment !== "" ? externalUserObj?.reset_password_attachment?.startsWith("https") ? null : externalUserObj?.reset_password_attachment : "",
      "external_portal_support_email": externalUserObj?.external_portal_support_email !== "" ? externalUserObj?.external_portal_support_email : "",
      "external_portal_support_number": externalUserObj?.external_portal_support_number !== "" ? externalUserObj?.external_portal_support_number : "", 
      "vendorregistration_termandcondition": tenantConfig.vendorregistration_termandcondition === "" ? "" : tenantConfig.vendorregistration_termandcondition,
      "outgoing_mail_server": get_system_params_content.outgoing_mail_server !== "" ? get_system_params_content.outgoing_mail_server : "",
      "max_email_connection_time_sec": get_system_params_content.max_email_connection_time_sec !== "" ? get_system_params_content.max_email_connection_time_sec : "",
      "min_email_interval_connection_sec": get_system_params_content.min_email_interval_connection_sec !== "" ? get_system_params_content.min_email_interval_connection_sec : null,
      "max_email_message_size_KB": get_system_params_content.max_email_message_size_KB !== "" ? get_system_params_content.max_email_message_size_KB : "",
      "max_email_message_batch_size_KB": get_system_params_content.max_email_message_batch_size_KB !== "" ? get_system_params_content.max_email_message_batch_size_KB : "",
      "mailusernamepassword": get_system_params_content.mailusernamepassword !== "" ? get_system_params_content.mailusernamepassword : "", 
      "mailuseSSL": get_system_params_content.mailuseSSL !== "" ? get_system_params_content.mailuseSSL : null, 
      "mailuseTLS": get_system_params_content.mailuseTLS !== "" ? get_system_params_content.mailuseTLS : null,
      "date_format_id": document.getElementById('date_picker_format_id') ? parseInt(document.getElementById('date_picker_format_id').value) : null,
      "theme_setting": themeSettings
    }).then(function(res){
      if(res.data.status==="success"){  
        updateAPICalled=false
        Helper.alert(res.data.data)
        get_system_params()
        props.setDateFormate(getDateFormatStringFromId(parseInt(document.getElementById('date_picker_format_id').value)))
      } 
    }).catch((res) => { 
      updateAPICalled=false
      get_system_params()
      getPopupMessageBasedOnStatus(res)
    })
  }
  
  const executeAPICreate = (themeSettings) => {
    if(createAPICalled) return;
    createAPICalled = true;
    let api = Api
    api.setUserToken() 
    api.axios().post(Settings.apiUrl+'/tenant_configuration', {
      "burger_menu_logo": get_system_params_content.burger_menu_logo !== "" ? get_system_params_content.burger_menu_logo: null,
      "burger_menu_background_color": get_system_params_content.burger_menu_background_color !== "" ? get_system_params_content.burger_menu_background_color: null,
      "default_language_id": parseInt(tenantConfig.default_language_id),
      "file_server_path": get_system_params_content.file_server_path !== "" ? get_system_params_content.file_server_path : null,
      "smtp_port_number": get_system_params_content.smtp_port_number !== "" ? parseInt(get_system_params_content.smtp_port_number) : null,
      "email_user_name": get_system_params_content.email_user_name !== "" ? get_system_params_content.email_user_name : null,
      "email_header_image": get_system_params_content.email_header_image !== "" ? get_system_params_content.email_header_image : null,
      "email_footer_image": get_system_params_content.email_footer_image !== "" ? get_system_params_content.email_footer_image : null,
      "attachment_size_limit_MB": "5",
      "bid_submission_attachment": externalUserObj?.bid_submission_attachment !== "" ? externalUserObj?.bid_submission_attachment : null,
      "grn_submission_attachment": externalUserObj?.grn_submission_attachment !== "" ? externalUserObj?.grn_submission_attachment : null,
      "invoice_submission_attachment": externalUserObj?.invoice_submission_attachment !== "" ? externalUserObj?.invoice_submission_attachment : null,
      "profile_update_attachment": externalUserObj?.profile_update_attachment !== "" ? externalUserObj?.profile_update_attachment : null,
      "work_confirmation_attachment": externalUserObj?.work_confirmation_attachment !== "" ? externalUserObj?.work_confirmation_attachment : null, 
      "reset_password_attachment": externalUserObj?.reset_password_attachment !== "" ? externalUserObj?.reset_password_attachment : null,
      "create_new_user_attachment": externalUserObj?.create_new_user_attachment !== "" ? externalUserObj?.create_new_user_attachment : null,
      "vendor_registration_attachment": externalUserObj?.vendor_registration_attachment !== "" ? externalUserObj?.vendor_registration_attachment : null,
      "external_portal_support_email": externalUserObj?.external_portal_support_email !== "" ? externalUserObj?.external_portal_support_email : null,
      "external_portal_support_number": externalUserObj?.external_portal_support_number !== "" ? externalUserObj?.external_portal_support_number : null,
      "vendorregistration_termandcondition": tenantConfig.vendorregistration_termandcondition === "" ? null : tenantConfig.vendorregistration_termandcondition,
      "outgoing_mail_server": get_system_params_content.outgoing_mail_server !== "" ? get_system_params_content.outgoing_mail_server : null,
      "max_email_connection_time_sec": get_system_params_content.max_email_connection_time_sec !== "" ? get_system_params_content.max_email_connection_time_sec : null,
      "min_email_interval_connection_sec": get_system_params_content.min_email_interval_connection_sec !== "" ? get_system_params_content.min_email_interval_connection_sec : null,
      "max_email_message_size_KB": get_system_params_content.max_email_message_size_KB !== "" ? get_system_params_content.max_email_message_size_KB : null,
      "max_email_message_batch_size_KB": get_system_params_content.max_email_message_batch_size_KB !== "" ? get_system_params_content.max_email_message_batch_size_KB : null,
      "mailusernamepassword": get_system_params_content.mailusernamepassword !== "" ? get_system_params_content.mailusernamepassword : null, 
      "mailuseSSL": get_system_params_content.mailuseSSL !== "" ? get_system_params_content.mailuseSSL : null, 
      "mailuseTLS": get_system_params_content.mailuseTLS !== "" ? get_system_params_content.mailuseTLS : null,
      "date_format_id": document.getElementById('date_picker_format_id') ? parseInt(document.getElementById('date_picker_format_id').value) : null,
      "theme_setting": themeSettings
    }).then(function(res){
      if(res.data.status==="success"){  
        createAPICalled = false;
        Helper.alert(res.data.message)
        get_system_params()
        props.setDateFormate(getDateFormatStringFromId(parseInt(document.getElementById('date_picker_format_id').value)))
      } 
    }).catch((res) => { 
      createAPICalled = false;
      getPopupMessageBasedOnStatus(res)
      get_system_params()
    })
  } 

  const executeIDPConfigCreateApi = () => {
    if(createAPICalledIDP) return;
    createAPICalledIDP = true; 

    let formDataPayload = new FormData();
    formDataPayload.append('data',  JSON.stringify({
      display_name: idpConfig?.display_name, 
      entity_id: idpConfig?.entity_id,
      sso_url: idpConfig?.sso_url, 
      slo_url: idpConfig?.slo_url 
    }));

    if (Object.keys(formDataPayload)?.length === 0) return;

    let api = Api
    api.setUserToken() 
    api.axios().post(Settings.apiUrl+'/tenant_configuration', formDataPayload).then(function(res){
      if(res.data.status==="success"){
        createAPICalledIDP = false;
        getIDPSettings() 
      } 
    }).catch((res) => {  
      createAPICalledIDP = false;
      getPopupMessageBasedOnStatus(res)
      getIDPSettings() 
    })
  } 

  const executeIDPConfigUpdateApi = () => { 
    if(updateAPICalledIDP) return;
    updateAPICalledIDP = true;

    let formDataPayload = new FormData();
    formDataPayload.append('data',  JSON.stringify({
      display_name: idpConfig?.display_name, 
      entity_id: idpConfig?.entity_id,
      sso_url: idpConfig?.sso_url, 
      slo_url: idpConfig?.slo_url 
    }));
    
    let api = Api
    api.setUserToken() 
    api.axios().put(Settings.apiUrl+'/idpconfiguration', formDataPayload).then(function(res){
      if(res.data.status==="success"){   
        updateAPICalledIDP = false;
        getIDPSettings() 
      } 
    }).catch((res) => {  
      updateAPICalledIDP = false;
      getPopupMessageBasedOnStatus(res)
      getIDPSettings() 
    })
  } 

  const SaveHandler = (themeSettings) => {
    if(!tenantConfig.date_format_id){
      Helper.alert('Date Format field missing!', 'error');
      return;
    }
    
    $('.theme_settings_page .rs_btn').trigger('click');
    
    if(IDPConfigExist){
      executeIDPConfigUpdateApi(); 
    } else {
      executeIDPConfigCreateApi();
    }
    
    if(configExist){ 
      executeAPIUpdate(themeSettings) 
      return; 
    }

    if(!configExist) { 
      executeAPICreate(themeSettings)
      return;
    } 
  }

  // Security system
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.administrationForms.system_parameters.system_parameters)){
    return <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
   }

  return ( 
    <Fragment> 
      {isLoading ? <SimpleLoading /> : SystemParametersWrapperModule()}
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const setStateToProps = (dispatch) => {
  return {
    setDateFormate:(formate) => {
      Helper.setCookie(Settings.dateFormateKey,formate,1000);
      dispatch({type:ActionTypes.SET_DATE_FORMATE , payload:formate}) 
    },
    setLogoURL: (logoURL,tenantData) => { dispatch({ type:ActionTypes.SET_LOGO_URL,url: logoURL, tenant: tenantData})},
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.administrationForms.system_parameters.system_parameters
}

export default connect(mapStateToProps, setStateToProps) (MasterComponentWraper(ApplySecurityRoles(SystemParams, SecurityOptions)))