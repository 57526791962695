import React, { Fragment, useEffect, useRef, useState } from 'react'; 
import { checkIfArrayIsEmpty, extractCriteriaArray, isEmptyObject } from '../../../../inc/Validation';
import Tabs from '../../../Widgets/Tabs/Tabs';  
import TechnicalSelectedLinesModal from "../../../RequestForQuotation/RFQDetailForm/TechnicalEvaluation/TechnicalSelectedLinesModal";
import { connect } from "react-redux";
import SimpleLoading from '../../../Loading/SimpleLoading';  
import Checkbox from '../../../Forms/Checkbox';
import RFQAttachmentPopup from "../../RFQ Vendor Reply/RFQList/RFQAttachmentPopup"; 
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings'; 
import LinesRecommendationPopup from './LinesRecommendationPopup';
import Button from '../../../inc/Button';

const SummaryMappingColumns = [
  "total_score",
  "total_score_in_percentage",
  "disqualified",
  "line_recommendation",
];
const SummarySidebarCols = [
    {
        id: 1,
        label: 'Total Score'
    },
    {
        id: 2,
        label: 'Total Score in Percentage'
    },
    {
        id: 3,
        label: 'Disqualified'
    }, 
    {
        id: 4,
        label: 'Line Recommendation'
    },
];

const TechConsolidationHeader = ({technicalData, rfq_scores_data, hideSidebarState=false, screenHeight=0, rfqHeader, language, auth}) => {  
    const [rfqAttachments, setRfqAttachments] = useState([]);
    const [vendor, setVendor] = useState([]); 
    const [openDropdownIndex, setOpenDropdownIndex] = useState({ section: -1, score: -1 }); 
    const [openVendorReasonTab, setOpenVendorReasonTab] = useState(false); 
    const [linesDetails, setLinesDetails] = useState({openPopup: false, rfq_vendorreply_id: null, id: null }) 
    const [scrollableDivHeight, setScrollableDivHeight] = useState(0);  
    const [scrollDivHeight, setScrollDivHeight] = useState(window?.innerHeight - 100);

    const [loading, setLoading] = useState(true);
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [showSelectedLinesModal, setShowSelectedLinesModal] = useState(false); 
 
    const mounted = useRef();
    const vendorReasonPopupRef = useRef();
    const column1Ref = useRef(null);
    const column2Ref = useRef(null);
    const column3Ref = useRef(null);
    const popupModalRef = useRef(null); 

    let emptyRowIndexForVendorScoring = 0; 

    useEffect(() => {
        if (!mounted.current) { mounted.current = true }; 
        setComponentWidthsHeights();
        setLoading(false);
    }, [technicalData]);
 
    useEffect(() => { 
        getCalculatedScrollableColumnHeight();
    }, [hideSidebarState, screenHeight]);

    useEffect(() => {
        const handleResize = () => {
            if (window?.innerHeight < 671) {
                setScrollDivHeight(window?.innerHeight - 84)
            } else {
                setScrollDivHeight(window?.innerHeight - 100);
            } 
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (vendorReasonPopupRef.current && !vendorReasonPopupRef.current.contains(event.target)) {
                setOpenVendorReasonTab(false)
            }
        }
    
        document.addEventListener('mousedown', handleClickOutside)
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside)
        }
    }, []);
  
    const getCalculatedScrollableColumnHeight = () => {
        const techEvalConsolidatedDiv = document.getElementsByClassName("technical_evaluation_with_consolidated_view_wrapper")[0];
        const offsetWidth = techEvalConsolidatedDiv?.offsetWidth;

        if (!hideSidebarState) {
            if (window.innerHeight === 670 && offsetWidth <= 806) {
                setScrollableDivHeight(screenHeight + 17);
            } else if ((window.innerHeight === 739 || window.innerHeight === 923) && offsetWidth <= 1331) {
                setScrollableDivHeight(screenHeight + 20);
            } else {
                setScrollableDivHeight(screenHeight);
            }
        } else {
            setScrollableDivHeight(screenHeight);
        }
    };

    const getRfqAttachments = (attachmentSourceId) => {
        if (!attachmentSourceId) return;

        let api = Api;
        api.setUserToken();
        api.axios()
        .get(Settings.apiUrl + `/get_attachments/${Settings?.source_id?.RfqVendorReply}/${attachmentSourceId}?attachment_for=1`)
        .then(function (res) { 
            if (res.data.status === "success") { 
                setRfqAttachments(res.data.data);
            }
        })
        .catch((error) => { console.log(error) });
    };
    
    const setComponentWidthsHeights = () => {
        if(isEmptyObject(technicalData)) return;
        // Execution of heights/widths logics for dynamic view (ComponentDidUpdate Logics)
        const usersCol = document.querySelector('.technical_evaluation_consolidated .users_col');
        const allEvaluatorCols = document.querySelectorAll('.technical_evaluation_consolidated .users_col');
        const vendorCols = document.querySelectorAll('.technical_evaluation_consolidated .vendor_col');
        const evaluationPointsCol = document.querySelector('.technical_evaluation_consolidated .evaluation-points');
        const PointsCol = document.querySelector('.technical_evaluation_consolidated .evaluation-scoring-points'); 
        const vendorScoringCol = document.querySelectorAll('.technical_evaluation_consolidated .spaced_scoring_col');
        const vendorSummaryCol = document.querySelectorAll('.technical_evaluation_consolidated .auto_spacing_summary_col');
        const vendorSocringColumns = document.querySelectorAll('.technical_evaluation_consolidated .individual_space_column');

        const SidebarCols = document.querySelectorAll('.technical_evaluation_consolidated .evaluation-criterias');
        const SidebarBlueCol = document.querySelectorAll('.technical_evaluation_consolidated .evaluation-section-heading');
        const SidebarEvaluationScores = document.querySelectorAll('.technical_evaluation_consolidated .evaluation-all-scores'); 
        const RowItems = document.querySelectorAll('.technical_evaluation_consolidated .scrollable_row.vendor-scores');

        const EmptyBlueRow = document.querySelectorAll('.technical_evaluation_consolidated .section_row_empty');
        const EmptyBlueColUserHighScore =  document.querySelectorAll('.technical_evaluation_consolidated .evaluation-scoring-empty');
 
        RowItems.forEach((div, index) => div.style.height = `${SidebarCols[index]?.getBoundingClientRect()?.height}px`);
        SidebarEvaluationScores.forEach((div, index) => div.style.height = `${SidebarCols[index]?.getBoundingClientRect()?.height}px`);

        EmptyBlueColUserHighScore.forEach((div, index) => div.style.height = `${SidebarBlueCol[index]?.getBoundingClientRect()?.height}px`); 
        EmptyBlueRow.forEach((div, index) => div.style.height = `${SidebarBlueCol[index]?.getBoundingClientRect()?.height + 0.60}px`)

        let userColWidths = usersCol?.getBoundingClientRect()?.width < 275 ? 275 : usersCol?.getBoundingClientRect()?.width;

        allEvaluatorCols.forEach((div) => { 
            div.style.minWidth = `${userColWidths}px`
        });
        vendorCols.forEach((div) => { 
            div.style.minWidth = `${userColWidths}px`
        });
        vendorScoringCol.forEach((div) => { 
            div.style.minWidth = `${userColWidths}px`
        });
        vendorSummaryCol.forEach((div) => { 
            div.style.minWidth = `${userColWidths}px`
        });
        vendorSocringColumns.forEach((div) => { 
            div.style.width = `${userColWidths}px`
        }); 

        PointsCol.style.height = `${usersCol?.getBoundingClientRect()?.height}px`;
        evaluationPointsCol.style.height = `${usersCol?.getBoundingClientRect()?.height}px`; 
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupModalRef.current && !popupModalRef.current.contains(event.target)) setOpenDropdownIndex({ section: -1, score: -1 }); 
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => { document.removeEventListener('mousedown', handleClickOutside) }
    }, [openDropdownIndex]);

    const renderSummaryGridCell = (colKey, columnName, row) => {  
        switch (colKey) {
            case 0: 
                return <span>{(row[columnName])}/{((extractCriteriaArray(technicalData?.sections, true)?.length) * 10) ?? 0}</span> ;
            case 1:
                return <span>{parseFloat(row[columnName])?.toFixed(2)}%</span>;
            case 2:
                return <div className='checkbox-wrapper'> <Checkbox disableWithoutIcon={true} isChecked={row[columnName]} /> </div>;
            case 3:
                return (
                    <div style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                        <span className='me-1'> <img src='/images/icons/lines-blue.svg' className='img-fluid' alt="Lines Icon" /> </span>
                        <span className='me-1'> {row[columnName]} </span> 
                        <span className='me-1'> Lines </span>
                    </div>
                );
            default:
                return ``
        }
    };

    const splitStringWithClass = (str) => {
        const chunks = str?.match(/.{1,40}/g) || []; 
        const remaining = str?.slice(chunks.join('').length);  
      
        return (
            <div>
                {chunks.map((chunk, index) => ( <div className="display_block" key={index}> {chunk} </div> ))}
                {remaining && ( <div className="display_block"> {remaining} </div> )}
            </div>
        );
    };

    const UserCommentsTab = (array) => { 
        if(checkIfArrayIsEmpty(array)) return;
        
        let navs = array.map((item, index) => {
            return {
              title: item.userName,
              isActive: index === 0,
            };
        });

        const CloseUserComments = (evt) => { 
            evt.stopPropagation(); 
            setOpenDropdownIndex({ section: -1, score: -1 })
        };

        return ( 
            <Fragment>
                <Tabs navs={navs} disableSticky={true} showAll={false}>
                    {array.map((item, index) => (
                        <div className='user_comments_tab' key={index}>
                            <div> <img src='/images/icons/score-high.svg' className='img-fluid' /> <span> Score:</span> {item.score}</div> 
                            <div> <p>{item.comments}</p> </div>
                        </div>
                    ))}
                </Tabs>
                <div className='mt-2'>
                    <Button 
                        title={"Close"}
                        onClick={CloseUserComments}
                        className='black-btn-style'
                    />
                </div> 
            </Fragment>
        )
    };

    const VendorReasonForSelectionTab = () => { 
        let evaluatorDetailsArray = technicalData?.summaryRowData[0]?.scores;
        if(checkIfArrayIsEmpty(evaluatorDetailsArray)) return;

        return ( 
            <Fragment> 
                <h1>Vendor Reason for Selection</h1>
                <div className='vendor_reasons_details_wrapper'> 
                    {evaluatorDetailsArray.map((item, index) => (
                        <div className='vendor_reason_selection_wrapper' key={index}>
                            <h2>{item.evaluator_name}:</h2>
                            <p>{item.reason ?? '-'}</p>
                        </div>
                    ))} 
                </div>
            </Fragment>
        )
    };

    const columnSimultaneousScrollFunctionality = (event) => {
        const scrollPosition = event.target.scrollTop;

        if (column1Ref.current && column2Ref.current && column3Ref.current) {
            column1Ref.current.scrollTop = scrollPosition;
            column2Ref.current.scrollTop = scrollPosition;
            column3Ref.current.scrollTop = scrollPosition;
        }
    };

    const renderSectionRowEmpty = (height) => <div style={{height: height ? height : null}} className='section_row_empty'></div>; 

    const OpenDropdown = (sectionIndex, scoreIndex) => { setOpenDropdownIndex({ section: sectionIndex, score: scoreIndex }) };

    const renderOverlay = () => ( <div className="background-loader-overlay"> <SimpleLoading /> </div> );

    const ExecuteAttachment = (e) => { 
        setShowAttachmentModal(true);
        getRfqAttachments(e?.reply_id); 
    };

    const ExecuteInfoBlue = (vendor) => { 
        setVendor(vendor);
        setShowSelectedLinesModal(true);
    };

    const RecommendationLinesPopupOpen = (vendor, colKey) => {
        if(colKey === 3){  
            setLinesDetails({
                id: vendor?.scorereplyheader_id,
                rfq_vendorreply_id: vendor?.rfq_vendorreply_id,
                openPopup: true
            });  
        }
    }
    
    const closeAttachmentModal = () => { setShowAttachmentModal(false) };
    return (
        <Fragment>
            <div ref={mounted} className="grid-container">
                {loading && renderOverlay()}
                {isEmptyObject(technicalData) ? <div>No Scores Found!</div> :  
                    <>
                        <div className="column" style={{maxHeight: `${screenHeight ? screenHeight : (window?.innerHeight - 100)}px`}} ref={column1Ref} onScroll={columnSimultaneousScrollFunctionality}>
                            <div className='row sticky-behaviour'> <div className='evaluation-criteria-heading'>Evaluation Criteria</div> </div> 
                            <div className='row'> <div className='evaluation-points'>Environment & Procurement understanding</div> </div>

                            {technicalData.sections.map((section) => (
                                <Fragment> 
                                    <div className='row'>
                                        <div className='evaluation-section-heading'>{section.name}</div>
                                    </div> 

                                    {section.criterias.map((col) => ( 
                                        <div className='row'>
                                            <div className='evaluation-criterias'>
                                                {col.criteria} {col.commentsMandatory && <span className='asterik_sign'>*</span>}
                                            </div>
                                        </div>
                                    ))}
                                </Fragment>
                            ))}

                            <div className='row'> <div className='summary-heading'>Summary</div> </div>

                            {SummarySidebarCols.map((col) => (
                                <div className='row summary_label_cols'>
                                    <div className='evaluation-criterias' style={{color: '#2D74E0'}}>
                                        {col.label}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="column scrollable" style={{maxHeight: `${screenHeight ? scrollableDivHeight : scrollDivHeight }px`}} ref={column2Ref} onScroll={columnSimultaneousScrollFunctionality}>  
                            
                            <div className="scrollable_row sticky-behaviour">
                                {technicalData.vendorsList.map((element) => (
                                    <div className='vendor_col' key={element.id}>
                                        <div className='vendor_div'>
                                            {element.vendor}
                                            <span className='ms-3'>
                                                <img style={{cursor: 'pointer'}} onClick={(e) => ExecuteAttachment(element)} className='img-fluid me-2' src='/images/icons/attachment.svg' alt="Attachment" />
                                                <img style={{cursor: 'pointer'}} onClick={() => setOpenVendorReasonTab(true)} className="img-fluid me-2" src="/images/icons/comments.svg" alt="Comments" />
                                                <img style={{cursor: 'pointer'}} onClick={(e) => ExecuteInfoBlue(element)} className='img-fluid' src='/images/icons/info-blue.svg' alt="Info Blue" />
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
    
                            <div className="scrollable_row">
                                {technicalData.users.map((row, rowIndex) => (  
                                    <div className="users_col" key={rowIndex}>
                                        {row.map((user) => ( 
                                            <div className='user_column' key={user.id}>
                                                {splitStringWithClass(user.userName)}  
                                            </div> 
                                        ))} 
                                    </div>
                                ))}
                            </div>
            
                            {technicalData.vendorScoring.map((item, vnIndex) => {
                                const matchingSection = technicalData.sections.find(section => section.name === item.section_name);
                                const criteriaLength = matchingSection ? matchingSection.criterias.length : 0;

                                let shouldRenderEmptyRow = false;

                                if (vnIndex === emptyRowIndexForVendorScoring) {
                                    shouldRenderEmptyRow = true;
                                    emptyRowIndexForVendorScoring += criteriaLength;
                                }

                                return (
                                    <Fragment key={vnIndex}>
                                        {shouldRenderEmptyRow && renderSectionRowEmpty()}
                                        <div className="scrollable_row vendor-scores">
                                            {item.all_scores.map((scores, scoreIndex) => { 
                                                const orderedScores = technicalData.users[0].slice(0, -1).map(user => {
                                                    const matchingScore = scores.find(score => score.userName === user.userName);
                                                    return matchingScore ? matchingScore.score : 0;
                                                });

                                                return (
                                                    <div className="spaced_scoring_col" key={scoreIndex}>
                                                        {orderedScores.map((orderedScore, innerScoreIndex) => (
                                                            <div className="individual_space_column" key={innerScoreIndex}>
                                                                {orderedScore}
                                                            </div>
                                                        ))}
                                                        <div className="individual_space_column" onClick={() => OpenDropdown(vnIndex, scoreIndex)}>
                                                            <img className="img-fluid" src="/images/icons/comments.svg" alt="comments" />
                                                            {openDropdownIndex.section === vnIndex && openDropdownIndex.score === scoreIndex && (
                                                                <div className="dropdown-menu-comments" ref={popupModalRef}>
                                                                    <h5>Understanding of the requirement.</h5>
                                                                    <div className="comments_tab">{UserCommentsTab(scores)}</div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div> 
                                    </Fragment>
                                );
                            })}

                            {renderSectionRowEmpty(47)}
                            
                            {!checkIfArrayIsEmpty(SummaryMappingColumns) &&
                                SummaryMappingColumns
                                .filter(columnName => columnName !== "rfq_vendorreply_id" && columnName !== "scorereplyheader_id")
                                .map((columnName, colKey) => ( 
                                    <div className="scrollable_row summary_cols"> 
                                        {!checkIfArrayIsEmpty(technicalData.summaryRowData) &&
                                            technicalData?.summaryRowData
                                            .map((row, rowKey) => {  
                                                return (
                                                    <div className='auto_spacing_summary_col d-flex justify-content-center align-items-center' key={rowKey}>
                                                        {row?.scores?.map((scoreObj, index) => (
                                                            <div className='individual_space_column' key={index} onClick={() => RecommendationLinesPopupOpen(scoreObj, colKey)}> 
                                                                {renderSummaryGridCell(colKey, columnName, scoreObj)}
                                                            </div>
                                                        ))}
                                                        
                                                        {(colKey === 0 || colKey === 1) && row?.scores?.length > 1 ? 
                                                            (
                                                                <div className="individual_space_column">
                                                                    {colKey === 0 ? `${row?.total_score}` : (row?.total_weighted_percentage)  + "%"}
                                                                </div>
                                                            ) : (
                                                                <div className="individual_space_column"></div>
                                                            )
                                                        }
                                                    </div>
                                                );
                                            })
                                        }
                                    </div> 
                            ))}
                        </div>

                        <div className="column" style={{maxHeight: `${screenHeight ? screenHeight : (window?.innerHeight - 100)}px`}} ref={column3Ref} onScroll={columnSimultaneousScrollFunctionality}>
                            <div className='row sticky-behaviour'> <div className='evaluation-scoring-heading'>Top Scorer</div> </div>
                            
                            <div className='row'> <div className='evaluation-scoring-points'></div> </div>
            
                            {technicalData.sections.map((section, sectionIndex) => (
                                <Fragment key={sectionIndex}> 
                                    <div className='row'><div className='evaluation-scoring-empty'></div></div>

                                    {section.criterias.map((col, criteriaIndex) => ( 
                                        <div className='row' key={criteriaIndex}>
                                            <div className='evaluation-all-scores'>
                                                {col.scoreVendorName} 
                                            </div>
                                        </div>
                                    ))}
                                </Fragment>
                            ))} 

                            <div className='row'> <div className='empty-heading-space'></div> </div> 
                            {SummarySidebarCols.map(() => ( <div className='row'> <div className='empty-summary-col'></div> </div> ))}
                        </div>
                    </>
                }
                
                { showAttachmentModal && (
                    <RFQAttachmentPopup
                        data={rfqAttachments}
                        language={language}
                        closeModal={closeAttachmentModal}
                        style={{ 
                            position: 'absolute',
                            top: `143px`,
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    />
                )}

                { openVendorReasonTab && 
                    <div className="rfq-attachment-popup vendor_reason_popup" ref={vendorReasonPopupRef} style={{ 
                        position: 'absolute',
                        top: `143px`,
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}> 
                        {VendorReasonForSelectionTab()} 
                    </div> 
                }
            </div>

            {linesDetails?.openPopup && ( 
                <LinesRecommendationPopup
                    rfq_id={rfqHeader.rfq_id}
                    setLinesDetails={setLinesDetails}
                    vendorreply_id={linesDetails?.rfq_vendorreply_id}
                    scorereplyheader_id={linesDetails?.id}
                    language={language}
                    rfqHeader={rfqHeader}
                    hideDeleteBtn={true}
                    user={auth?.user}
                    disabled={true}
                /> 
            )}

            {showSelectedLinesModal && (
                <TechnicalSelectedLinesModal
                    rfqHeader={rfqHeader}
                    language={language}
                    setShowSelectedLinesModal={setShowSelectedLinesModal}
                    vendor={vendor}
                    hideDeleteBtn={true}
                    setVendor={setVendor}
                    user={auth?.user}
                />
            )} 
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { 
        language: state.language,
        auth:state.auth
    };
};

export default connect(mapStateToProps) (TechConsolidationHeader);