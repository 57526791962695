import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoutBtn from '../Auth/LogoutBtn';
import TanentSwitcher from '../Auth/TanentSwitcher';
class UserWidget extends Component {
    constructor(props){
        super(props);
        this.state = {
            isDropdownOpen:false
        }
        this.dropdownRef = React.createRef();
    }
    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
            this.setState({ isDropdownOpen: false });
        }
    };
    getImageUrl (user){ 
        if(!user?.Image || user?.Image?.includes("undefined")){
            return "url(/images/blank-profile.jpg)";
        }
        const imageData = user?.Image?.slice(2,-1)
        const imageUrl = user?.Image?.startsWith("data")?user?.Image:`data:image/${user?.Image_type};base64,${imageData}`;
        return `url(${imageUrl})`;
    }
    render() {
        let user = this.props.auth.user ? this.props.auth.user : {};
        let userTenant = user.tenant ? user.tenant  : null; 

        return (
            <div className={ "user_widget_menu" + (this.state.isDropdownOpen ? ' open ': ' close') } ref={this.dropdownRef}>
                <div className='d-flex' onClick={ e => { this.setState({isDropdownOpen: !this.state.isDropdownOpen})}}>
                    <span className='u_name'>{user.first_name +' '+ user.last_name}</span>
                    <div className='u_p_wraper' style={{backgroundImage:this.getImageUrl(user)}}>
                    </div>
                    <img className='arrow' src="/images/icons/angle-down.svg" alt=""/>
                </div>
                <div className='dropdownwidget'>
                    { !this.props?.user?.admin_user && (
                        <ul>
                            {this.props.type === "password" ? <></>:<li>
                                <Link to={'/my-account'}>
                                    My Account
                                </Link>
                            </li>}
                            {/* <li>
                                <a>Switch Tentant</a>
                                <TanentSwitcher/>
                            </li>
                            <li><Link to={'/settings'}>Settings</Link></li> */}
                            {userTenant ? <li><Link to={'/tenant/edit/' + userTenant.tenant_Id}>My Tenant</Link></li> : ''}
                        </ul>
                    )}
                    <LogoutBtn/>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        appOptions:state.options,
        user:state.auth.user
    }
  }
  export default connect(mapStateToProps) (UserWidget);