import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import Button from '../inc/Button';
import NoteAttachmentHistory from './NoteAttachmentHistory';

const PreviewModalNotes = (
    { handleNotesPreviewClose, condition, showActionHistoryDropdown, showCanvas, openCurrentAssignees, setActionHistoryState, actionHistoryLoadingState, isOpen, disableAttachments, hideUploaderCondition, hideNotes, source_id, historyArray, hideHistory, hideVendorPortal, attachmentsource_integrator, actionHistoryForRFQ, hideAttachments, notesource_integrator }
) => {
    return (
        <Offcanvas backdropClassName='offcanvas-preview-component-backdrop' className='notes-preview-component-offcanvas' backdrop={true} placement='end' show={isOpen} onHide={handleNotesPreviewClose} >
            <Offcanvas.Body>
                <div className='vendor-notes-popup'> 
                    <NoteAttachmentHistory 
                        isCustomHeight={true} 
                        isCustomHeightActionHistory={true}
                        hidePreviewNotes={true}
                        showActionHistoryDropdown={showActionHistoryDropdown}
                        hideNotes={hideNotes} 
                        historyArray={historyArray}
                        hideHistory={hideHistory}
                        hideVendorPortal={hideVendorPortal}
                        actionHistoryForRFQ={actionHistoryForRFQ}
                        hideAttachments={hideAttachments} 
                        notesource_integrator={notesource_integrator}
                        attachmentsource_integrator={attachmentsource_integrator}
                        source_id={source_id}
                        hideUploaderCondition={hideUploaderCondition}
                        disableAttachments={disableAttachments}
                        actionHistoryLoadingState={actionHistoryLoadingState} 
                        setActionHistoryState={setActionHistoryState}
                        openCurrentAssignees={openCurrentAssignees} 
                        openCanvas={showCanvas} 
                        condition={condition}
                    />
                    <div className='action_btns'>
                        <Button
                            onClick={handleNotesPreviewClose}
                            title={'Close'}
                            className="black-btn-style"
                        />
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
};

export default PreviewModalNotes;