import React, { useState, useEffect, useRef, Fragment } from 'react'
import NavigationHeder from '../../Navigations/NavigationHeder'
import {
  ButtonSkeletonComponent,
  TextSkeletonComponent,
} from '../../Skeleton/SkeletonComponent'
import Button from '../../inc/Button'
import FixedNavigationHeder from '../../Navigations/FixedNavigationHeder'
import RFQCommonApiCalls from '../RFQCommonApiCalls'
import SimpleLoading from '../../Loading/SimpleLoading'
import { CalculateDateDelta } from '../../External Screens/RFQ Vendor Reply/RFQBiddingDetails/CalculateDateDelta'
import Helper from '../../../inc/Helper'
import $ from 'jquery'
import ReasonPopUp from '../../External Screens/RFQ Vendor Reply/RFQBiddingDetails/ReasonPopUp'
import Collapse from '../../inc/Collapse'
import TabContentWraper from '../../Widgets/Tabs/TabContentWraper'
import GeneralInformationRFQReply from '../RFQDetailForm/HeaderView/GeneralInformationRFQReply'
import CommonScoringSections from '../../External Screens/Request For Quotation/Scoring Module/CommonScoringSections'
import AddressList from '../../Tenant/Address/AddressList'
import ContactAddress from '../../Tenant/Address/ContactAddress'
import { Link, useParams } from 'react-router-dom'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import Settings from '../../../inc/Settings'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Api from '../../../inc/Api'
import SegmentsDistribution from '../../Segments/SegmentsDistribution'
import ExternalComplianceView from '../../External Screens/Request For Quotation/Compliances/ExternalComplianceView'
import LineViewComponent from '../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'
import RFQAttachmentPopup from '../../External Screens/RFQ Vendor Reply/RFQList/RFQAttachmentPopup'
import TotalWidget from '../../Widgets/TotalWidget/TotalWidget'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import AlternateLinesPopup from './AlternateLinesPopup'
import { Alert } from 'react-bootstrap'
import { getNewValue } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import BAFOModal from '../RFQDetailForm/BAFOModal'
import axios from 'axios'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'

let cancelTokenSource = axios.CancelToken.source();
let lineObj = null

const RFQVendorReply = (props) => {
  const [showPopUp, setShowPopUp] = useState(false)
  const [comment, setComment] = useState('')
  const [rfqVendorData, setRfqVendorData] = useState({})
  const [updateRfqVendorData, setUpdateRfqVendorData] = useState({})
  const [allFieldsAreZero, setAllFieldsAreZero] = useState(false)
  const [onBehalfBid, setOnBehalfBid] = useState(false)
  const [onEdit, setOnEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isBidEditable,setIsBidEditable]=useState(false)
  const [isAllowBidInAuction,setIsAllowBidInAuction]=useState(false)
  const [viewAlternateLines,setViewAlternateLines]=useState(false)

  const [employeesList, setEmployeesList] = useState([])
  const [securityLevelList, setSecurityLevelList] = useState([])
  const [quotationTypeList, setQuotationTypeList] = useState([])
  const [paymentTermList, setPaymentTermList] = useState([])
  const [modeOfDeliveryList, setModeOfDeliveryList] = useState([])
  const [deliveryTermList, setDeliveryTermList] = useState([])
  const [currencyList, setCurrencyList] = useState([])
  const [rfqVendorReplyStatus, setRfqVendorReplyStatus] = useState([])
  const [actions, setActions] = useState([])

  const [lineId, setLineId] = useState(0)
  const [alternativeLines, setAlternativeLines] = useState(false)
  const [attachmentSourceId, setAttachmentSourceId] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [popUpPosition, setPopUpPosition] = useState({ top: 0, right: 0 })
  const [rfqVendorAttachments, setRFQVendorAttachments] = useState([])
  const [show,setShow]=useState(true)
  const [saveBtnApi,setSaveBtnApi]=useState(false)
  const [showBafoModal,setShowBafoModal]=useState(false)
  const [vendorsData,setVendorsData] = useState([])
  const [isAuctionBidEditable,setIsAuctionBidEditable]=useState(false)

  const [selectedSupNav, setSelectedSupNav] = useState('Header View')
  const [scrollDitection, setScrollDitection] = useState('down')
  const [height, setHeight] = useState(0)

  const myDivRef = useRef(null)
  let sectionName = 'request-for-quotation-reply-edit'

  const commonApiCalls = new RFQCommonApiCalls()
  const { id } = useParams()

  const handleClose = () => setShow(false);

  const getRFQVendorReplyData = async () => {
    try {
      setLoading(true);
      const rfqHeaderData = await commonApiCalls.getAllVendorReplyHeaderById(id);
      if (rfqHeaderData) {
        setRfqVendorData(rfqHeaderData);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in fetching RFQ vendor reply data", error);
    } finally {
      setLoading(false);
    }
  }

  const getProcumentSettings = () => {
    if(!rfqVendorData?.entity_id){
      return
    }
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiProcurementSettingsUrl + `/procurementsetting_by_entity/${rfqVendorData?.entity_id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setIsBidEditable(res?.data?.data?.is_edit_allowed_by_buyer_after_rfq_expiry)  
          setIsAllowBidInAuction(res?.data?.data?.allow_buyer_to_edit_bid_on_behalf_in_auction)
        }
      })
      .catch((res) => {
      
      })
  }

  useEffect(()=>{
    getProcumentSettings()
  },[rfqVendorData?.entity_id])

  useEffect(() => {
    if(rfqVendorData?.RFQ_type ===2){
      return
    }
    const interval = setInterval(() => {
    const newDateDelta = CalculateDateDelta(rfqVendorData?.expiry_datetime)
      const dateExpiryCheck =
        newDateDelta &&
        newDateDelta.days === 0 &&
        newDateDelta.hours === 0 &&
        newDateDelta.minutes === 0 &&
        newDateDelta.seconds === 0
      const isQuotationTypeValid = rfqVendorData?.quotation_type === 1;
      const isCommercialUnsealed = !rfqVendorData?.commercial_usealed;
  
      if (isQuotationTypeValid && isCommercialUnsealed) {
        setIsBidEditable(true);
      } else if (isBidEditable) {
        setAllFieldsAreZero(false);
      } else {
        setAllFieldsAreZero(dateExpiryCheck);
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [rfqVendorData?.expiry_datetime,isBidEditable])

  useEffect(() => {
    if(rfqVendorData?.RFQ_type !==2){
      return
    }
    let activeEndDate = rfqVendorData?.auction_start_date_time; 
    let startExpired = false; 
    const interval = setInterval(() => {
      const newDateDelta = CalculateDateDelta(activeEndDate);
  
      const isExpired =
        newDateDelta &&
        newDateDelta.days === 0 &&
        newDateDelta.hours === 0 &&
        newDateDelta.minutes === 0 &&
        newDateDelta.seconds === 0;

    
      if(!isAllowBidInAuction){
         setIsAuctionBidEditable(true)
      }else if(!startExpired && isExpired) {
        startExpired = true;
        activeEndDate = rfqVendorData?.auction_end_date_time;
        setIsAuctionBidEditable(false); 
      } else if (!startExpired) {
        setIsAuctionBidEditable(true); 
      } else if (startExpired && isExpired) {
        setIsAuctionBidEditable(true); 
        setAllFieldsAreZero(true)
      }
    }, 1000);
  
    return () => clearInterval(interval);
  }, [rfqVendorData?.auction_start_date_time, rfqVendorData?.auction_end_date_time, isAllowBidInAuction]);
  
  useEffect(()=>{
    getRFQVendorReplyData()
  },[])

  useEffect(() => {
    getAsyncData()
    get_reply_actions()
  }, [])

  useEffect(()=>{
    getAllVendors()
  },[rfqVendorData])

  useEffect(()=>{
    getAllVendors()
  },[rfqVendorData])

 
  const handleSaveClickCall = async () => {
    if (!id) return;
    let api = Api;
    api.setUserToken();
    let payload = updateRfqVendorData;
  
    if (payload?.rank === "") {
      payload.rank = 0;
    }

    if (payload.discount_amount === "") {
      payload.discount_amount=0
    }

    if (payload.discount_percentage=== "") {
      payload.discount_percentage=0;
    }

    if (payload.rounding_amount=== "") {
      payload.rounding_amount=0;
    }
  
    try {
      const res = await api
        .axios()
        .put(
          `${Settings.apiPurchaseRequisitionUrl}/RFVendorreplyheader/${id}`,
          payload,{ cancelToken: cancelTokenSource.token }
        );
      Helper.alert(res.data.message, 'success');
      setUpdateRfqVendorData({});
    } catch(res) {
      getPopupMessageBasedOnStatus(res)
    }
  }

  const handleSaveClick = () => {
    Helper.createDebouncedAPIFunction([async ()=>{return handleSaveClickCall()}], setSaveBtnApi, cancelTokenSource, false, 300000)();
  }

  const addComponentTop = () => {
    let items = [
      {
        title: Helper.getLabel(
          props.language,
          'document_number',
          'Document Number'
        ),
        subtitle: !rfqVendorData ? (
          <TextSkeletonComponent />
        ) : (
          rfqVendorData?.document_number ?? rfqVendorData?.RFQ_vendorreply_id
        ),
      },
      {
        title: Helper.getLabel(props.language, 'title', 'RFQ Title'),
        subtitle: !rfqVendorData ? (
          <TextSkeletonComponent />
        ) : (
          (rfqVendorData?.title?.length > 30) ? (
            rfqVendorData?.title?.substring(0, 30) + "..." || "-"
          ) : rfqVendorData?.title || "-"
        ),
      },
      {
        title: Helper.getLabel(props.language, 'vendor_name', 'Vendor  Name'),
        subtitle: !rfqVendorData ? (
          <TextSkeletonComponent />
        ) : (
          rfqVendorData?.Vendor_name ?? '-'
        ),
      },
      {
        title: Helper.getLabel(
          props.language,
          'publish_Date',
          'Publish Date and Time'
        ),
        subtitle: !rfqVendorData ? (
          <TextSkeletonComponent />
        ) : (
          DatepickerFunctions.convertDateTimeFromDataBase(
            rfqVendorData?.publish_datetime
          ) || '-'
        ),
      },
      {
        title: Helper.getLabel(
          props.language,
          'expiry_date',
          'Expiry Date and Time'
        ),
        subtitle: !rfqVendorData ? (
          <TextSkeletonComponent />
        ) : (
          DatepickerFunctions.convertDateTimeFromDataBase(
            rfqVendorData?.expiry_datetime
          ) || '-'
        ),
      },
    ]

    if(rfqVendorData?.RFQ_type===2){
      items = items?.filter((item)=>{return item.title !== Helper.getLabel(props.language,'expiry_date','Expiry Date and Time')})
    }

    let invoiceDocumentStatus = rfqVendorReplyStatus?.find(
      (item) => item.id === rfqVendorData?.vendor_replystatus
    )

    let tagTitle = invoiceDocumentStatus?.name || '-'
    let tags = [{ title: tagTitle.replace(/_/g, " ") || null }]

    return (
      <div className={`rfq_detail_form`}>
        <div className='rfq-internal-blue'>
          <BlueCallToAction
            scrollDitection={scrollDitection}
            items={items}
            tags={tags}
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    Helper.resizeWindowHandle(myDivRef,setHeight,window)
  },[myDivRef.current?.clientHeight,scrollDitection,show]);

  const getAsyncData = async () => {
    // here we check and store localStore data into variable...
    try {
      const rfqReplyStatuses = await commonApiCalls.getAllRfqVendorReplyStatus()
      const employeesData = await commonApiCalls.getAllEmployees()
      const currenciesData = await commonApiCalls.getAllCurrencies()
      const securityLevelData = await commonApiCalls.getRfqSecurityLevels()
      const quotationTypeData = await commonApiCalls.getRfqQuotationTypes()
      const paymentTermsData = await commonApiCalls.getPaymentTerms()
      const modeOfDeliveryData = await commonApiCalls.getModeOfDelivery()
      const deliveryTermsData = await commonApiCalls.getDeliveryTerms()
  
      setRfqVendorReplyStatus(rfqReplyStatuses)
      setEmployeesList(employeesData)
      setCurrencyList(currenciesData)
      setSecurityLevelList(securityLevelData)
      setQuotationTypeList(quotationTypeData)
      setPaymentTermList(paymentTermsData)
      setModeOfDeliveryList(modeOfDeliveryData)
      setDeliveryTermList(deliveryTermsData)
    } catch (err) {
      console.log(err)
    }
  }

  const getAllVendors = async () => {
     try{
      const vendorsData = await commonApiCalls.getAllRfqVendors(rfqVendorData?.RFQ_id)
      let vendors = vendorsData?.filter((item)=>item?.vendorreply_id === parseInt(id))
      setVendorsData(vendors)
     }
     catch(err){
       
     }
  }

  const gridValueColorFormat = (params) => {
    const cellValue =
      params.value === null || params.value === undefined ? " " : params.value;
     const cellClass = cellValue === " " ? "rfq-null-value-cell" : "";
    return <div style={{display:'flex',alignItems:'center',height:'40px'}}><div className={`rfq-cell ${cellClass}`}>{cellValue}</div></div>
  };

  let navs = [
    {
      title: Helper.getLabel(props.language, 'general', 'General'),
      data_section: 'one',
      active_status: 'active_tab',
    },
    {
      title: Helper.getLabel(props.language, 'address', 'Address'),
      data_section: 'two',
      active_status: '',
    },
    {
      title: Helper.getLabel(props.language, 'contact', 'Contact'),
      data_section: 'three',
      active_status: '',
    },
    {
      title: Helper.getLabel(props.language, 'compliance', 'Compliances'),
      data_section: 'four',
      active_status: '',
    },
    {
      title: Helper.getLabel(props.language, 'segments', 'Segments'),
      data_section: 'five',
      active_status: '',
    },
  ]

  if (rfqVendorData?.display_scoring_criteria_to_supplier) {
    navs.push({
      title: Helper.getLabel(
        props.language,
        'scoring_criteria',
        'Scoring Criteria'
      ),
      data_section: 'six',
      active_status: '',
    })
  }

  let superNav = [
    { title: Helper.getLabel(props.language, 'header_view', 'Header View') },
    { title: Helper.getLabel(props.language, 'line_view', 'Line View') },
  ]

  const displayViewAttachedLogo = (params) => {
    return <div className='d-flex justify-content-center align-items-center' style={{marginLeft:"-17px"}}><div className='position-relative'><img src="/images/icons/attach.svg" alt="attach-icon" style={{height:"24px"}} />{params?.data?.attachment_exists && <div className='orange-attachment-dot'></div>}</div></div>
  }

  const addComponentRight = () => {
    return (
      <MasterSidebar>
        <NoteAttachmentHistory
          scrollDitection={scrollDitection}
          height={height}
          hideHistory={true}
          source_id={Settings?.source_id?.RfqVendorReply}
          notesource_integrator={id}
          attachmentsource_integrator={id}
          hideAttachments={rfqVendorData?.quotation_type === 1 && !rfqVendorData?.commercial_usealed}
        />
      </MasterSidebar>
    )
  }

  const handleEdit = (type) => {
    if(rfqVendorData?.quotation_type === 1 && rfqVendorData?.RFQ_type !== 2 && (!rfqVendorData?.commercial_usealed) && type==="edit on behalf"){
      Helper.alert(
        'Edit bid on-behalf is not allowed on sealed RFQ.',
        'error'
      )
      return
    }
    if (allFieldsAreZero) {
      Helper.alert(
        'Edit bid on-behalf is not allowed',
        'error'
      )
    } else {
      if(type==="edit on behalf"){
        setOnBehalfBid(true)
        let data = {
          edit_on_behalf:true
        }
        updateRFQHeader(data)
        setOnEdit(false)
      }else{
        setOnEdit(true)
      }
    }
  }



  const AddressCreate = (data) => {
    if (rfqVendorData?.addresssource_id !== null) {
      return
    }
    setRfqVendorData({
      ...rfqVendorData,
      addresssource_id:data.address_source_id
    })
  }

  const ContactCreate = (data) => {
    if (rfqVendorData?.contactsource_id !== null) {
      return
    }
    setRfqVendorData({
      ...rfqVendorData,
      contactsource_id:data.contact_source_id
    })
  }

  const updateRFQHeader = (tempData, type) => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .put(
        Settings.apiPurchaseRequisitionUrl + `/RFVendorreplyheader/${id}`,
        tempData
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          type !== 'submit' && interestedTypeMessage(res, type)
          setShowPopUp(false)
          setRfqVendorData((prev) => {
            return { ...prev, ...tempData }
          })
          getRFQVendorReplyData()
        }
      })
      .catch(function (error) {
         getPopupMessageBasedOnStatus(error)
      })
  }

  const interestedTypeMessage = (res, type) => {
    if (type === 'interested') {
      return Helper.alert('Interested in Bid', 'success')
    } else if (type === 'not interested') {
      return Helper.alert('Not Interested in Bid', 'success')
    } else if (type === 'save') {
      return Helper.alert(res.data.message, 'success')
    } else {
      return null
    }
  }

  const updateBidStatus = (type) => {
    const tempData = {
      interested_to_participate: interestedType(type),
      comment_no_participation: type === 'not interested' ? comment : null,
    }
    updateRFQHeader(tempData, type)
  }

  const interestedType = (type) => {
    if (type === 'interested') {
      return true
    } else if (type === 'not interested') {
      return false
    } else {
      return null
    }
  }

  const get_reply_actions = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + `/enum/RFvendorreply_action`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setActions(res.data.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const update_reply_actions = (type) => {
    const matchingAction = actions.find(
      (action) => action.name.toLowerCase() === type
    )
    const data = {
      rfq_id: null,
      vendorreply_id: parseInt(id),
      action: null,
      day_hour: null,
      type: null,
      reason: null,
      vendors: null,
    }
    if (matchingAction) {
      const { id } = matchingAction
      data.action = id
    }
    let api = Api
    api.setUserToken()
    setLoading(true)
    api
      .axios()
      .post(
        Settings.apiPurchaseRequisitionUrl + `/vendoraction_on_vendorreply`,
        data
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data.message, 'success')
          if (type === 'recall') {
            updateBidStatus('recall')
          }
          getRFQVendorReplyData()
        }
      })
      .catch((res) => { 
        setLoading(false)
        getPopupMessageBasedOnStatus(res) 
        setLoading(false)
      })
  }

  const onCreateFirstDistribution = (newDisSouceId) => {
    let api = Api

    api.setUserToken()
    api
      .axios()
      .put(Settings.apiPurchaseRequisitionUrl + '/RFVendorreplyheader/' + id, {
        distributionsource_id: newDisSouceId,
      })
      .then((res) => {})
    setRfqVendorData({ ...rfqVendorData, distributionsource_id: newDisSouceId })
  }

  const get_rfq_attachments = (id, source_id) => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(
        Settings.loginUrl +
          `/get_attachments/${source_id}/${id}?attachment_for=1`
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          id !== null
            ? setRFQVendorAttachments(res.data?.data)
            : setRFQVendorAttachments(null)
          lineObj?.api?.refreshServerSide({purge:false})
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const attachmentCellClick = (event) => {
    if (event.colDef.field === 'attached') {
      setShowModal(true)
      let scrollY = window.scrollY || document.documentElement.scrollTop
      let mouseY = event.event.clientY
      let absoluteMouseY = mouseY + scrollY
      let mouseX = event.event.clientX
      let absoluteMouseX = window.innerWidth - mouseX - 100
      setPopUpPosition({ top: absoluteMouseY, right: absoluteMouseX })
      const attachedId = event.data?.RFQLine_attachmentsource_id
      const fetchId =event.data.RFQLine_id
      setLineId(fetchId)
      setAttachmentSourceId(attachedId)
      setAlternativeLines(event.data.alternate_line)
      setRFQVendorAttachments([])
      const source_id = 61
      get_rfq_attachments(fetchId, source_id)
    }
    if(event.colDef.field === 'alternate_line'){
      setViewAlternateLines(true)
      setLineId(event?.data.line_id)
    }
  }

  const handleReturnBid = () => {
    setShowBafoModal(true)
  }

  const closeModal = () => {
    setShowModal(false);
    $('html').removeClass('rs_popup_open');
  };

  const onAlternateLinePopupClose = () => {
    setViewAlternateLines(false);
    $('html').removeClass('rs_popup_open');
  };

  const onBafoModalPopupClose = () => {
    setShowBafoModal(false);
    $('html').removeClass('rs_popup_open');
  };

  const hasVendorReplyStatus = () => {
    return [2,3, 4, 5, 8, 9].includes(rfqVendorData?.vendor_replystatus);
  };

  const bidStatus = hasVendorReplyStatus();
  const bidStatusEdit = [3, 4, 5, 8, 9].includes(rfqVendorData?.vendor_replystatus)

  const onGridReady = (params) => {
    setTimeout(() => {
     params.api.forEachNode(node => {
       if (node?.data?.alternate_line) {
       const rowId = node.data.setRowDataGridRowId;
       const rowElement = document.querySelector(`[row-id="${rowId}"]`);
       if (rowElement) {
         rowElement.classList.add('rfq-grid-alternate-row');
       }          
       }
     });
   }, 800);
 }

 const handleFetchData = (response) => {
  const data = response?.data?.data?.filter((item=>item.alternate_line===false))
  return {
    rowData: data?.map((item) => ({
      ...item,
      description: item.compliance_description,
      gridId: `${Date.now()}_${getNewValue()}`
    })),
    rowCount: data?.length,
  }
}

 //for menu
 const moreOptions = () => {
  return (
    <div>
      <ul className='actions_nav'>
        <li>
          <Link
            to={`/rfq-vendor-reply/rfq-versions?RFQ_vendorreply_id=${id}`}
            state={{document_number:rfqVendorData?.document_number,title:rfqVendorData?.title}}
          >
            View Versions
          </Link>
        </li>
      </ul>
    </div>
  )
}

const  alternateLineViewCell = (params) => {
  if(params.data.line_id){
      return <div className="rs_inactive_label">View Alternate Lines</div>;
  }
  return <></>;
}

const handleBidSubmission = () => {
  Helper.confirmMe(() => {
    update_reply_actions('submit')
  }, "Your bid will be submitted once you confirm. However, you can still recall it for any amendments until it expires.", null, "Are you sure you want to submit bid?",400)
}

const handleBidRecall = () => {
  Helper.confirmMe(() => {
    update_reply_actions('recall')
  }, "If you recall your bid, you will be able to make changes before submitting it again. Please confirm to proceed with this action.", null, "Are you sure you want to recall bid?",400)
}

let lineAdditionalColumns =  [
  {
    field: 'alternate_line',
    headerName: Helper.getLabel(
      props.language,
      'alternate_lines',
      'Alternate lines'
    ),
    minWidth: 200,
    cellRenderer: alternateLineViewCell,
  },
  {
    field: 'attached',
    minWidth: 120,
    headerName: Helper.getLabel(
      props.language,
      'attached',
      'Attached'
    ),
    cellClass: 'rfq-attached-cell',
    cellRenderer: displayViewAttachedLogo,
    filter:false
  },
]

if(!rfqVendorData?.allow_alternate_lines_on_vendor_response){
  lineAdditionalColumns = lineAdditionalColumns.filter((item)=>item.field !== 'alternate_line')
}

  return (
    <div className='scrollBarBlinkControl'>
      {saveBtnApi ? <OverlayLoader isLogoCenter={true} />: null}
      <div className={`rfq-internal-screens reply_rfq_detail_form`}>
        <div ref={myDivRef}>
          <NavigationHeder
            removeMargin={true}
            title={'RFQ Vendor Reply'}
            backUrl={-1}
            moreOptions={moreOptions}
          >
            {!rfqVendorData ? (
              <ButtonSkeletonComponent />
            ) : (
              !rfqVendorData?.edit_on_behalf  &&
               (
                <Button
                  title={Helper.getLabel(
                    props.language,
                    'edit_bid_on_behalf',
                    'Edit Bid on Behalf'
                  )}
                  onClick={()=>{handleEdit('edit on behalf')}} 
                  isDisable={rfqVendorData?.RFQ_status===2|| rfqVendorData?.RFQ_status === 9 || rfqVendorData?.onhold ||  rfqVendorData?.RFQ_status === 3 || rfqVendorData?.RFQ_status===5 || rfqVendorData?.RFQ_status===6 || rfqVendorData?.RFQ_status===7}
                />
              )
            )}
             {!rfqVendorData ? (
              <ButtonSkeletonComponent />
            ) : (
            (!onEdit) && !rfqVendorData?.edit_on_behalf && (
                <Button
                  title={Helper.getLabel(
                    props.language,
                    'edit',
                    'Edit'
                  )}
                  onClick={()=>{handleEdit('edit')}} 
                  isDisable={rfqVendorData?.RFQ_status===2 || rfqVendorData?.RFQ_status === 9 || rfqVendorData?.onhold ||  rfqVendorData?.RFQ_status === 3 || rfqVendorData?.RFQ_status===5 || rfqVendorData?.RFQ_status===6 || rfqVendorData?.RFQ_status===7}
                />
              )
            )}
            {!rfqVendorData ? (
              <ButtonSkeletonComponent />
            ) : (
              <>
                {rfqVendorData?.interested_to_participate === null && rfqVendorData?.edit_on_behalf &&
                  (
                    <Fragment>
                      <Button
                        className='rfq-interested-status-btn'
                        title={Helper.getLabel(
                          props.language,
                          'interested_to_participate',
                          'Interested to participate'
                        )}
                        isDisable={allFieldsAreZero || rfqVendorData?.RFQ_status===2|| rfqVendorData?.RFQ_status === 9 || rfqVendorData?.onhold || rfqVendorData?.RFQ_status === 3 || rfqVendorData?.RFQ_status===5 || rfqVendorData?.RFQ_status===6 || rfqVendorData?.RFQ_status===7}
                        onClick={() => updateBidStatus('interested')}
                      />
                      <Button
                        className='rfq_print_btn'
                        title={Helper.getLabel(
                          props.language,
                          'not_interested_to_participate',
                          'Not Interested to participate'
                        )}
                        onClick={() => setShowPopUp(true)}
                        isDisable={allFieldsAreZero || rfqVendorData?.RFQ_status===2|| rfqVendorData?.RFQ_status === 9 || rfqVendorData?.onhold || rfqVendorData?.RFQ_status === 3 || rfqVendorData?.RFQ_status===5 || rfqVendorData?.RFQ_status===6 || rfqVendorData?.RFQ_status===7}
                      />
                    </Fragment>
                  )}
                {!!rfqVendorData?.interested_to_participate && rfqVendorData?.edit_on_behalf && (
                  <Button
                    isActive={false}
                    className='rfq_print_btn'
                    title={Helper.getLabel(props.language, 'recall', 'Recall')}
                    isDisable={
                      rfqVendorData?.vendor_replystatus !== 2 || rfqVendorData?.vendor_replystatus === 12 ||
                      allFieldsAreZero || rfqVendorData?.onhold ||  rfqVendorData?.RFQ_status===2|| rfqVendorData?.RFQ_status === 9 || rfqVendorData?.RFQ_status ===3 || rfqVendorData?.RFQ_status===5 || rfqVendorData?.RFQ_status===6 || rfqVendorData?.RFQ_status===7
                    }
                    onClick={handleBidRecall}
                  />
                )}

               {rfqVendorData?.interested_to_participate && rfqVendorData?.edit_on_behalf && rfqVendorData?.RFQ_type !== 2 && (
                  <Button
                    title={Helper.getLabel(props.language, 'return_bid', 'Return bid')}
                    onClick={handleReturnBid}
                    isDisable={
                      !allFieldsAreZero && rfqVendorData?.vendor_replystatus !==2 && rfqVendorData?.vendor_replystatus !==3
                    }
                  />
                )}

                {((rfqVendorData?.interested_to_participate && rfqVendorData?.edit_on_behalf) || onEdit) && (
                  <Button
                    isActive={false}
                    className='rfq_save_btn'
                    title={Helper.getLabel(props.language, 'save', 'Save')}
                    onClick={handleSaveClick}
                    isDisable={
                      rfqVendorData?.vendor_replystatus === 3 ||
                      allFieldsAreZero || isAuctionBidEditable || rfqVendorData?.onhold ||  rfqVendorData?.RFQ_status===2 || rfqVendorData?.RFQ_status===3
                      || rfqVendorData?.RFQ_status === 9 || rfqVendorData?.RFQ_status===5 || rfqVendorData?.RFQ_status===6 || rfqVendorData?.RFQ_status===7
                    }
                  />
                )}
                {rfqVendorData?.interested_to_participate && rfqVendorData?.edit_on_behalf && (
                  <Button
                    isActive={false}
                    className='rfq_vendor_reply_publish_btn'
                    title={Helper.getLabel(props.language, 'submit', 'Submit')} // vendor reply
                    isDisable={
                      rfqVendorData?.vendor_replystatus === 2 ||
                      rfqVendorData?.vendor_replystatus === 3 ||
                      isAuctionBidEditable ||
                      allFieldsAreZero || rfqVendorData?.onhold ||  rfqVendorData?.RFQ_status===2  || rfqVendorData?.RFQ_status === 3 
                      || rfqVendorData?.RFQ_status === 9 || rfqVendorData?.RFQ_status===5 || rfqVendorData?.RFQ_status===6 || rfqVendorData?.RFQ_status===7
                    }
                    onClick={handleBidSubmission}
                  />
                )}
                <TotalWidget
                  source_id={Settings.source_id?.RfqVendorReply}
                  integrator={id}
                />
              </>
            )}
          </NavigationHeder>
          {rfqVendorData?.onhold && show && (
          <Alert variant='dark' onClose={handleClose} dismissible>
            <p className='mb-0'>This RFQ is in hold state. you can't make any change in this RFQ.</p>
          </Alert>
          )}
         {rfqVendorData?.quotation_type === 1 && !rfqVendorData?.commercial_usealed && show && (
          <Alert variant='dark' className='border-0 border-white rounded-0'>
            <p className='mb-0'>This RFQ has been marked as <span className='fw-bolder'>sealed</span>.</p>
          </Alert>
          )}
          {rfqVendorData?.RFQ_status === 2 && show && (
          <Alert variant='dark' onClose={handleClose} dismissible>
            <p className='mb-0'>This RFQ is canceled. you can't make any change in this RFQ.</p>
          </Alert>
        )}

        {rfqVendorData?.RFQ_status === 9 && show && (
          <Alert variant='dark' onClose={handleClose} dismissible>
            <p className='mb-0'>This RFQ is re-tendered. you can't make any change in this RFQ untill its publish again.</p>
          </Alert>
        )}
         
          <FixedNavigationHeder
            addComponentTop={addComponentTop}
            sectionName={sectionName}
            navs={navs}
            scrollDitection={scrollDitection}
            selectedSupNav={selectedSupNav}
            setSelectedSupNav={setSelectedSupNav}
            superNav={superNav}
          />
        </div>
        {loading ? (
          <OverlayLoader />
        ) : (
          <TabContentWraper
            height={height}
            setScrollDitection={setScrollDitection}
            leftSideContent={
              <>
                {selectedSupNav == 'Header View' && (
                  <>
                    <div
                      className='lists_contant one'
                      id={`${sectionName}General`}
                      style={{ position: 'relative' }}
                    >
                      <Collapse
                        title={Helper.getLabel(
                          props.language,
                          'general_information',
                          'General Information'
                        )}
                        open={true}
                        className='mb-5'
                      >
                        <GeneralInformationRFQReply
                          rfqHeader={rfqVendorData}
                          setRfqHeader={setRfqVendorData}
                          updateRFQ={updateRfqVendorData}
                          setUpdateRFQ={setUpdateRfqVendorData}
                          employeesList={employeesList}
                          currencyList={currencyList}
                          securityLevelList={securityLevelList}
                          quotationTypeList={quotationTypeList}
                          paymentTermList={paymentTermList}
                          modeOfDeliveryList={modeOfDeliveryList}
                          deliveryTermList={deliveryTermList}
                          isLoading={loading}
                          allowEdit={
                           rfqVendorData?.onhold  || rfqVendorData?.RFQ_status===2|| rfqVendorData?.RFQ_status === 9 || rfqVendorData?.RFQ_status === 3 || rfqVendorData?.RFQ_status===5 || rfqVendorData?.RFQ_status===6 || rfqVendorData?.RFQ_status===7 || !rfqVendorData?.edit_on_behalf ? false: rfqVendorData?.interested_to_participate === true &&
                            !bidStatus &&
                            !allFieldsAreZero && !isAuctionBidEditable
                          }
                          allowEditOnly={
                            rfqVendorData?.onhold  || rfqVendorData?.RFQ_status === 9 || rfqVendorData?.RFQ_status === 3 || rfqVendorData?.RFQ_status===5 || rfqVendorData?.RFQ_status===6 || rfqVendorData?.RFQ_status===7 ? false: ((rfqVendorData?.interested_to_participate === true && rfqVendorData?.edit_on_behalf) || (onEdit)) &&
                             !bidStatusEdit &&
                             !allFieldsAreZero && !isAuctionBidEditable
                           }
                        />
                      </Collapse>
                    </div>

                    <div
                      className='lists_contant two'
                      id={`${sectionName}Address`}
                      style={{ position: 'relative' }}
                    >
                      <div className='mt-5'>
                        <AddressList
                          disableRecords={
                            onEdit  ? false : rfqVendorData?.interested_to_participate !== true ||
                            bidStatus || rfqVendorData?.onhold ||  rfqVendorData?.RFQ_status===2|| rfqVendorData?.RFQ_status === 9 || allFieldsAreZero || isAuctionBidEditable || rfqVendorData?.RFQ_status ===3 || rfqVendorData?.RFQ_status===5 || rfqVendorData?.RFQ_status===6 || rfqVendorData?.RFQ_status===7 || !rfqVendorData?.edit_on_behalf
                          }
                          onAddressCreate={AddressCreate}
                          addressSourc_id={rfqVendorData?.addresssource_id}
                          source_id={Settings.source_id.RfqVendorReply}
                          address_source_integrator={id}
                        />
                      </div>
                    </div>

                    <div
                      className='lists_contant three'
                      id={`${sectionName}Contact`}
                      style={{ position: 'relative' }}
                    >
                      <Collapse
                        title={Helper.getLabel(
                          props.language,
                          'contact',
                          'Contact'
                        )}
                        className='mb-5 mt-5'
                        open={true}
                      >
                        <ContactAddress
                          canEdit={
                            rfqVendorData?.onhold ||  rfqVendorData?.RFQ_status===2|| rfqVendorData?.RFQ_status ===3 || isAuctionBidEditable || rfqVendorData?.RFQ_status === 9  || rfqVendorData?.RFQ_status===5 || rfqVendorData?.RFQ_status===6 || rfqVendorData?.RFQ_status===7 || (!rfqVendorData?.edit_on_behalf && !onEdit) ? false : !!rfqVendorData?.interested_to_participate &&
                            !bidStatus || onEdit 
                          }
                          contactsource_integrator={id}
                          address_id={null}
                          contactsource_id={rfqVendorData?.contactsource_id}
                          source_id={Settings?.source_id?.RfqVendorReply}
                          onContactCreate={ContactCreate}
                        />
                      </Collapse>
                    </div>
                    <div
                      className='lists_contant four'
                      id={`${sectionName}Compliances`}
                      style={{ position: 'relative' }}
                    >
                      <ExternalComplianceView
                        rfq_id={rfqVendorData?.RFQ_id}
                        rfq_vendorreply_id={id}
                        disableEditing={!(rfqVendorData?.vendor_replystatus === 1 || rfqVendorData?.vendor_replystatus === 2)}
                      />
                    </div>
                    <div
                      className='lists_contant five'
                      id={`${sectionName}Segments`}
                      style={{ position: 'relative' }}
                    >
                      <Collapse
                        open={true}
                        title={Helper.getLabel(
                          props.language,
                          'segments',
                          'Segments'
                        )}
                        className='mt-5'
                      >
                        <SegmentsDistribution
                          isEditable={
                            rfqVendorData?.RFQ_status===2|| rfqVendorData?.RFQ_status === 9 || rfqVendorData?.RFQ_status === 3 || rfqVendorData?.onhold  || rfqVendorData?.RFQ_status===5 || rfqVendorData?.RFQ_status===6 || rfqVendorData?.RFQ_status===7 || !rfqVendorData?.edit_on_behalf ? false : rfqVendorData?.interested_to_participate === true && 
                            !allFieldsAreZero && !isAuctionBidEditable &&
                            !bidStatus 
                          }
                          id='rfq_reply_header_segments'
                          disableFinancial={true}
                          onCreateFirstDistribution={onCreateFirstDistribution}
                          distribution_source_id={
                            rfqVendorData?.distributionsource_id
                          }
                          integrator={id}
                          source_id={Settings?.source_id?.RfqVendorReply}
                          totalAmount={100}
                        />
                      </Collapse>
                    </div>
                    <div
                      className='lists_contant six mt-5'
                      id={`${sectionName}Scoring Criteria`}
                      style={{ position: 'relative' }}
                    >
                      {rfqVendorData?.display_scoring_criteria_to_supplier ===
                      true ? (
                        <CommonScoringSections
                          language={props.language}
                          RFQ_id={rfqVendorData?.RFQ_id}
                          rfqHeader={rfqVendorData}
                          externalMode={true}
                          collapseTitle='Scoring Criteria'
                        />
                      ) : null}
                    </div>
                  </>
                )}

                {selectedSupNav === 'Line View' && (
                  <>
                    {loading ? (
                      <SimpleLoading />
                    ) : (
                      <div className='rfq-line-view line_scoring_module mb-4'>
                        <LineViewComponent
                          defaultValues={{
                            requester_id: rfqVendorData?.requester_id,
                          }}
                          lineGridId="rfq-internal-lines"
                          source_id={rfqVendorData?.source_id}
                          line_source={rfqVendorData?.source_id}
                          isEditable={rfqVendorData?.onhold || !rfqVendorData?.edit_on_behalf || rfqVendorData?.RFQ_status===2|| rfqVendorData?.RFQ_status === 3 || rfqVendorData?.RFQ_status === 9 || rfqVendorData?.RFQ_status===5 || rfqVendorData?.RFQ_status===6 || rfqVendorData?.RFQ_status===7? false :  rfqVendorData?.interested_to_participate===null?false:rfqVendorData.interested_to_participate &&
                            !allFieldsAreZero && !isAuctionBidEditable &&
                            !bidStatus}
                          lineGridTitle={Helper.getLabel(
                            props.language,
                            'request_for_quotation_vendor_reply_lines',
                            'Request For Quotation Vendor Reply Lines'
                          )}
                          header_id={id}
                          purchase_status={null}
                          document_status={null}
                          delivery_date={null}
                          onbehalf_request={false}
                          vendor_id={null}
                          vendorsite_id={null}
                          requester_id={rfqVendorData?.requester_id}
                          orderer_id={rfqVendorData?.order_id}
                          setOrderStatusProps={null}
                          warehouse_id={null}
                          taxgroup_item={null}
                          taxgroup_id={null}
                          viewType="rfq internal"
                          formType='rfq'
                          rfqExternal={true}
                          billingrulegrid_id="normal"
                          distributionGridId="distribution-normal"
                          onCellClicked={attachmentCellClick}
                          alternate_line={false}
                          disableAllBtn={true}
                          onLineGridReady={onGridReady}
                          fetchData={handleFetchData}
                          params={lineObj}
                          proposedQtyEnable={ rfqVendorData?.onhold ||
                            rfqVendorData?.RFQ_status === 2 ||
                            rfqVendorData?.RFQ_status === 3 ||
                            rfqVendorData?.RFQ_status === 9 ||
                            rfqVendorData?.RFQ_status === 5 ||
                            rfqVendorData?.RFQ_status === 6 ||
                            rfqVendorData?.RFQ_status === 7 || 
                            !rfqVendorData?.edit_on_behalf
                              ? false
                              : rfqVendorData?.interested_to_participate === null
                              ? false
                              : rfqVendorData.interested_to_participate && !allFieldsAreZero && !bidStatus && !isAuctionBidEditable}
                          additionalLineColumns={lineAdditionalColumns}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            }
            rightSideContent={addComponentRight}
          />
        )}
        {showPopUp && (
          <ReasonPopUp
            setShowPopup={setShowPopUp}
            comment={comment}
            language={props.language}
            setComment={setComment}
            handleCommentSave={() => {
              $('html').removeClass('rs_popup_open')
              updateBidStatus('not interested')
            }}
          />
        )}
        {showModal && (
          <RFQAttachmentPopup
            closeModal={closeModal}
            data={rfqVendorAttachments}
            language={props.language}
            type={alternativeLines === true ? 'line view' : ''}
            source_id={80}
            attachmentsource_integrator={lineId}
            setRfqAttachment={setRFQVendorAttachments}
            onAttachmentUpload={() => {
              get_rfq_attachments(lineId, 80)
            }}
            style={{
              top: popUpPosition.top,
              right: popUpPosition.right,
            }}
            attachmentsource_id={attachmentSourceId}
          />
        )}
        {
          viewAlternateLines && <AlternateLinesPopup 
          rfqVendorData={rfqVendorData}
          onAlternateLinePopupClose={onAlternateLinePopupClose}
          allFieldsAreZero={rfqVendorData?.rfq_status===2?isAuctionBidEditable:allFieldsAreZero}
          bidStatus={bidStatus}
          id={id}
          attachmentCellClick={attachmentCellClick}
          onGridReady={onGridReady}
          gridValueColorFormat={gridValueColorFormat}
          displayViewAttachedLogo={displayViewAttachedLogo}
          language={props.language}
          vendorReplyLineId={lineId}
          lineId={lineId}
          get_rfq_attachments={get_rfq_attachments}
          setLineId={setLineId}
          setRFQVendorAttachments={setRFQVendorAttachments}
          rfqAttachmentData={rfqVendorAttachments}
          />
        }
        {
          showBafoModal && <BAFOModal language={props.language} type="vendor" handleBafoModalClose={onBafoModalPopupClose} commonApiCalls={commonApiCalls} setRefreshAfterPublish={setLoading} vendors={vendorsData} rfq_id={rfqVendorData?.RFQ_id} onReturnBid={()=>{getRFQVendorReplyData()}}/>
        }
      </div>
    </div>
  )
}

export default MasterComponentWraper(RFQVendorReply)
