import React, { useState } from 'react'
import Popup from '../../Popup/Popup'
import Input from '../../Forms/Input'
import Helper from '../../../inc/Helper'
import Dropdown from '../../Forms/Dropdown'
import Checkbox from '../../Forms/Checkbox'
import Collapse from '../../inc/Collapse'
import AddressList from '../../Tenant/Address/AddressList'
import ContactAddress from '../../Tenant/Address/ContactAddress'
import Button from '../../inc/Button'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import FormValidator from '../../../inc/FormValidator'
import { Tab, Tabs } from 'react-bootstrap'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'
import ToggleSwitch, { TitlePosition } from '../../Forms/ToggleSwitch'

const ContactPersonModal = ({ onPopupClose, contactPersonData, setContactPersonData, genders, contactTypes, vendor_id, gridObj, designations, disableCondition, isNeedDesignations }) => {
  const [loading,setLoading]=useState(false)
  const [key,setKey]=useState('general')
  let validator

  const validationConfigure = () => {
    let formFieldConfig = [
      { name: 'first_name', displayName: Helper.getLabel(null, 'first_name', 'First name'), types: ['Required'] },
      { name: 'last_name', displayName: Helper.getLabel(null, 'last_name', 'Last name'), types: ['Required'] },
    ]

    if (!validator) {
      validator = new FormValidator(formFieldConfig, null)
    }
  }

  validationConfigure()
  const onInputChangeHandler = (event) => {
    setContactPersonData((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      }
    })
  }

  const onCheckboxChangeHanlder = (name, value) => {
    setContactPersonData((prevState) => {
      return {
        ...prevState,
        [name]: value === 'on',
      }
    })
  }

  const ToggleChangeHandler = (value) => {
    setContactPersonData((prevState) => {
      return {
        ...prevState,
        'is_active': value,
      }
    })
  }

  const getContactPersonData = async (vendorcontact_id) => {
    let api = Api
    api.setUserToken()
    try {
      const response = await api.axios().get(Settings?.apiVendorUrl + `/vendorcontact/${vendor_id}`);
      if (response) {
        const contactPerson = response?.data?.data?.find((item)=>item.vendorcontact_id===vendorcontact_id)
        setContactPersonData(contactPerson)
      }
    } catch (error) {
      
    }
  };
  let conditionDesignation = typeof contactPersonData?.designation === 'object' ? contactPersonData?.designation?.contactdesignation_id : contactPersonData?.designation
  let payload = {
    first_name: contactPersonData?.first_name,
    last_name: contactPersonData?.last_name, 
    middle_name: contactPersonData?.middle_name,
    nick_name: contactPersonData?.nick_name,
    gender: contactPersonData?.vendorcontact_id && (contactPersonData?.gender === '') ? "": parseInt(contactPersonData?.gender),
    is_active: contactPersonData?.is_active,
    job_title: contactPersonData?.job_title,
    is_portal_access_enabled: contactPersonData?.is_portal_access_enabled,
    designation_id: !contactPersonData?.vendorcontact_id && contactPersonData?.designation === "" ? null : conditionDesignation,
    contacttype_id: !contactPersonData?.vendorcontact_id && contactPersonData?.contacttype_id === ""? null: typeof contactPersonData?.contact_type === 'object' ? contactPersonData?.contact_type?.contacttype_id : contactPersonData?.contacttype_id,
    contactsource: null,
  }

  const onSaveClick = async () => {
    let validData = payload
    if (!validator.isValid(validData)) {
      validator.displayMessage(this)
      return
    }
    let api = Api
    api.setUserToken()
    setLoading(true)
    if (contactPersonData?.vendorcontact_id) {
      try {
        const res = await api.axios().put(Settings?.apiVendorUrl + `/vendorcontact/${contactPersonData?.vendorcontact_id}`, payload)
        if (res) {
          Helper.alert(res?.data?.message, 'success')
          gridObj?.api?.deselectAll()
          await getContactPersonData(contactPersonData?.vendorcontact_id)
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        getPopupMessageBasedOnStatus(error)
      }
    } else {
      try {
        const res = await api?.axios()?.post(Settings?.apiVendorUrl + `/vendorcontact/${vendor_id}`, payload)
        if (res) {
          Helper.alert(res?.data?.message, 'success')
          gridObj?.api?.deselectAll()
          await getContactPersonData(res?.data?.vendorcontact_id)
          setLoading(false)
          // onPopupClose()
        }
      } catch (error) {
        setLoading(false)
        getPopupMessageBasedOnStatus(error)
      }
    }
  }

  const onAddressCreate = (responseData) => {
     setContactPersonData({
      ...contactPersonData,
      addresssource_id: responseData.address_source_id
     })
  }

  const onContactCreate = (responseData) => {
    setContactPersonData({
     ...contactPersonData,
     contactsource_id: responseData.contact_source_id
    })
  }

  const handleTabClick = (key, e) => {
    if ((key === 'address' || key === 'contact') && !contactPersonData?.vendorcontact_id) {
      e.preventDefault();  
      Helper.alert('Please save mandatory information first.','error');
    }else{
      setKey(key)
    }
  }

  return (
    <>
    <Popup autoOpen={true} width='1100px' onClose={onPopupClose}>
      <div className='d-flex justify-content-end w-100 mb-3 gap-2'>
        <Button title={Helper.getLabel(null, 'close', 'Close')} className='black-btn-style' isActive={false} onClick={onPopupClose}></Button>
        <Button title={Helper.getLabel(null, 'save_and_close', 'Save')} className='rfq_save_btn' isActive={true} onClick={onSaveClick} isDisable={disableCondition}></Button>
      </div>
      <div className='vendor_contact_person_tabs'>
        <Tabs defaultActiveKey='general' activeKey={key} onSelect={handleTabClick}>
          <Tab eventKey='general' title={'General Information'}>
            <Collapse open={true} title='General Information'  className='mt-4'>
              <div className='row'>
                <div className='col-lg-4'>
                  <Input
                    type='text'
                    id={'first_name'}
                    required={true}
                    name={'first_name'}
                    disable={disableCondition}
                    label={Helper.getLabel(null, 'first_name', 'First name')}
                    onChange={onInputChangeHandler}
                    value={contactPersonData?.first_name}
                  />
                </div>
                <div className='col-lg-4'>
                  <Input
                    type='text'
                    id={'middle_name'}
                    required={false}
                    name={'middle_name'}
                    label={Helper.getLabel(null, 'middle_name', 'Middle name')}
                    onChange={onInputChangeHandler}
                    disable={disableCondition}
                    value={contactPersonData?.middle_name}
                  />
                </div>
                <div className='col-lg-4'>
                  <Input type='text' id={'last_name'} required={true} name={'last_name'} label={Helper.getLabel(null, 'last_name', 'Last name')} onChange={onInputChangeHandler} value={contactPersonData?.last_name} disable={disableCondition} />
                </div>
                <div className='col-lg-4'>
                  <Input type='text' id={'nick_name'} required={false} name={'nick_name'} label={Helper.getLabel(null, 'nick_name', 'Nick name')} onChange={onInputChangeHandler} value={contactPersonData?.nick_name} disable={disableCondition} />
                </div>
                <div className='col-lg-4'>
                  <Dropdown label={Helper.getLabel(null, 'gender', 'Gender')} options={genders} id='gender_id' name='gender' required={false} onChange={onInputChangeHandler} value={contactPersonData?.gender} isDisable={disableCondition} />
                </div>
                <div className='col-lg-4'>
                  <Input type='text' id={'job_title'} required={false} name={'job_title'} label={Helper.getLabel(null, 'job_title', 'Job title')} onChange={onInputChangeHandler} value={contactPersonData?.job_title} disable={disableCondition} />
                </div>
                {isNeedDesignations && <div className='col-lg-4'>
                  <Dropdown
                    label={Helper.getLabel(null, 'designation', 'Designation')}
                    options={designations}
                    id='designation'
                    name='designation'
                    required={false}
                    isDisable={disableCondition}
                    onChange={onInputChangeHandler}
                    value={contactPersonData?.vendorcontact_id?contactPersonData?.designation?.contactdesignation_id:contactPersonData?.contactdesignation_id}
                  />
                </div>}
                <div className='col-lg-4'>
                  <Dropdown
                    label={Helper.getLabel(null, 'contact_type', 'Contact type')}
                    options={contactTypes}
                    id='contact_type_id'
                    name='contacttype_id'
                    required={false}
                    isDisable={disableCondition}
                    onChange={onInputChangeHandler}
                    value={contactPersonData?.vendorcontact_id?contactPersonData?.contact_type?.contacttype_id:contactPersonData?.contacttype_id}
                  />
                </div>
                <div className='col-lg-4'>
                  {/* <Checkbox name='is_active' label={Helper.getLabel(null, 'is_active', 'Is active')} required={false} isChecked={contactPersonData?.is_active} onChange={onCheckboxChangeHanlder} disableWithoutIcon={disableCondition} /> */}
                  <ToggleSwitch 
                   margin={'mb-4'}
                   onChange={ToggleChangeHandler} 
                   title={contactPersonData?.is_active===true?<span className='rs_active_label'>{'Active'}</span>:<span className='rs_inactive_label'>{'Inactive'}</span>}
                   titlePosition={TitlePosition.right}
                   value={contactPersonData?.is_active}
                   isvalue={true}
                   isDisable={disableCondition}
                  />
                </div>
                <div className='col-lg-4'>
                  <Checkbox
                    name='is_portal_access_enabled'
                    label={Helper.getLabel(null, 'is_portal_access_enabled', 'Is portal access enabled')}
                    required={false}
                    isChecked={contactPersonData?.is_portal_access_enabled}
                    onChange={onCheckboxChangeHanlder}
                    disableWithoutIcon={disableCondition}
                  />
                </div>
              </div>
            </Collapse>
          </Tab>
          <Tab eventKey={'address'} title={'Address'}>
            <div  className='mt-4'>
              <AddressList  key={contactPersonData?.vendorcontact_id || 'initial'} source_id={Settings?.source_id?.vendor_contact_person} onAddressCreate={onAddressCreate} addressSourc_id={contactPersonData?.addresssource_id} address_source_integrator={contactPersonData?.vendorcontact_id} disableRecords={disableCondition} />
            </div>
          </Tab>
          <Tab eventKey={'contact'} title={'Contact'}>
            <div>
              <Collapse open={true} title='Contact Details' className='mt-4'>
                <ContactAddress  key={contactPersonData?.vendorcontact_id || 'initial'} source_id={Settings.source_id.vendor_contact_person} contactsource_id ={contactPersonData?.contactsource_id} contactsource_integrator ={contactPersonData?.vendorcontact_id} onContactCreate={onContactCreate} canEdit={!disableCondition}/>
              </Collapse>
            </div>
          </Tab>
        </Tabs>
      </div>
    </Popup>
     {loading ? <OverlayLoader isLogoCenter={true} isPopupInTab={true} /> : <></>}
    </>
  )
}

export default ContactPersonModal
