import React from 'react';
import EditPO from '../../components/Purchase Orders Internal/Edit PO/EditPO';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import Gui_id_list from '../../inc/Gui_id_list';
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';
import Alert from '../../components/inc/Alert';
import MasterComonent from '../../components/Backend/MasterComonent';
import { useLocation } from 'react-router-dom';

const PurchaseAgreementEditPage = ({security}) => {    
    const location = useLocation(); 
    const urlParams = new URLSearchParams(location?.search);

    let commonUserDocumentAccess = urlParams?.get('common_user_access') && location?.state?.prevRoutePath === '/dashboard' ? true : false; 
    let PAFormVisible = security?.canView(Gui_id_list.procure.purchase_agreement.purchase_agreement_edit) || commonUserDocumentAccess;

    if (!PAFormVisible) {
        return (
            <MasterComonent>
                <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
            </MasterComonent>
        )
    }
    return (
        <EditPO
            security={security}
            commonUserDocumentAccess={commonUserDocumentAccess}
            title={Helper.getLabel(null, 'edit_purchase_agreement', "Edit Purchase Agreement")}
            lineTitle={Helper.getLabel(null, 'agreement_lines', "Purchase Agreement Lines")}
            backUrl={location?.state?.isPrevPath || location?.state?.prevRoutePath === '/dashboard' ? -1 : '/purchase-agreement/all'} 
            versionUrl='/purchase-agreement/version'
            historyUrl='/purchase-agreement/history'
            logsUrl='/purchase-agreement/logs'
            goodReceivingUrl='/purchase-agreement/good-receiving/notes'
            returnOrderUrl='/purchase-agreement/return-order/all'
            amendmentRequestUrl='/purchase-agreement/amendment-request/all'
            hideStartDate={false}
            hideEndDate={false}
            hideBillingRule={false}
            source_id={Settings.source_id.purchase_agreement}
            visibleEndDate={true}
            visibleStartDate={true}
            formType={'purchase agreement'}
            type={'purchase agreement'}
        />
    );
};

const SecurityOptions = {
    gui_id: Gui_id_list.procure.purchase_agreement.purchase_agreement_edit
};

export default ApplySecurityRoles(PurchaseAgreementEditPage, SecurityOptions); 