import axios from 'axios';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import FormValidator from '../../../inc/FormValidator';
import $ from 'jquery';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import Popup from '../../Popup/Popup';
import Collapse from '../../inc/Collapse';
import { Form } from 'react-bootstrap';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Button from '../../inc/Button';
import Helper from '../../../inc/Helper';

let cancelTokenSource = axios.CancelToken.source();
const OrgModal = ({organization_id, reloadParentGrid, setShowCreateModal, language}) => {
    const [formData, setFormData] = useState({});

    const [orgs, setOrgs] = useState([]);
    const [parentOrg, setParentOrg] = useState([]);
    const [owner, setOwner] = useState([]);
    const [delegated, setDelegated] = useState([]);
    const [managerPos, setManagerPos] = useState([]);
    const [delegatedManagerPos, setDelegatedManagerPos] = useState([]);

    const allEmployeeData = useRef([]);

    const [apiCallBtn, setApiCallBtn] = useState(false); 

    let formValidatory;

    const validationConfigure = () => {
        let formFieldConfig = [   
            {name:'organizationtype_id', displayName:Helper.getLabel(language,'organization','Organization'), types:['Required'] },
            {name:'name', displayName:Helper.getLabel(language,'name','Name'), types:['Required'] }, 
            {name:'shortname', displayName:Helper.getLabel(language,'shortname','Short Name'), types:['Required']} 
        ];

        if(!formValidatory) {
            formValidatory = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();
 
    let orgnisationObjCalled = false;
    let orgTypeListCalled = false; 
    let employeeCalled = false;
    let orgCalled = false;

    useEffect(() => {
        getSystemOrganisations();
        getSystemEmployees();
        getSystemOrganisationTypes(); 
        if(!organization_id) {
            setFormData({});
            return;
        }
        getOrganisationByID(); 
    }, []);
 
    const getOrganisationByID = () => {
        if (orgnisationObjCalled) return;
        orgnisationObjCalled = true;

        setApiCallBtn(true);
        let api = Api;
        api.setUserToken();
        api
            .axios()
            .get(Settings.apiOrgUrl+`/organization/${organization_id}`, {})
            .then(function (res) {
                if (res.data.status === "success") { 
                    setFormData(res?.data?.data);
                    setApiCallBtn(false);
                }
            })
            .catch((e) => {
                setApiCallBtn(false);
                setFormData({});
            });
    };

    
    const getSystemOrganisations = () => {
        if(orgCalled) return;
        orgCalled = true;

        let api = Api 
        api.setUserToken()
        api.axios().get(Settings.apiOrgUrl+`/organization`,{}).then(function(res){
            if(res.data.status==="success"){
                setParentOrg(res?.data?.data?.map((item) => {
                    return {
                        value: item.organization_id,
                        label: item.name
                    }
                }))
            }
        })
    }

    const getSystemEmployees = () => {
        if(employeeCalled) return;
        employeeCalled = true;

        let api = Api 
        api.setUserToken()
        api.axios().get(Settings.apiOrgUrl+'/employee_dropdown?status=true',{}).then(function(res){
            if(res.data.status==="success"){  
                let employeeDropdownData = res?.data?.data?.map((item) => {
                    return {
                        value: item.employee_id,
                        label: `${item.code} - ${item.displayname}`
                    }
                })
                setOwner(employeeDropdownData)
                setDelegated(employeeDropdownData)
                setManagerPos(employeeDropdownData)
                setDelegatedManagerPos(employeeDropdownData)
            }
        })
    }
 
    const getSystemOrganisationTypes = () => {
        if(orgTypeListCalled) return;
        orgTypeListCalled = true;

        let api = Api 
        api.setUserToken()
        api.axios().get(Settings.apiOrgUrl+'/organizationType',{}).then(function(res){
        if(res.data.status==="success"){
            setOrgs(res?.data?.data?.map((item) => {
                return {
                    value: item.organizationtype_id,
                    label: item.name
                }
            })) 
        }  
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res) 
        })
    }

    const mapEmployeeData = (item) => {
        return {
            id: item.employee_id,
            text: `${item.code} - ${item.displayname}`
        }
    }

    const fieldsArrayGeneral = [
        {className:'col-lg-4', reRenderRequired: true, required: true, options: orgs, disable: apiCallBtn, label: Helper.getLabel(language,'organization_id',"Organization type"), placeHolder: 'Select organization type', inputType: "dropdown", id:'organizationtype_id', name:'organizationtype_id'},
        {className:'col-lg-4', required: true, maxLength: 40, disable: apiCallBtn, label: Helper.getLabel(language,'name',"Name"), inputType: "text", id:'name', name:'name'}, 
        {className:'col-lg-4', required: true, maxLength: 20, disable: apiCallBtn, label: Helper.getLabel(language,'shortname',"Short name"), inputType: "text", id:'shortname', name:'shortname'},  
        {className:'col-lg-4', reRenderRequired: true, options: Settings.seedDataStatus, disable: apiCallBtn, label: Helper.getLabel(language,'status',"Status"), placeHolder: 'Select status', inputType: "dropdown", id:'status', name:'status'}, 
        {className:'col-lg-4', reRenderRequired: true, options: parentOrg, disable: apiCallBtn, label: Helper.getLabel(language,'parent_organization',"Parent organization"), placeHolder: 'Select parent organization', inputType: "dropdown", id:'parent_organization_id', name:'parent_organization_id'}, 
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'external_code',"External code"), inputType: "text", id:'external_code', name:'external_code'},
        {className:'col-lg-4', reRenderRequired: true, pagination: true, apiUrl: Settings.apiOrgUrl+'/employee_dropdown?status=true', allDataRef: allEmployeeData, mapFunction: mapEmployeeData, options: owner, disable: apiCallBtn, label: Helper.getLabel(language,'owner',"Owner"), placeHolder: 'Select owner', inputType: "dropdown", id:'owner', name:'owner'},
        {className:'col-lg-4', reRenderRequired: true, pagination: true, apiUrl: Settings.apiOrgUrl+'/employee_dropdown?status=true', allDataRef: allEmployeeData, mapFunction: mapEmployeeData, options: delegated, disable: apiCallBtn, label: Helper.getLabel(language,'delegated_owner',"Delegated owner"), placeHolder: 'Select delegated owner', inputType: "dropdown", id:'delegated_owner', name:'delegated_owner'},
        {className:'col-lg-4', reRenderRequired: true, pagination: true, apiUrl: Settings.apiOrgUrl+'/employee_dropdown?status=true', allDataRef: allEmployeeData, mapFunction: mapEmployeeData, options: managerPos, disable: apiCallBtn, label: Helper.getLabel(language,'manager_position',"Manager position"), placeHolder: 'Select manager position', inputType: "dropdown", id:'manager_position', name:'manager_position'},
        {className:'col-lg-4', reRenderRequired: true, pagination: true, apiUrl: Settings.apiOrgUrl+'/employee_dropdown?status=true', allDataRef: allEmployeeData, mapFunction: mapEmployeeData, options: delegatedManagerPos, disable: apiCallBtn, label: Helper.getLabel(language,'delegated_manager_position',"Delegated manager position"), placeHolder: 'Select delegated manager position', inputType: "dropdown", id:'delegated_manager_position', name:'delegated_manager_position'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'workflow_execution_method',"Workflow execution method"), inputType: "checkbox", id:'workflow_execution_method', name:'workflow_execution_method'}
    ];

    const executePOSTAPI = async () => {
        try {
            let api = Api;
            api.setUserToken();

            const res = await api.axios().post(Settings.apiOrgUrl+`/organization`, {
                ...formData,
                translationsource_id: null, 
                status: formData?.status === 'true',
                owner: formData?.owner ? parseInt(formData?.owner) : null,
                delegated_owner: formData?.delegated_owner ? parseInt(formData?.delegated_owner) : null,
                manager_position: formData?.manager_position ? parseInt(formData?.manager_position) : null,
                delegated_manager_position: formData?.delegated_manager_position ? parseInt(formData?.delegated_manager_position) : null,
                parent_organization_id: formData?.parent_organization_id ? parseInt(formData?.parent_organization_id) : null,
                workflow_execution_method: formData?.workflow_execution_method == 'on' ? 1 : 0
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                reloadParentGrid();
                onPopupClose();
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const executePUTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();

            const res = await api.axios().put(Settings.apiOrgUrl+`/organization/${organization_id}`, {
                ...formData,
                translationsource_id: null, 
                status: formData?.status === 'true',
                owner: formData?.owner ? parseInt(formData?.owner) : "",
                delegated_owner: formData?.delegated_owner ? parseInt(formData?.delegated_owner) : "",
                manager_position: formData?.manager_position ? parseInt(formData?.manager_position) : "",
                delegated_manager_position: formData?.delegated_manager_position ? parseInt(formData?.delegated_manager_position) : "",
                parent_organization_id: formData?.parent_organization_id ? parseInt(formData?.parent_organization_id) : "",
                workflow_execution_method: formData?.workflow_execution_method == 'on' ? 1 : 0
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                reloadParentGrid();
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    };

    const handleSubmit = () => { 
        if(!formValidatory.isValid({...formData})){
            formValidatory.displayMessage();
            formValidatory.reset();
            return;
        };
  
        if(!organization_id) {
            Helper.createDebouncedAPIFunction( 
                [executePOSTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        } else {
            Helper.createDebouncedAPIFunction( 
                [executePUTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        };
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} 
                width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">{!organization_id?'Create Organization':'Edit Organization'}</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setFormData} currentState={formData} fieldsArray={fieldsArrayGeneral} />
                            </div>
                        </Form> 
                    </Collapse>
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        className='black-btn-style'
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />

                    <Button
                        isDisable={apiCallBtn}
                        className={organization_id?"rfq_save_btn":"solid_btn" } 
                        isActive={!organization_id}
                        title={!organization_id?Helper.getLabel(language, "create", "Create"):Helper.getLabel(language, "save", "Save")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps) (OrgModal); 