import React, { Fragment, useState } from 'react';
import { checkIfArrayIsEmpty } from '../../../../inc/Validation';
import Checkbox from '../../../Forms/Checkbox';
import Helper from '../../../../inc/Helper';

/* Scoring Summary Table (HTML - React)

ColumnHeadersData: [ {} ] / should have same number of objects as RowData,
RowData: [ {} ] / should have same number of objects as ColumnHeadersData,
setOpenPopupView: { id: STR or INT, openPopup: BOOLEAN } / handles the opening closing of lineView Component, toggles id
executeScoreVendorReplyApi: for auto saving of disqualified field in summary grid
*/

const CommonScoringSummaryTable = ({
    ColumnHeadersData, 
    RowData, 
    disableCheckbox, 
    out_of_total_score=0,
    setOpenPopupView,
    getUpdatedScoreDisqualificationFromSummaryTable,
    executeScoreVendorReplyApi,
    RFQVendors
}) => { 
    const [scoreDisqualification, setScoreDisqualification] = useState([]) 
    const columns = !checkIfArrayIsEmpty(RowData) ? Object.keys(RowData[0]) : []

    const handlerLineRecommendClick = (colKey, row) => { 
        if(colKey === 3) {
            setOpenPopupView((prevState) => {
                return {
                    ...prevState,
                    openPopup: true,
                    id: row?.scorereplyheader_id,
                    disableLinesRecommendation: row?.disqualified,
                    vendorreply_id: row?.rfq_vendorreply_id
                }
            })
        }
    }

    const convertToDisplayName = (columnName) => {
        const words = columnName.split('_');
        const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
        return capitalizedWords.join(' ');
    }

    const checkboxValueChangeHandler = (val, row, colKey) => {
        let value = val === "on" ? true : false; 
        if (colKey === 2) { 
            if(row?.line_recommendation > 0) {
                Helper.alert('You cannot disqualify this vendor, because you have recommended few lines in it', 'error');
                return;
            };

            let objIndex = scoreDisqualification.findIndex(id => id.scorereplyheader_id === row.scorereplyheader_id);
            let UpdatedArray = [...scoreDisqualification]; 
            
            if (objIndex === -1) { 
                UpdatedArray.push({
                    disqualified: value,
                    scorereplyheader_id: row.scorereplyheader_id
                });
            } else { 
                UpdatedArray[objIndex] = {
                    disqualified: value,
                    scorereplyheader_id: row.scorereplyheader_id
                };
            }
            setScoreDisqualification(UpdatedArray);
  
            if(executeScoreVendorReplyApi) executeScoreVendorReplyApi(row?.scorereplyheader_id, value);
        }
    }

    { getUpdatedScoreDisqualificationFromSummaryTable(scoreDisqualification) } // sending the current state inside the function as of args,

    const renderTableCell = (colKey, columnName, row) => {   
        switch (colKey) {
            case 0: 
                return <span name={`total_score_${row.rfq_vendorreply_id}`}>{`${row[columnName]}/${out_of_total_score}`}</span>;
            case 1: 
                return <span name={`score_prct_${row.rfq_vendorreply_id}`}>{parseFloat(row[columnName]).toFixed(2)}%</span>;
            case 2:
                return <Checkbox restrictCheckboxSelection={(parseInt(row?.line_recommendation) > 0)} disableWithoutIcon={disableCheckbox} onChange={(name, val) => checkboxValueChangeHandler(val, row, colKey)} isChecked={row[columnName]} />;
            case 3:
                return (
                    <Fragment>
                        <span className='me-1'> <img src='/images/icons/lines-blue.svg' className='img-fluid' alt="Lines Icon" /> </span>
                        <span className='me-1'> {row[columnName]} </span> 
                        <span className='me-1'> Lines </span>
                    </Fragment>
                );
            default:
                return ``
        }
    }
    
    return (
        <div className="common_scoring_summary_table_wrapper">
            <h2 className="summary_main_heading">Summary</h2>
            <div className="common_scoring_summary_table">
                <div className="table-wrapper">
                    <table className="table-view">
                        <thead>
                            <tr>
                                <th>Line</th>
                                {!checkIfArrayIsEmpty(RFQVendors) &&
                                    RFQVendors.map((rfqVendor) => {
                                        const matchingSummaryHeaderCol = ColumnHeadersData.find(
                                            (row) => row.rfq_vendorreply_id === rfqVendor.reply_id
                                        );

                                        if (matchingSummaryHeaderCol) {
                                            return (
                                                <th
                                                    name={`evaluator_${matchingSummaryHeaderCol.rfq_vendorreply_id}`}
                                                    key={matchingSummaryHeaderCol.rfq_vendorreply_id}
                                                >
                                                    {matchingSummaryHeaderCol.evaluator_name}
                                                </th>
                                            );
                                        }
                                        return null;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!checkIfArrayIsEmpty(columns) &&
                                columns
                                    .filter(
                                    (columnName) =>
                                        columnName !== "rfq_vendorreply_id" &&
                                        columnName !== "scorereplyheader_id" 
                                    )
                                    .map((columnName, colKey) => (
                                    <Fragment key={columnName}>
                                        <tr>
                                            <td>
                                                <h2 className="evaluator_type">
                                                    {convertToDisplayName(columnName)}
                                                </h2>
                                            </td>
                                            {!checkIfArrayIsEmpty(RowData) &&
                                                RowData
                                                .sort((a, b) => {
                                                    const indexA = RFQVendors.findIndex(
                                                        (val) => val.reply_id === a.rfq_vendorreply_id
                                                    );
                                                    const indexB = RFQVendors.findIndex(
                                                        (val) => val.reply_id === b.rfq_vendorreply_id
                                                    );
                                                    return indexA - indexB;
                                                })
                                                .map((row, rowKey) => {
                                                    const matchingSummaryScoring = RFQVendors?.find(
                                                        (val) => val.reply_id === row.rfq_vendorreply_id
                                                    );
                                                    if (matchingSummaryScoring) { 
                                                        return (
                                                            <td
                                                            onClick={() =>
                                                                handlerLineRecommendClick(colKey, row)
                                                            }
                                                            key={rowKey}
                                                            >
                                                                {renderTableCell(colKey, columnName, row)}
                                                            </td>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                        </tr>
                                    </Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default (CommonScoringSummaryTable);