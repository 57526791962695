import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverlayLoader from '../PurchaseRequisition/common/OverlayLoader';
import Popup from '../Popup/Popup';
import Helper from '../../inc/Helper'; 
import Button from '../inc/Button';
import { Form } from 'react-bootstrap';
import Collapse from '../inc/Collapse';
import $ from 'jquery';
import FieldsRenderer from '../Purchase Orders Internal/Edit PO/FieldsRenderer';
import FormValidator from '../../inc/FormValidator';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus, isEmptyObject, preparePayloadForPUTAPI } from '../../inc/Validation';
import AttachmentFileModal from './AttachmentFileModal';

let cancelTokenSource = axios.CancelToken.source();
const AttachmentTypesModal = ({ attachment_type_id, reloadParentGrid, setShowCreateModal, language, countries, vendorTypes }) => {
    const [sources, setSources] = useState([]);

    const [formData, setFormData] = useState({});  
    const [updateFormDataFields, setUpdateFormDataFields] = useState({});

    const [apiCallBtn, setApiCallBtn] = useState(false); 
    const [files,setFiles] = useState([]);

    let formValidatory;

    const validationConfigure = () => {
        let formFieldConfig = [
            { name:'name', displayName:Helper.getLabel(language,'name','Name'), types:['Required'] },  
            { name:'source_id', displayName:Helper.getLabel(language,'source_id','Source'), types:['Required'] }
        ];

        if(!formValidatory) {
            formValidatory = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();

    let sourceApiCalled = false; 
    let attachmentObjCalled = false;
  
    useEffect(() => { 
        getAttachmentByID();
        loadSource();
    }, []);

    const getAttachmentByID = () => {
        if (attachmentObjCalled || !attachment_type_id) return;
        attachmentObjCalled = true;

        setApiCallBtn(true);
        let api = Api;
        api.setUserToken();
        api
            .axios()
            .get(Settings.apiUrl + `/attachmenttype/${attachment_type_id}`, {})
            .then(function (res) {
                if (res.data.status === "success") { 
                    setFormData(res?.data?.data); 
                    setApiCallBtn(false);
                }
            })
            .catch((e) => {
                setApiCallBtn(false);
                setFormData({}); 
            });
    };
 
    const loadSource = () => {
        if(sourceApiCalled) return;
        sourceApiCalled = true;

        Api.setUserToken();
        Api.axios().get(Settings.apiUrl + '/source')
            .then(res => {
                if (res.data.status === 'success') {
                    setSources(res.data.data?.map(item => {
                        return {
                            label: item.source_name,
                            value: item.source_id
                        }
                    }));
                }
            });
    };

    const fieldsArrayGeneral = [
        {className:'col-lg-4', required: true, maxLength: 20, disable: apiCallBtn, label: Helper.getLabel(language,'name',"Name"), inputType: "text", id:'name', name:'name'},
        {className:'col-lg-4', required: true, reRenderRequired: true, placeHolder: 'Select source', disable: apiCallBtn, inputType: "dropdown", label: Helper.getLabel(language,'source',"Source"), options: sources, id:'source_id', name:'source_id'}, 
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'vendor_type',"Vendor Type"), inputType: "dropdown", id:'vendor_type', name:'vendortype', options:vendorTypes,reRenderRequired:true},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'country',"Country"), inputType: "dropdown", id:'country_id', name:'country_id', options:countries,reRenderRequired:true},
        {className:'col-lg-4', required: false, disable: apiCallBtn, label: Helper.getLabel(language,'tooltip',"Tooltip"), inputType: "text", id:'tool_tip', name:'tool_tip'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'mandatory',"Mandatory"), inputType: "checkbox", id:'mandatory', name:'mandatory'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'external_allowed',"Allowed to external"), inputType: "checkbox", id:'external_allowed', name:'external_allowed'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'is_technical',"Is technical"), inputType: "checkbox", id:'is_technical', name:'is_technical'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'is_commercial',"Is commercial"), inputType: "checkbox", id:'is_commercial', name:'is_commercial'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'is_template_based_attachment',"Is template based attachment"), inputType: "checkbox", id:'is_template_based_attachment', name:'is_template_based_attachment'},
    ];
 
    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();

            let payload = {
                ...formData,
                external_allowed: formData?.external_allowed == 'on',
                is_commercial: formData?.is_commercial == 'on',
                is_technical: formData?.is_technical == 'on',
                mandatory: formData?.mandatory == 'on', 
                is_template_based_attachment:formData?.is_template_based_attachment == "on",
                source_id: formData?.source_id !== '' ? formData?.source_id : null,
                vendor_type:formData?.vendortype !== '' ? formData?.vendortype : null,
                template_name: (formData?.is_template_based_attachment==="on" || formData?.is_template_based_attachment === true) ? files[0]?.name : null,
                country_id:formData?.country_id !== '' ? formData?.country_id : null
            }

            let finalFormData = new FormData()
            finalFormData.append('file',files[0])
            finalFormData.append('data',  JSON.stringify(payload))

            const res = await api.axios().post(Settings.apiUrl + '/attachmenttype', finalFormData,
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                reloadParentGrid();
                onPopupClose();
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const executePUTAPI = async () => {
        if(isEmptyObject(updateFormDataFields) && checkIfArrayIsEmpty(files)) return;

        try { 
            let api = Api;
            api.setUserToken();

            let apiPayloadObj = preparePayloadForPUTAPI(updateFormDataFields);

            apiPayloadObj.template_name = (formData?.is_template_based_attachment==="on" || formData?.is_template_based_attachment === true) ? files[0]?.name : "";
            
            if (apiPayloadObj?.vendortype !== null) {
                apiPayloadObj.vendor_type = apiPayloadObj.vendortype;
                delete apiPayloadObj.vendortype;
            }

            let finalFormData = new FormData(); 

            if(!checkIfArrayIsEmpty(files)){
                finalFormData.append('file',files[0] ?? null);
            }
 
            finalFormData.append('data', JSON.stringify(apiPayloadObj));

            const res = await api.axios().put(Settings.apiUrl + `/attachmenttype/${attachment_type_id}`, finalFormData,
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                setFiles([]);
                setUpdateFormDataFields({});
                getAttachmentByID();
                reloadParentGrid();
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const onClickFileNameDownload = () => {
        if(!attachment_type_id) return;
        
        let api = Api
        api.setUserToken()
        
        api
        .axios()
        .get(Settings.loginUrl + `/get_attachmenttype_doc/${attachment_type_id}`, { responseType: 'blob' })
        .then(async function (res) {
            if (res.status === 200) {
                const response = res
                const contentDisposition = response.headers['content-disposition']
                const fileName =
                    contentDisposition?.split('filename=')[1] || 'dummy.jpg'
                const fileBlob = new Blob([response.data], {
                    type: response.headers['content-type'],
                })
                const downloadLink = document.createElement('a')
                downloadLink.href = URL.createObjectURL(fileBlob)
                downloadLink.download = fileName
        
                document.body.appendChild(downloadLink)
                downloadLink.click()
                document.body.removeChild(downloadLink)
            }
        })
        .catch((res) => {
            getPopupMessageBasedOnStatus(res);
        })
    }

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    };

    const handleSubmit = () => { 
        if(!formValidatory.isValid({...formData})){
            formValidatory.displayMessage();
            formValidatory.reset();
            return;
        };
  
        if(!attachment_type_id) {
            Helper.createDebouncedAPIFunction( 
                [executePOSTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        } else {
            Helper.createDebouncedAPIFunction( 
                [executePUTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        };
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} 
                width="1000px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">{!attachment_type_id?'Create Attachment Type':'Edit Attachment Type'}</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}

                       
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer 
                                    updateState={(updateFunction) => { 
                                        setUpdateFormDataFields((prevState) => updateFunction(prevState));
                                        setFormData((prevState) => updateFunction(prevState));
                                    }} 
                                    currentState={formData} fieldsArray={fieldsArrayGeneral} 
                                />
                                {(formData?.is_template_based_attachment === "on" || formData?.is_template_based_attachment === true) && <AttachmentFileModal onClickFileNameDownload={onClickFileNameDownload} previousFileName={ formData?.template_name } setFiles={setFiles} files={files}/>}
                            </div>
                        </Form> 
                    </Collapse>
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                        className='black-btn-style'
                    />

                    <Button
                        isDisable={apiCallBtn}
                        className={attachment_type_id?"rfq_save_btn":"solid_btn" } 
                        isActive={!attachment_type_id}
                        title={!attachment_type_id?Helper.getLabel(language, "create", "Create"):Helper.getLabel(language, "save", "Save")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps) (AttachmentTypesModal);