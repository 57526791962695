import React from 'react'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import Settings from '../../../inc/Settings'

const NotesModal = ({ handleNotesClose, vendor_id, isOpen , isTransitioning, setIsTransitioning}) => {
  const handleTransitionEnd = () => {
    if (isOpen) { 
        setIsTransitioning(false);
    }
};
  return (
    <div className='vendor-notes-popup'>
      <div className='workflow_offcanvas_header'>
        <div
          className={`offcanvas-overlay ${isOpen ? 'show' : ''}`}
          onClick={handleNotesClose}
        ></div>

        <div
          className={`offcanvas-container ${isOpen ? 'show' : ''} ${
            isTransitioning ? 'transitioning' : ''
          }`}
          onTransitionEnd={handleTransitionEnd}
        >
          <div className='offcanvas-content'>
            <NoteAttachmentHistory
              hideHistory={true}
              source_id={Settings.source_id.vendor}
              notesource_integrator={vendor_id}
              hideAttachments={true}
              attachmentsource_integrator={vendor_id}
              isCustomHeight={true}
              hidePreviewNotes={true}
            />
            <div className='action_btns'>
              <button className=' btn black-btn-style' onClick={handleNotesClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotesModal
