import React, { useState, useEffect } from 'react';
import './PendingActions.css'; 
import Collapse from '../../inc/Collapse';
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell';
import { useNavigate } from 'react-router-dom';
import WorkFlowCommonHeaderAPICalls from '../../Header/WorkflowCommonHeaderAPICalls';
import Api from '../../../inc/Api';
import { checkIfArrayIsEmpty } from '../../../inc/Validation';
import navigationListForPendingActions from '../../../inc/PendingActionsNavigationList';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getNewValue } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import SimpleLoading from '../../Loading/SimpleLoading';

const PendingActions = ({ language, sources=[], setPendingActionsList }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const CellHandler = (event) => {
    if (event.colDef.field === 'action') {
      const {module_id} = event.data
      const matchedModule = navigationListForPendingActions.find(item => item.source_id === event.data.module_id);
      if (matchedModule) {
        const linkWithIds = matchedModule.link
          .replace(':id', event.data.module_item_id)
          .replace(':instance_id', event.data.instance_task_id);

        let statePayload;
        if(module_id===Settings.source_id.auction_awarding || module_id===Settings.source_id.auction_commercial || module_id===Settings.source_id.auction){
           statePayload = {state: {prevRoutePath:'/dashboard',rfqType:2}}
        }else{
          statePayload = { state: { prevRoutePath: '/dashboard' } }
        }    
        navigate(linkWithIds, statePayload);
      }
    };
  };

  //function for render statuses
  const getStatusColor = (status) => {
    if (typeof status !== 'string') {
      return '-';
    };

    if(status){ 
      switch (status?.toLowerCase()) {
        case 'vendor':
          return '#12BDB21C'
        case 'purchase requisition':
          return '#8239BC1C'
        case 'purchase order':
          return '#54A0CA1C'
        case 'purchase agreement':
          return '#54A0CA1C'
        case 'good receiving':
          return '#ECF5F9'
        case 'contract':
          return '#FBB5001C'
        case 'direct order':
          return '#54A0CA1C'
        case 'awarding':
          return '#9002021C'
        case 'auction awarding':
          return '#9002021C'
        case 'auction line':
          return '#9002021C'
        case 'auction line distribution':
          return '#9002021C'
        case 'request for quotation':
          return '#54A0CA1C'
        case 'auction':
          return '#54A0CA1C'
        case 'rfq technical evaluation':
          return '#54A0CA1C'
        case 'rfq financial evaluation':
          return '#54A0CA1C'
        case 'auction financial evaluation':
          return '#54A0CA1C'
        case 'rfq commercial evaluation':
          return '#54A0CA1C'
        case 'rfq awarding':
          return '#54A0CA1C'
        case 'request for information':
          return '#54A0CA1C'
        case 'invoice':
          return '#FBB5001C'
        case 'return order':
          return '#54A0CA1C'
        case 'work confirmation':
          return '#54A0CA1C'
        default:
          return '#303030' 
      }   
    };
    // more will be added as per the implementations of workflow system,
  }

  const gridLinkValueFormat = (params) => {   
    const sourceLabel = !checkIfArrayIsEmpty(sources) ? (sources?.find((id) => id.value === params.value) !== undefined ? sources?.find((id) => id.value === params.value)?.label : '') : params?.value; 
    return `<div style="background-color: ${getStatusColor(params?.value == 54 ? sourceLabel?.split(',')[2].trim() : sourceLabel)}" class="rs_active_label_actions">${params?.value == 54 ? sourceLabel?.split(',')[2].trim() : sourceLabel}</div>`
  };

  const gridLinkValueFormatAssignee = (params) => {
    return `<div class="rs_active_label_normal">${params?.value ? params?.value : params?.data?.initiated_by_name}</div>`
  };

  const displayLogo = (params) => {
    if(!params.value){
      return `<div><img src="${'/images/icons/take_action.svg'}" style="height:38px; width: 100px" /></div>`;
    } 
  };

  const handleFetchData = (response) => {
    if(typeof setPendingActionsList === 'function'){
      setPendingActionsList(response?.data);
    } 
    return {
      rowData: response?.data?.data?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: response?.data?.total_records
    }
  }

  const handleErrorResponse = (res) => {
    if(typeof setPendingActionsList === 'function'){
      setPendingActionsList({ total_records: 0 });
    } 
  }

  const gridColumnHeaders = [
    { field:'document_number', valueFormatter: (params) => (params?.value ?? '-'), filter: false, sortable: false, minWidth: 280, headerName: Helper.getLabel(language,'document_number',"Document number"), editable: false },
    { field:'initiated_by_name', filter: false, sortable: false, minWidth: 280, headerName: Helper.getLabel(language,'requester',"Requester"), editable: false },
    { field:'initiated_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter: false, sortable: false, minWidth: 280, headerName: Helper.getLabel(language,'date',"Date"), editable: false },
    { field:'assigned_by_name', filter: false, sortable: false, minWidth: 280, headerName: Helper.getLabel(language,'from',"From"), editable: false, valueFormatter: gridLinkValueFormatAssignee, cellRenderer: CustomHtmlCell },
    // {field:'date', minWidth: 420, headerName: Helper.getLabel(language,'date',"Date"), inputType: "new_datepicker", valueFormatter:(params) => console.log(params.value), editable: true},
    // {field:'comment', minWidth: 420, headerName: Helper.getLabel(language,'comment',"Comments"), editable: false},
    { field:'due_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter: false, sortable: false, minWidth: 280, headerName: Helper.getLabel(language,'due_date',"Due Date"), editable: false},
    { field:'module_id', filter: false, sortable: false, minWidth: 280, headerName: Helper.getLabel(language,'souce',"Source"), inputType: "dropdown", options: sources, editable: false, valueFormatter: gridLinkValueFormat,  cellRenderer: CustomHtmlCell },
    { field:'action', filter: false, sortable: false, minWidth: 280, headerName: Helper.getLabel(language,'action',"Action"), valueFormatter: displayLogo, cellRenderer: CustomHtmlCell, editable: false }
  ];

  return ( 
    <div style={{height: '650px', marginBottom: '35px'}}> 
      <Collapse title={Helper.getLabel(language,'pending_actions','Pending Actions')} open={true} className="pending_actions_collapse mb-4 mt-4">
        {
          loading ? <SimpleLoading /> : 
          <AgGridNew 
            pagination={true}
            onErrorFromApi={handleErrorResponse}
            fetchData={handleFetchData}
            apiUrl={Settings.apiUrl + `/workflow/pending_actions`} 
            payload={{ task_type: "incompleted" }}
            apiMethod={"post"}
            columnDefs={gridColumnHeaders} 
            onCellClicked={CellHandler}
            hideDeleteBtn={true} 
            hideAddBtn={true}
            height={500}
            gridId={'rs_pending_actions_list'}
          />
        }
      </Collapse> 
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
};

export default connect(mapStateToProps) (PendingActions);