import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";

import Helper from "../../../inc/Helper";
import Button from "../../inc/Button";
import NavigationHeder from "../../Navigations/NavigationHeder";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import SimpleLoading from "../../Loading/SimpleLoading";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";
import RFQCommonApiCalls from "../RFQCommonApiCalls";
import SelectHeaderModal from "./SelectHeaderModal.js";
import ConvertToModal from "./ConvertToModal";
import AddVendorModal from "./AddVendorModal";
import AgGridNew from "../../grid/ag/ag-grid-new.js";
import { gridLinkValueFormat } from "../../grid/ag/commonFunctions/GridCommonFunctions.js";
import DatepickerFunctions from "../../../inc/DatepickerHelper.js";
import Gui_id_list from "../../../inc/Gui_id_list.js";
import ApplySecurityRoles from "../../SecurityRoles/ApplySecurityRoles.js";
import MasterComonent from "../../Backend/MasterComonent.js";
import Alert from "../../inc/Alert.js";

const AllRequisitions = (props) => {
  const commonApiCalls = new RFQCommonApiCalls();
  const [lineTypes, setLineTypes] = useState([]);
  const [allUnits, setAllUnits] = useState([]);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [selectedPRs, setSelectedPRs] = useState([]);
  const [uniqueHeaderIDs, setUniqueHeaderIDs] = useState([]);
  const [selectedHeaderID, setSelectedHeaderID] = useState([]);
  const [selectedDocType, setSelectedDocType] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isConverting, setIsConverting] = useState(false);
  const [showSelectHeaderModal, setShowSelectHeaderModal] = useState(false);
  const [showConvertToModal, setShowConvertToModal] = useState(false);
  const [showAddVendorModal, setShowAddVendorModal] = useState(false);
  const navigate = useNavigate();


  const cellHandler = (event, navigate) => {
    if (event.colDef.field === "requisition_number") {
      navigate(
        "/purchase-requisition/summary?pr_view_id=" +
          event.data.requisition_id +
          "&return=rfq",{state:{isPrevPath:true}}
      );
    }
    if (event.colDef.field === "item_category_id" && event.data.item_id) {
      navigate(
        "/purchase-requisition/shop/" + event.data.item_id + "?return=rfq"
      );
    }
  };

  const getLineTypes = async () => {
    let api = Api;
    api.setUserToken();

    try {
      const lineTypesRes = await api
        .axios()
        .get(Settings.loginUrl + "/enum/po_lineType");
      if (lineTypesRes.data?.status === "success") {
        setLineTypes(lineTypesRes.data.data);
      }
    } catch (error) {
      Helper.alert(error?.response?.data?.message, "error");
    }
  };

  const getAllOpenPR = async (reset) => {
    let api = Api;
    api.setUserToken();

    try {
      setIsLoading(false);  
    } catch (error) {
      setIsLoading(false);
      Helper.alert(error?.response?.data?.message, "error");
    }
  };

  const handlePRSelection = (event) => {
    let selectedRows = [];
    event.api.getSelectedRows()?.forEach((row) => {
      selectedRows.push({
        requisition_id: row.requisition_id,
        requisition_number: row.requisition_number,
        line_id: row.line_id,
      });
    });
    setSelectedPRs(selectedRows);
  };

  const handleCellClick = (event) => {
    setSelectedPRs([event.data]);
  };

  const handleSelectionModals = () => {
    //check whether header_id(requisition_is) is same or not
    if (selectedPRs.length === 0) return;
    const uniqueIds = new Set(selectedPRs.map((pr) => pr.requisition_id));
    const sameId = selectedPRs.every(
      (pr) => pr.requisition_id === selectedPRs[0].requisition_id
    );
    if (sameId) {
      setSelectedHeaderID(Array.from(uniqueIds)?.[0]);
      setShowConvertToModal(true);
    } else {
      setUniqueHeaderIDs(Array.from(uniqueIds));
      setShowSelectHeaderModal(true);
    }
  };

  const handleConvertTo = async () => {
    // Doc Type PO
    if (selectedDocType == 0 || selectedDocType == 1) {
      setShowConvertToModal(false);
      setShowAddVendorModal(true);
      // Doc Type Contract
    } 
    else if (selectedDocType == 2) {
      Helper.alert("Its's not implemented yet", "error");
    } else if (selectedDocType == 3) {
      try {
        setIsConverting(true);
        const PRLinesInAction = [];
        selectedPRs.forEach((PR) => {
          // if (PR.requisition_id === selectedHeaderID)
            PRLinesInAction.push(PR.line_id);
        });
        const convertToData = {
          PR_header_id: selectedHeaderID,
          pr_lines: PRLinesInAction,
          document_type: selectedDocType,
        };
        const convertToRes = await commonApiCalls.convertPRLinesToRFQ(
          convertToData
        );
        if (convertToRes) {
          setIsConverting(false);
          setShowConvertToModal(false);
          Helper.alert(convertToRes?.message, "success");
          $("html").removeClass("rs_popup_open");
          navigate(
            "/request-for-quotation/rfqdetailform?rfq_view_id=" +
              convertToRes?.header_id
          );
        }
      } catch (error) {
        setIsConverting(false);
        console.log("Error in converting to RQF", error);
        Helper.alert(error?.response?.data?.message, "error");
      }
    }
    else if (selectedDocType == 4) {
      try {
        setIsConverting(true);
        const PRLinesInAction = [];
        selectedPRs.forEach((PR) => {
          // if (PR.requisition_id === selectedHeaderID)
            PRLinesInAction.push(PR.line_id);
        });
        const convertToData = {
          PR_header_id: selectedHeaderID,
          pr_lines: PRLinesInAction,
          document_type: selectedDocType,
        };
        const convertToRes = await commonApiCalls.convertPRLinesToRFQ(
          convertToData
        );
        if (convertToRes) {
          setIsConverting(false);
          setShowConvertToModal(false);
          Helper.alert(convertToRes?.message, "success");
          $("html").removeClass("rs_popup_open");
          navigate(
            "/request-for-quotation/rfqdetailform?rfq_view_id=" +
              convertToRes?.header_id,{state:{rfqType:2}}
          );
        }
      } catch (error) {
        setIsConverting(false);
        Helper.alert(error?.response?.data?.message, "error");
      }
    }
  };

  const handleAddVendorSubmit = async (vendor_id, vendorsite_id) => {
    setIsConverting(true);
    const PRLinesInAction = [];

    selectedPRs.forEach((PR) => {
      // if (PR.requisition_id === selectedHeaderID)
        PRLinesInAction.push(PR.line_id);
    });

    const convertToData = {
      PR_header_id: selectedHeaderID,
      pr_lines: PRLinesInAction,
      document_type: selectedDocType,
      vendor_id: parseInt(vendor_id),
      vendorsite_id: parseInt(vendorsite_id),
    };

    try {
      const convertToRes = await commonApiCalls.convertPRLinesToRFQ(
        convertToData
      );
      if (convertToRes) {
        setIsConverting(false);
        setShowConvertToModal(false);
        Helper.alert(convertToRes?.message, "success");
        $("html").removeClass("rs_popup_open");
        selectedDocType == 1 ? navigate(`/contract-order/edit/${convertToRes.header_id}`): navigate(`/purchase-order/edit/${convertToRes.header_id}`);
      }
    } catch (error) {
      setIsConverting(false);
      getPopupMessageBasedOnStatus(error)
    }
  };

  const handleCloseSelectHeaderModal = () => {
    setShowSelectHeaderModal(false);
  };

  const handleCloseConvertToModal = () => {
    setShowConvertToModal(false);
  };

  useEffect(() => {
    const getAsyncData = async () => {
      const units = await commonApiCalls.getAllUnits();
      const currencies = await commonApiCalls.getAllCurrencies();
      setAllUnits(units);
      setAllCurrencies(currencies);
    };
    getAsyncData();

    getLineTypes();
  }, []);

  useEffect(() => {
    if (
      lineTypes?.length > 0 &&
      allUnits.length > 0 &&
      allCurrencies.length > 0
    )
      getAllOpenPR();
  }, [lineTypes, allUnits, allCurrencies]);

  const itemCellRenderer = (params) => {
    if (!params?.data) { return null }
    const { type, item_id, category_id } = params.data;
    switch (type) {
      case 0:
        return ( <div className="rs_grid_simple_link"> {Helper.getLabel(props.language, "item_id", "Item ID")} - {item_id} </div> );
      case 1:
        return ( `${Helper.getLabel(props.language, "category_id", "Category ID")} - ${category_id}` );
      default:
        return ( Helper.getLabel(props.language, "comments", "Comments") );
    }
  }

  const formattedNameValue = (params) => {
    if (!params?.data) {return null}
    const { type, item_name, category_name, comments } = params.data;
    switch (type) {
      case 0:
        return item_name
      case 1:
        return category_name;
      default:
        return comments;
    }
  }

  const Columns_Headings = [
    {
      field: "requisition_number",
      headerName: Helper.getLabel(props.language, "requisition", "Requisition"),
      cellRenderer: (params)=>gridLinkValueFormat(params),
      editable: false,
      minWidth: 200,
    },
    {
      field: "item_category_id",
      headerName: Helper.getLabel(
        props.language,
        "item_category_id",
        "Item/Category ID"
      ),
      cellRenderer: (params) => {return itemCellRenderer(params) },
      editable: false,
      minWidth: 200,
    },
    {
      field: "description",
      headerName: Helper.getLabel(props.language, "name", "Name"),
      editable: false,
      minWidth: 200,
      valueFormatter:(params)=>{return formattedNameValue(params) }
      // valueFormatter:(params)=>{return params.value ? params.value : "-"}
    },
    {
      field: "ordered_qty",
      headerName: Helper.getLabel(props.language, "quantity", "Quantity"),
      editable: false,
      minWidth: 150,
    },
    {
      field: "unit",
      headerName: Helper.getLabel(props.language, "unit", "Unit"),
      editable: false,
      minWidth: 150,
      cellRenderer:(params) => {return params.value
        ? allUnits.filter(
            (unit) => unit.unit_id === parseInt(params.value)
          )[0]?.code
        : "-"}
    },
    {
      field: "unit_price",
      headerName: Helper.getLabel(props.language, "unit_price", "Unit Price"),
      editable: false,
      minWidth: 150,
    },
    {
      field: "currency_id",
      headerName: Helper.getLabel(props.language, "currency", "Currency"),
      editable: false,
      minWidth: 150,
      cellRenderer:(params)=>{
        return params.value
        ? allCurrencies.filter(
            (currency) =>
              parseFloat(currency.currency_id) ===
              parseFloat(params?.value)
          )[0]?.currency_code
        : "-"
      }
    },
    {
      field: "entity",
      headerName: Helper.getLabel(props.language, "entity", "Entity"),
      editable: false,
      minWidth: 200,
    },
    {
      field: "site",
      headerName: Helper.getLabel(props.language, "site", "Site"),
      editable: false,
      minWidth: 200,
    },
    {
      field: "warehouse",
      headerName: Helper.getLabel(props.language, "warehouse", "Warehouse"),
      editable: false,
      minWidth: 200,
    },
    {
      field: "submitter",
      headerName: Helper.getLabel(props.language, "submitter", "Submitter"),
      editable: false,
      minWidth: 200,
    },
    {
      field: "delivery_date",
      headerName: Helper.getLabel(
        props.language,
        "delivery_date",
        "Delivery Date"
      ),
      editable: false,
      minWidth: 200,
      valueFormatter:(params)=>params.value?DatepickerFunctions.convertDateFromDataBase(params.value):"-"
      },
    {
      field: "createddatetime",
      headerName: Helper.getLabel(
        props.language,
        "creation_date_time",
        "Creation Date/Time"
      ),
      editable: false,
      minWidth: 200,
      valueFormatter:(params)=>params.value?DatepickerFunctions.convertDateTimeFromDataBase(params.value):"-"
    },
  ];

  let security = props?.security;
  
  if (props.auth?.user?.usertype===0 && !security.canView(Gui_id_list.request.purchase_requisitions.open_purchase_requisitions_main)) {
    return (
      <Fragment> 
        <Alert 
          message="You do not have any access to the following screen, please contact your administrator!" 
          type="danger" 
        /> 
      </Fragment>
    );
  }

  return (
    <div>
      <NavigationHeder
        title={Helper.getLabel(
          props.language,
          "open_purchase_requisition",
          "Open Purchase Requisitions"
        )}
        backUrl={"/request"}
        hideMoreBtn={true}
      >
        {/* <Button
          isActive={false}
          className="rfq_print_btn"
          title={Helper.getLabel(props.language, "print", "Print")}
          icon={"/images/icons/print.svg"}
        /> */}
        <Button
          isActive={true}
          isDisable={props.auth?.user?.usertype===0 && !security.canCreate(Gui_id_list.request.purchase_requisitions.open_purchase_requisitions_main_convert_button)} 
          title={Helper.getLabel(props.language, "convert", "Convert")}
          onClick={handleSelectionModals}
        />
      </NavigationHeder>
      {/* <AdvanceSearch
        language={props.language}
        entity={entity}
        setEntity={setEntity}
        site={site}
        setSite={setSite}
        warehouse={warehouse}
        setWarehouse={setWarehouse}
        item={item}
        setItem={setItem}
        requester={requester}
        setRequester={setRequester}
        getAllOpenPR={getAllOpenPR}
      /> */}
      {isLoading ? (
        <SimpleLoading />
      ) : (
        <AgGridNew
          gridId={'rfq_open_prs_list'}
          apiUrl={Settings.apiPurchaseRequisitionUrl + "/open_pr"}
          pagination={true}
          hideDeleteBtn={true}
          hideAddBtn={true}
          columnDefs={Columns_Headings}
          onSelectionChanged={handlePRSelection}
          onCellClicked={(event) => {
            handleCellClick(event);
            return cellHandler(event, navigate);
          }}     
          height={500}  
        />
      )}
      {showSelectHeaderModal ? (
        <SelectHeaderModal
          language={props.language}
          show={showSelectHeaderModal}
          handleCloseModal={handleCloseSelectHeaderModal}
          setShowConvertToModal={setShowConvertToModal}
          uniqueHeaderIDs={uniqueHeaderIDs}
          selectedPRs={selectedPRs}
          selectedHeaderID={selectedHeaderID}
          setSelectedHeaderID={setSelectedHeaderID}
        />
      ) : null}
      {showConvertToModal ? (
        <ConvertToModal
          language={props.language}
          show={showConvertToModal}
          handleCloseModal={handleCloseConvertToModal}
          selectedDocType={selectedDocType}
          setSelectedHeaderID={setSelectedHeaderID}
          setSelectedDocType={setSelectedDocType}
          handleConvertTo={handleConvertTo}
          isConverting={isConverting}
        />
      ) : null}

      {showAddVendorModal ? (
        <AddVendorModal
          language={props.language}
          handleCloseModal={() => setShowAddVendorModal(false)}
          handleSave={handleAddVendorSubmit}
          isConverting={isConverting}
          setSelectedDocType={setSelectedDocType}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};
 
const SecurityOptions = {
  gui_id: Gui_id_list.request.purchase_requisitions.open_purchase_requisitions_main
}

export default connect(mapStateToProps) (((ApplySecurityRoles(AllRequisitions, SecurityOptions))))