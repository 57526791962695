import React from 'react'
import Helper from '../../../../inc/Helper'
import Dropdown from '../../../Forms/Dropdown'
import Input from '../../../Forms/Input'
import Settings from '../../../../inc/Settings'

const GeneralAdditionalInformation = ({
  rfqHeader,
  handleOnChange,
  language,
  rfqHeaderKeyPressData,
  handleInputChange,
  deliveryTermList,
  employeesList,
  modeOfDeliveryList,
  currencyList,
  taxGroupList,
  taxGroupItemList,
  isDisabled,
  employeesData,
  mapEmployeesData
}) => {
  return (
    <>
      <div className='generalInformation-advanced'>
        <div className='generalInformation-basic-grid'>
          <Dropdown
            label={`${Helper.getLabel(language, 'requester', 'Requester')}`}
            options={ rfqHeader?.requester_id
              ? [{
                  value: rfqHeader.requester_id,
                  label: rfqHeader.requester_name ?? employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(rfqHeader.requester_id))?.displayname,
                }]
              : employeesList?.map(item => ({
                  value: item.employee_id,
                  label: item.displayname,
              }))}
            placeHolder={`${Helper.getLabel(language, 'select_requester', 'Select requester')}`}
            id='rfq_general_requester'
            name='requester_id'
            reRenderRequired={true}
            onChange={handleOnChange}
            value={rfqHeader?.requester_id}
            pagination={true} 
            apiUrl={Settings?.apiOrgUrl + `/employee_dropdown?status=true`} 
            allDataRef={employeesData}
            mapFunction={mapEmployeesData}
            isDisable={isDisabled}
          />
          <Dropdown
            label={`${Helper.getLabel(language, 'orderer', 'Orderer')}`}
            options={rfqHeader?.orderer_id ? [{
              value: rfqHeader.orderer_id,
              label: rfqHeader.orderer_name ?? employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(rfqHeader.orderer_id))?.displayname,
            }]:employeesList?.map((item) => {
              return {
                value: item.employee_id,
                label: item.displayname,
              }
            })}
            placeHolder={`${Helper.getLabel(language, 'orderer', 'Select orderer')}`}
            id='rfq_general_orderer'
            name='orderer_id'
            onChange={handleOnChange}
            reRenderRequired={true}
            value={rfqHeader?.orderer_id}
            pagination={true} 
            apiUrl={Settings?.apiOrgUrl + `/employee_dropdown?status=true`} 
            allDataRef={employeesData}
            mapFunction={mapEmployeesData}
            isDisable={isDisabled}
          />
          <Dropdown
            label={`${Helper.getLabel(language, 'currency', 'Currency')}`}
            options={currencyList?.map((item) => {
              return {
                value: item.currency_id,
                label: `${item.currency_code} - ${item.currency_name}`,
              }
            })}
            placeHolder={`${Helper.getLabel(language, 'select_currency', 'Select currency')}`}
            id='rfq_general_currency'
            name='currency_id'
            onChange={handleOnChange}
            value={rfqHeader?.currency_id}
            reRenderRequired={true}
            isDisable={isDisabled}
            required
          />
          <Dropdown
            label={`${Helper.getLabel(language, 'tax_group', 'Tax group')}`}
            options={taxGroupList?.map((item) => {
              return {
                value: item.taxgroup_id,
                label: `${item.name}`,
              }
            })}
            placeHolder={`${Helper.getLabel(language, 'select_tax_group', 'Select tax group')}`}
            id='rfq_general_tax_group'
            name='taxgroup_id'
            onChange={handleOnChange}
            value={rfqHeader?.taxgroup_id}
            reRenderRequired={true}
            isDisable={isDisabled}
          />
          <Dropdown
            label={`${Helper.getLabel(language, 'tax_group_item', 'Tax group item')}`}
            options={taxGroupItemList?.map((item) => {
              return {
                value: item.taxgroupitem_id,
                label: `${item.name}`,
              }
            })}
            placeHolder={`${Helper.getLabel(language, 'select_tax_group_item', 'Select tax group item')}`}
            id='rfq_general_tax_group_item'
            name='taxgroupitem_id'
            onChange={handleOnChange}
            value={rfqHeader?.taxgroupitem_id}
            reRenderRequired={true}
            isDisable={isDisabled}
          />
          <Dropdown
            label={`${Helper.getLabel(language, 'mode_of_delivery', 'Mode of delivery')}`}
            options={modeOfDeliveryList?.map((item) => {
              return {
                value: item.mode_of_delivery_id,
                label: item.name,
              }
            })}
            placeHolder={`${Helper.getLabel(language, 'select_mode_of_delivery', 'Select mode of delivery')}`}
            id='rfq_general_mode_of_delivery'
            name='mode_of_delivery_id'
            onChange={handleOnChange}
            value={rfqHeader?.mode_of_delivery_id}
            reRenderRequired={true}
            isDisable={isDisabled}
          />
          <Dropdown
            label={`${Helper.getLabel(language, 'delivery_term', 'Delivery term')}`}
            options={deliveryTermList?.map((item) => {
              return {
                value: item.delivery_term_id,
                label: item.name,
              }
            })}
            placeHolder={`${Helper.getLabel(language, 'select_delivery_term', 'Select delivery term')}`}
            reRenderRequired={true}
            id='rfq_general_delivery_term'
            name='delivery_term_id'
            onChange={handleOnChange}
            value={rfqHeader?.delivery_term_id}
            isDisable={isDisabled}
          />
          <Input
            label={`${Helper.getLabel(language, 'email', 'Email')}`}
            value={rfqHeaderKeyPressData?.email_address ? rfqHeaderKeyPressData?.email_address : rfqHeader?.email_address}
            name='email_address'
            onChange={handleInputChange}
            onBlur={handleOnChange}
            disable={isDisabled}
            maxLength={100}
          />
          <Input
            label={`${Helper.getLabel(language, 'external_reference', 'External reference')}`}
            value={rfqHeaderKeyPressData?.external_reference ? rfqHeaderKeyPressData?.external_reference : rfqHeader?.external_reference}
            name='external_reference'
            onChange={handleInputChange}
            onBlur={handleOnChange}
            disable={isDisabled}
            maxLength={80}
          />
          <div className='generalInformation-oldCartItem-grid1-info mb-4'>
            <label>{Helper.getLabel(language, 'bid_count_policy_met', 'Bid count policy met')}</label>
            {/* Bid count policy met field convert normal field to switch field */}
            <div className='form-check form-switch'>
              <input
                className='form-check-input rfqCheckbox-style'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDisabled'
                disabled
                checked={rfqHeader?.bid_count_policy_met ? rfqHeader?.bid_count_policy_met : false}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='bidding_requirement rfq-internal-requirements'>
        <div className='scope_of_work'>
          <h2 className='border-bottom-h2'>{Helper.getLabel(language, 'notes_to_vendor', 'Notes to vendor')}</h2>
          <div className='request_for_quotation_content w-100 mt-2'>
            <textarea className='request_for_quotation_content rfq-textarea w-100' disabled={isDisabled} onChange={handleInputChange} onBlur={handleOnChange} name='note_to_vendor' maxLength={4000}>
              {rfqHeaderKeyPressData?.note_to_vendor ? rfqHeaderKeyPressData?.note_to_vendor : rfqHeader?.note_to_vendor}
            </textarea>
          </div>
        </div>
      </div>
    </>
  )
}

export default GeneralAdditionalInformation
