import React, { Fragment, useCallback, useEffect, useState } from 'react';
import NavigationHeder from '../../../Navigations/NavigationHeder';
import ButtonNew from '../../../inc/ButtonNew'; 
import Gui_id_list from '../../../../inc/Gui_id_list';
import Alert from '../../../inc/Alert';
import { useNavigate } from 'react-router-dom';
import Helper from '../../../../inc/Helper'; 
import { connect } from 'react-redux';
import MasterComponentWraper from '../../../Backend/MasterComponentWraper';
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings'; 
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import AgGridNew from '../../../grid/ag/ag-grid-new';
import { getDisplayNamesFromArray, getOptions, gridLinkValueFormat, optionsKeyCreator } from '../../../grid/ag/commonFunctions/GridCommonFunctions';

let gridApi = null;

const BiddingCountListings = (props) => {
    const [questionnaire, setQuestionnaire] = useState([]) 

    const navigate = useNavigate()
 
    let questionnaireCalled = false

    useEffect(() => { 
        getQuestionnaire()
    }, [])
 
    const getQuestionnaire = () => {
        if(questionnaireCalled) return;
 
        let api = Api
        questionnaireCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/questionnaire?is_active=True',{}).then(function(res){
            if(res.data.status==="success") { 
                setQuestionnaire(res?.data?.data?.map(element => {
                    return {
                        value: element.questionaire_id,
                        label: element.description
                    }
                })) 
            }
        }).catch((res) => {  
            console.log(res.response.data.message, 'error')
        })
    }

    const DeleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();

        let delBiddingCount_ID = element?.data?.rfq_bidcountpolicy_id;

        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl+`/RFQBidCountPolicy/${delBiddingCount_ID}`)
            const rowNode = gridApi?.getRowNode(delBiddingCount_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi.applyServerSideTransaction(transaction)
            }, 200)
            gridApi.refreshServerSide({ purge: true })
            gridApi?.deselectAll();
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);
     
    const CellHandler = (event) => {   
        if(event.colDef.field == 'rfq_bidcountpolicy_id') { 
            navigate(`/edit-bidding-count/${event?.data?.rfq_bidcountpolicy_id}`)
        }
    }

    let security = props.security;
    let frontendIds= Gui_id_list;
    if(!security.canView(Gui_id_list.procure.request_for_quotation.control_forms.bidding_count.bidding_count)){
      return <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    }

    const gridColHeaders = [
        {field:'rfq_bidcountpolicy_id', filter: false, sortable: false, headerName:Helper.getLabel(props.language,'rfq_bidcountpolicy_id',"RFQ Bid Policy"), cellRenderer: (params) => gridLinkValueFormat(params), editable: false},
        {field:'from_numberofbid', filter: 'agNumberColumnFilter', headerName:Helper.getLabel(props.language,'from_numberofbid',"From No. Of Bid"), editable: false},
        {field:'to_numberofbid', filter: 'agNumberColumnFilter', headerName:Helper.getLabel(props.language,'to_numberofbid',"To No. Of Bid"), editable: false},
        {field:'questionnaire_id', filterParams: { values: (params) => getOptions(params, questionnaire), keyCreator: (params) => optionsKeyCreator(params, 'value'), valueFormatter: params => params.value.label }, filter: 'agSetColumnFilter', cellRenderer: (params) => getDisplayNamesFromArray(params, questionnaire, 'value', 'label'), headerName:Helper.getLabel(props.language,'questionnaire_id',"Questionnaire"), editable: false},  
        {
            field: 'is_active',
            filter: 'agSetColumnFilter',
            filterParams: { 
                values: [true, false], 
                keyCreator: (params) => params.value, 
                valueFormatter: (params) => params.value ? 'true' : 'false' 
            }, 
            cellRenderer: 'agCheckboxCellRenderer', 
            cellEditor: 'agCheckboxCellEditor', 
            cellRendererParams: { disabled: true },
            editable: false, 
            minWidth: 160,
            valueGetter: (params) => {
                if(!params?.data?.is_active || !params?.data){
                    return false
                }
                else {
                    return params.data?.is_active
                }
            },
            headerName: Helper.getLabel(props.language, "active", 'Active')
        },
    ];

    return (
        <div className='bidding_count_listings'>
            <div className='container-fluid'>
                <NavigationHeder hideMoreBtn={true}  backUrl='/setup' title={Helper.getLabel(props.language,'bid_count_policy',"Bid Count Policy")}>
                    <ButtonNew to="/create-bidding-count" title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.procure.request_for_quotation.control_forms.bidding_count.bidding_count_main_create_button)} /> 
                </NavigationHeder> 

                <AgGridNew
                    apiUrl={Settings.apiUrl+'/RFQBidCountPolicy'} 
                    hideDeleteBtn={!security.canDelete(frontendIds.procure.request_for_quotation.control_forms.bidding_count.bidding_count_main_delete_button)}
                    pagination={false}
                    columnDefs={gridColHeaders} 
                    hideAddBtn={true}
                    height={500}
                    uniqueField={'rfq_bidcountpolicy_id'}
                    onCellClicked={CellHandler}
                    handleDeleteSelectedRows={DeleteHandler}
                    onGridReady={(params) => gridApi = params.api} 
                    gridId={"rs_bidding_count_list"}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}

const SecurityOptions = {
    gui_id:Gui_id_list.procure.request_for_quotation.control_forms.bidding_count.bidding_count
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(BiddingCountListings, SecurityOptions))))