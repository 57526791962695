import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../../inc/Api'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings'
import ExtHeaderLine from '../../../Header/ExtHeaderLine'
import SimpleLoading from '../../../Loading/SimpleLoading'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import MasterComonent from '../../../Backend/MasterComonent'
import RFQAttachmentPopup from './RFQAttachmentPopup'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import getRfqExternalColumns from './RFQColumnsHeader'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import RFQAuctionPopup from './RFQAuctionPopup'
import { getBidStatus } from '../../../grid/ag/commonFunctions/GridCommonFunctions'

let gridApi = null

const RFQList = (props) => {
  const [rfqStatusList, setRFQStatusList] = useState([])
  const [rfqQuotationType, setRFQQuotationType] = useState([])
  const [rfqAttachments, setRFQAttachments] = useState([])
  const [popUpPosition, setPopUpPosition] = useState({ top: 0, right: 0 })
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showAuctionModal, setShowAuctionModal] = useState(false)
  const [actions, setActions] = useState([])
  const [auctionData,setAuctionData]=useState([])
  
  const navigate = useNavigate()
  const location = useLocation()

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }
  
  const status = getSearchParam('status')
 
  const getAdditionalParams = () => {
    let addParams  = props.type==="auction" ? `&rfq_type=2` : `&rfq_type=0`
    if (status && status !== 2) {
      addParams += `&status=${status}`;
    } else if (parseInt(status) === 2) {
      addParams += `&status=2,3`;
    } 
    return addParams;
  };

  //Api Calls
  //Api call for statuses
  const get_rfq_status = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + '/enum/RFvendorreplyheaderstatus_external')
      .then(function (res) {
        if (res.data.status === 'success') {
          setRFQStatusList(res.data?.data)
          setLoading(false)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
        setLoading(false)
      })
  }

  const get_rfq_quotation_type = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + '/enum/RQ_type')
      .then(function (res) {
        if (res.data.status === 'success') {
          setRFQQuotationType(res.data?.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const get_rfq_attachments = (id) => {
    let api = Api
    api.setUserToken()
    if (id === null) return
    api
      .axios()
      .get(Settings.loginUrl + `/get_attachments/${id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          id !== null
            ? setRFQAttachments(res.data?.data)
            : setRFQAttachments(null)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const get_reply_actions = () => {
    let api = Api
    api.setUserToken()
    setLoading(true)
    api
      .axios()
      .get(Settings.loginUrl + `/enum/RFvendorreply_action`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setActions(res.data.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
        setLoading(false)
      })
  }

  const update_reply_actions = (type, event) => {
    event.event.preventDefault();
    const matchingAction = actions.find(
      (action) => action.name.toLowerCase() === type,
    )
    console.log(type,matchingAction)
    let data = {
      rfq_id: null,
      vendorreply_id: event?.data?.RFQ_vendorreply_id,
      action: null,
      day_hour: null,
      type: null,
      reason: null,
      vendors: null,
    }
    if (matchingAction) {
      const { id } = matchingAction
      console.log(id)
      data.action = id
    }

    console.log("data",data)
    let api = Api
    api.setUserToken()
    api
      .axios()
      .post(
        Settings.apiPurchaseRequisitionUrl + `/vendoraction_on_vendorreply`,
        data,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data.message, 'success')
          gridApi.refreshServerSide({purge:false})
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const handleBidRecall = (event) => {
    Helper.confirmMe(() => {
      update_reply_actions('recall',event)
    }, "If you recall your bid, you will be able to make changes before submitting it again. Please confirm to proceed with this action.", null, "Are you sure you want to recall bid?",400)
  }

  useEffect(() => {
    get_rfq_status()
    get_rfq_quotation_type()
    get_reply_actions()
  }, [location])

  const getAllAuctionDetails= (rfq_id) => {
    let api = Api
    api
      .axios()
      .get(`${Settings.apiPurchaseRequisitionUrl}/view_auction_bid/${rfq_id}`)
      .then((res) => {
        if (res.data.status === 'success') {
          setAuctionData(res.data?.data)
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, 'error')
      })
  }

  const handleGridReady= (params) => {
    gridApi = params.api
  }

  //On Column Click Event Handler
  const cellHandler = (event) => {
    if (event.colDef.field === 'document_number') {
      if(props.type==="auction"&&props?.auth?.user?.usertype===0){
         navigate(`/request-for-quotation-reply/${event?.data?.RFQ_vendorreply_id}`)
         return
      }else{
      navigate(
        '/rfq-vendor-reply/rfq-bidding-details?RFQ_vendorreply_id=' +
          event?.data?.RFQ_vendorreply_id,
      )
    }
    }
    if (event.colDef.field === 'attached') {
      setShowModal(true)
      let scrollY = window.pageYOffset || document.documentElement.scrollTop
      let mouseY = event.event.clientY
      let absoluteMouseY = mouseY + scrollY
      let mouseX = event.event.clientX
      let absoluteMouseX = window.innerWidth - mouseX - 30
      setPopUpPosition({ top: absoluteMouseY, right: absoluteMouseX })
      const attachedId = event.data?.RFQ_attachmentsource_id
      setRFQAttachments([])
      get_rfq_attachments(attachedId)
    }
    if (event.colDef.field === 'vendor_details') {
      setShowAuctionModal(true)
      let scrollY = window.pageYOffset || document.documentElement.scrollTop
      let mouseY = event.event.clientY
      let absoluteMouseY = mouseY + scrollY
      let mouseX = event.event.clientX
      let absoluteMouseX = window.innerWidth - mouseX - 30
      setPopUpPosition({ top: absoluteMouseY, right: absoluteMouseX })
      getAllAuctionDetails(event?.data?.RFQ_id)
    }
    if (event.colDef.field === 'interested_to_participate') {
      if(props.type==="auction"&&props?.auth?.user?.usertype===0){
        navigate(`/request-for-quotation-reply/${event?.data?.RFQ_vendorreply_id}`)
        return
     }else{
     navigate(
       '/rfq-vendor-reply/rfq-bidding-details?RFQ_vendorreply_id=' +
         event?.data?.RFQ_vendorreply_id,
     )
    }
    }
    if (event.colDef.field === 'recall_bid') {
      if(new Date() > new Date(event.data.auction_end_date_time)){
        return
      }
      handleBidRecall(event)
    }
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const closeAuctionModal = () => {
    setShowAuctionModal(false)
  }

  //column heading data
  let Columns_Headings = getRfqExternalColumns({
    language:props.language,
    rfqQuotationType:rfqQuotationType,
    rfqStatusList:rfqStatusList,
    type:props.type
  })


  if(props.type==="auction"){
    Columns_Headings = Columns_Headings?.filter((item)=>item.headerName !==  Helper.getLabel(
      props.language,
      'expiration_date_time',
      'Expiration date and time',
    ) )
    Columns_Headings = Columns_Headings.concat([{
      field:'rank',
      headerName:Helper.getLabel(props.language,"my_rank","My rank"),
      editable:false,
      minWidth:180,
      valueFormatter:(params)=>{return params?.value ?? "-"}
    },{
      field:'bid_amount',
      headerName:Helper.getLabel(props.language,"my_bid","My bid"),
      editable:false,
      minWidth:180,
      valueFormatter:(params)=>{return params?.value ?? "-"}
    },{
      field:'vendor_details',
      headerName:Helper.getLabel(props.language,"vendor_details","Competitor bidding details"),
      editable:false,
      minWidth:210,
      filter:false,
      sortable:false,
      cellRenderer: (params) => getBidStatus(false),
    }])
  }

  let additionalParams = getAdditionalParams()

  return (
    <div className={`${props.auth?.user?.usertype===1?'request_for_quotation_archive external-theme':''}`}>
      <MasterComonent>
        <div className="container-fluid">
          <NavigationHeder
            backUrl={"/dashboard"}
            hideBackButton={false}
            hideMoreBtn={true}
            title={props.type==="auction"?Helper.getLabel(props.language,'auction','Auction'):`Request for Quotation`}
          >
          </NavigationHeder>
          {loading ? (
            <SimpleLoading />
          ) : (
            <Fragment>
              {props.auth?.user?.usertype===1 && <ExtHeaderLine
                title={Helper.getLabel(
                  props.language,
                  'request_for_quotation',
                  'Request for Quotation',
                )}
              />}
              <div>
                 <AgGridNew
                  apiUrl={props?.type==="auction" && props.auth?.user?.usertype===0?`${Settings.apiPurchaseRequisitionUrl}/RFvendorreplyheader`:`${Settings.apiPurchaseRequisitionUrl}/external/RFQ`}
                  additionalParams={additionalParams}
                  filterAdditionalParams={additionalParams}
                  pagination={true}
                  columnDefs={Columns_Headings}
                  onGridReady={handleGridReady}
                  isDisabledCheckbox={true}
                  onCellClicked={cellHandler}
                  uniqueField={'RFQ_vendor_id'}
                  hideAddBtn={true}
                  height={500}
                  hideDeleteBtn={true}
                  gridId={`rfq-external-listing-page`}
               />
                {showModal && (
                  <RFQAttachmentPopup
                    closeModal={closeModal}
                    data={rfqAttachments}
                    language={props.language}
                    style={{
                      top: popUpPosition.top,
                      right: popUpPosition.right,
                      width:'500px'
                    }}
                  />
                )}
                {
                  showAuctionModal && <RFQAuctionPopup
                  closeModal={closeAuctionModal}
                  data={auctionData}
                  language={props.language}
                  style={{
                    top: popUpPosition.top,
                    right: popUpPosition.right,
                  }}   
                  />
                }
              </div>
            </Fragment>
          )}
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(RFQList)
