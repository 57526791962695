import React, { useState } from 'react'
import MasterComonent from '../Backend/MasterComonent'
import { connect } from 'react-redux'
import NavigationHeder from '../Navigations/NavigationHeder'
import Helper from '../../inc/Helper'
import MasterSidebar from '../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../Widgets/NoteAttachmentHistory'
import BlueCallToAction from '../Widgets/BlueCallToAction/BlueCallToAction'
import SimpleLoading from '../Loading/SimpleLoading'
import TotalWidget from '../Widgets/TotalWidget/TotalWidget'
import Button from '../inc/Button'
import ChargesDetails from "./ChargesDetails"
import { useLocation, useParams } from "react-router-dom"



const MiscellaneousCharges = (props) => {
 const [loading,setLoading]=useState(false)

 const {id}=useParams()
 const location = useLocation()

 

  // right side component
  const addComponentRight = () => {
    return (
      <MasterSidebar>
        <NoteAttachmentHistory
          notesource_integrator={id}
          attachmentsource_integrator={id}
          source_id={location?.state?.source_id}
          hideHistory={true}
        />
      </MasterSidebar>
    )
  }

  //top header
  const addComponentTop = () => {
    let items = [
      {
        title: Helper.getLabel(props.language, 'document_number', 'Document Number'),
        subtitle: location?.state?.document_number,
      },
      {
        title: Helper.getLabel(props.language, 'document_type', 'Document Type'),
        subtitle: location?.state?.document_type,
      },
      {
        title: Helper.getLabel(props.language, 'description', 'Description'),
        subtitle: location?.state?.description,
      },
    ]
   

    return (
      <div className="rfq_detail_form">
        <div className={props.auth.user.usertype===1?"rfq_details_external_action rfq-internal-blue":"rfq-internal-blue"}>
          <BlueCallToAction items={items} tags={null} />
        </div>
        <div className="mb-4" />
      </div>
    )
  }

  return (
    <div className={props.auth.user?.usertype===1?"request_for_quotation_detail_screen rfq-internal-screens":"request_for_quotation_detail_screen internal-screen-theme rfq-internal-screens"}>
      {loading ? (
        <SimpleLoading />
      ) : (
        <MasterComonent>
          <NavigationHeder
            title={`${Helper.getLabel(
              props.language,
              'miscellaneous_charges',
              'Miscellaneous Charges',
            )}`}
          >
            <TotalWidget source_id={location?.state?.source_id} integrator={id} />
          </NavigationHeder>
          <div className="tabs_default">
             <ChargesDetails addComponentRight={addComponentRight} addComponentTop={addComponentTop} id={id} source_id={location?.state?.source_id} editable={location?.state?.editable}/>
          </div>
        </MasterComonent>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(
  MiscellaneousCharges,
)
