import React, { Fragment,useState } from 'react'; 
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import Helper from '../../../inc/Helper';
import { useNavigate } from 'react-router-dom';
import FeedPopUp from './FeedPopUp';
import { getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../inc/Validation';

const UserFeed = ({  
  commentsBoxRequired, 
  commentsBtnRequired, 
  numberOfComments,
  fields,
  sourceName,
  item,
  setFeedData,
  get_feed_data
}) => {

  const [noteValue,setNoteValue]=useState('')
  const [attachments,setAttachments]=useState(false)
  const [commentLoad,setCommentLoad]=useState(false)

  const [displayedComments, setDisplayedComments] = useState([]);
  const [loadIncrement, setLoadIncrement] = useState(5);
  const [showLoadLess, setShowLoadLess] = useState(false);

  const navigate = useNavigate()

  const totalComments = item.notes?.notes?.length;


  const loadMoreComments = () => {
    const newLoadIncrement = loadIncrement + 5; 
    const newDisplayedComments = item.notes?.notes?.slice(0, newLoadIncrement);
    setLoadIncrement(newLoadIncrement);
    setDisplayedComments(newDisplayedComments);
    if (newLoadIncrement >= totalComments) {
      setShowLoadLess(true);
    }
  };
  const loadLessComments = () => {
    setLoadIncrement(2); 
    const initialComments = item.notes?.notes?.slice(0, 5);
    setDisplayedComments(initialComments);
    setShowLoadLess(false);
  };

  const handleAttachmentAcknowledgement=()=>{
    const data = {
      "attachment_acknowledge":true
    }
    setAttachments(true)
    if(item.attachment_reviewed===Boolean(true)) return
    postFeedData(data,"attachment_reviewed",data.attachment_acknowledge)
  }

  const closeAttachmentModal = () => {
    setAttachments(false)
  }

  const handleComments = () => {
    setCommentLoad(!commentLoad)
    const initialComments = item.notes?.notes?.slice(0, loadIncrement);
    setDisplayedComments(initialComments);
    setLoadIncrement(5)
  }

  const postFeedData=(data,key,value)=>{
    let api = Api
    api.setUserToken()
    api
        .axios()
        .post(Settings.apiUrl + `/acknowledge_feed/${item.feed_id}`,data)
        .then(function (res) {
          if (res.data.status === 'success') {
            key==="acknowledged"&&Helper.alert(res.data?.message, 'success')
            setFeedData((prevFeeds) =>
            prevFeeds.map((feed) =>
              feed.feed_id === item.feed_id
                ? { ...feed, [key]: value }
                : feed
            )
          );
          }
        })
        .catch((res) => {
          getPopupMessageBasedOnStatus(res) 
        })
  }
  const postNoteData=()=>{
    if(noteValue===''){
      return
    }
    const noteData = {
      "note":noteValue,
      "note_type":1,
      "source_id":item.source_id,
      "notesource_integrator":item.sourceintegrator_id,
    }
    let api = Api
    api.setUserToken()
    api
        .axios()
        .post(Settings.apiUrl + `/note`,noteData)
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data?.message, 'success')
            get_feed_data()
            setNoteValue('')
          }
        })
        .catch((res) => {
          getPopupMessageBasedOnStatus(res) 
        })
  }

  const getDocumentNumber = () => {
    if("document_number" in fields || "receiving_reference_number" in fields || "reference_number" in fields){
      if(item.source_id===Settings.source_id?.purchase_order || item.source_id === Settings?.source_id?.contract){
        return `# ${fields?.document_number}`
      }else if(item.source_id===Settings.source_id?.work_confirmation){
        return `# ${fields?.receiving_reference_number}`
      }else if (item.source_id===Settings.source_id?.invoice){
        return `# ${fields?.reference_number}`
      }else if (item.source_id===Settings.source_id?.RfqVendorReply){
        return fields?.RFQ_document_number?`# ${fields?.RFQ_document_number}` : null
      } else{
        return fields?.RFQ_document_number?`# ${fields?.document_number}`  : null
      }
    }
  }

  const handleFeedClick = () => {
    if(item.source_id===Settings.source_id?.purchase_order){
      navigate(`/purchase-order-details?purchase_order_id=${item.sourceintegrator_id}`)
    }else if(item.source_id===Settings.source_id?.invoice){
      navigate(`/invoice-details?invoice_id=${item.sourceintegrator_id}`)
    }else if(item.source_id===Settings.source_id?.work_confirmation){
      navigate(`/work-confirmation-details?shipment_id=${item.sourceintegrator_id}`)
    }else if(item.source_id===Settings?.source_id?.contract){
      navigate(`/contract-order-details?&purchase_order_id=${item.sourceintegrator_id}`)
    }else if(item.source_id===Settings?.source_id?.RfqVendorReply){
      navigate(`/rfq-vendor-reply/rfq-bidding-details?RFQ_vendorreply_id=${item.sourceintegrator_id}`)
    }else{
      return null
    }
  }

  const convertDate = (inputDate) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    const date = new Date(inputDate);
    return date.toLocaleDateString('en-US', options);
  };

  const handleSourceName = (item) => {
    if(item.source_id===Settings.source_id.RfqVendorReply){
      return "RFQ"
    }else if(item.source_id === Settings.source_id.contract){
      return "Contract Order"
    }else{
      return sourceName
    }
  }

  const UserFeedsWrapperModule = () => {
    return (
      <div className='user_feeds d-flex justify-content-start mt-3'>
        <div className='container-fluid p-0'>
          {UserFeedsCompanyInformation()}  
          {DocumentInformationDetails(fields)}
          {UserFeedsReactions()} 
          {UserComments()}
        </div>
      </div>
    )
  }

  const UserFeedsCompanyInformation = () => {
    return (
      <div className='row company_info'>
        <div className='col-12 d-flex justify-content-between align-items-center flex-row'>
          <button className='document_details p-0 bg-transparent border-0' onClick={handleFeedClick}>
            <h2> <span className='me-2'><img className='img-fluid' src='/images/icons/document-po.svg' alt=""/></span>{handleSourceName(item)} {getDocumentNumber()}</h2>
          </button>
        </div>
      </div>
    )
  }

  const DocumentInformationDetails = (fields={}) => {
    return ( // when api arrives, map it out
      <div className='document_info'>
        <div className='row'>
         {Object.entries(fields)?.map(([key,value])=>{
           return <div className='col-12'><h2><span style={{textTransform:"capitalize"}}>{key.replace(/_/g, " ")} : </span>{value}</h2></div>
         })}
        </div>
      </div>
    )
  }
  
  const UserFeedsReactions = () => {
    return (
      <div className='user_feeds_reactions'>
        {commentsBtnRequired && 
          <div className='user_comments' onClick={handleComments}>
            <img className='img-fluid' src='/images/icons/comms.svg' alt="" />
            <h2>{numberOfComments} Comments</h2>
          </div>
        }
          <div className='user_acknowledgement' style={{cursor:"pointer",position:"relative"}} onClick={handleAttachmentAcknowledgement}>
          <div style={{ position: "relative" }}>
            <img className='img-fluid' style={{ marginTop: "-3px",transform:"rotate(148deg)" }} src={'/images/icons/attachment.svg'} alt="Attachment" />
            {!objectIsEmpty(item.attachment) && item.attachment_reviewed!==Boolean(true)&&<div style={{ position: "absolute", top: "3px", right: "3px", width: "8px", height: "8px", borderRadius: "50%", backgroundColor: "#FC5E24" }}></div>}  
          </div>
          <h2 className={item.attachment_acknowledge===Boolean(true)?'acknowledge-title':''}>Attachments</h2>
          {attachments && <FeedPopUp attachment={item?.attachment} style={{position: 'absolute', left: '0', top: '85%',}} closeModal={closeAttachmentModal}/>}
        </div>
      </div>
    )
  }

  const UserComments = () => {
    const imageData = item.notes?.notes[0]?.image?.slice(2,-1)
    const imageUrl = `data:image/${item.notes?.notes[0]?.image};base64,${imageData}`;
    return (
      <>
      <div className='user_entity_textarea'>
        <div className='entity_logo'>
          <img className='img-fluid user-profile' src={objectIsEmpty(item.notes) || (item.notes?.notes[0]?.image===null)?"/images/blank-profile.jpg":imageUrl} alt=""/>
        </div>
        {commentsBoxRequired && 
          <div className='note_form'>
            <input type="text" placeholder='Enter your comments' value={noteValue} onChange={(e)=>{setNoteValue(e.target.value)}}/>
            <button onClick={postNoteData}><img src="/images/icons/paper-plane.svg" alt="Submit" /></button>
          </div>
        }
      </div>
      <div className='external_feed_comments'>
      {item.notes_count > 0 && commentLoad && <h3>Comments</h3>}
      {item.notes_count>0&&commentLoad&&
     displayedComments.map((comment)=>{
      const imageData = comment?.image?.slice(2,-1)
      const imageUrl = `data:image/${comment?.image};base64,${imageData}`;
       return <div className='user_entity_textarea'>
        <div className='entity_logo'>
          <img className='img-fluid user-profile' src={comment?.image===null?"/images/blank-profile.jpg":imageUrl}   alt=""/>
        </div>
          <div className='note_form comment-box'>
           <div className='d-flex justify-content-between notes-info'>
              <h2 className='user-name-style'>{comment?.createdby}</h2>
              <p>{convertDate(comment?.createddate)}</p>
           </div>
           <p>{comment?.note}</p>
          </div>
       </div>
      })}
     {item.notes_count > 0 && commentLoad && <>
          {showLoadLess ? (
              <div className='loading-btns' onClick={loadLessComments}>
              <p>Load Less Comments...</p>
            </div>
          ) : (
            totalComments > displayedComments.length && <div className='loading-btns' onClick={loadMoreComments}>
              <p>Load More Comments...</p>
            </div>
          )}
      </>}
      </div>
      </>
    )
  }

  return (
    <Fragment>{ UserFeedsWrapperModule() }</Fragment>
  )
}

export default UserFeed;