import React, { useState } from 'react'
import { connect } from 'react-redux'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings'
import LineViewComponent from '../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'
import { getNewValue } from '../../../grid/ag/commonFunctions/GridCommonFunctions'
import AlternateLines from './AlternateLines'
import SimpleLoading from '../../../Loading/SimpleLoading'



const ProposalLineGrid = (props) => {
  const [selectedRows,setSelectedRows]=useState([])
  const [loading,setLoading]=useState(false)
  
  const handleFetchData = (response) => {
    const data = response?.data?.data?.filter((item=>item.alternate_line===false))
    return {
      rowData: data?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: data?.length,
    }
  }
  
  const onLineGridReady = (grid) => {
    props.gridObj = grid
  }

  const onLineSelectionChanged = (event) => {
    setSelectedRows(event?.api?.getSelectedNodes())
    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    },500)
  }

  return (
    <div>
      <LineViewComponent
        defaultValues={{
          // requester_id: rfqVendorData?.requester_id,
        }}
        lineGridId='rfq-internal-lines'
        source_id={Settings.source_id?.RfqVendorReply}
        line_source={Settings.source_id?.RfqVendorReply}
        isEditable={false}
        lineGridTitle={Helper.getLabel(props.language, 'request_for_quotation_vendor_reply_lines', 'Request For Quotation Vendor Reply Lines')}
        header_id={props.rfq_vendor_id}
        purchase_status={null}
        document_status={null}
        delivery_date={null}
        onbehalf_request={false}
        vendor_id={null}
        vendorsite_id={null}
        hideAddBtn={true}
        hideMoreBtns={true}
        hideDeleteBtn={true}
        // requester_id={rfqVendorData?.requester_id}
        // orderer_id={rfqVendorData?.order_id}
        setOrderStatusProps={null}
        warehouse_id={null}
        taxgroup_item={null}
        taxgroup_id={null}
        viewType='version'
        version={props.version}
        formType='rfq'
        rfqExternal={true}
        billingrulegrid_id='normal'
        distributionGridId='distribution-normal'
        alternate_line={false}
        returnApiCondition={props.version === null}
        disableAllBtn={true}
        fetchData={handleFetchData}
        onLineGridReady={onLineGridReady}
        proposedQtyEnable={false}
        hideBillingRule={true}
        onLineSelectionChanged={onLineSelectionChanged}
      />
      {loading ? <SimpleLoading /> : <AlternateLines version={props.version} header_id={selectedRows[0]?.data?.line_id} />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(ProposalLineGrid)
