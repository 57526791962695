import React, { useEffect, useRef, useState } from "react";

import Helper from "../../inc/Helper";
import Settings from "../../inc/Settings";
import Api from "../../inc/Api";
import SimpleLoading from "../Loading/SimpleLoading";

import BlueInfo from "../../assets/BlueInfo.svg";
import TechnicalSelectedLinesModal from "./RFQDetailForm/TechnicalEvaluation/TechnicalSelectedLinesModal";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatepickerFunctions from "../../inc/DatepickerHelper";
import { getPopupMessageBasedOnStatus } from "../../inc/Validation";
import AwardingLinesGrid from "./RFQDetailForm/AwardingLinesGrid";

function RFQAwardingScreen({ language, rfqHeader }) {
  const [totalDetails, setTotalDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [vendorsData,setVendorsData]=useState([])
  const [vendors,setVendors]=useState([])

  const getAwardedLinesTotalDetails = () => {
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.apiPurchaseRequisitionUrl +
          `/get_awarded_lines_total/${rfqHeader?.RFQ_id}`
      )
      .then(function (res) {
        if (res.data?.status === "success") {
          setTotalDetails(res.data.data);
        }
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const getAwardingVendorsCardData = () => {
    let api = Api;
    api.setUserToken();
    setIsLoading(true)
    api
      .axios()
      .get(
        Settings.apiPurchaseRequisitionUrl +
          `/awarding_cards/${rfqHeader?.RFQ_id}`
      )
      .then(function (res) {
        if (res.data.status === "success") {
           setVendorsData(res.data.data)
           setIsLoading(false)
        }
      })
      .catch((res) => {
        setIsLoading(false)
      });
  };

  const getAllRfqVendors = () => {
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.apiPurchaseRequisitionUrl +
          `/get_vendors_of_RFQ/${rfqHeader?.RFQ_id}`
      )
      .then(function (res) {
        if (res.data.status === "success") {
           setVendors(res.data.data)
        }
      })
      .catch((res) => {
        
      });
  };

  useEffect(() => {
   getAwardedLinesTotalDetails()
   getAwardingVendorsCardData()
   getAllRfqVendors()
  }, []);

  const getDefaultColor = (index) => {
    const defaultColors = ["#fff0e4", "#E6EbFB", "#F7EDFE"];
    return defaultColors[index % defaultColors.length];
  };

  const getReasonForSelection = (entries) => {
    const type2Entry = entries?.find(entry => entry.scoreRFQ_type === 1 && entry.awarding===true);
    return type2Entry ? type2Entry?.reason || "-" : "-";
  };

  const getVendorReasonForSelection = (entries) => {
    const type2Entry = entries?.find(entry => entry.scoreRFQ_type === 0);
    return type2Entry ? type2Entry?.reason || "-" : "-";
  };

  const getAwardingLines = (entries) => {
    const type2Entry = entries?.find(entry => entry.scoreRFQ_type === 1 && entry.awarding===true);
    return type2Entry ? type2Entry?.award_to || 0 : "-";
  };

  const getVendorName = (item) => {
    const type2Entry = vendors?.find(vendor => vendor?.vendorreply_id === item?.rfq_vendorreply_id);
    return type2Entry ? type2Entry?.vendor_name || "-" : "-";
  };

  const getAwardedEvaluatorName = (entries) => {
    const type2Entry = entries?.find(entry => entry.scoreRFQ_type === 2);
    return type2Entry ? type2Entry?.evaluator_name || "-" : "-";
  };

  const lineRecommendationsRow = (entries) => {
    const showRow = entries?.some(entry => entry.line_recommendation > 0 && entry.scoreRFQ_type === 0); 
    if (!showRow) {
      return null;
    }
    const recommendedByNames = entries
      .filter(entry => entry.line_recommendation > 0 && entry.scoreRFQ_type === 0)
      .map(entry => entry.evaluator_name);
    
      return recommendedByNames 
  };

  const linesPreferred = (entries) => {
    const showRow = entries?.some(entry => entry.preferred > 0 && entry.scoreRFQ_type === 1);
    if (!showRow) {
      return null;
    }
    const preferredByNames = entries
      .filter(entry => entry.preferred > 0 && entry.scoreRFQ_type === 1)
      .map(entry => entry.evaluator_name);
    
      return preferredByNames 
  };
  
  
  return isLoading ? (
    <SimpleLoading />
  ) : (
   rfqHeader?.rfq_status!==3 && rfqHeader?.rfq_status !== 7 ? <div>Awarding is not initiated yet !</div> : <div className="awarding_parent">
      <AwardingLinesGrid language={language} apiUrl={ Settings.apiPurchaseRequisitionUrl + `/get_awarded_lines/${rfqHeader?.RFQ_id}`}/>
      <TotalAward totalDetails={totalDetails} />
      <div className="vendor_cards_container">
        {vendorsData?.map((item,index) => {
          let technicalScore = item?.entries?.find((item)=>{return item.scoreRFQ_type === 0})
          return (
            <VendorCard
              color={getDefaultColor(index)}
              language={language}
              rfqHeader={rfqHeader}
              vendorName={getVendorName(item)}
              totalView={Helper.formateCurrency(item.line_total_amounts?.subtotal)}
              reasonOfAwarding={getReasonForSelection(item.entries)}
              reasonOfVendorSelection={getVendorReasonForSelection(item.entries)}
              technicalScore={`${technicalScore?.total_score_in_percentage?.toFixed(2)} / ${100 || "-"}`}
              financialRanking={item.ranking || "-"}
              recommendedBy={lineRecommendationsRow(item.entries) || "-"}
              preferredBy={linesPreferred(item.entries)}
              awardedBy={getAwardedEvaluatorName(item.entries)}
              attachmentSourceId={item?.attachmentsource_id}
              vendorId={item?.rfq_vendorreply_id}
              awardTo={getAwardingLines(item.entries)}
              lineAmount={item?.line_total_amounts}
            />
          )
        })}
      </div>
      {/* <div style={{ margin: "50px 0" }}>COST ALLOCATION</div>
      <div className="footer_cards_container">
        <FooterCard
          heading="Available budget"
          value="66.3k"
          image={GreenBars}
          color="#C8FACD"
        />
        <FooterCard
          heading="Actual expenditure"
          value="43.7k"
          image={BlueBars}
          color="#CAFDF5"
        />
        <FooterCard
          heading="Reserve encumbrance"
          value="92.3k"
          image={GoldenBars}
          color="#FFF5CC"
        />
        <FooterCard
          heading="Budgetreservation for pre-encumbrance"
          value="92.3k"
          image={OrangeBars}
          color="#FFE9D5"
        />
      </div> */}
    </div>
  );
}

function TotalAward(totalDetails) {
  return (
      <div className="total_award_box">
        <div className="box_heading_style">Total Award</div>
        <div className="content_box">
          <div className="box">
            <div className="row">
              <span className="heading">Line amount</span>
              <span className="value">
                {Helper.formateCurrency(totalDetails?.totalDetails?.line_amount) ?? "-"}
              </span>
            </div>
            <div className="row">
              <span className="heading">Line discount</span>
              <span className="value">
                {Helper.formateCurrency(totalDetails?.totalDetails?.line_discount) ?? "-"}
              </span>
            </div>
            <div className="row">
              <span className="heading">Tax</span>
              <span className="value">{Helper.formateCurrency(totalDetails?.totalDetails?.tax) ?? "-"}</span>
            </div>
          </div>
          <div className="box">
            <div className="row">
              <span className="heading">Miscellaneous Charges</span>
              <span className="value">
                {Helper.formateCurrency(totalDetails?.totalDetails?.miscellaneous_charges) ?? "-"}
              </span>
            </div>
            <div className="row">
              <span className="heading">Total</span>
              <span className="value">
                {Helper.formateCurrency(totalDetails?.totalDetails?.subtotal) ?? "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
  );
}

function VendorCard({
  color,
  language,
  rfqHeader,
  vendorName,
  totalView,
  reasonOfAwarding,
  reasonOfVendorSelection,
  technicalScore,
  financialRanking,
  recommendedBy,
  awardedBy,
  attachmentSourceId,
  vendorId,
  awardTo,
  preferredBy,
  lineAmount,
  user
}) {
  const [showSelectedLinesModal, setShowSelectedLinesModal] = useState();
  const [attachments,setAttachments]=useState([])

  const [showTotalViewData, setShowTotalViewData] = useState(false);
  const [showAttachmentsData, setShowAttachmentsData] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);

  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setMaxHeight(contentRef.current.scrollHeight);
    }
  }, [showAttachmentsData,attachments]);

  const handleToggleTotalViewData = () => {
    setShowTotalViewData((prev) => !prev);
  };

  const handleToggleAttachmentsData = () => {
    setShowAttachmentsData((prev) => !prev)
    getRfqAttachments(attachmentSourceId)
  }

  const handleLineViewPopUp = () => {
    setShowSelectedLinesModal(true);
  };

  const getRfqAttachments = (attachmentSourceId) => {
    if (!attachmentSourceId) return;

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + `/get_attachments/${attachmentSourceId}?attachment_for=1`)
      .then(function (res) {
        if (res.data.status === "success") {
          setAttachments(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onDownloadClick = (id) => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + `/get_attachment/${id}`,{ responseType: 'blob' })
      .then(function (res) {
        if (res.status === 200) {
          const response = res
          const contentDisposition = response.headers['content-disposition']
          const fileName =
            contentDisposition?.split('filename=')[1] || 'dummy.jpg'
          const fileBlob = new Blob([response.data], {
            type: response.headers['content-type'],
          })
          const downloadLink = document.createElement('a')
          downloadLink.href = URL.createObjectURL(fileBlob)
          downloadLink.download = fileName

          document.body.appendChild(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  return (
    <>
      <div className='vendor_card' style={{ backgroundColor: `${color}` }}>
        <div className='heading_style'>{vendorName}</div>
        <div className='card_contents'>
          <div className='row' id='attachment_img_relative'>
            <span className='heading'>No. of Lines Awarded</span>
            <span className='heading'>
              <img
                src={BlueInfo}
                alt=''
                style={{ marginRight: '8px', cursor: 'pointer' }}
                onClick={handleLineViewPopUp}
                height={24}
                width={24}
                onKeyDown={() => {}}
              />{' '}
              {awardTo}
            </span>
          </div>
          <div className='row'>
            <span className='heading'>Financial Ranking</span>
            <span className='value'>{financialRanking}</span>
          </div>
         {rfqHeader?.rfq_type===2?<></>:<div className='row'>
            <span className='heading'>Technical Score in Percentage</span>
            <span className='value'>{technicalScore}</span>
          </div>}
          {rfqHeader?.rfq_type===2?<></>: <div className='row d-flex'>
            <span className='heading'>Recommended By</span>
            <div className=' d-flex flex-column align-items-end w-auto'>
              {Array.isArray(recommendedBy) && recommendedBy.length > 0
                ? recommendedBy?.map((name, index) => (
                    <span className='value' key={index}>
                      {name}
                    </span>
                  ))
                : '-'}
            </div>
          </div>}
          <div className='row d-flex'>
            <span className='heading'>Preferred By</span>
            <div className=' d-flex flex-column align-items-end w-auto'>
              {Array.isArray(preferredBy) && preferredBy.length > 0
                ? preferredBy?.map((name, index) => (
                    <span className='value' key={index}>
                      {name}
                    </span>
                  ))
                : '-'}
            </div>
          </div>
          <div className='row'>
            <span className='heading'>Awarded By</span>
            <span className='value'>{awardedBy}</span>
          </div>
          <div className='row'>
            <span className='heading'>Total view</span>
            <span className='value'>
              {totalView}{' '}
              <FontAwesomeIcon
                icon={showTotalViewData ? faChevronUp : faChevronDown}
                onClick={handleToggleTotalViewData}
                color='#2D74E0'
                style={{
                  width: '13px',
                  height: '13px',
                  marginBottom: '2px',
                  cursor: 'pointer',
                  marginLeft:"5px"
                }}
              />
            </span>
            <div className={`d-flex flex-column gap-1 mt-2 ${showTotalViewData?'show-vendor-data':'hide-vendor-data'}`}>
                <div className='d-flex justify-content-between'>
                  <span className='heading'>Line amount</span>
                  <span className='value'>{Helper.formateCurrency(lineAmount?.line_amount)}</span>
                </div>
                <div className='d-flex justify-content-between'>
                  <span className='heading'>Line discount</span>
                  <span className='value'>{Helper.formateCurrency(lineAmount?.line_discount)}</span>
                </div>
                <div className='d-flex justify-content-between'>
                  <span className='heading'>Tax</span>
                  <span className='value'>{Helper.formateCurrency(lineAmount?.tax)}</span>
                </div>
                <div className='d-flex justify-content-between'>
                  <span className='heading'>Miscellaneous Charges</span>
                  <span className='value'>{Helper.formateCurrency(lineAmount?.miscellaneous_charges)}</span>
                </div>
            </div>
          </div>
          {rfqHeader?.rfq_type===2?<></>:<div className='row flex-column gap-1'>
            <span className='heading'>Reason of Vendor Selection</span>
            <span className='value'>{reasonOfVendorSelection}</span>
          </div>}
          <div className='row flex-column gap-1'>
            <span className='heading'>Reason of Awarding</span>
            <span className='value'>{reasonOfAwarding}</span>
          </div> 
          <div className='row'>
            <span className='heading'>Attachments</span>
            <span className='value'><FontAwesomeIcon
                icon={showAttachmentsData ? faChevronUp : faChevronDown}
                onClick={handleToggleAttachmentsData}
                color='#2D74E0'
                style={{
                  width: '13px',
                  height: '13px',
                  marginBottom: '2px',
                  cursor: 'pointer',
                  marginLeft:"5px"
                }}
            /></span>
            <div ref={contentRef} className={`d-flex flex-column gap-2 mt-2  ${showAttachmentsData?'':'hide-vendor-data'}`} style={{maxHeight:showAttachmentsData?`${maxHeight}px`:0, opacity:showAttachmentsData?1:0,transition:'max-height 0.5s, opacity 0.5s'}}>
                {attachments?.map((item)=>(
                  <div className="d-flex justify-content-between align-items-center"> 
                    <div className="d-flex flex-column">
                      <h6 className="document-file-name download-file-style fw-bold" onClick={()=>onDownloadClick(item.attachment_id)}>{item?.filename}</h6>
                      <span>{item?.filesize.toFixed(3)} MB . {item?.attachmenttype_name} . {DatepickerFunctions.convertDateTimeFromDataBase(item.createddatetime)}</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {showSelectedLinesModal && (
        <TechnicalSelectedLinesModal
          rfqHeader={rfqHeader}
          language={language}
          setShowSelectedLinesModal={setShowSelectedLinesModal}
          type="awarding"
          id={vendorId}
          user={user}
        />
      )}
    </>
  )
}

function FooterCard({ heading, value, image, color }) {
  return (
    <div className="footer_card" style={{ backgroundColor: `${color}` }}>
      <div className="heading">{heading}</div>
      <div className="row">
        <div className="value">{value}</div>
        <img src={image} alt="" width={52} height={35} />
      </div>
    </div>
  );
}

export default RFQAwardingScreen;
