import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import Collapse from '../../inc/Collapse';
import RsDataLoader from '../../inc/RsDataLoader';
import AddressFormPopup from './AddressFormPopup';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getNewValue, getOptions, gridLinkValueFormat, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import SimpleLoading from '../../Loading/SimpleLoading';

let gridObj = null

const AddressList = (props) => {
  const [state, setState] = useState({
    isAccessable: true,
    dataExportable: props.dataExportable,
    selectedAddress: null,
    selectedNode: null,
    addressPopupOpen: false,
    addressSourc_id: props.addressSourc_id ? props.addressSourc_id : null,
    addresssourceintegrator: props.address_source_integrator ? props.address_source_integrator : null,
    source_id: props.source_id,
    source_name: null,
    gridAddressList: props.data ? props.data : [],
    loading:false,
  });

  const autoReload = props.autoReload;

  useEffect(() => {
    if (props.addressSourc_id && props.addressSourc_id !== state.addressSourc_id) {
      setState(prevState => ({
        ...prevState,
        addressSourc_id: props.addressSourc_id
      }));
    }
  }, [props.addressSourc_id]);

  const onAddAddressClickHandler = () => {
    const gridData = Helper.getDataFromGrid(gridObj);
    setState(prevState => ({
      ...prevState,
      gridAddressList: gridData,
      selectedAddress: {},
      addressPopupOpen: true
    }));
  };

  const onAddressPopupCloseHandler = () => {
    setState(prevState => ({
      ...prevState,
      selectedAddress: {},
      selectedNode: null,
      addressPopupOpen: false
    }));
  };

  const onAddressPopupClose = (address) => {
    setState(prevState => ({
      ...prevState,
      selectedAddress: {},
      selectedNode: null,
      addressPopupOpen: false
    }));
  };

  const onCellClicked = (event) => {
    if (event.colDef.field === 'description') {
      setState(prevState => ({
        ...prevState,
        selectedAddress: event.data,
        selectedNode: event,
        addressPopupOpen: true
      }));
    }
  };

  const onAddressDeleteHanlder  = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl + '/address/' + element?.data?.address_id)
      const rowNode = gridObj?.api?.getRowNode(element?.data?.address_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api?.applyServerSideTransaction(transaction)
      }, 300)
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const onAddressGridReady = (event) => {
    gridObj = event;
    if (props.onGridReady && typeof props.onGridReady === 'function') {
      props.onGridReady(event);
    }
  };

  const setGridData = (calbackFunction) => {
    const newData = calbackFunction(gridObj);
    setState(prevState => ({
      ...prevState,
      gridAddressList: newData
    }));
  };

  const onAddressCreate = (response) => {
    // if (autoReload) {
      if (response.status === 'Success') {
        setState(prevState => ({
          ...prevState,
          loading:true,
          addressSourc_id: response.address_source_id
        }));
        setTimeout(()=>{
          setState(prevState => ({
            ...prevState,
            loading:false,
            addressSourc_id: response.address_source_id
          }));
        },300)
      }
    // }

    if (props.onAddressCreate && typeof props.onAddressCreate === 'function') {
      props.onAddressCreate(response);
    }
  };

  const getPurposeNameFromId = (params) => {
    let purposeName = params.value;
    props.dataLoaderProps.purpose.purpose.forEach(item => {
      if (purposeName == item.id) {
        purposeName = item.name;
      }
    });
    return purposeName;
  };

  const canCreateEditDelete = () => {
    if (typeof props.canEdit === "undefined") {
      return true;
    }
    return (props.canEdit && props.canEdit === true);
  };

  const isViewOnly = () => {
    return !canCreateEditDelete();
  };

  const language = props.language;

  const Columns_Headings = [
    {
      field: 'description',
      headerName: Helper.getLabel(language, "description", 'Description'),
      cellRenderer: (params) => gridLinkValueFormat(params),
    },
    {
      field: 'purpose',
      headerName: Helper.getLabel(language, 11227, 'Purpose'),
      valueFormatter: getPurposeNameFromId,
      filter:'agSetColumnFilter',
      filterParams: {
      values: (params) => getOptions(params, props.dataLoaderProps.purpose.purpose),
      keyCreator: (params) => optionsKeyCreator(params, 'id'),
      valueFormatter: params => params.value.name,
      }
    },
    { field: 'country_name', headerName: Helper.getLabel(language, 11228, 'Country / Region')},
    { field: 'addressline_1', headerName: Helper.getLabel(language, 11229, 'Address line 1') },
    { field: 'is_primary', headerName: Helper.getLabel(language, 11230, 'Primary'), cellRenderer:"agCheckboxCellRenderer", cellRendererParams:{disabled:true},  filter: 'agSetColumnFilter', filterParams: { values: [true, false] } }
  ];

  const addressGridId = props.id ? props.id : 'address-grid_2';
    
  const conditionForOverlay = (response) => {
    return response?.data?.data?.address?.length === 0
  }

  const fetchData = (response) => {
    return {
      rowData:response.data?.data?.address?.length === 0 ? [] : response.data?.data?.address?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}` 
      })),
      rowCount:response.data?.data?.address?.length === 0?0 : response.data?.data?.address?.length,
    }
  }

  return (
    <Collapse title={Helper.getLabel(language, 11220, 'Address')} open={true}>
      <div className='tenant-address mt-1 mb-4'>
        {/* <AgGrid
          hideDelete={props.disableRecords}
          addBtnDissable={props.disableRecords}
          onGridReady={onAddressGridReady}
          dissableCheckBox={!canCreateEditDelete()}
          allBtnHide={!canCreateEditDelete()}
          onDelete={onAddressDeleteHanlder}
          onAddClick={onAddAddressClickHandler}
          onCellClicked={onCellClicked}
          data={state.gridAddressList}
          id={addressGridId}
          header={Columns_Headings}
          viewOnly={true}
          hideSave={true}
          dissableRowSelect={true}
        /> */}
        {state.loading ? <SimpleLoading /> : <AgGridNew 
          gridId={addressGridId}
          columnDefs={Columns_Headings}
          hideAddBtn={!canCreateEditDelete()}
          hideDeleteBtn={!canCreateEditDelete()}
          isAddBtnDisable={props.disableRecords}
          isDeleteBtnDisable={props.disableRecords} 
          onAddClick={onAddAddressClickHandler}
          handleDeleteSelectedRows={onAddressDeleteHanlder}
          apiUrl={Settings.apiUrl + '/get_address/' + state.addressSourc_id}
          allBtnHide={props.allBtnHide}
          onGridReady={onAddressGridReady}
          fetchData={fetchData}
          returnApiCondition={!state.addressSourc_id}
          uniqueField={"address_id"}
          onCellClicked={onCellClicked}
          conditionForOverlay={conditionForOverlay}
        />}
      </div>
      {state.addressPopupOpen &&
        <AddressFormPopup
          disableFields={props.disableRecords}
          adsGrid={gridObj}
          selectedNode={state.selectedNode}
          canEdit={props.canEdit}
          dataExportable={state.dataExportable}
          setGridData={setGridData}
          onAddressCreate={onAddressCreate}
          source_id={state.source_id}
          addressSourc_id={state.addressSourc_id}
          addressGridObj={gridObj}
          addresssourceintegrator={state.addresssourceintegrator}
          onPopupClose={onAddressPopupClose}
          address={state.selectedAddress}
          closeBtnClick={onAddressPopupCloseHandler}
        />
      }
    </Collapse>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    locations:state.locations
  };
};

export default connect(mapStateToProps)(RsDataLoader(AddressList, { purpose: true }));
