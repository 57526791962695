import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const OverlayCommon = ({toolTipText}) => {
  return (
    <OverlayTrigger
      placement='top'
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip style={{ zIndex: 100003, position: 'fixed', top: '10px', left: '10px' }}>{toolTipText}</Tooltip>}
    >
      <img src='/images/icons/info-circle-logo.svg' alt='info-blue-logo' style={{ cursor: 'pointer', marginLeft: '0.5rem', marginRight: "-1.4rem" }} />
    </OverlayTrigger>
  )
}

export default OverlayCommon
