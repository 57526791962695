import React, { useState } from 'react';
import RangeSlider from './RangeSlider'; 
import TogglerSwitchApplicable from './TogglerSwitchApplicable'; 
import { checkIfArrayIsEmpty } from '../../../../inc/Validation'; 
import TechnicalEvaluationCommonCalls from './TechnicalEvaluationCommonCalls';  

/*
props:

sectionName will be the heading of criteria's section, 
sectionMandatory will display asterik,  
commentsRequired are by-default false, but it could be set to true, 
List Of Vendors can be mapped, number of vendors will make more columns, we are setting a default 
example of implementation of such logics, then in future, we can adjust according to APIs
-

Example:  
vendors= {
    [
        {
            score_id:'range_vn_abc',
            score: 6,
            commentsRequired: true
        },
        {
            score_id:'range_vn_eabc',
            score: 7,
            commentsRequired: false
        },
        {
            score_id:'range_vn_attbc',
            score: 2,
            commentsRequired: true
        }, 
    ]
}

*/

let timeoutDeplayForCommentsAPIExecution;
let timeoutDeplayForApplicableAPIExecution;
const Sections = ({ sectionName, out_of_total_score, criteriaDescription, ArrayRFQMasterScoring, checkboxVal, RFQVendorsList, disableToggle, detailRFQId, sectionMandatory, vendors }) => { 
    const [vendorComments, setVendorComments] = useState(vendors.map((vendor) => vendor?.comment ?? ''));
    const [vendorSlider, setVendorSlider] = useState(vendors.map((vendor) => vendor?.score ?? 0));
    
    const handleCommentChange = (index, e, scorecriterialine_id) => {  
        let newComment = e;
        let updatedVendors = [...vendors];

        updatedVendors[index] = { ...updatedVendors[index], comment: newComment };
        vendors = updatedVendors;
    
        let updatedComments = [...vendorComments];
        updatedComments[index] = newComment;
        setVendorComments(updatedComments);

        clearTimeout(timeoutDeplayForCommentsAPIExecution);
        timeoutDeplayForCommentsAPIExecution = setTimeout(() => (debouncedApiCallForComments(newComment, vendorSlider[index], scorecriterialine_id)), 300);
    };
     
    const handleSliderChange = (e,index) => {
        let newValue = e;
        let updatedValues = [...vendorSlider];
        updatedValues[index] = newValue;
        setVendorSlider(updatedValues)
    };
 
    const onToggleValueChange = (toggleValue) => {  
        clearTimeout(timeoutDeplayForApplicableAPIExecution);
        timeoutDeplayForApplicableAPIExecution = setTimeout(() => (debouncedApiCallForApplicable(toggleValue)), 300);
    };

    const debouncedApiCallForComments = async (comments, score, scorecriterialine_id) => { 
        if(!scorecriterialine_id) return;
    
        const technicalEvaluationCommonCall = new TechnicalEvaluationCommonCalls();
        await technicalEvaluationCommonCall.updateVendorScoringOfTechnicalEvaluation(scorecriterialine_id, { comments: (comments), score: parseInt(score) });
    };

    const debouncedApiCallForApplicable = async (applicableVal) => {
        if (checkIfArrayIsEmpty(vendors)) return;
    
        const technicalEvaluationCommonCall = new TechnicalEvaluationCommonCalls();
    
        const updatePromises = vendors.map(async (vendor) => {
            let scorecriterialine_id = vendor?.scorecriterialine_id;
            return technicalEvaluationCommonCall.updateVendorScoringOfTechnicalEvaluation(scorecriterialine_id, { not_applicable: (applicableVal) });
        });
     
        await Promise.all(updatePromises);
    };
    return (
        <div className="row">
            <div className="column section-head fixed_column_criteria">
                <h1>
                    {sectionName}
                    {sectionMandatory && <span className="asterik_sign">*</span>}
                </h1>
                <p style={{display: 'flex', flexDirection:'column'}}>{criteriaDescription}</p>
                <div className='checkbox-applicable'>
                    <TogglerSwitchApplicable
                        onToggleValueChange={onToggleValueChange}
                        out_of_total_score={out_of_total_score}
                        disableToggle={disableToggle}
                        ArrayRFQMasterScoring={ArrayRFQMasterScoring} 
                        RFQVendors={vendors[0]?.allVendorsList}
                        title={'Not Applicable'} 
                        value={checkboxVal} 
                        name={`checkbox_field_rfq_${detailRFQId}`} 
                    /> 
                </div>
            </div>

            {
                vendors.map((item) => {
                    const index = RFQVendorsList.findIndex(
                        (val) => val?.reply_id === item?.reply_id
                    );

                    const matchingVendorScoring = index !== -1 ? RFQVendorsList[index] : null;

                    return {
                        index,
                        item,
                        matchingVendorScoring,
                    };
                }).map(({ index, item, matchingVendorScoring }) => {
                    return (
                        <div 
                            className={'column'}
                            data-name={item ? `criteria_${item?.id}` : ''}
                        > 
                            {matchingVendorScoring ? (
                                <>
                                    <RangeSlider
                                        currentComments={vendorComments[index]}
                                        out_of_total_score={out_of_total_score}
                                        scorecriterialine_id={item?.scorecriterialine_id}
                                        name={`range_slider_${item?.reply_id}`}
                                        allVendorsList={item?.allVendorsList}
                                        disableField={item?.disableFields}
                                        range_id={item?.score_id}
                                        state={item?.score}
                                        sliderValue={vendorSlider[index]}
                                        onSliderChange={(e) => handleSliderChange(e, index)}
                                    />
                                    <textarea
                                        name={`comment_field_rfq[][]`}
                                        value={vendorComments[index]}
                                        maxLength={100}
                                        disabled={item?.disableFields}
                                        onChange={(e) => handleCommentChange(index, e?.target?.value, item?.scorecriterialine_id)}
                                        className={`textarea-for-comments ${item.commentsRequired ? "required" : "default"}`}
                                    ></textarea>
                                </>
                            ) : (
                                null
                            )}
                        </div>
                    );
                })
            } 
        </div>
    );
};
 
export default Sections;