import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux' 
import Api from '../../../../inc/Api'
import Gui_id_list from '../../../../inc/Gui_id_list'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings' 
import MasterComponentWraper from '../../../Backend/MasterComponentWraper' 
import Alert from '../../../inc/Alert'
import ButtonNew from '../../../inc/ButtonNew'  
import NavigationHeder from '../../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import { getLabelFromDropdown, gridLinkValueFormat } from '../../../grid/ag/commonFunctions/GridCommonFunctions'
import VendorBusinessClassModal from '../Create/VendorBusinessClassModal'
import SimpleDropdown from '../../../grid/ag/cellEditor/SimpleDropdown'

let gridApi = null;
const BusinessArchive = (props) => { 
  const [businessClassId, setBusinessClassId] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false); 
  const [attachments,setAttachments]=useState([])

  useEffect(()=>{
    getAttachments()
  },[])
 
  const CellHandler = (event) => {   
    if(event.colDef.field === 'name') {
      setBusinessClassId(event?.data?.bussinessclassification_id);
      setShowCreateModal(true);
    }
  }

  const getAttachments = () => {
    let api = Api 
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/get_attachment_source/${Settings.source_id.vendor}`,{}).then(function(res){
      if(res.data.status==="success"){  
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.attachmenttype_id,
            label: element.name
          })
        })
        setAttachments(UPDATED_STATE) 
      }
    }).catch((res) => {
    })
  };
  
  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delBusinessClass_ID = element?.data?.bussinessclassification_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiVendorUrl+`/businessclassification/${delBusinessClass_ID}`)
      const rowNode = gridApi?.getRowNode(delBusinessClass_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200)
      gridApi?.refreshServerSide({ purge: true });
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);


  const reloadGrid = () => {
    gridApi?.refreshServerSide({ purge: true });
    gridApi?.deselectAll();
  };

  const onClickNewButton = () => {
    setShowCreateModal(true);
    setBusinessClassId(null);
  };

  const Columns_Headings = [
    { field:'name', headerName:Helper.getLabel(props.language,'name',"Name"), cellRenderer: gridLinkValueFormat },
    { field:'description', headerName:Helper.getLabel(props.language,'description',"Description"), valueFormatter:(params)=>{return params.value || "-"}},
    { field: 'attachmenttype_id',headerName:Helper.getLabel(props.language,'attachment_type',"Attachment type"),cellEditor:SimpleDropdown, cellEditorParams:{values:attachments}, editable:false, valueFormatter:(params)=>{return getLabelFromDropdown(params) || "-"} },
    { field:'attachment_mandatory_for_vendor', headerName:Helper.getLabel(props.language,'description',"Attachment mandatory for vendor"), cellRenderer:'agCheckboxCellRenderer', valueGetter: (params) => {
      if(!params?.data?.attachment_mandatory_for_vendor || !params?.data){
          return false
      }
      else {
          return params.data?.attachment_mandatory_for_vendor
      }
  }, cellRendererParams: { disabled: true },},
  { field:'past_date_allowed', headerName:Helper.getLabel(props.language,'past_date_allowed',"Past date allowed"), cellRenderer:'agCheckboxCellRenderer', valueGetter: (params) => {
    if(!params?.data?.past_date_allowed || !params?.data){
        return false
    }
    else {
        return params.data?.past_date_allowed
    }
}, cellRendererParams: { disabled: true },}
  ];

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.vendor_business_classification.vendors_business_classification)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  };

  return ( 
    <div className='vendors_business_classification_archive'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} backUrl='/master-data' title={Helper.getLabel(props.language,'vendors_business',"Vendors Business Classification")}>
          <ButtonNew onClick={onClickNewButton} title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.masterData.vendor_business_classification.vendors_business_classification_main_create_button)} /> 
        </NavigationHeder> 
 
        <AgGridNew
          apiUrl={Settings.apiVendorUrl+'/businessclassification'} 
          hideDeleteBtn={!security.canDelete(frontendIds.masterData.vendor_business_classification.vendors_business_classification_main_delete_button)}
          uniqueField={'bussinessclassification_id'}
          pagination={false}
          columnDefs={Columns_Headings}
          hideAddBtn={true}
          height={500}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={DeleteHandler}
          onGridReady={(params) => gridApi = params.api}
          gridId={"rs_vendors_business_class"}
        />  
      </div>
      {showCreateModal && <VendorBusinessClassModal reloadParentGrid={reloadGrid} vendor_business_id={businessClassId} setShowCreateModal={setShowCreateModal} attachments={attachments}/>}
    </div> 
  )
};
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendor_business_classification.vendors_business_classification
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(BusinessArchive, SecurityOptions))))