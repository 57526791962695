import React from 'react';
import RFQList from '../../components/External Screens/RFQ Vendor Reply/RFQList/RFQList';
import { connect } from 'react-redux';
import RFQHeaderListScreen from '../Request For Quotation/RFQHeaderListScreen';

const AuctionScreen = (props) => {
  return (
    props?.auth?.user?.usertype===0?<RFQHeaderListScreen rfqType={2} title={"Auction"} />:<RFQList type="auction" />
  );
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(AuctionScreen)
