import React, { Fragment, useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import FieldsRenderer from './FieldsRenderer'

const PriceDiscountTab = (props) => {

  //States For Dropdowns
    const [paymentTerms, setPaymentTerms] = useState([])
    const paymentTermDataRef = useRef([]);
  //End

  //API Calls Renderer
   useEffect(() => {
    PaymentTermsAPI()
   }, [])
  // End

  //API Reduction 
   let paymentTermsCalled = false
  //End

  
  // API Calls
  const PaymentTermsAPI = () => {
    if(paymentTermsCalled) {
      return;
    }
      let api = Api
      paymentTermsCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+'/paymentterm?active=true',{}).then(function(res){
      if(res.data.status==="success"){
        let PaymentARR = []
        res.data.data.forEach(item => {  
        PaymentARR.push({
          value: item.paymentterm_id,
          label: `${item.name}`
        })
      }) 
      setPaymentTerms(PaymentARR)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  const mapPaymentTermData = (item) => {
    return {
        text: `${item.name}`,
        id: item.paymentterm_id
    };
  }

  //End 

    let textBoxInputSettings = [ 
      { pagination: true, apiUrl: Settings.apiVendorUrl+'/paymentterm?active=true', allDataRef: paymentTermDataRef, mapFunction: mapPaymentTermData, reRenderRequired:true, placeHolder: Helper.getLabel(props.language,'select_payment_term',"Select payment terms"), disable: !props.isEditable, inputType: "dropdown", label: Helper.getLabel(props.language,'payment_term',"Payment terms"), options: 
        props?.state?.payment_term_id
          ? 
            [{
              value: props?.state?.payment_term_id,
              label: props?.state?.payment_term_name ?? paymentTermDataRef?.current?.find(item => parseInt(item.paymentterm_id) === parseInt(props?.state?.payment_term_id))?.name,
            }]
          : 
            paymentTerms,
        id:'payment_term_id', name:'payment_term_id'
      },
      {label:Helper.getLabel(props.language,'discount_percentage',"Discount percentage"), inputType: "number", max:100, name:'discount_percentage',disable:!props.isEditable,reRenderRequired:true},
      {label:Helper.getLabel(props.language,'discount_amount',"Discount amount"), inputType: "number", name:'discount_amount',disable:!props.isEditable,reRenderRequired:true},
      {label:Helper.getLabel(props.language,'rounding_amount',"Rounding amount"), inputType: "number", name:'rounding_amount',disable:!props.isEditable,reRenderRequired:true},
      {label:Helper.getLabel(props.language,'price_inclusive_of_tax',"Price inclusive of tax"), inputType: "checkbox", name:'price_include_tax' ,disable:!props.isEditable, reRenderRequired:true, className:'col-lg-12'},
    ]

  return (
    <Fragment>
        <FieldsRenderer fieldsArray={textBoxInputSettings} updateState={props.setState} currentState={props.state} />
    </Fragment>
  )
}
 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (PriceDiscountTab) 