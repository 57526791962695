import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";

export default class CommonAPIVendorCalls {
    api;

    constructor() {
      this.api = Api;
      this.api.setUserToken();
    } 

    async getVendorTypes() {
        const res = await this.api.axios().get(Settings.apiUrl + `/enum/vendortype`, {})
        const Updated_data = [];

        if (res.data.status === "success") {
            res.data.data.forEach((element) => {
                Updated_data.push({
                    value: element.id,
                    label: element.name
                })
            })

            return Updated_data
        }
        return Updated_data
    }

    async pushVendorsToDynamics (apiPayload) {
        const res = await this.api.axios().post(Settings.lynkaz_middleware_api, apiPayload);
        if (res.data.status === "success") return res.data;
        return res
    }

    async getVendorCurrencies() {
        const res = await this.api.axios().get(Settings.apiUrl + `/currency`, {})
        const Updated_data = [];

        if (res.data.status === "success") {
            res.data.data.forEach((element) => {
                Updated_data.push({
                    value: element.currency_id,
                    label: element.currency_name
                })
            })

            return Updated_data
        }
        return Updated_data
    }

    async getVendorGroups() {
        const res = await this.api.axios().get(Settings.apiVendorUrl+`/vendorgroup`,{})
        const Updated_data = [];

        if (res.data.status === "success") {
            res.data.data.forEach((element) => {
                Updated_data.push({
                    value: element.vendorgroup_id,
                    label: element.name
                })
            })

            return Updated_data
        }
        return Updated_data
    }
    
    async getVendorEntities() {
        const res = await this.api.axios().get(Settings.apiUrl+`/get_entities`)
        const Updated_data = [];

        if (res.data.status === "success") {
            res?.data?.data?.forEach((element) => {
                Updated_data?.push({
                    value: element.entity_id,
                    label: element.name
                })
            })

            return Updated_data
        }
        return Updated_data
    }
    
    async getVendorTaxGroups() {
        const res = await this.api.axios().get(Settings.apiUrl+`/taxgroup`,{})
        const Updated_data = [];

        if (res.data.status === "success") {
            res.data.data.forEach((element) => {
                Updated_data.push({
                    value: element.taxgroup_id,
                    label: element.name
                }) 
            })

            return Updated_data
        }
        return Updated_data
    }
    
    async getVendorOnHold() {
        const res = await this.api.axios().get(Settings.apiUrl+`/enum/vendoronholdoperation`,{})
        const Updated_data = [];

        if (res.data.status === "success") {
            res.data.data.forEach((element) => {
                Updated_data.push({
                    value: element.id,
                    label: element.name
                }) 
            })

            return Updated_data
        }
        return Updated_data
    }
  
    async getVendorPaymentTerms() {
        const res = await this.api.axios().get(Settings.apiVendorUrl+`/paymentterm?active=true`,{})
        const Updated_data = [];

        if (res.data.status === "success") {
            res.data.data.forEach((element) => {
                Updated_data.push({
                    value: element.paymentterm_id,
                    label: element.name
                }) 
            })

            return Updated_data
        }
        return Updated_data
    }

    async getVendorVersionEntities(vendorsite_id, version) {
        const res = await this.api.axios().get(Settings.apiVendorUrl + `/vendor_sites_entities_version/${vendorsite_id}/${version}`, {}); 
        if (res.data.status === "success") return res.data.data 
        return []
    }
}