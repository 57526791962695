import React, { useState, useEffect } from 'react';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import { Alert } from 'react-bootstrap';
import RsDataLoader from '../../inc/RsDataLoader';
import SimpleLoading from '../../Loading/SimpleLoading';
import NavigationHeder from '../../Navigations/NavigationHeder';
import CompanyAddress from './CompanyAddress';
import Documents from './Documents';
import GeneralInformation from './GeneralInformation';
import NavigationSteps from './NavigationSteps';
import ProcurementCategory from './ProcurementCategory';
import SuccessStep from './SuccessStep';
import TermsCondition from './TermsCondition';
import UserDeatils from './UserDetails';
import BankDetails from './BankDetails';
import WorkFlowCommonHeaderAPICalls from '../../Header/WorkflowCommonHeaderAPICalls';
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../inc/Validation';
import BusinessClassificationDetails from './BusinessClassification';
import NotesModal from './NotesModal';
import $ from "jquery"
import WorkFlowOffCanvas from '../../Header/WorkFlowOffCanvas';
import ContactPerson from './ContactPerson';
import axios from 'axios';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import UploadCataloguePopUp from './UploadCataloguePopUp';

let cancelTokenSource = axios.CancelToken.source();

function RegisterVendor(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [vendorTypes, setVendorTypes] = useState([]);
    const [data, setData] = useState({});
    const [activeStep, setActiveStep] = useState(1);
    const [paymentTerms, setPaymentTerms] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [completedSteps,setCompletedSteps]=useState([])
    const [taxGroup,setTaxGroup]=useState([])
    const [taxReasons,setTaxReasons]=useState([])
    const [files, setFiles] = useState([]);
    const [masterBank, setMasterBank] = useState([]);    
    const [vendorContacts, setVendorContacts] = useState([]);    
    const [workflowEligible, setWorkflowEligible] = useState(null)
    const [workflowExist, setWorkflowExist] = useState({})
    const [show, setShow] = useState(true);
    const [vendorBusinessGroup,setVendorBusinessGroup]=useState([])
    const [allAddress,setAllAddress]=useState([])
    const [allContacts,setAllContacts]=useState([])
    const [notesModal,setNotesModal]=useState(false)
    const [notesBtn,setNotesBtn]=useState(false)
    const [uploadBtn,setUploadBtn]=useState(false)
    const [uploadModal,setUploadModal]=useState(false)
    const [recallBtnHover,setRecallBtnHover]=useState(false)
    const [recallBtnPopup,setRecallBtnPopup]=useState(false)
    const [editBtnHover,setEditBtnHover]=useState(false)
    const [workflowActionObject,setWorkflowActionObject] = useState({})
    const [isTransitioning, setIsTransitioning] = useState(false); 
    const [newNotes,setNewNotes]=useState([])
    const [submitBtnApi,setSubmitBtnApi]=useState(false)
    const handleClose = () => setShow(false);


    const cookieKeySteps = `vendor_reg_step_${props.dataLoaderProps?.auth.user.user_id}`;
    const cookieCompletedSteps = `vendor_completed_steps_${props.dataLoaderProps?.auth.user.user_id}`
    const source_id = 6;
    let deserializedArray

    useEffect(() => {
        loadVendorTypes();
        loadAllCurrencies();
        loadAllPaymentTerms();
        loadAllTaxGroup();
        loadAllTaxReasons()
        const cookieKeyStepsValue = Helper.getCookie(cookieKeySteps) ? parseInt(Helper.getCookie(cookieKeySteps)) : 1;
        let retrievedArray = Helper.getCookie(cookieCompletedSteps)
        if (retrievedArray) {
             deserializedArray = JSON.parse(retrievedArray);
        }
        const cookieCompletedStepValue = Helper.getCookie(cookieCompletedSteps) ? deserializedArray : []
        setActiveStep(cookieKeyStepsValue);
        setCompletedSteps(cookieCompletedStepValue)
        laodVendorDeails();
    }, []);

    let updateData = {
        workflow_initiated:true,
    }

    let updateDataNew = {
        status: 1
    }

    const getWorkFlowCalls = async (id) => {
        const workflowCalls = new WorkFlowCommonHeaderAPICalls()
        const trigger = await workflowCalls.getWorkFlowTriggers()
        const mode = !checkIfArrayIsEmpty(trigger) && trigger.find(id => id.name == "Update").id 
        const eligibleWorkflow = await workflowCalls.checkWorkFlowForSpecificModule(6, props.dataLoaderProps.auth.user.tenant_id, mode)
        const workflowExist = await workflowCalls.getWorkFlowInstanceExist(props.dataLoaderProps.auth.user.tenant_id, 6, id)
        const workActionObject = await workflowCalls.getWorkFlowTaskActionsList(props.dataLoaderProps.auth.user.tenant_id,props.dataLoaderProps.auth.user.user_id,6, id)
        if(workflowExist){ 
          setWorkflowExist(workflowExist)
          setWorkflowEligible(eligibleWorkflow)
          setWorkflowActionObject(workActionObject)
        }
    }

    const handleUpdateVendorApiCall = async (id,isEdit=false) => {
      try {
        if (!data.vendor_id || data.status === 1 || (!objectIsEmpty(workflowExist) && data.work_initiated === true) || (!objectIsEmpty(workflowExist) && workflowExist?.instance_exist === true)) {
            goStep(activeStep + 1);
            return;
        }

        const hasIncompleteSteps = completedSteps.slice(0, -1).some(step => step === false);

        if (hasIncompleteSteps && !isEdit) {
            Helper.alert('Please ensure all steps are completed before submitting the vendor for approval.', 'error');
            return; 
        }
     
        let payload 
        let api = Api;

        if(isEdit){
          payload = {status:2,workflow_initiated:false}
        }else{
          payload = (!workflowExist?.instance_exist && workflowEligible) ? updateData : updateDataNew;
        }

        const res = await api.axios().put(Settings.apiVendorUrl + '/update_vendorstatus/' + id, payload,{ cancelToken: cancelTokenSource.token });

        if (res.data.status === 'success') {
            Helper.alert(res.data.message);
            if(!isEdit){
              updateOnLoadSteps(6, true);
              goStep(activeStep + 1);
            }else{
              setActiveStep(8)
            }
            laodVendorDeails();
        } else {
            Helper.alert(res.data.message, 'error');
        }
    } catch (error) {
        getPopupMessageBasedOnStatus(error);
    }
    }

    const updateVendorData = (id,isEdit=false) => {
      Helper.createDebouncedAPIFunction([async ()=>{return handleUpdateVendorApiCall(id,isEdit)}], setSubmitBtnApi, cancelTokenSource, false, 500000)();
    }

    const loadVendorTypes = () => {
        Api.setUserToken();
        Api.axios().get(Settings.apiUrl + '/enum/vendortype').then(res => {
            setVendorTypes(res.data.data);
        });
    };

    const loadAllCurrencies = () => {
        Api.setUserToken();
        Api.axios().get(Settings.apiUrl + '/currency').then(res => {
            setCurrencies(res.data.data);
        });
    };

    const loadAllPaymentTerms = () => {
        Api.setUserToken();
        Api.axios().get(Settings.apiVendorUrl + "/paymentterm?active=true").then(res => {
            setPaymentTerms(res.data.data);
        });
    };

    const loadAllTaxReasons = () => {
      Api.setUserToken();
      Api.axios().get(Settings.apiVendorUrl + "/vendorreason").then(res => {
          setTaxReasons(res.data.data);
      });
    };

    const loadAllTaxGroup = () => {
        Api.setUserToken();
        Api.axios().get(Settings.apiUrl+'/taxgroup').then(res => {
            if(res.data.status==="success"){
            let taxGroupData = []
              res.data.data.forEach(item=>{
                taxGroupData.push({
                        value: item.taxgroup_id,
                        label: item.name
                })
              })
            setTaxGroup(taxGroupData)
            }
        });
    };

    const loadFiles = async (attachmentsource_integrator) => {
        let api = Api;
        api.setUserToken();
        let source_id= 6;
        let apiUrl = Settings.apiUrl + '/get_attachments/'+source_id+'/'+attachmentsource_integrator;
        api.axios().get(apiUrl).then(function(res){
            if(res.data.status==="success"){
                setFiles(res.data.data)
            }
        })
    }

    const getVendorGridBanks = async (site_id) => {
        let api = Api;
        api.setUserToken();
        if(!site_id) return
        api
          .axios()
          .get(Settings.apiVendorUrl + `/vendorbank/${site_id}`)
          .then(function (res) {
            if (res.data.status === "success") {
              setMasterBank(res.data.data);
            }
          })
          .catch((res) => {
          });
      };

      const getVendorContacts = async (vendor_id) => {
        let api = Api;
        api.setUserToken();
        api
          .axios()
          .get(Settings.apiVendorUrl + `/vendorcontact/${vendor_id}`)
          .then(function (res) {
            if (res.data.status === "success") {
              setVendorContacts(res.data.data);
            }
          })
          .catch((res) => {
          });
      };

      const getBusinessClassifications = async (vendor_id) => {
        let api = Api;
        api.setUserToken();
        if(!vendor_id) return
        api
          .axios()
          .get(Settings.apiVendorUrl + `/vendorbusinessclassificationgroup/${vendor_id}`)
          .then(function (res) {
            if (res.data.status === "success") {
              setVendorBusinessGroup(res.data.data);
            }
          })
          .catch((res) => {
          });
      };
      
      const getAllAddress = async (address_source_id) => {
        if(!address_source_id) return
        let api = Api;
        api.setUserToken();
        api
          .axios()
          .get(Settings.apiUrl + `/get_address/${address_source_id}`)
          .then(function (res) {
            if (res.data.Status === "Success") {
              setAllAddress(res.data.data.address);
            }
          })
          .catch((res) => {
          });
      };

      const getAllContact = async (contact_source_id) => {
        if(!contact_source_id) return
        let api = Api;
        api.setUserToken();
        api
          .axios()
          .get(Settings.apiUrl + `/get_contact/${contact_source_id}`)
          .then(function (res) {
            if (res.data.Status === "Success") {
              setAllContacts(res.data.data.contact);
            }
          })
          .catch((res) => {
          });
      };

    
      const loadNotes = async (data) => {
        const api = Api;
        api.setUserToken();
        if(!data?.vendor_id) return

        let apiUrl

        if (data?.vendor_id && Settings?.source_id?.vendor) {
            apiUrl = Settings.apiUrl + `/note/${Settings?.source_id.vendor}/${data?.vendor_id}?note_type=${1}`;
        }

        api.axios()
            .get(apiUrl)
            .then((res) => {
                if (res.data.status === 'success') {
                    setNewNotes(res.data.data)
                }
            })
            .catch(() => {
            });
    };

    const goStep = (stepNumber) => {
        setActiveStep(stepNumber);
        Helper.setCookie(cookieKeySteps, stepNumber, 20);
    };

    const setVendorState = (newData) => {
        const stateData = { ...data, ...newData };
        if (newData.websiteurl) {
            stateData.website_url = newData.websiteurl;
        }
        if (newData.genname) {
            stateData.gen_name = newData.genname;
        }
        if (newData.category) {
            stateData.category = newData.category;
        }
        setData(stateData);
    };

    const laodVendorDeails = async () => {
        setIsLoading(true);
        Api.setUserToken();
        Api.axios().get(Settings.apiVendorUrl + '/get_vendor_by_user')
        .then(res => {
            const responseData = res.data.data;

            if (responseData.vendor_id) {
                return Api.axios().get(Settings.apiVendorUrl + `/vendorsites/${responseData.vendor_id}`)
                .then(async resSites => {
                    const firstSite = resSites.data?.data[0];

                    const newData = {
                        currency_id: firstSite?.currency_id,
                        taxgroup_id: firstSite?.taxgroup_id,
                        taxpayer_id: firstSite?.taxpayer_id,
                        taxregistration_number: firstSite?.taxregistration_number,
                        paymentterm_id: firstSite?.paymentterm_id,
                        vendorsite_id: firstSite?.vendorsite_id,
                        exempted_from_tax:firstSite?.exempted_from_tax,
                        reason_for_tax_exemption:firstSite?.reason_for_tax_exemption
                    };
                    
                    const combinedData = {
                        ...responseData,
                        ...newData,
                    };
                    setVendorState(combinedData)
                    await loadFiles(responseData?.vendor_id)
                    await getVendorGridBanks(firstSite?.vendorsite_id)
                    await getVendorContacts(responseData?.vendor_id)
                    await getBusinessClassifications(responseData?.vendor_id)
                    await getAllAddress(responseData?.addresssource_id)
                    await getAllContact(responseData?.contactsource_id)
                    await loadNotes(responseData)
                    setIsLoading(false)
                    await getWorkFlowCalls(responseData?.vendor_id)
                });
            } else {
                setVendorState(responseData);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const saveVendorData = (nextStep) => {
        setIsLoading(true);
        const api = Api;
        const vendorData = {
            code: data.code,
            name: data.name,
            alternate_name: Helper.getNullableValue(data.alternate_name),
            legacy_code: Helper.getNullableValue(data.legacy_code),
            vendor_type: Helper.getNullableValue(data.vendor_type),
            onetime: false,
            dunsnumber: Helper.getNullableValue(data.dunsnumber),
            yearestablished: "2022-1-01",
            incorporationyear: "2022-1-01",
            website_url: Helper.getNullableValue(data.website_url),
            externalcode: Helper.getNullableValue(data.externalcode),
            linkedin: Helper.getNullableValue(data.linkedin),
            facebook: Helper.getNullableValue(data.facebook),
            twitter: Helper.getNullableValue(data.twitter),
            instagram: Helper.getNullableValue(data.instagram),
            youtube: Helper.getNullableValue(data.youtube),
            notesource_id: null,
            attachmentsource_id: null,
            processinstance_id: null,
            translationsource_id: null,
            addresssource_id: null,
            contactsource_id: null,
        };

        api.axios().post(Settings.apiVendorUrl + '/', vendorData).then(res => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        });
    };

    useEffect(()=>{
      getVendorGridBanks(data?.vendorsite_id)
      getBusinessClassifications(data?.vendor_id)
      loadFiles(data?.vendor_id)
    },[data?.category?.length])

    useEffect(()=>{
        getStepCompltedSteps()
    },[data?.category?.length,files,masterBank,vendorBusinessGroup,allAddress,allContacts])

    // const updateCompletedSteps = (stepIndex, isCompleted) => {
    //     const updatedSteps = [...completedSteps];
    //     updatedSteps[stepIndex] = isCompleted;
    //     console.log('2')
    //     setCompletedSteps(updatedSteps);
    //     const array = JSON.stringify(updatedSteps)
    //     Helper.setCookie(cookieCompletedSteps,array,365)
    // };

    const updateOnLoadSteps = (stepIndex,isCompleted) => {
         setCompletedSteps(prevCompletedSteps => {
            const updatedSteps = [...prevCompletedSteps];
            updatedSteps[stepIndex] = isCompleted;
            const array = JSON.stringify(updatedSteps);
            Helper.setCookie(cookieCompletedSteps, array, 365); 
            return updatedSteps;
        });
    }

    const getStepCompltedSteps = () => {
        const completedSteps2 = [];
        const checkStepCompletion = (step, condition) => {
          if (condition) {
            completedSteps2.push(step);
            updateOnLoadSteps(step, true);
          } else {
            updateOnLoadSteps(step, false);
          }
        };   
          
        checkStepCompletion(0, data.vendorsite_id !== null && data.vendorsite_id !== undefined);
        checkStepCompletion(1, allAddress?.length > 0 || allContacts?.length > 0);
        checkStepCompletion(2, vendorContacts?.length >= 1 )
        checkStepCompletion(3, vendorBusinessGroup?.length >= 1);
        checkStepCompletion(4, masterBank?.length >= 1);
        checkStepCompletion(5, data?.category?.length >= 1);
        checkStepCompletion(6, files?.length >= 1);
        checkStepCompletion(7, data.agree_on_terms_condition);
        checkStepCompletion(8, data?.document_status !== undefined && data.document_status !== 'Draft');
      
        return completedSteps2;
      };    
    const getStepComponent = (step) => {
        const stepProps = {
            ...props,
            setVendorState: setVendorState,
            data: data,
            activeStep: step,
            goStep: goStep,
            updateStep: updateOnLoadSteps,
            currencies: currencies,
            vendorTypes: vendorTypes,
            paymentTerms:paymentTerms,
            taxGroup:taxGroup,
            taxReasons:taxReasons,
            cookieName:cookieCompletedSteps,
        };

        if (isLoading) {
            return <SimpleLoading />;
        }

        switch (step) {
            case 1:
                return <GeneralInformation {...stepProps} loadVendorDetails={laodVendorDeails} />;
            case 2:
                return <CompanyAddress {...stepProps} source_id={source_id} loadVendorDetails={laodVendorDeails} />;
            case 3:
                return <ContactPerson {...stepProps} source_id={source_id} needButtons={true} />;
            case 4:
                return <BusinessClassificationDetails {...stepProps} source_id={source_id} getBusinessClassifications={getBusinessClassifications} />
            case 5:
                return <BankDetails {...stepProps} masterBank={masterBank} setMasterBank={setMasterBank} completedSteps={completedSteps}  />;
            case 6:
                return <ProcurementCategory {...stepProps} loadVendorDetails={laodVendorDeails} />;
            case 7:
                return <Documents {...stepProps} source_id={source_id} updateVendorData={()=>updateVendorData(data?.vendor_id)} attachmentsource_integrator={data?.vendor_id?data?.vendor_id:null}  />;
            case 8:
                return <TermsCondition {...stepProps} saveData={saveVendorData} updateVendorData={()=>updateVendorData(data?.vendor_id)}/>;
            case 9:
                return <SuccessStep {...stepProps} />;
            default:
                return <div>404!</div>;
        }
    };

    const handleNotesClose = () => {
        setNotesModal(false)
        setIsTransitioning(true)
        loadNotes(data)
        $('html').removeClass('rs_popup_open');
    }

    const handleUploadModalClose = () => {
        setUploadModal(false)
        $('html').removeClass('rs_popup_open');
    }

    const reloadWorkFlowFunction = () => {
      laodVendorDeails()
      setActiveStep(8)
    }

    const language = props.dataLoaderProps.language;
    const activeStepValue = activeStep;
    const dataValue = data;
    const completedStepsValues = completedSteps

    return (
      <div className='join_to_vendor container-fluid external-theme'>
        <NavigationHeder hideBackButton={true}
          title={Helper.getLabel(
            language,
            'vendor_registration',
            'Vendor Registration'
          )}
          hideMoreBtn={true}
        >
        {/* <button className='border-0 bg-transparent vendor-notes-btn' onMouseEnter={()=>{setUploadBtn(true)}} onMouseLeave={()=>{setUploadBtn(false)}} onClick={()=>{setUploadModal(true)}}><FontAwesomeIcon icon={faUpload} color={uploadBtn ? "" : "#868686"} style={{width:"14px"}} /> Upload catalogue</button> */}
        {data?.document_status === "Rejected" && <button className="border-0 bg-transparent vendor-notes-btn" onClick={()=>{updateVendorData(data?.vendor_id,true)}} onMouseEnter={()=>{setEditBtnHover(true)}} onMouseLeave={()=>{setEditBtnHover(false)}}><FontAwesomeIcon icon={faEdit} color={editBtnHover?"#2D74E0":"#868686"} style={{width:"14px"}} /> Edit</button>}
        {data?.document_status === "Under Review" && <button className="border-0 bg-transparent vendor-notes-btn" onClick={()=>{setRecallBtnPopup(true)}} onMouseEnter={()=>{setRecallBtnHover(true)}} onMouseLeave={()=>{setRecallBtnHover(false)}}><img className='img-fluid mx-2' style={{height:"60%"}} src={recallBtnHover?'/images/icons/refresh-blue.svg':'/images/icons/refresh-grey.svg'} alt="" />Recall</button>}
        <button className='border-0 bg-transparent vendor-notes-btn d-flex align-items-center gap-1' onMouseEnter={()=>{setNotesBtn(true)}} onMouseLeave={()=>{setNotesBtn(false)}} onClick={()=>{setNotesModal(true)}}><img className='img-fluid mt-1' src={notesBtn?'/images/icons/commsblue.svg':'/images/icons/comms.svg'} alt="" /> Comments {newNotes?.new_note_added && <div className='attachment-module-highlighter' style={{background:"#FC5E24"}}></div>}</button>
        </NavigationHeder>
        {data && data?.document_status !== "Draft" && show  && !isLoading && (
          <Alert variant='dark' onClose={handleClose} dismissible>
            <p className='mb-0'>You can't make changes to the form right now except document upload because the vendor is currently <span className='fw-bolder'>{data?.document_status?.replace("_"," ")}</span>. </p>
          </Alert>
        )}
        {/* <h3 className='jon_page_title'>
          Welcome to {props.dataLoaderProps?.auth?.user?.tenant_name}
        </h3> */}
       { /** <AlertNew
          type='warning'
          icon='/images/icons/info-yellow.svg'
          message='Organize your profile by completing few steps from the registration process.'
        /> **/}
        <UserDeatils data={dataValue} {...props} vendorTypes={vendorTypes} />
        <div className='d-flex gap-3'>       
          <NavigationSteps
            goStep={goStep}
            data={data}
            activeStep={activeStepValue}
            completedSteps={completedStepsValues}
          />
        <div className='join_vendor_form w-100'>
          {getStepComponent(activeStepValue)}
        </div>
        </div>
        {notesModal && <NotesModal handleNotesClose={handleNotesClose} vendor_id={data?.vendor_id} isOpen={notesModal} isTransitioning={isTransitioning} setIsTransitioning={setIsTransitioning}/>}
        {recallBtnPopup &&
          <WorkFlowOffCanvas
            isOpen={recallBtnPopup}
            type={{ label: "Recall", value: 6 }}
            closeSidebar={setRecallBtnPopup}
            source_id={6}
            source_item_id={data?.vendor_id}
            workFlowActionObject={workflowActionObject}
            reloadFunction={reloadWorkFlowFunction}
          />
        }
        {uploadModal && 
          <UploadCataloguePopUp
            handleUploadCatalogueModalClose={handleUploadModalClose}
          />
        }
        {submitBtnApi ? <OverlayLoader /> : null}
      </div>
    )
}

export default RsDataLoader(RegisterVendor);
