import React from "react"; 
import TermConditionsTemplates from "../../../External Screens/Request For Quotation/Terms Conditions/TermConditionsTemplates"; 
import Settings from "../../../../inc/Settings";

const ContractTerms = ({ transactional_document_type, sourceintegration_id, disableComponent, rfqType }) => { 
  return (
    <TermConditionsTemplates transactional_document_type={transactional_document_type} disableComponent={disableComponent} TransactionalLevel={true} sourceintegration_id={sourceintegration_id}  module_source_id={rfqType===2?Settings.source_id.auction:Settings.source_id.request_for_quotation} />
  );
};

export default ContractTerms;