import React, { Fragment, useState, useEffect,useRef } from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import FieldsRenderer from './FieldsRenderer'
import RsDataLoader from '../../inc/RsDataLoader'
import NewInputDatePicker from '../../Forms/NewInputDatePicker'
import Dropdown from '../../Forms/Dropdown'
import $ from 'jquery';
import Input from '../../Forms/Input'
import Checkbox from '../../Forms/Checkbox'
const SetupTab = (props) => {

  const useRefData = useRef({entity:false,site_loaded:false,warehouse_loaded:false,site:false,sitePageNumber:1,warehousePageNumber:1,parentStateChanegTimmer:null});
  //States For Dropdowns
  const [buyerGroup, setBuyerGroup] = useState([])
  const employeesData = useRef([])
  const [currency, setCurrency] = useState([])
  const [sitesList, setSitesList] = useState([])
  const [warehouseList, setWarehouseList] = useState([])
  const [classificationList,setClassificationList]=useState([])
  const [setupState, setSetupState] = useState(props.state)
  //End

  const sitesData = useRef([])
  const warehouseData = useRef([])
  const allClassificationsData = useRef([])

  //API Calls Renderer
    useEffect(() => {
        CurrencyAPI() 
        BuyerGroupAPI()
        ClassificationsAPI()
        if(!useRefData.current?.site_loaded){
          useRefData.current.site_loaded = true;
          loadSiteById(props.state?.site_id)
        }
        if(!useRefData.current?.warehouse_loaded){
          useRefData.current.warehouse_loaded = true;
          warehouseLoadById(props.state?.warehouse_id)
        }
        
        
    }, [])
  // End

  //API Reduction 
   let buyerGroupCalled = false
   let CurrencyCalled = false 
  //End


  // API Calls

  const loadSiteById = (rsSiteId) => {
      if(!rsSiteId){
        return;
      }
      let api = Api
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/site/'+rsSiteId).then(function(res){
      if(res.data.status==="success"){
        setSitesList( (prevDatea) => {
          let sitleResData = res.data.data;
          prevDatea.push({
            label:sitleResData?.code + ' - ' + sitleResData?.name,
            value:sitleResData?.site_id
          })
          return prevDatea;
        })
      } 
      
    })
  }
  const warehouseLoadById = (rsWarehouseId) => {
      if(!rsWarehouseId){
        return;
      }
      let api = Api
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/warehouse/'+rsWarehouseId).then(function(res){
      if(res.data.status==="success"){
        setWarehouseList( (prevDatea) => {
          let warehouseResData = res.data.data;
          prevDatea.push({
            label:warehouseResData?.code + ' - ' + warehouseResData?.name,
            value:warehouseResData?.warehouse_id
          })
          return prevDatea;
        })
      } 
      
    })
  }
  // API Calls
  const BuyerGroupAPI = () => {
    if(buyerGroupCalled) {
      return;
    }
      let api = Api
      buyerGroupCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiItemUrl+'/item_buyergroup',{}).then(function(res){
      if(res.data.status==="success"){
          let ITEMBUYERGROUP = []
          res.data.data.forEach((item) => {
             ITEMBUYERGROUP.push({
                value: item.itembuyergroup_id,
                label: item.name
             })
          })
          setBuyerGroup(ITEMBUYERGROUP)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }


  const CurrencyAPI = () => {
    if(CurrencyCalled) {
      return;
    }
      let api = Api
      CurrencyCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/currency',{}).then(function(res){
      if(res.data.status==="success"){
        let CurrencyARR = []
        res.data.data.forEach(item => {  
        CurrencyARR.push({
          value: item.currency_id,
          label: `${item.currency_code} - ${item.currency_name}`
        })
      })
      setCurrency(CurrencyARR)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  const ClassificationsAPI = () => {
    if(props.type !== "purchase agreement"){
        return;
    }
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseOrderUrl+'/PA_classification').then(function(res){
      if(res.data.status==="success"){
        let CLASSIFICATIONARR = []
        res.data.data.forEach(item => {  
        CLASSIFICATIONARR.push({
          value: item.pa_classification_id,
          label: item.name
        })
       }) 
       setClassificationList(CLASSIFICATIONARR)    
      } 
  }).catch((res) => { 
  })
}
 
  //End
    let textBoxInputSettings = [ 
        {label:Helper.getLabel( props.language,'buyer_group',"Buyer group") ,options: buyerGroup, inputType: "dropdown", name:'buyer_group_id', id:'buyergroup_id',disable:!props.isEditable,placeHolder:Helper.getLabel(props.language,'select_buyer_group',"Select buyer group")},
        // {label:Helper.getLabel(props.language,'requester',"Requester"), options: props.requester, inputType: "dropdown",name:'requester_id', id: 'requester_id',disable:!props.isEditable,placeHolder:Helper.getLabel(props.language,'select_requester',"Select requester")},
        // {label:Helper.getLabel(props.language,'orderer',"Orderer"), options: props.ordered, inputType: "dropdown",name:'orderer_id', id:'orderer_id',disable:!props.isEditable,placeHolder:Helper.getLabel(props.language,'select_orderer',"Select orderer")},
        {label:Helper.getLabel(props.language,'currency_id',"Currency"), required: true, options:currency, inputType: "dropdown", name:'currency_id', id:'currency_id',disable:!props.isEditable,placeHolder:Helper.getLabel(props.language,'select_currency',"Select currency")},
        // {label:Helper.getLabel(props.language,'allow_access_only_to_buyer_group',"Allow access only to buyer group"), inputType: "checkbox", name:'allow_access_to_only_buyer_group',disable:!props.isEditable},
        {label:Helper.getLabel(props.language,'email',"Email"), inputType: "text", name:'emailaddress',disable:!props.isEditable,maxLength:80},
        {label:Helper.getLabel(props.language,'external_reference',"External reference"), inputType: "text", name:'external_reference',disable:!props.isEditable,maxLength:255},
        //{label:Helper.getLabel(props.language,'entity',"Entity"), required: true, options: props.entities, inputType: "dropdown", name:'entity_id', id: 'entity_id',disable:!props.isEditable},
        //{label:Helper.getLabel(props.language,'site',"Site"), required: true, options:props.sites, inputType: "dropdown", name:'site_id', id: 'site_id',disable:!props.isEditable}, 
        //{label:Helper.getLabel(props.language,'warehouse',"Warehouse"), required: true, options: warehouse,  inputType: "dropdown",disable:!props.isEditable, className:'col-lg-4', name:'warehouse_id', id: 'warehouse_id'}, 
    ]
    const onDateChangeHanlder = (value,name) => {
        props.setState((prevState) => {
          return {
              ...prevState,
              [name]:value
          }
      })
    }
  const onEntityChangeHandler = (event) => {
    props.setState((prevState) => {
          return {
              ...prevState,
              [event.target.name]:event.target.value,
              site_id:'',
              warehouse_id:'',
          }
      })
  }
  const onSiteChangeHandler = (event) => {
    
    props.setState((prevState) => {
          return {
              ...prevState,
              [event.target.name]:event.target.value,
              //site_id:'',
              warehouse_id:'',
          }
    })
  }
  const onWarehouseChangeHandler = (event) => {
    props.setState((prevState) => {
          return {
              ...prevState,
              [event.target.name]:event.target.value,
              //site_id:'',
              //warehouse_id:'',
          }
      })
  }
  const inputChangeHandler = (event) => {
    setSetupState( (prevStateData) => {
      return{
        ...prevStateData,
        [event.target.name]:event.target.value
      }
    })
    clearTimeout(useRefData.current.parentStateChanegTimmer);
    useRefData.current.parentStateChanegTimmer = setTimeout(function(){
      props.setState((prevState) => {
        return {
            ...prevState,
            [event.target.name]:event.target.value
        }
    })
    },150)
    /*
   
    */
  }
  const checkboxChangeHandler = (name,value) => {
    props.setState((prevState) => {
          return {
              ...prevState,
              [name]:value == 'on' ? true : false
          }
      })
  }
 
  const mapEmployeesData = (item) => {
    return {
      id: item.employee_id,
      text: item.displayname,
    };
  }

  const mapSitesData = (item) => {
    return {
      text: `${item.code} - ${item.name}`,
      id: item.site_id
    };
  }

  const mapWarehouseData = (item) => {
    return {
      text: `${item.code} - ${item.name}`,
      id: item.warehouse_id
    };
  }

  const mapClassificationsList = (item) => {
    return {
      text: item.name,
      id: item.pa_classification_id
    };
  }

  const handleOnChange = (e) => {
    let value = e.target.value
  
    props?.setState(prev => {
      const updatedPOState = { ...prev, [e.target.name]: value };
      if (e.target.name === "requester_id") {
        const requesterName = employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(value))?.displayname;
        updatedPOState.requester_name = requesterName;
      }
      if (e.target.name === "orderer_id") {
        const ordererName = employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(value))?.displayname;
        updatedPOState.orderer_name = ordererName;
      }
      return updatedPOState;
    });
  }

  const siteSelect2Settings = {
      ajax: {
        url: (siteParams) => {
          useRefData.current.sitePageNumber = siteParams.page || 1;
          let rsDepEntityId = props.state?.entity_id;
          let url = Settings.apiUrl;
          if(rsDepEntityId){
            url += `/get_site?entity_id=${rsDepEntityId}`
          }
          return url
        },
        dataType: 'json',
        processResults: function(data) {
          let isMorePageExist = ( data.total_records - ( useRefData.current.sitePageNumber * Settings.dropdownPageSize )) >0 ? true : false ; 
          return {
            pagination: {
              more: isMorePageExist
            },
            results: data.data.map(function(item) {
              return {
                text: `${item.code} - ${item.name}`,
                id: item.site_id
              };
            })
           
          };
        }
      }
  }
  const warehouseSelect2Settings = {
      ajax: {
        url: (warehosueParams) => {
          useRefData.current.warehousePageNumber = warehosueParams.page || 1;
          let rsDepsite_id = props.state?.site_id;
          let url = Settings.apiUrl;
          if(rsDepsite_id){
            url += `/get_warehouse?site_id=${rsDepsite_id}`
          }
          return url
        },
        dataType: 'json',
        processResults: function(data) {
          let isMorePageExist = ( data.total_records - ( useRefData.current.warehousePageNumber * Settings.dropdownPageSize )) >0 ? true : false ; 
          return {
            pagination: {
              more: isMorePageExist
            },
            results: data.data?.map(function(item) {
              return {
                text: `${item.code} - ${item.name}`,
                id: item.warehouse_id
              };
            })
          
          };
        },
        cache: true // You can enable caching for better performance
      }
  }
  
  return (
    <Fragment>
        <FieldsRenderer fieldsArray={textBoxInputSettings} updateState={props.setState} currentState={props.state} />
        <div className='row'>
            <div className='col-xs-12 col-sm-6 col-md-6'>
              <Dropdown
                label={`${Helper.getLabel(props?.language, 'requester', 'Requester')}`}
                options={ props?.state?.requester_id
                  ? [{
                      value: props?.state?.requester_id,
                      label: props?.state?.requester_name ?? employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(props?.state?.requester_id))?.displayname,
                    }]
                  : props?.requester?.map(item => ({
                      value: item.employee_id,
                      label: item.displayname
                  }))}
                placeHolder={`${Helper.getLabel(props?.language, 'select_requester', 'Select requester')}`}
                id='requester_id'
                name='requester_id'
                reRenderRequired={true}
                onChange={handleOnChange}
                value={props?.state?.requester_id}
                pagination={true} 
                apiUrl={Settings?.apiOrgUrl + `/employee_dropdown?status=true`} 
                allDataRef={employeesData}
                mapFunction={mapEmployeesData}
                isDisable={!props?.isEditable}
              />
            </div>
            <div className='col-xs-12 col-sm-6 col-md-6'>
              <Dropdown
              label={`${Helper.getLabel(props?.language, 'orderer', 'Orderer')}`}
              options={props?.state?.orderer_id ? [{
                value: props?.state?.orderer_id,
                label: props?.state?.orderer_name ?? employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(props?.state?.orderer_id))?.displayname,
              }]:props?.ordered?.map((item) => {
                return {
                  value: item.employee_id,
                  label: item.displayname,
                }
              })}
              placeHolder={`${Helper.getLabel(props?.language, 'orderer', 'Select orderer')}`}
              id='orderer_id'
              name='orderer_id'
              onChange={handleOnChange}
              reRenderRequired={true}
              value={props?.state?.orderer_id}
              pagination={true} 
              apiUrl={Settings?.apiOrgUrl + `/employee_dropdown?status=true`} 
              allDataRef={employeesData}
              mapFunction={mapEmployeesData}
              isDisable={!props?.isEditable}
            />
            </div>
              <div className='col-xs-12 col-sm-6 col-md-6'>
                <Dropdown id="entity_po" disableClear={true} label ={Helper.getLabel(props.language,'entity',"Entity")} required ={true} options =  {props.entities} name='entity_id' value={props.state?.entity_id} onChange={onEntityChangeHandler} disable = { !props.isEditable } placeHolder={Helper.getLabel(props.language,'select_entity',"Select entity")}  reRenderRequired={true}/>
              </div>
              <div className='col-xs-12 col-sm-6 col-md-6'>
                <Dropdown pagination={true} apiUrl={Settings?.apiUrl + `/get_site`} additionalParams={{entity_id:props.state?.entity_id}} allDataRef={sitesData} mapFunction={mapSitesData} id="site_id_po" disableClear={true} label ={Helper.getLabel(props.language,'site',"Site")} required ={true} options =  {sitesList} name='site_id' value={props.state?.site_id} onChange={onSiteChangeHandler} disable = { !props.isEditable } placeHolder={Helper.getLabel(props.language,'select_site',"Select site")} reRenderRequired={true}/>
              </div>
              <div className='col-xs-12 col-sm-6 col-md-6'>
                <Dropdown pagination={true} apiUrl={Settings?.apiUrl + `/get_warehouse`} additionalParams={{site_id:props.state?.site_id}} allDataRef={warehouseData} mapFunction={mapWarehouseData} id="swarehouse_id_po" disableClear={true} label ={Helper.getLabel(props.language,'warehouse',"Warehouse")} required ={true} options =  {warehouseList} name='warehouse_id' value={props.state?.warehouse_id} onChange={onWarehouseChangeHandler} disable = { !props.isEditable } placeHolder={Helper.getLabel(props.language,'select_warehouse',"Select warehouse")} reRenderRequired={true}/>
              </div>
              {props?.type==="purchase agreement" && <>
                 <div className='col-xs-12 col-sm-6 col-md-6'>
                 <Dropdown id='pa_classification_id' required={true} label={Helper.getLabel(props.language,'classification',"Classification")} placeHolder={Helper.getLabel(props.language,'select_classification',"Select classification")} options={classificationList} value={props.state?.pa_classification_id} name='pa_classification_id' onChange={handleOnChange} apiUrl={Settings.apiPurchaseOrderUrl+'/PA_classification'} pagination={true} allDataRef={allClassificationsData} mapFunction={mapClassificationsList} reRenderRequired={true}/>
                </div>
                 <div className='col-xs-12 col-sm-6 col-md-6'>
                  <Checkbox isChecked={props.state.pick_startdate_and_enddate_from_lines} label={Helper.getLabel(props.language,'pick_startdate_and_enddate_from_lines',"Pick start date and end date from lines")} id="pick_startdate_and_enddate_from_lines" name='pick_startdate_and_enddate_from_lines' disableWithoutIcon = {!props.isEditable} onChange={checkboxChangeHandler} />
                 </div>
              </>}
              {/* {props.type === "invoice on account"?null:<div className='col-xs-12 col-sm-6 col-md-6'>
                <Checkbox isChecked={props.state.blindreceivingof_qty} label={Helper.getLabel(props.language,'blind_receiving_of_quantity',"Blind receiving of quantity")} id="blindreceivingofqty" name='blindreceivingof_qty' disable = {!props.isEditable} onChange={chckboxChangeHandler} />
              </div>} */}
              {/* <div className='col-xs-12 col-sm-6 col-md-6'>
                <Input value={setupState.external_reference} label={Helper.getLabel(props.language,'external_reference',"External reference")} id="externalreference" name='external_reference' onChange={inputChangeHandler} disable = {!props.isEditable}/>
              </div> */}
        </div>
        <div className='po_start_end_date_section'>
          {props?.visibleStartDate || props?.visibleEndDate ? <h3 className='inline_title_1'>{Helper.getLabel(null,'start_and_end_date','Start and end date')}</h3> : '' }
          
          <div className='row'>
              
            {
              props?.visibleStartDate == true ? <div className='col-xs-12 col-sm-4'><NewInputDatePicker disable={!props.isEditable} label ={Helper.getLabel(props.language,'start_date',"Start date")} name ="start_date" id ="start_date_setup" value = {props.state.start_date}  onChange ={onDateChangeHanlder} /></div> : ''
            }
            
            {props?.visibleEndDate == true ? <div className='col-xs-12 col-sm-4'> <NewInputDatePicker  disable={!props.isEditable} label ={Helper.getLabel(props.language,'end_date',"End date")} name ="end_date" id ="end_date_setup" value = {props.state.end_date}  onChange ={onDateChangeHanlder} /></div> : ''}
          </div>
        </div>
    </Fragment>
  )
}
 

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (SetupTab) 