import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MasterComonent from '../../components/Backend/MasterComonent'
import InnerScreen from '../../components/Inner Screens/InnerScreen'
import Helper from '../../inc/Helper'
import Gui_id_list from '../../inc/Gui_id_list'
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles'
import Alert from '../../components/inc/Alert'
import { getSubdomain } from '../../inc/Validation'

const ProcureScreen = ({ language, security }) => {

  if (!security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_view)) {
    return (
      <MasterComonent>
        <Alert message="You do not have the necessary permissions to access this screen. Please contact your administrator for assistance." type="danger" />
      </MasterComonent>
    );
  }

  const subdomain = getSubdomain()

  const canViewRFQAll = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_rfq_all);
  const canViewRFQOpen = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_rfq_open);
  const canViewRFQVendorReply = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_rfq_vendor_reply);

  const canViewPOAll = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_purchase_order_all);
  const canViewPOOpenReOpen = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_purchase_order_open_reopen_freeze);
  const canViewPOFreeze = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_purchase_order_freeze);
  const canViewPOOnHold = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_purchase_order_on_hold);
  const canViewPOCancelled = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_purchase_order_cancelled);

  const canViewROAll = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_release_order_all);
  const canViewROOpenReOpen = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_release_order_open_reopen_freeze);
  const canViewROFreeze = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_release_order_freeze);
  const canViewROOnHold = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_release_order_on_hold);
  const canViewROCancelled = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_release_order_cancelled);

  const canViewCOAll = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_contract_order_all);
  const canViewCOOpenReOpen = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_contract_order_open_reopen_freeze);
  const canViewCOFreeze = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_contract_order_freeze);
  const canViewCOOnHold = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_contract_order_on_hold);
  const canViewCOCancelled = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_contract_order_cancelled);
  const canViewCOAmendments = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_contract_order_amendments);

  const canViewPAAll = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_purchase_agreement_all);
  const canViewPAOpenReOpen = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_purchase_agreement_open_reopen_freeze);
  const canViewPAFreeze = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_purchase_agreement_freeze);
  const canViewPAOnHold = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_purchase_agreement_on_hold);
  const canViewPACancelled = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_purchase_agreement_cancelled);
  const canViewPAAmendments = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_purchase_agreement_amendments);
  const canViewPAComingRenewal = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_purchase_agreement_coming_renew);
  const canViewPARenew = security?.canView(Gui_id_list.screens.sourcing_screen.main_sourcing_screen_purchase_agreement_renew);

  return (
    <div className="modules_screen_lynkaz">
      <MasterComonent>
        <InnerScreen innerTitle="Sourcing">
            {(canViewRFQAll || canViewRFQOpen || canViewRFQVendorReply) && (
              <div className="col-lg-3 col-md-4 col-6 gy-4">
                <div className="inner-page-header border-bottom">
                  <h2>{Helper.getLabel(language, 'request_for_quotations', 'Request for Quotations')}</h2>
                </div>
                <div className="inner-page-routes">
                  {canViewRFQAll && (
                    <Link to="/request-for-quotation/rfqheaderlist">
                      {Helper.getLabel(language, 'request_for_quotations_all', 'Request for Quotations - All')}
                    </Link>
                  )}
                  {canViewRFQOpen && (
                    <Link to="/request-for-quotation/rfqheaderlist?status=0">
                      {Helper.getLabel(language, 'request_for_quotations_open', 'Request for Quotations - Open')}
                    </Link>
                  )}
                  {canViewRFQVendorReply && (
                    <Link to="/request-for-quotation/rfqheaderlist?vendor_reply=true">
                      {Helper.getLabel(language, 'request_for_quotations_vendor_reply', 'Request for Quotations - Vendor Reply')}
                    </Link>
                  )}
                </div>
              </div>
            )}
 
            {(canViewPOAll || canViewPOOpenReOpen || canViewPOFreeze || canViewPOOnHold || canViewPOCancelled) && (
              <div className="col-lg-3 col-md-4 col-6 gy-4">
                <div className="inner-page-header border-bottom">
                  <h2>{Helper.getLabel(language, 'purchase_orders', 'Purchase Orders')}</h2>
                </div>
                <div className="inner-page-routes">
                  {canViewPOAll && (
                    <Link to="/purchase-order/all">
                      {Helper.getLabel(language, 'purchase_orders_all', 'Purchase Orders - All')}
                    </Link>
                  )}
                  {canViewPOOpenReOpen && (
                    <Link to="/purchase-order/all?status=0,3,6">
                      {Helper.getLabel(language, 'purchase_orders_open_withdraw_freeze', 'Purchase Orders - Open, Reopen, Withdraw Freeze')}
                    </Link>
                  )}
                  {canViewPOFreeze && (
                    <Link to="/purchase-order/all?status=4">
                      {Helper.getLabel(language, 'purchase_orders_freeze', 'Purchase Orders - Freeze')}
                    </Link>
                  )}
                  {canViewPOOnHold && (
                    <Link to="/purchase-order/all?status=5">
                      {Helper.getLabel(language, 'purchase_orders_onhold', 'Purchase Orders - On Hold')}
                    </Link>
                  )}
                  {canViewPOCancelled && (
                    <Link to="/purchase-order/all?status=7">
                      {Helper.getLabel(language, 'purchase_orders_cancelled', 'Purchase Orders - Cancelled')}
                    </Link>
                  )}
                </div>
              </div>
            )}
 
            {(canViewROAll || canViewROOpenReOpen || canViewROFreeze || canViewROOnHold || canViewROCancelled) && (
              <div className="col-lg-3 col-md-4 col-6 gy-4">
                <div className="inner-page-header border-bottom">
                  <h2>{Helper.getLabel(language, 'release_order', 'Release Orders')}</h2>
                </div>
                <div className="inner-page-routes">
                  {canViewROAll && (
                    <Link to="/release-order/all">
                      {Helper.getLabel(language, 'release_order_all', 'Release Orders - All')}
                    </Link>
                  )}
                  {canViewROOpenReOpen && (
                    <Link to="/release-order/all?status=0,3,6">
                      {Helper.getLabel(language, 'release_order_open_withdraw_free', 'Release Orders - Open, Reopen, Withdraw Freeze')}
                    </Link>
                  )}
                  {canViewROFreeze && (
                    <Link to="/release-order/all?status=4">
                      {Helper.getLabel(language, 'release_orders_freeze', 'Release Orders - Freeze')}
                    </Link>
                  )}
                  {canViewROOnHold && (
                    <Link to="/release-order/all?status=5">
                      {Helper.getLabel(language, 'release_orders_on_hold', 'Release Orders - On Hold')}
                    </Link>
                  )}
                  {canViewROCancelled && (
                    <Link to="/release-order/all?status=7">
                      {Helper.getLabel(language, 'release_orders_cancelled', 'Release Orders - Cancelled')}
                    </Link>
                  )}
                </div>
              </div>
            )}
 
            {(canViewCOAll || canViewCOOpenReOpen || canViewCOFreeze || canViewCOOnHold || canViewCOCancelled || canViewCOAmendments) && (
              <div className="col-lg-3 col-md-4 col-6 gy-4">
                <div className="inner-page-header border-bottom">
                  <h2>{Helper.getLabel(language, 'contract_orders', 'Contract Orders')}</h2>
                </div>
                <div className="inner-page-routes">
                  {canViewCOAll && (
                    <Link to="/contract-order/all">
                      {Helper.getLabel(language, 'contract_orders_all', 'Contract Orders - All')}
                    </Link>
                  )}
                  {canViewCOOpenReOpen && (
                    <Link to="/contract-order/all?status=0,3,6">
                      {Helper.getLabel(language, 'contract_orders_open_withdraw_freeze', 'Contract Orders - Open, Reopen, Withdraw Freeze')}
                    </Link>
                  )}
                  {canViewCOFreeze && (
                    <Link to="/contract-order/all?status=4">
                      {Helper.getLabel(language, 'contract_orders_freeze', 'Contract Orders - Freeze')}
                    </Link>
                  )}
                  {canViewCOOnHold && (
                    <Link to="/contract-order/all?status=5">
                      {Helper.getLabel(language, 'contract_orders_on_hold', 'Contract Orders - On Hold')}
                    </Link>
                  )}
                  {canViewCOCancelled && (
                    <Link to="/contract-order/all?status=7">
                      {Helper.getLabel(language, 'contract_orders_cancelled', 'Contract Orders - Cancelled')}
                    </Link>
                  )}
                  {canViewCOAmendments && (
                    <Link to="/contract-order/all?status=10">
                      {Helper.getLabel(language, 'contract_orders_amendments', 'Contract Orders - Amendments')}
                    </Link>
                  )}
                </div>
              </div>
            )}
 
            {(canViewPAAll || canViewPAOpenReOpen || canViewPAFreeze || canViewPAOnHold || canViewPACancelled || canViewPAAmendments || canViewPAComingRenewal || canViewPARenew) && (
              <div className="col-lg-3 col-md-4 col-6 gy-4">
                <div className="inner-page-header border-bottom">
                  <h2>{Helper.getLabel(language, 'purchase_agreements', 'Purchase Agreements')}</h2>
                </div>
                <div className="inner-page-routes">
                  {canViewPAAll && (
                    <Link to="/purchase-agreement/all">
                      {Helper.getLabel(language, 'purchase_agreements_all', 'Purchase Agreements - All')}
                    </Link>
                  )}
                  {canViewPAOpenReOpen && (
                    <Link to="/purchase-agreement/all?status=0,3,6">
                      {Helper.getLabel(language, 'purchase_agreements_open_withdraw_freeze', 'Purchase Agreements - Open, Reopen, Withdraw Freeze')}
                    </Link>
                  )}
                  {canViewPAFreeze && (
                    <Link to="/purchase-agreement/all?status=4">
                      {Helper.getLabel(language, 'purchase_agreements_freeze', 'Purchase Agreements - Freeze')}
                    </Link>
                  )}
                  {canViewPAOnHold && (
                    <Link to="/purchase-agreement/all?status=5">
                      {Helper.getLabel(language, 'purchase_agreements_onhold', 'Purchase Agreements - On Hold')}
                    </Link>
                  )}
                  {canViewPACancelled && (
                    <Link to="/purchase-agreement/all?status=7">
                      {Helper.getLabel(language, 'purchase_agreements_cancelled', 'Purchase Agreements - Cancelled')}
                    </Link>
                  )}
                  {canViewPAAmendments && (
                    <Link to="/purchase-agreement/all?status=10">
                      {Helper.getLabel(language, 'purchase_agreements_amendments', 'Purchase Agreements - Amendments')}
                    </Link>
                  )}
                  {canViewPAComingRenewal && ( // link will be added in future
                    <Link to="/procure">
                      {Helper.getLabel(language, 'purchase_agreements_coming_renewal', 'Purchase Agreements - Coming Renewal')}
                    </Link>
                  )}
                  {canViewPARenew && ( // link will be added in future
                    <Link to="/procure">
                      {Helper.getLabel(language, 'purchase_agreements_renew', 'Purchase Agreements - Renew')}
                    </Link>
                  )}
                </div>
              </div>
            )} 

            {
              <div className="col-lg-3 col-md-4 col-6 gy-4">
                <div className="inner-page-header border-bottom">
                  <h2>{Helper.getLabel(language, 'auction', 'Auction')}</h2>
                </div>
                <div className="inner-page-routes">
                  {(canViewRFQAll || canViewRFQOpen || canViewRFQVendorReply) && (
                    <Link to="/auction">
                      {Helper.getLabel(language, 'auction', 'Auction')}
                    </Link>
                  )}
                </div>
              </div>
            } 
        </InnerScreen>
      </MasterComonent>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { 
    language:state.language
  }
}
 
const SecurityOptions = {
  gui_id: Gui_id_list.screens.sourcing_screen.main_sourcing_screen_view
};

export default connect(mapStateToProps) ((( ApplySecurityRoles( ProcureScreen, SecurityOptions))));