import { Tab } from "bootstrap";
import React, { Component } from "react";
import { Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import Api from "../../../inc/Api";
import User from "../../../inc/Auth/User";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import SimpleLoading from "../../Loading/SimpleLoading";
import NoteExportable from "./NoteExportable";
import NoteItem from "./NoteItem";
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from "../../../inc/Validation";
/**
 * notesource_integrator : Unique id of table
 * source_id : Source id
 */
class Notes extends Component {
  constructor(props) {
    super(props);
    this.user = null
    this.isLoadNotes = false;
    this.state = {
      key: this.props.auth?.user?.usertype===1?'vendor-portal': 'internal',
      isPosting: false,
      noteText: "",
      isLoading: false,
      source_id: this.props.source_id ? this.props.source_id : null, 
      hideVendorPortal: this.props.hideVendorPortal ? true : false,
      notesource_integrator: this.props.notesource_integrator
        ? this.props.notesource_integrator
        : null,
      allNotes: [],
      vendorPortalNotes: [] 
    };
  }
  componentDidMount() {
    this.loadNotes();
    this.user = new User(this.props.auth)
  }
  componentDidUpdate(privProps, prevState) {
    let that = this;
    if (privProps.notesource_integrator != this.props.notesource_integrator || privProps.source_id !== this.props.source_id) {
      this.setState(
        {
          notesource_integrator: this.props.notesource_integrator, 
        },
        function () {
          that.isLoadNotes = false;
          that.loadNotes();
        }
      );
    }
    if(prevState.key != this.state.key){
      that.isLoadNotes = false;
      that.loadNotes();
    }
    if (privProps.source_id !== this.props.source_id) {
      that.setState({
        source_id:this.props.source_id
      })
    }
  }

  createNotes(notesPayload) {
    let api = Api;
    api.setUserToken();

    let that = this;
    that.setState({ isPosting: true });

    api
      .axios()
      .post(Settings.apiUrl + "/note", notesPayload)
      .then(function (res) {
        that.setState({ isPosting: false, noteText: "" },
          function() {
            that.isLoadNotes = false;
            that.loadNotes();
          }
        ); 
      }).catch(error => {
        getPopupMessageBasedOnStatus(error)
      })
  }

  loadNotes() {
    if (this.isLoadNotes) {
      return;
    }
    let api = Api;
    api.setUserToken();
    this.setState({
      isLoading: true,
      allNotes: [],
    });  
    let notUrl = Settings.apiUrl + "/note";
    if (this.state?.source_id && this.state?.notesource_integrator) {
      notUrl =
        Settings.apiUrl +
        "/note/" +
        this.state.source_id +
        "/" +
        this.state.notesource_integrator;
    } else {
      this.setState({
        isLoading: false,
        allNotes: [],
      });
      return;
    } 
    this.isLoadNotes = true;
    let type = this.state.key === 'internal' ? 0 : 1;
    notUrl = notUrl + '?note_type='+ type
    api
      .axios()
      .get(notUrl)
      .then((res) => { 
        if (res.data.status === "success") {
          let allNotes = res.data.data;
          if (Array.isArray(allNotes)) {
            this.setState({
              allNotes: allNotes,
              isLoading: false,
            });
          } else {
            this.setState({
              allNotes: allNotes.notes, 
              notesource_integrator: allNotes.notesource_integrator,
              source_id: allNotes.source_id,
              isLoading: false,
            });
          }
          let noteState = type === 0 ? "isNewInternalNotes" : "isNewExternalNotes";
          this.props.updateNewNotes(noteState, res?.data?.data?.new_note_added);
          
          const notesIds = res?.data?.data?.unread_notes;
          if (res?.data?.data?.notes?.length > 0 && !checkIfArrayIsEmpty(notesIds)) {
            this.handleUnreadNotes(notesIds);
          }
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleUnreadNotes(notesIds){
    const postApiUrl = Settings.apiUrl + '/noteacknowledge'
    const payload = {
       note_id: notesIds,
    }
    let api = Api
    let type = this.state.key === 'internal' ? 0 : 1;
    let notesState = type===0 ? "isNewInternalNotes" : "isNewExternalNotes"
    api
      .axios()
      .post(postApiUrl, payload)
      .then(() => {
        this.props.updateNewNotes(notesState,false)
      })
      .catch((error) => {
      })
  }
  

  displayNotes() {
    if (this.state.isLoading) { return <SimpleLoading /> } 
    return (
      <div className="note_lists common_notes_attachment_scrollbar" style={{height: this.props.isCustomHeight ? (this.props.hideVendorPortal ? `calc(100vh - 325px)` : `calc(100vh - 385px)`) : `${this?.props?.notesHeight - 118.6}px` }}>
        {
          this.state.key === 'internal' ?
          ( this.state.allNotes.map((item, key) => { return <NoteItem note={item} key={key} /> }) )
          :
          (this.state.allNotes.map((item, key) => { return <NoteItem note={item} key={key} /> } ))
        }
      </div>
    );
  }

  onCreateNoteHanlder(e) {
    if (this.state.noteText === "") {
      Helper.alert("Please type note", "error");
      return;
    }  
    let noteData = {};
    {
      noteData.note = this.state.noteText;
      noteData.note_type = this.state.key === 'internal' ? 0 : 1;
      noteData.source_id = this.state.source_id;
      noteData.notesource_integrator = parseInt(this.state.notesource_integrator);

      this.createNotes(noteData);
    } 
  }

  onChangeNote(e) {
    this.setState({
      noteText: e.target.value,
    });
  }

  render() {
    if (this.props.exportable) { return <NoteExportable {...this.props} /> }
    return (
      <div className="notes_widgets">
        <div className="note-inner-tab">
          { !this.user?.isExernalUser() && 
            <Tabs
              id="controlled-tab-example"
              activeKey={this.state.key}
              onSelect={(k) =>  this.setState({ key: k })}
              className="mb-3 note-inner-tab"
            > 
              <Tab eventKey="internal" title={<div className='d-flex align-items-center justify-content-center gap-1'>Internal {this.props.isNewInternalNotes && <div className="attachment-module-highlighter" style={{background:"#FC5E24"}} />} </div>} /> 
              { !this.state.hideVendorPortal && <Tab eventKey="vendor-portal" title={<div className='d-flex align-items-center justify-content-center gap-1'> Vendor portal {this.props.isNewExternalNotes && <div className="attachment-module-highlighter" style={{background:"#FC5E24"}} />} </div>} /> } 
            </Tabs>
          }
        </div>
        <div className="note_form">
          <textarea
            value={this.state.noteText}
            maxLength={255}
            onChange={(e) => this.onChangeNote(e)}
            placeholder={Helper.getLabel(
              null,
              "write_a_new_note",
              "Write a new note"
            )}
          />
          <button onClick={(e) => this.onCreateNoteHanlder(e)}>
            {this.state.isPosting ? (
              <SimpleLoading width="15px" />
            ) : (
              <img src="/images/icons/paper-plane.svg" alt="Submit" />
            )}
          </button>
        </div>
        {this.displayNotes()} 
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language,
    user:state.auth.user,
  }
}

export default connect(mapStateToProps)(Notes); 