import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import AgGrid from '../../grid/ag/ag-grid'
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell'
import Alert from '../../inc/Alert'
import ButtonNew from '../../inc/ButtonNew'
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'

const HeaderAccessLevels = (props) => {

    const [teamLinesAccessLevels, setTeamLinesAccessLevels] = useState([])  
    const [employeeData, setEmployeeData] = useState([])  
    const [teamHeader, setTeamHeader] = useState([])  
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
  
    let teamLinesCalled = false 
    let teamHeaderCalled = false 
    let employeeCalled = false 
 
    useEffect(() => {
        getTeamLinesData() 
        getEmployeesData()
        getTeamHeaderData()
    }, [])
  
    const getTeamLinesData = () => {
      if(teamLinesCalled){
      return;
      }
      let api = Api
      teamLinesCalled = true
      setLoading(true)
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/teamlines',{}).then(function(res){
      if(res.data.status==='success'){   
        setTeamLinesAccessLevels(res.data.data.reverse())
        setLoading(false)
      } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        setLoading(false)
      })
    }

    const getEmployeesData = () => {
        if(employeeCalled){
         return;
        }
        let api = Api
        employeeCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiOrgUrl+'/employee_dropdown?status=true',{}).then(function(res){
         if(res.data.status==='success'){   
            let employeeARR = []
            res.data.data.forEach(item => {  
                employeeARR.push({
                    value: item.employee_id,
                    label: item.displayname
                })
            }) 
            setEmployeeData(employeeARR) 
         } 
       }).catch((res) => { 
         getPopupMessageBasedOnStatus(res)  
       })
    }

    const getTeamHeaderData = () => {
        if(teamHeaderCalled){
         return;
        }
        let api = Api
        teamHeaderCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/teamheader',{}).then(function(res){
         if(res.data.status==='success'){   
            let teamHeaderARR = []
            res.data.data.forEach(item => {  
                teamHeaderARR.push({
                    value: item.teamheader_id,
                    label: item.name
                })
            }) 
            setTeamHeader(teamHeaderARR) 
         } 
       }).catch((res) => { 
         getPopupMessageBasedOnStatus(res)   
       })
    }
   
    const CellHandler = (event) => {
      if(event.node.data.teamLine_id !== ''){ 
      if(event.colDef.field === 'teamLine_id') { 
        navigate(`/edit-teamlines-access-levels/${event.data.teamLine_id}`) 
      }
      }else {
        Helper.alert('Team Line Doesnt Exists!', 'error')
      }
    }
  
    const gridLinkValueFormat = (params) => { 
        return `<div class="rs_grid_simple_link">${params.value ? params.value : '-'}</div>`;
    }
  
    const DeleteParamters = (allRows) => {
        let api = Api
        api.setUserToken() 
        allRows.forEach(element => {
          api.axios().delete(Settings.apiUrl+`/teamheader/${element.teamheader_id}`,{}).then(function(res){
            if(res.data.status==="success"){
              Helper.alert(res.data.message)
              getTeamLinesData()
            } 
          }).catch((res) => { 
            getPopupMessageBasedOnStatus(res) 
            getTeamLinesData()
          })
        })
    }
  
    const Columns_Headings = [
      {field:'teamLine_id',  headerName: Helper.getLabel(props.language,'teamLine_id','Team Line Id'), editable: false, cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat }, 
      {field:'teamheader_id', inputType: 'dropdown', options: teamHeader, headerName: Helper.getLabel(props.language,'teamheader_id','Team Header') , editable: false},
      {field:'employee_id', inputType: 'dropdown', options: employeeData, headerName: Helper.getLabel(props.language,'employee_id','Employee') , editable: false},  
    ]
  
    let security = props.security;
    let frontendIds = Gui_id_list;
    if(!security.canView(Gui_id_list.procure.purchase_orders.control_forms.team_header_access_levels.team_header_access_levels)){
    return <Fragment>
        <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
    }
  
  return (
    <div className='lines_access_levels_archive'>
        <div className='container-fluid'>
            <NavigationHeder backUrl='/procure' title={Helper.getLabel(props.language,'team_header_access_levels','Team Lines Access Levels')}>
                <ButtonNew isDisable={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.team_header_access_levels.team_header_access_levels_main_create_button)} to="/create-teamlines-access-levels" title={Helper.getLabel(props.language,'new','New')} />
            </NavigationHeder> 

            {loading ? <SimpleLoading /> : (
              <div className='lines_access_levels'>
                <AgGrid id="rs_lines_access_levels" hideDelete={!security.canDelete(frontendIds.procure.purchase_orders.control_forms.team_header_access_levels.team_header_access_levels_main_delete_button)} onCellClicked={CellHandler} onDelete={DeleteParamters} header={Columns_Headings} data={teamLinesAccessLevels} addBtnHide={true} hideSave={true} viewOnly={true}/>
              </div> 
            )}
        </div>
    </div> 
  )
}

 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
const SecurityOptions = {
    gui_id:Gui_id_list.procure.purchase_orders.control_forms.team_header_access_levels.team_header_access_levels
}
  
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(HeaderAccessLevels, SecurityOptions))))