import React, { Fragment, useEffect, useRef, useState } from 'react'
import Gui_id_list from '../../../../inc/Gui_id_list'
import { connect } from 'react-redux'
import MasterComponentWraper from '../../../Backend/MasterComponentWraper'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'
import Alert from '../../../inc/Alert'
import NavigationHeder from '../../../Navigations/NavigationHeder' 
import Helper from '../../../../inc/Helper'
import Tabs from '../../../Widgets/Tabs/Tabs'
import Collapse from '../../../inc/Collapse'
import FieldsRenderer from '../../../Purchase Orders Internal/Edit PO/FieldsRenderer' 
import Api from '../../../../inc/Api'
import Settings from '../../../../inc/Settings'
import Button from '../../../inc/Button'
import { useNavigate } from 'react-router-dom'
import { isEmptyObject } from 'jquery'
import { convertObjectValuesToInteger, getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import DatepickerFunctions from '../../../../inc/DatepickerHelper'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import { getNewValue } from '../../../grid/ag/commonFunctions/GridCommonFunctions'
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader'

const BiddingCount = (props) => {
    const [biddingCount, setBiddingCount] = useState({})
 
    const wrapperDiv = useRef();
    const [height, setHeight] = useState(0);

    const [biddingDates, setBiddingDates] = useState([])
    const [questionaireAPI, setQuestionaireAPI] = useState([])
    const [allActionReq, setAllActionReq] = useState([])

    const [loading, setLoading] = useState(false) 
    const navigate = useNavigate()

    // api reduction flag
    let biddingDateAPICalled = false
    let allActionsCalled = false
    
    // api trigger functions
    useEffect(() => {
        getBiddingDatesGrid()
        getAllActionsRequired()
    }, [])

    useEffect(() => { 
        Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
    }, [wrapperDiv?.current?.clientHeight, loading]);

    // api calls functions,
    const getBiddingDatesGrid = () => {
        if(biddingDateAPICalled) return;

        setLoading(true)
        let api = Api
        biddingDateAPICalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/questionnaire?is_active=True',{}).then(function(res){
            if(res.data.status==="success"){   
                let updated_dropdown_data = []
                let updated_grid_data = []
                res.data.data.forEach(element => {
                    updated_dropdown_data.push({
                        value: element.questionaire_id,
                        label: element.description
                    })
                    updated_grid_data.push({
                        ...element,
                        date: DatepickerFunctions.convertDateTimeFromDataBase(element.createddatetime) 
                    })
                });
                setBiddingDates(updated_grid_data)
                setQuestionaireAPI(updated_dropdown_data)
                setLoading(false)
            }
        }).catch((res) => {  
            getPopupMessageBasedOnStatus(res) 
            setLoading(false)
        })
    }

    const getAllActionsRequired = () => {
        if(allActionsCalled) return;
    
        let api = Api
        allActionsCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/ActionRequiredNumberBidNotMet',{}).then(function(res){
            if(res.data.status==="success"){   
                let updated_dropdown_data = []
                res.data.data.forEach(element => {
                    updated_dropdown_data.push({
                        value: element.id,
                        label: element.name
                    })
                });
                setAllActionReq(updated_dropdown_data)  
            }
        }).catch((res) => {  
            console.log(res.response.data.message, 'error')  
        })
    }

    // modules,

    const BidCounting = () => { 
        const gridColumnHeaders = [
            {field:'date', filter: false, sortable: false, headerName:Helper.getLabel(props?.language,'dates',"Dates"), editable: false},
        ];

        const defaultNavs = [
            {title:Helper.getLabel(props?.language,'general','General'), isActive: true } 
        ];

        let dataSource = {
            getRows: async function (params) {
                params?.success({
                    rowData: biddingDates?.map((item) => ({
                        ...item,
                        gridId: `${Date.now()}_${getNewValue()}`
                    })),
                    rowCount: biddingDates?.length
                })
            },
        };
        
        const onSegmentsNumberSequenceReady = (params) => {
            if(params){  
                setTimeout(()=>{ 
                    params?.api?.setGridOption('serverSideDatasource', dataSource);
                }, 200)
            };
        };
  
        return (
            <div className='row'>
                <div className='col-3'>
                    <div className='bidding_count_grid'> 
                        <AgGridNew
                            pagination={false}
                            key={loading?'render':'rerender'}
                            onGridReady={onSegmentsNumberSequenceReady}
                            columnDefs={gridColumnHeaders}
                            allBtnHide={true}
                            hidePaginationFooter={true}
                            height={500}  
                            isDisabledCheckbox={true}
                            gridId={'rs_bidding_dates_grid'}
                        />
                    </div>
                </div>
                <div className='col-9'>
                    <Tabs navs={defaultNavs} tabContantsId='bidding-count-tabs' showAll={false} scrollOffset="300">
                       <Fragment>{BidCountingGeneralModule()}</Fragment> 
                    </Tabs>
                </div>
            </div>
        )
    }

    const BidCountingGeneralModule = () => {
        const textBoxInputSettingsGeneral = [
            {label:Helper.getLabel(props.language,'from_numberofbid',"Bid count from"), className: 'col-lg-4', inputType: "text", name:'from_numberofbid', id:'from_numberofbid'},
            {label:Helper.getLabel(props.language,'to_numberofbid',"Bid count to"), className: 'col-lg-4', inputType: "text", name:'to_numberofbid', id:'to_numberofbid'},
            {label:Helper.getLabel(props.language,'minimumrequired_numberofbid',"Minimum required number of bids"), className: 'col-lg-4', inputType: "text", name:'minimumrequired_numberofbid', id:'minimumrequired_numberofbid'},
            {label:Helper.getLabel(props.language,'actionrequired_numberbidnotmet',"Action required number bid not met"), className: 'col-lg-4', inputType: "dropdown", options: allActionReq, name:'actionrequired_numberbidnotmet', id:'actionrequired_numberbidnotmet'},
            {label:Helper.getLabel(props.language,'questionniare_id',"Questionnaire"), className: 'col-lg-4', inputType: "dropdown", options: questionaireAPI, name:'questionniare_id', id:'questionniare_id'},
            {className:'col-lg-4',label: Helper.getLabel(props.language,'active',"Active"), inputType: "checkbox", id:'is_active', name:'is_active'},
        ]
        return (
            <Collapse open={true} title={Helper.getLabel(props.language,'general',"General")}>
                <FieldsRenderer updateState={setBiddingCount} currentState={biddingCount} fieldsArray={textBoxInputSettingsGeneral} />
            </Collapse>
        )
    }

    // API executions

    const executeCreationAPIForBiddingCount = () => {
        setLoading(true);
        let api = Api 
        api.setUserToken()
        api.axios().post(Settings.apiUrl+'/RFQBidCountPolicy', {...convertObjectValuesToInteger(biddingCount),is_active: biddingCount?.is_active == 'on'}).then(function(res){
            if(res.data.status==="success"){ 
                Helper.alert(res.data.message)  
                getBiddingDatesGrid()
                setLoading(false);
                navigate('/bidding-count-listings')
            }
        }).catch((res) => {  
            getPopupMessageBasedOnStatus(res) 
            setLoading(false);
        })
    }
 
    const SaveHandler = () => {  
        if(isEmptyObject(biddingCount)) return;

        if(!/^\d+$/.test(biddingCount?.from_numberofbid.trim())){
            Helper.alert('Invalid integer entered!', 'error')
            return;
        }
        if(!/^\d+$/.test(biddingCount?.to_numberofbid.trim())){
            Helper.alert('Invalid integer entered!', 'error')
            return;
        }
        if(!/^\d+$/.test(biddingCount?.minimumrequired_numberofbid.trim())){
            Helper.alert('Invalid integer entered!', 'error')
            return;
        }

        executeCreationAPIForBiddingCount();
    }
 
    let security = props.security;
    let frontendIds = Gui_id_list;
    if(!security.canView(Gui_id_list.procure.request_for_quotation.control_forms.bidding_count.bidding_count_create_screen)){
      return <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    }

    return (
        <Fragment>
            { loading ? <OverlayLoader /> : null }
            <div className='container-fluid'>
                <div ref={wrapperDiv}>
                    <NavigationHeder hideMoreBtn={true} backUrl='/bidding-count-listings' title={Helper.getLabel(props.language,'bid_count_policy', "Bid Count Policy")} >
                        <Button isDisable={!security.canCreate(frontendIds.procure.request_for_quotation.control_forms.bidding_count.bidding_count_create_screen_save_button)} isActive= {false} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} className="rfq_save_btn" />
                    </NavigationHeder>
                </div>

                <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowX: 'hidden', overflowY: 'auto'}}>
                    {BidCounting()}
                </div> 
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { 
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
};

const SecurityOptions = { gui_id:Gui_id_list.procure.request_for_quotation.control_forms.bidding_count.bidding_count_create_screen };

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(BiddingCount, SecurityOptions))));