import React, {useState} from 'react';
import './WorkflowHeader.css';
import WorkFlowOffCanvas from './WorkFlowOffCanvas'; 
import { connect } from 'react-redux';
import { checkIfArrayIsEmpty } from '../../inc/Validation';
  
const WorkflowHeaderDropdown = ({ check_dropdown_type, updateApiCallState, apiCallState, executeLogicsBeforeTakeAction, taskActions, executeApiOfTaskAction, createdby, module_item_id, module_id, user, reloadFunction, setEnableFields, source_id, source_item_id, workFlowFunction }) => {
    const [isOpen, setIsOpen] = useState(false);  
    const [currentWorkflowType, setCurrentWorkflowType] = useState({
        label: null,
        value: null
    })
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const handleToggleClick = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (value, label) => {
        setIsOpen(false);
        setCurrentWorkflowType((prevState) => {
            return {
                ...prevState,
                value: value,
                label: label
            }
        });
        if(executeLogicsBeforeTakeAction) {
            if (executeLogicsBeforeTakeAction(value,label)) return;
        };
        setSidebarOpen(true);
    }; 
    
    const handleAssigneeClick = () => { 
        setCurrentWorkflowType((prevState) => {
            return {
                ...prevState,
                value: 43,
                label: "View current assignment"
            }
        }); 
        setSidebarOpen(true)
    };

    const modifyActionTakenLabel = (label) => {
        switch (label) {
            case 'Approve':
                return 'Approved';
            case 'Reject':
                return 'Rejected';
            case 'Request more information':
                return 'Requested more information';
            case 'Complete':
                return 'Completed';
            case 'Delegate':
                return 'Delegated';
            default:
                return label;
        }
    };

    return (
        <div className="custom-dropdown-workflow">
            { checkIfArrayIsEmpty(taskActions) || (checkIfArrayIsEmpty(taskActions?.actions) && checkIfArrayIsEmpty(taskActions?.action_taken)) ? (
                <button className='dropdown-toggle default-drp' type="button" onClick={handleAssigneeClick}> View current assignee </button>
            ) : (
                <button
                    className={`dropdown-toggle ${isOpen ? 'active-drp' : 'default-drp'}`}
                    type="button"
                    onClick={handleToggleClick}
                >
                    {check_dropdown_type ? 'Action Taken' : 'Take Action'}
                    <img className="img-fluid ms-2" src={isOpen ? '/images/icons/up.svg' : '/images/icons/down.svg'} alt="Chevron" />
                </button>
            )}

            {isOpen && (
                <div className="dropdown-menu">
                    {(!checkIfArrayIsEmpty(taskActions.actions) && checkIfArrayIsEmpty(taskActions.action_taken))
                            ? taskActions.actions
                            .filter(option => (
                                option.label !== "Recall" || 
                                (user?.usertype === 0 ? (createdby === user?.user_id || user?.roles?.hasOwnProperty('71') || user?.is_buyer) : createdby === user?.user_id)
                            ))
                            .map((option) => (
                                <button
                                    key={option.value}
                                    type="button"
                                    className="dropdown-item"
                                    disabled={check_dropdown_type}
                                    onClick={() => handleOptionClick(option.value, option.label)}
                                >
                                    {option.label}
                                </button>
                        ))
                        : (checkIfArrayIsEmpty(taskActions.actions) && !checkIfArrayIsEmpty(taskActions.action_taken))
                        ? taskActions.action_taken.map((option) => (
                            <button
                                key={option.value}
                                type="button"
                                className="dropdown-item"
                                disabled={check_dropdown_type}
                                onClick={() => handleOptionClick(option.value, option.label)}
                            >
                                {modifyActionTakenLabel(option?.label)}
                            </button>
                        ))
                        : taskActions
                            .filter(option => { 
                                return option.label !== "Recall" || (createdby === user?.user_id || user?.roles?.hasOwnProperty('71') || user?.is_buyer)
                            })
                            .map((option) => (
                                <button
                                    key={option.value}
                                    type="button"
                                    className="dropdown-item"
                                    disabled={check_dropdown_type}
                                    onClick={() => handleOptionClick(option.value, option.label)}
                                >
                                    {option.label}
                                </button>
                        ))
                    }
                </div>
            )}

            {sidebarOpen && 
                <WorkFlowOffCanvas  
                    source_id={source_id}
                    source_item_id={source_item_id}
                    module_item_id={module_item_id}
                    module_id={module_id}
                    apiCallState={apiCallState}
                    updateApiCallState={updateApiCallState}
                    setEnableFields={setEnableFields}
                    reloadFunction= {reloadFunction}
                    workFlowActionObject={taskActions} 
                    isOpen={sidebarOpen} 
                    type={currentWorkflowType} 
                    closeSidebar={setSidebarOpen} 
                    workFlowFunction={workFlowFunction}
                    executeApiOfTaskAction={executeApiOfTaskAction}
                />
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        user: state.auth.user,
        language:state.language
    }
}

export default  connect (mapStateToProps)  ( WorkflowHeaderDropdown ); 