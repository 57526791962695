import React, { useState,useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../../inc/Api'
import MasterComonent from '../../../Backend/MasterComonent'
import Settings from '../../../../inc/Settings'
import { connect } from 'react-redux'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import Helper from '../../../../inc/Helper'
import InvoiceData from './InvoiceData'
import WorkConfirmationApiCalls from '../../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'
import Button from '../../../inc/Button'
import TotalWidget from '../../../Widgets/TotalWidget/TotalWidget'
import WorkFlowCommonHeaderAPICalls from '../../../Header/WorkflowCommonHeaderAPICalls'
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import WorkFlowCommonHeader from '../../../Header/WorkFlowCommonHeader'


const InvoiceDetails = (props) => {
  const location = useLocation()
  const invoiceApiCalls = new WorkConfirmationApiCalls()

  const [invoiceDetails, setInvoiceDetails] = useState({})
  const [linesData, setLinesData] = useState([])
  const [lineIds, setLineIds] = useState([])
  const [loading, setLoading] = useState(false)
  const [sourceId, setSourceId] = useState(null)
  const [documentStatuses,setDocumentStatuses]=useState([])
  const [documentStatus,setDocumentStatus]=useState('')
  
  // Workflow States
  const [workflowActionHistory, setWorkflowActionHistory] = useState([])
  const [workflowEligible, setWorkflowEligible] = useState(null)
  const [workflowExist, setWorkflowExist] = useState({})

  //function to get params
  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  const invoice_id = getSearchParam('invoice_id');
  const workflow_instance_id = getSearchParam('instance_id');

  const navigate = useNavigate()

  const getInvoiceDetails = () => {
    if (!invoice_id) {
      return
    }
    setLoading(true)
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/invoice_header/${invoice_id}`, {})
      .then(function (res) {
        if (res.data.status === 'success') {
          setInvoiceDetails(res.data.data)
          if (res.data.data.purchaseorder_id) {
            invoiceApiCalls
              .getAllHeaderLines(
                res.data.data.purchaseorder_id,
                Settings.source_id.purchase_order,
              )
              .then((lineId) => {
                if (lineId) setLineIds(lineId)
              })
          }
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const getWorkFlowCalls = async () => { 
    const workflowCalls = new WorkFlowCommonHeaderAPICalls();
    const trigger = await workflowCalls.getWorkFlowTriggers();
    const mode = !checkIfArrayIsEmpty(trigger) && trigger.find(id => id.name == "Update").id;
    const eligibleWorkflow = await workflowCalls.checkWorkFlowForSpecificModule(Settings.source_id.invoice, props?.auth?.user?.tenant_id, mode);
    const workflowExist = await workflowCalls.getWorkFlowInstanceExist(props?.auth?.user?.tenant_id, Settings.source_id.invoice, invoice_id);
 
    setWorkflowExist(workflowExist);
    setWorkflowEligible(eligibleWorkflow);

    const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(Settings.source_id.invoice, invoice_id);
    setWorkflowActionHistory(workflowActionHistory);
  }

  const getSourceId = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + `/source`)
      .then(function (res) {
        if (res.data.status === 'success') {
          const sources = res.data.data
          const filteredSource = sources.find(
            (source) => source.source_name === 'Invoice-LineDetails',
          )
          if (filteredSource) {
            setSourceId(filteredSource.source_id)
          }
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const getAllLines = () => {
    if (!invoice_id || !sourceId || !invoiceDetails?.source_id) {
      return
    }
    setLoading(true)
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(
        Settings.apiPurchaseOrderUrl +
          `/lines/${invoice_id}/${invoiceDetails?.source_id}`,
        {},
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          let updatedData = []
          for (let obj of res.data.data) {
            getLineItemDetails(obj.line_id)
              .then((data) => {
                obj.items = data
                updatedData.push(obj)
              })
              .catch((error) => {
                obj.items = []
                updatedData.push(obj)
              })
          }
          setTimeout(() => {
            setLinesData(updatedData)
            setLoading(false)
          }, 300)
        }
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const getLineItemDetails = (line_id) => {
    return new Promise((resolve, reject) => {
      let api = Api
      api.setUserToken()
      api
        .axios()
        .get(Settings.apiPurchaseOrderUrl + `/lines/${line_id}/${sourceId}`, {})
        .then((res) => {
          if (res.data.status === 'success') {
            resolve(res.data.data)
          } else {
            reject(new Error('API call failed'))
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const getAllInvoiceDocumentStatus =  () => { 
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + `/enum/invoice_header_documentstatus`)
      .then(function (res) {
        if (res.data.status === 'success') {
           setDocumentStatuses(res.data.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }
 
  const handleEditButton = () => {
    navigate(`/invoice/edit/${invoice_id}`,{state:{prevPath:"/invoice-details"}})
  }

  const handleStatusChangeOfDocument = async () => {
    let invoicePayload = { status: 0 };

    setLoading(true);
    let api = Api
    api.setUserToken()
    try {
      const res = await api
        .axios()
        .put(
          Settings.apiPurchaseOrderUrl +
            `/invoice_header/${invoice_id}`,
          invoicePayload,
        )  
      if (res.data.status === 'success') {
        Helper.alert(res.data?.message, 'success');
        getInvoiceDetails();
        setLoading(false);
      }
    } catch(res) {
      getPopupMessageBasedOnStatus(res)
      setLoading(false);
    }
  }
 
  useEffect(() => {
    if(!props?.auth?.user) return;
    getWorkFlowCalls()
  }, [props?.auth?.user])

  useEffect(() => {
    getSourceId()
  }, [])

  useEffect(()=>{
    getAllInvoiceDocumentStatus()
  },[])

  useEffect(() => {
    if (sourceId) {
      getInvoiceDetails()
    }
  }, [sourceId])

  useEffect(() => {
    if (!invoice_id || !sourceId || !invoiceDetails) return
    getAllLines()
  }, [invoice_id, sourceId, invoiceDetails])

  useEffect(() => {
    if (invoiceDetails?.document_status !== undefined) {
      const document_status = documentStatuses?.find(
        (option) => option.id === invoiceDetails.document_status
      );
      setDocumentStatus(document_status?.name);
    }
  }, [invoiceDetails, documentStatus]);
  
  return (
    <div
      className={
        props.user?.usertype === 0
          ? 'work-confirmation-internal-screen external-detail-screens responsive-screen-style'
          : 'request_for_quotation_detail_screen external-detail-screens external-theme responsive-screen-style'
      }
    >
      <MasterComonent>
        <NavigationHeder
          backUrl={location?.state?.prevPath==="/work-confirmation/edit"?"/invoice-list":-1}
          title={`<span style="color:#757575;">${Helper.getLabel(
            props.language,
            'invoice_list',
            'Invoices List',
          )} > </span><span style="color:#313638;">Invoice Details # </span> ${invoiceDetails?.document_number||invoice_id}`}
          hideMoreBtn={true}
        >
           <Button
              onClick={()=>{navigate(-1)}}
              className='black-btn-style'
              title={Helper.getLabel(props.language, 'close', 'Close')}
            />
          {workflowExist?.instance_details?.last_instance_status?.[4] === 'Rejected' && invoiceDetails?.document_status === 3 && (
            <Button
              onClick={handleStatusChangeOfDocument}
              className="blue-btn-style"
              title={Helper.getLabel(props.language, 'edit', 'Edit')} 
            />
          )}
          {invoiceDetails?.document_status === 0 && (
            <Button
              onClick={handleEditButton}
              className="blue-btn-style"
              title={Helper.getLabel(props.language, 'edit', 'Edit')}
            />
          )}
          <TotalWidget source_id={invoiceDetails?.source_id} integrator={invoiceDetails?.invoice_id} ></TotalWidget>
          {/* <Button
            title={Helper.getLabel(
              props.language,
              'view_invoices',
              'View Invoices',
            )}
          /> */}
        </NavigationHeder>

        {workflowExist?.instance_exist && workflowEligible && props.auth?.user?.usertype===0 && !loading &&
          <div className='invoice_workflow'>
            <WorkFlowCommonHeader
              instance_task_id={workflow_instance_id}
              workflowInstanceDetails={workflowExist?.instance_details}
              module_id={!workflow_instance_id ? Settings.source_id.invoice : null}
              source_id={Settings.source_id.invoice}
              source_item_id={invoice_id}
              SubmitWorkflowObjDetails={workflowExist?.workflow_name}
              module_item_id={!workflow_instance_id ? invoice_id : null}
              workflowInstanceExist={workflowExist?.instance_exist}
              workflowEligible={workflowEligible}
              workflowName={'Invoice Workflow'}
              reloadFunction={() => {
                getWorkFlowCalls();
                getInvoiceDetails();
              }}
            />
          </div>
        }

        <div className="tabs_default internal-tabs">
           {<InvoiceData
            workflowActionHistory={workflowActionHistory}
            shipment_id={invoice_id}
            language={props.language}
            invoiceDetails={invoiceDetails}
            linesData={linesData}
            lineIdOptions={lineIds}
            getAllLines={getAllLines}
            isLoading={loading}
            userType={props.user?.usertype}
            documentStatuses={documentStatuses}
            document_status={invoiceDetails?.document_status}
          />}
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(InvoiceDetails)
